/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import RootAction from '../../../config/store/RootAction';
import RootState from '../../../config/store/RootState';
import { IErrorActionData } from '../../../models/Error';
import {
  GetDealDesignListAction, GetDealDesignListActionTypes, getDealDesignListFailure, getDealDesignListSuccess,
} from '../actions/GetDealDesignList';
import getDealDesignListService from '../services/GetDealDesignList';

const GetDealDesignListEpic = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  filter(isOfType(GetDealDesignListActionTypes.GET_DEAL_DESIGN_LIST)),
  mergeMap((action: GetDealDesignListAction) => from(
    getDealDesignListService(action.payload.filter),
  ).pipe(
    map((response: any) => getDealDesignListSuccess({ dealDesignList: response.data.dealDesignList.models })),
    catchError((error: IErrorActionData) => of(
      getDealDesignListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDealDesignListEpic;
