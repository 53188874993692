import React, { FunctionComponent } from 'react';
import {
  Avatar, Box, Button, Paper,
} from '@mui/material';
import clsx from 'clsx';

import IMInfoPair from '../IMInfoPair';
import useStyles from './styles';

type ClassType = {
  container?: string;
  title?: string;
  subtitle?: string;
  leftIcon?: string;
  leftIconContainer?: string;
  leftAvatar?: string;
  rightIcon?: string;
  rightIconLabel?: string;
};

export interface RightIconBtn {
  id?: string;
  icon?: React.ElementType;
  disabled?: boolean;
  action?: () => void;
  title?: string;
  variant?: 'text' | 'outlined' | 'contained';
}

export interface LeftIcon {
  icon?: React.ElementType;
  variant?: 'square' | 'circular';
  avatarUrl?: string;
  disabled?: boolean;
}

interface IInfoListItemProps {
  id: string;
  title: string;
  subtitle?: string;
  showTitleToolTip?: boolean;
  showSubtitleToolTip?: boolean;
  variant?: 'outlined' | 'elevation',
  leftIconProps?: LeftIcon;
  hideLeftIcon?: boolean;
  hideRightIcon?: boolean;
  rightIconProps?: RightIconBtn;
  clickable?: boolean;
  onClick?: () => void;
  classes?: ClassType;
}

const IMInfoListItem: FunctionComponent<IInfoListItemProps> = (props) => {
  const classes = useStyles();
  const LeftIcon = props.leftIconProps?.icon;
  const RightIcon = props.rightIconProps?.icon;

  return (
    <Paper
      variant={props.variant || 'outlined'}
      onClick={props.clickable ? props.onClick : undefined}
      className={clsx(classes.container, props.classes?.container, props.clickable && classes.clickableMousePointer)}
    >
      { !props.hideLeftIcon
        && (
        <Box className={clsx(classes.leftIconContainer, props.classes?.leftIconContainer)}>
          { LeftIcon && <LeftIcon disable={props.leftIconProps?.disabled} className={clsx(classes.icon, props.classes?.leftIcon)} />}
          { props.leftIconProps?.avatarUrl && (
            <Avatar
              variant={props.leftIconProps.variant || 'circular'}
              src={props.leftIconProps.avatarUrl}
              className={clsx(classes.avatar, props.classes?.leftAvatar)}
            />
          )}
        </Box>
        )}
      <IMInfoPair
        id={props.id}
        title={props.title || '-'}
        subtitle={props.subtitle || ''}
        showTitleToolTip={props.showTitleToolTip}
        showSubtitleToolTip={props.showSubtitleToolTip}
        classes={{
          title: props.classes?.title,
          subtitle: props.classes?.subtitle,
          container: classes.titleContainer,
        }}
      />
      { !props.hideRightIcon && (RightIcon || props.rightIconProps?.title) && (
        <Button
          id={props.rightIconProps?.id}
          disabled={props.rightIconProps?.disabled}
          variant={props.rightIconProps?.variant || 'text'}
          startIcon={RightIcon && <RightIcon className={clsx(classes.rightIcon, props.classes?.rightIcon)} />}
          onClick={props.rightIconProps?.action}
          className={clsx(classes.rightIconLabel, props.classes?.rightIconLabel)}
        >
          {props.rightIconProps?.title || ''}
        </Button>
      )}
    </Paper>
  );
};

export default IMInfoListItem;
