import { Reducer } from 'redux';

import { AdditionalCashList, mergeTwoLists } from '../models/AdditionalCashList';
import { GetAdditionalCashListActionTypes, GetAdditionalCashListActions } from '../actions/GetAdditionalCashList';
import { IErrorActionData } from '../../../models/Error';

export interface IAdditionalCashListState {
  additionalCashList: AdditionalCashList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialOrderListState = {
  additionalCashList: new AdditionalCashList([]),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IAdditionalCashListState;

const GetAdditionalCashListReducer: Reducer<IAdditionalCashListState, GetAdditionalCashListActions> = (
  state = initialOrderListState,
  action: GetAdditionalCashListActions,
): IAdditionalCashListState => {
  switch (action.type) {
    case GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST_SUCCESS: {
      const newAdditionalCashList = mergeTwoLists(
        state.additionalCashList.additionalCashList,
        action.payload.additionalCashList,
      );
      return {
        ...state,
        additionalCashList: new AdditionalCashList(newAdditionalCashList),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST_RESET:
      return initialOrderListState;

    default:
      return state;
  }
};

export default GetAdditionalCashListReducer;
