import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IEDCMachineList } from '../models/EDCMachineList';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum GetEDCMachineListActionTypes {
  GET_EDC_MACHINE_LIST = 'GET_EDC_MACHINE_LIST',
  GET_EDC_MACHINE_LIST_SUCCESS = 'GET_EDC_MACHINE_LIST_SUCCESS',
  GET_EDC_MACHINE_LIST_FAILED = 'GET_EDC_MACHINE_LIST_FAILED',
  GET_EDC_MACHINE_LIST_RESET = 'GET_EDC_MACHINE_LIST_RESET',
}

export enum AvailableFilterProperty {
  storeAliasId = 'storeAliasId',
}

export interface IGetEDCMachineListActionData {
  filter: IFilter[];
}

export interface IGetEDCMachineListSuccessActionData {
  data: IEDCMachineList;
}

export const getEDCMachineList = createAction(GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST)
<IGetEDCMachineListActionData>();
export const getEDCMachineListSuccess = createAction(GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST_SUCCESS)
<IGetEDCMachineListSuccessActionData>();
export const getEDCMachineListFailure = createAction(GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST_FAILED)
<IErrorActionData>();
export const getEDCMachineListReset = createAction(GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST_RESET)();

export type GetEDCMachineListAction = ActionType<typeof getEDCMachineList>;
type GetEDCMachineListSuccessAction = ActionType<typeof getEDCMachineListSuccess>;
type GetEDCMachineListFailureAction = ActionType<typeof getEDCMachineListFailure>;
type GetEDCMachineListResetAction = ActionType<typeof getEDCMachineListReset>;

export type GetEDCMachineListActions = GetEDCMachineListAction
| GetEDCMachineListSuccessAction
| GetEDCMachineListFailureAction
| GetEDCMachineListResetAction;
