import { Reducer } from 'redux';

import { DeleteAddressActionTypes, DeleteAddressActions } from '../actions/DeleteAddress';
import { IErrorActionData } from '../../../models/Error';

export interface IDeleteAddressState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDeleteAddressState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDeleteAddressState;

const DeleteAddressReducer: Reducer<IDeleteAddressState, DeleteAddressActions> = (
  state = initialDeleteAddressState,
  action: DeleteAddressActions,
): IDeleteAddressState => {
  switch (action.type) {
    case DeleteAddressActionTypes.DELETE_ADDRESS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case DeleteAddressActionTypes.DELETE_ADDRESS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case DeleteAddressActionTypes.DELETE_ADDRESS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case DeleteAddressActionTypes.DELETE_ADDRESS_RESET:
      return initialDeleteAddressState;

    default:
      return state;
  }
};

export default DeleteAddressReducer;
