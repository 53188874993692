import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { constructPermission } from '../../../utils/common';
import { GetUserProfileActions, GetUserProfileActionTypes } from '../actions/GetUserProfile';
import { IUserProfileData, UserProfileData } from '../models/UserProfile';

export interface IGetUserProfileState {
  userProfileData: UserProfileData;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialGetUserProfileState: IGetUserProfileState = {
  userProfileData: new UserProfileData('', {} as IUserProfileData),
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const GetUserProfileReducer: Reducer<IGetUserProfileState, GetUserProfileActions> = (
  state = initialGetUserProfileState,
  action: GetUserProfileActions,
): IGetUserProfileState => {
  switch (action.type) {
    case GetUserProfileActionTypes.GET_USER_PROFILE_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetUserProfileActionTypes.GET_USER_PROFILE_ACTION_SUCCESS:
      return {
        ...state,
        userProfileData: new UserProfileData(
          action.payload.authorisationToken,
          action.payload.userData,
          constructPermission(action.payload.permissions),
        ),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetUserProfileActionTypes.GET_USER_PROFILE_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetUserProfileActionTypes.GET_USER_PROFILE_ACTION_RESET:
      return initialGetUserProfileState;

    default:
      return state;
  }
};

export default GetUserProfileReducer;
