import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { IInfluencerDetails, InfluencerDetails } from '../models/InfluencerDetails';
import { GetInfluencerDetailsActionTypes, GetInfluencerDetailsActions } from '../actions/GetInfluencerDetails';

export interface IGetInfluencerDetailsState {
  influencerDetails: InfluencerDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialInfluencerDetailsState = {
  influencerDetails: new InfluencerDetails({} as IInfluencerDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetInfluencerDetailsState;

const GetInfluencerDetailsReducer: Reducer<IGetInfluencerDetailsState, GetInfluencerDetailsActions> = (
  state = initialInfluencerDetailsState,
  action: GetInfluencerDetailsActions,
): IGetInfluencerDetailsState => {
  switch (action.type) {
    case GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS_SUCCESS:
      return {
        ...state,
        influencerDetails: new InfluencerDetails(action.payload.data),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        influencerDetails: new InfluencerDetails({} as IInfluencerDetails),
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS_RESET:
      return initialInfluencerDetailsState;

    default:
      return state;
  }
};

export default GetInfluencerDetailsReducer;
