import { Reducer } from 'redux';

import { GetCartSummaryActions, GetCartSummaryActionTypes } from '../actions/GetCartSummary';
import { IErrorActionData } from '../../../models/Error';
import { ICartSummary, CartSummary } from '../models/CartSummary';

export interface IGetCartSummaryState {
  cartSummary: CartSummary;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetCartSummaryState = {
  cartSummary: new CartSummary({} as ICartSummary),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetCartSummaryState;

const GetCartSummaryReducer: Reducer<IGetCartSummaryState, GetCartSummaryActions> = (
  state = initialGetCartSummaryState,
  action: GetCartSummaryActions,
): IGetCartSummaryState => {
  switch (action.type) {
    case GetCartSummaryActionTypes.GET_CART_SUMMARY:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCartSummaryActionTypes.GET_CART_SUMMARY_SUCCESS:
      return {
        ...state,
        cartSummary: new CartSummary(action.payload.cartSummary),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetCartSummaryActionTypes.GET_CART_SUMMARY_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCartSummaryActionTypes.GET_CART_SUMMARY_RESET:
      return initialGetCartSummaryState;

    default:
      return state;
  }
};

export default GetCartSummaryReducer;
