import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum DeleteCartItemActionTypes {
  DELETE_CART_ITEM = 'DELETE_CART_ITEM',
  DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS',
  DELETE_CART_ITEM_FAILED = 'DELETE_CART_ITEM_FAILED',
  DELETE_CART_ITEM_RESET = 'DELETE_CART_ITEM_RESET',
}

interface IDeleteCartItemActionData {
  cartAliasId: string;
  cartItemAliasId: string;
}

export const deleteCartItem = createAction(DeleteCartItemActionTypes.DELETE_CART_ITEM)<IDeleteCartItemActionData>();
export const deleteCartItemSuccess = createAction(DeleteCartItemActionTypes.DELETE_CART_ITEM_SUCCESS)();
export const deleteCartItemFailure = createAction(DeleteCartItemActionTypes.DELETE_CART_ITEM_FAILED)<IErrorActionData>();

export const deleteCartItemReset = createAction(DeleteCartItemActionTypes.DELETE_CART_ITEM_RESET)();

export type DeleteCartItemAction = ActionType<typeof deleteCartItem>;
type DeleteCartItemSuccessAction = ActionType<typeof deleteCartItemSuccess>;
type DeleteCartItemFailureAction = ActionType<typeof deleteCartItemFailure>;
type DeleteCartItemResetAction = ActionType<typeof deleteCartItemReset>;

export type DeleteCartItemActions = DeleteCartItemAction
| DeleteCartItemSuccessAction
| DeleteCartItemFailureAction
| DeleteCartItemResetAction;
