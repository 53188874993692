interface IPersonDetails {
  lastInteractionTs: number;
  name: string;
  userAliasId: string;
}

interface IBasicDealDetails {
  creatorDetails: IPersonDetails;
  currentAssigneeDetails: IPersonDetails;
  dealAliasId: string;
  dealCompletion: string;
  dealStatus: {
    reason: string;
    state: string;
  };
  dealValidUntilTs: number;
  info: {
    remarks: string;
  };
  lastInteraction: {
    date: number;
    type: string;
  };
  potentialAmount: number;
  potentialQuantity: {
    quantity: number;
    skuMeasurementUnitAliasId: string;
  };
  productCategoryDetails: {
    productCategoryAliasId: string;
    productCategoryName: string;
  };
}

interface ICustomerDetails {
  customerAliasId: string;
  name: string;
  phoneNumber: string;
  gstin: string;
  customerType?: string;
}

interface IAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  pincode: string;
  state: string;
}

interface ISiteDetails {
  address: IAddress;
  name: string;
  siteAliasId: string;
}

interface IProjectDetails {
  constructionType: string;
  projectCity: string;
  projectIdentifier: string;
  projectName: string;
  stageOfConstruction: string;
}

export interface IDealDetails {
  dealDetails: IBasicDealDetails;
  customerDetails: ICustomerDetails;
  projectDetails: IProjectDetails;
  siteDetails: ISiteDetails;
}

export class DealDetails {
  dealDetails = {} as IDealDetails;
  constructor(dealDetails: IDealDetails) {
    this.dealDetails = dealDetails;
  }
}
