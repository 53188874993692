import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { INewAddressPayload } from '../models/NewAddress';

const CREATE_ADDRESS = gql`
  query createAddress($path: String!, $payload: any) {
    createAddress (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "photon") {
        data
      }
  }
`;

const createAddressService = (payload: INewAddressPayload, customerId: string) => client.query(
  {
    query: CREATE_ADDRESS,
    variables: {
      path: URL.createAddress.replace('{customerId}', customerId),
      payload,
    },
  },
);

export default createAddressService;
