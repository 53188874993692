/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  filter, map, catchError, mergeMap,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import {
  GetDealListActions,
  GetDealListActionTypes,
  getDealListSuccess,
  getDealListFailure,
  GetDealListAction,
} from '../actions/GetDealList';
import GetDealListServices from '../services/GetDealList';

const GetDealListEpic = (action$: ActionsObservable<GetDealListActions>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDealListActionTypes.GET_DEAL_LIST)),
  mergeMap((action: GetDealListAction) => from(GetDealListServices(action.payload.filterValues, action.payload.page)).pipe(
    map((response) => getDealListSuccess({
      data: response.data.getDealList.data || [],
      pageInfo: response.data.getDealList.pageInfo || {},
    })),
    catchError((error: any) => of(
      getDealListFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDealListEpic;
