import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { INewCartItemDetails } from '../models/NewCartItemDetails';

export enum AddCartItemActionTypes {
  ADD_CART_ITEM = 'ADD_CART_ITEM',
  ADD_CART_ITEM_SUCCESS = 'ADD_CART_ITEM_SUCCESS',
  ADD_CART_ITEM_FAILED = 'ADD_CART_ITEM_FAILED',
  ADD_CART_ITEM_RESET = 'ADD_CART_ITEM_RESET',
}

interface IAddCartItemActionData {
  newCartItem: INewCartItemDetails;
  cartAliasId: string;
}

interface IAddCartItemSuccessActionData {
  newCartItemDetails: INewCartItemDetails;
}

export const addCartItem = createAction(AddCartItemActionTypes.ADD_CART_ITEM)<IAddCartItemActionData>();
export const addCartItemSuccess = createAction(
  AddCartItemActionTypes.ADD_CART_ITEM_SUCCESS,
)<IAddCartItemSuccessActionData>();
export const addCartItemFailure = createAction(
  AddCartItemActionTypes.ADD_CART_ITEM_FAILED,
)<IErrorActionData>();

export const addCartItemReset = createAction(AddCartItemActionTypes.ADD_CART_ITEM_RESET)();

export type AddCartItemAction = ActionType<typeof addCartItem>;
type AddCartItemSuccessAction = ActionType<typeof addCartItemSuccess>;
type AddCartItemFailureAction = ActionType<typeof addCartItemFailure>;
type AddCartItemResetAction = ActionType<typeof addCartItemReset>;

export type AddCartItemActions = AddCartItemAction
| AddCartItemSuccessAction
| AddCartItemFailureAction
| AddCartItemResetAction;
