export interface INewCustomer {
  id: number;
  phoneNumber: string;
  createdAt?: string;
  createdBy?: number;
  deletedAt?: null,
  email?: string;
  firstName?: string;
  gst?: string;
  info?: null,
  lastName?: string;
  modifiedBy?: number;
  updatedAt?: string;
}

export class NewCustomer {
  newCustomer = {} as INewCustomer;
  constructor(newCustomer: INewCustomer) {
    this.newCustomer = newCustomer;
  }
}
