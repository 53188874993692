/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ActionsObservable, StateObservable } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { from, of } from 'rxjs';
import _ from 'lodash';

import {
  FetchRemoteConfig,
  FetchRemoteConfigActionTypes,
  fetchRemoteConfigFailure,
  fetchRemoteConfigSuccess,
} from '../actions/fetchRemoteConfig';
import { IErrorActionData } from '../../../models/Error';
import fetchRemoteConfig from '../services/fetchRemoteConfig';
import RootState from '../../../config/store/RootState';

const fetchRemoteConfigEpic = (action$: ActionsObservable<FetchRemoteConfig>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG)),
  mergeMap((action: FetchRemoteConfig) => from(fetchRemoteConfig()).pipe(
    map((response) => {
      const formattedData = _.mapKeys(response, (value, key) => _.camelCase(key));
      return fetchRemoteConfigSuccess(formattedData);
    }),
    catchError((error: IErrorActionData) => of(
      fetchRemoteConfigFailure({
        errorMessage: error.errorMessage,
        errorCode: error.errorCode,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default fetchRemoteConfigEpic;
