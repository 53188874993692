import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_DISPLAY_INVENTORY_SUMMARY = gql`
  query getDisplayInventorySummary($path: String!) {
    getDisplayInventorySummary @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getDisplayInventorySummaryService = (storeAliasId: string) => {
  const url = URL.getDisplayInventorySummary.replace('{storeAliasId}', storeAliasId);
  return client.query({ query: GET_DISPLAY_INVENTORY_SUMMARY, variables: { path: url } });
};

export default getDisplayInventorySummaryService;
