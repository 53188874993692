import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GENERATE_QUOTATION = gql`
  query generateQuotation($path: String!, $payload: any) {
    generateQuotation (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const generateQuotationService = (cartAliasId: string, customerId: number, storeAliasId: string) => {
  const url = URL.generateQuotation.replace('{cartAliasId}', cartAliasId);
  return client.query({
    query: GENERATE_QUOTATION,
    variables:
    { path: url, payload: { customerId, storeAliasId } },
  });
};

export default generateQuotationService;
