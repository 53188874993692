import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IStore } from '../models/StoreList';

export enum GetStoreListActionTypes {
  GET_SORE_LIST_ACTION = 'GET_SORE_LIST_ACTION',
  GET_SORE_LIST_ACTION_SUCCESS = 'GET_SORE_LIST_ACTION_SUCCESS',
  GET_SORE_LIST_ACTION_FAILURE = 'GET_SORE_LIST_ACTION_FAILURE',
  GET_SORE_LIST_ACTION_RESET = 'GET_SORE_LIST_ACTION_RESET',
}

export interface IGetStoreListSuccessActionData {
  storeListData: IStore[];
  pageInfo: IPageInfo;
}

export interface IGetStoreListActionData {
  page: IPage;
}

export interface ISetSelectedStoreActionData {
  storeId: string;
}

export const getStoreList = createAction(GetStoreListActionTypes.GET_SORE_LIST_ACTION)<IGetStoreListActionData>();
export const getStoreListSuccess = createAction(GetStoreListActionTypes.GET_SORE_LIST_ACTION_SUCCESS)
<IGetStoreListSuccessActionData>();
export const getStoreListFailure = createAction(GetStoreListActionTypes.GET_SORE_LIST_ACTION_FAILURE)<IErrorActionData>();
export const getStoreListReset = createAction(GetStoreListActionTypes.GET_SORE_LIST_ACTION_RESET)();

export type GetStoreListAction = ActionType<typeof getStoreList>;
type GetStoreListSuccessAction = ActionType<typeof getStoreListSuccess>;
type GetStoreListFailureAction = ActionType<typeof getStoreListFailure>;
type GetStoreListResetAction = ActionType<typeof getStoreListReset>;

export type GetStoreListActions =
  | GetStoreListAction
  | GetStoreListSuccessAction
  | GetStoreListFailureAction
  | GetStoreListResetAction;
