import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const UPDATE_PAYMENT = gql`
query updatePayment($path: String!, $payload: any) {
  updatePayment(path: $path, payload: $payload) 
    @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
      data
    }
  }
`;

const updatePaymentService = (orderReferenceId: string, paymentAliasId: string, action: string) => {
  const url = URL.updatePayment.replace('{orderReferenceId}', orderReferenceId);
  return client.query({ query: UPDATE_PAYMENT, variables: { path: url, payload: { paymentAliasId, action } } });
};

export default updatePaymentService;
