import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IUpdateCartItemPayload } from '../models/UpdateCartItem';

export enum UpdateCartItemActionTypes {
  UPDATE_CART_ITEM = 'UPDATE_CART_ITEM',
  UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS',
  UPDATE_CART_ITEM_FAILED = 'UPDATE_CART_ITEM_FAILED',
  UPDATE_CART_ITEM_RESET = 'UPDATE_CART_ITEM_RESET',
}

interface IUpdateCartItemActionData {
  updateCartItem: IUpdateCartItemPayload;
  cartAliasId: string;
  cartItemAliasId: string;
}

export const updateCartItem = createAction(UpdateCartItemActionTypes.UPDATE_CART_ITEM)<IUpdateCartItemActionData>();
export const updateCartItemSuccess = createAction(UpdateCartItemActionTypes.UPDATE_CART_ITEM_SUCCESS)();
export const updateCartItemFailure = createAction(
  UpdateCartItemActionTypes.UPDATE_CART_ITEM_FAILED,
)<IErrorActionData>();

export const updateCartItemReset = createAction(UpdateCartItemActionTypes.UPDATE_CART_ITEM_RESET)();

export type UpdateCartItemAction = ActionType<typeof updateCartItem>;
type UpdateCartItemSuccessAction = ActionType<typeof updateCartItemSuccess>;
type UpdateCartItemFailureAction = ActionType<typeof updateCartItemFailure>;
type UpdateCartItemResetAction = ActionType<typeof updateCartItemReset>;

export type UpdateCartItemActions = UpdateCartItemAction
| UpdateCartItemSuccessAction
| UpdateCartItemFailureAction
| UpdateCartItemResetAction;
