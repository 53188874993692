import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IOrderPaymentPayload } from '../models/OrderPayment';

export enum CreateOrderPaymentActionTypes {
  CREATE_ORDER_PAYMENT = 'CREATE_ORDER_PAYMENT',
  CREATE_ORDER_PAYMENT_SUCCESS = 'CREATE_ORDER_PAYMENT_SUCCESS',
  CREATE_ORDER_PAYMENT_FAILURE = 'CREATE_ORDER_PAYMENT_FAILURE',
  CREATE_ORDER_PAYMENT_RESET = 'CREATE_ORDER_PAYMENT_RESET',
}

export interface ICreateOrderPaymentActionData {
  orderReferenceId: string;
  paymentPayload: IOrderPaymentPayload;
}

export const createOrderPayment = createAction(CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT)<ICreateOrderPaymentActionData>();
export const createOrderPaymentSuccess = createAction(CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT_SUCCESS)();
export const createOrderPaymentFailure = createAction(CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT_FAILURE)<IErrorActionData>();
export const createOrderPaymentReset = createAction(CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT_RESET)();

export type CreateOrderPaymentAction = ActionType<typeof createOrderPayment>;
type CreateOrderPaymentSuccessAction = ActionType<typeof createOrderPaymentSuccess>;
type CreateOrderPaymentFailureAction = ActionType<typeof createOrderPaymentFailure>;
type CreateOrderPaymentResetAction = ActionType<typeof createOrderPaymentReset>;

export type CreateOrderPaymentActions =
  | CreateOrderPaymentAction
  | CreateOrderPaymentSuccessAction
  | CreateOrderPaymentFailureAction
  | CreateOrderPaymentResetAction;
