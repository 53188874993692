/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  editOrderSuccess,
  editOrderFailure,
  EditOrderAction,
  EditOrderActionTypes,
} from '../actions/EditOrder';
import editOrderService from '../services/EditOrder';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const EditOrderEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(EditOrderActionTypes.EDIT_ORDER_ACTION)),
  mergeMap((action: EditOrderAction) => from(
    editOrderService(action.payload.orderReferenceId),
  ).pipe(
    map((response: any) => (editOrderSuccess({ cartAliasId: response.data.editOrder.data.cartAliasId }))),
    catchError((error: any) => of(
      editOrderFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default EditOrderEpic;
