const URL = {
  login: '/users/login/v2',
  autoLogin: '/users/login/auto/v2',
  verifyOtp: '/users/otp/verify/v2',
  resendOTP: '/users/otp/resend/v2',
  logout: '/users/logout/v2',
  getStoreList: '/stores/list-basic/v1',
  getStoreDetails: '/stores/{storeAliasId}/v1',
  createCustomer: '/pn/customers/v1',
  getCustomerDetailsList: '/pn/customers/{customerId}/v1',
  updateCustomerDetails: '/pn/customers/{customerId}/v1',
  createAddress: '/pn/customers/{customerId}/address/v1',
  createProject: ' ', // TODO:- Add endpoint later
  getCustomerAddress: '/pn/customers/{customerId}/address/{addressId}/v1',
  getAddressList: '/pn/customers/{customerId}/address/v1',
  getPincodeInfo: '/location/pincode_address/v1',
  getProductList: '/products/v4',
  getSavedCartItemList: '/carts/cart/v1',
  getCustomerSavedCartListCount: '/carts/cart/v1',
  createCart: '/carts/cart/v1',
  cartSummary: '/carts/cart/{cartAliasId}/v1',
  cancelCart: '/carts/cart/{cartAliasId}/v1',
  addCartItem: '/carts/cart/{cartAliasId}/items/v1',
  getCartItemList: '/carts/cart/{cartAliasId}/items/v1',
  updateCartItem: '/carts/cart/{cartAliasId}/items/{cartItemAliasId}/v1',
  deleteCartItem: '/carts/cart/{cartAliasId}/items/{cartItemAliasId}/v1',
  getCartState: '/carts/cart/{cartAliasId}/state/v1',
  createOrder: '/carts/order/v2',
  getOrderDetails: '/oms/fofo/cpo/{orderReferenceId}/v1',
  getOrderList: '/oms/fofo/cpo/v1',
  cancelOrder: '/oms/fofo/cpo/{orderReferenceId}/cancel/v1',
  getSupplyOrderList: '/oms/fofo/cso/v2',
  createOrderPayment: '/oms/fofo/cpo/{orderReferenceId}/payment/v1',
  getOrderPaymentDetails: '/oms/fofo/cpo/{orderReferenceId}/payment/v1',
  updatePayment: '/oms/fofo/cpo/{orderReferenceId}/awaiting-payment-action/v1',
  getProformaInvoiceUrl: '/invoices/proforma/{invoiceAliasId}/download',
  generateInvoice: '/oms/fofo/generate-invoice',
  saveCart: '/orders/cart/{cartAliasId}/v1',
  generateQuotation: '/carts/cart/{cartAliasId}/quotation/v1',
  getQuotationUrl: '/quotations/quotation/{quotationAliasId}/download/v3',
  createInfluencer: '/influencers/v1',
  updateInfluencer: '/influencers/{influencerAliasId}/v1',
  editSKUDeliveryDate: '/oms/fofo/cpo/{orderReferenceId}/sku/{skuId}/v1',
  getStoreEmployeeList: '/users/store/{storeAliasId}/v2',
  addCartItemDiscount: '/carts/cart/{cartAliasId}/items/{cartItemAliasId}/discount/v1',
  deleteCartItemDiscount: '/carts/cart/{cartAliasId}/items/{cartItemAliasId}/discount/v1',
  dispatchSupplyOrder: '/oms/fofo/cso/{supplyOrderId}/dispatch/v2',
  editOrder: '/carts/order/{orderReferenceId}/v1',
  startProcessingOrder: '/oms/fofo/cpo/{orderReferenceId}/start-processing/v2',
  startProcessingSupplyOrder: '/oms/fofo/cso/start-processing/v1',
  getOrderCustomerDetails: '/oms/fofo/cpo/{orderReferenceId}/customer/v1',
  getShipmentDetails: '/fulfilment/{supplyOrderAliasId}/shipments/v2',
  getShipmentInvoiceUrl: '/invoices/download/{invoiceAliasId}',
  getOpeningCashBalance: '/payments/show-balance/{storeAliasId}/v2',
  addCmsSlip: '/payments/cms-slip/{closureAliasId}/v2',
  editCmsSlip: '/payments/cms-slip/{closureAliasId}/v2',
  getOpenCashTransactions: '/payments/active-cash-transactions/{storeAliasId}/v2',
  addCashClosure: '/payments/closure/v2',
  editCashClosure: '/payments/closure/{closureAliasId}/v2',
  downloadCashClosureReport: '/payments/report-closure/{storeAliasId}/v2',
  downloadCashTransactionsReport: '/payments/report-cash-transactions/{storeAliasId}/v2',
  getCashTransactionList: '/payments/cash-transactions-list/{storeAliasId}/v2',
  markWrongTransaction: '/payments/fofo/wrong-cash/v2',
  getAuditLogs: '/oms/fofo/cpo/{orderReferenceId}/audit-logs/v1',
  getAdditionalCashList: '/payments/active-remaining-cash/{storeAliasId}/v2',
  getCashClosureList: '/payments/closures-list/{storeAliasId}/v2',
  getCashClosureDetails: '/payments/closure/{closureAliasId}/v2',
  getDownloadCashClosureStatus: '/payments/report-closure/{storeAliasId}/v2',
  getDownloadTransactionStatus: '/payments/report-cash-transactions/{storeAliasId}/v2',
  deleteAddress: '/pn/customers/{customerId}/address/{addressId}/v1',
  updateAddress: '/pn/customers/{customerId}/address/{addressId}/v1',
  getRefundList: '/payments/fofo/refund-list/{storeAliasId}/v2',
  confirmRefund: '/payments/fofo/refund-confirm/{refundAliasId}/v2',
  cancelRefund: '/payments/fofo/refund-cancel/{refundAliasId}/v2',
  cancelSKU: '/fulfilment/{supplyOrderAliasId}/cancel-order-items/v1',
  getCustomerCreditBalanceDetails: '/payments/fofo/customer-balance/{customerId}/v2',
  recordRefund: '/payments/fofo/record-refund/v2',
  requestReturn: '/fulfilment/shipment/{shipmentAliasId}/return/v1',
  getSOCancellationDetails: '/fulfilment/supply-order-details/v1',
  getShipmentSkuList: '/fulfilment/shipment/{shipmentAliasId}/v1',
  getShipmentList: '/fulfilment/{supplyOrderAliasId}/shipments/v1',
  getCancelSkuList: '/fulfilment/{supplyOrderAliasId}/order-items/v1',
  getSearchCustomerList: '/pn/customers/search/v2',
  getReturnList: '/fulfilment/returns/v1',
  getReturnDetails: '/fulfilment/return/{returnAliasId}/v1',
  getAccountStatement: '/payments/fofo/ledger-statement/{customerAliasId}/v2',
  getLedgerTxnInvoiceUrl: '/invoices/download/{invoiceAliasId}',
  downloadLedgerAccountStatement: '/payments/fofo/generate-ledger-report/{customerAliasId}/v2',
  getDownloadLedgerStatementStatus: '/payments/fofo/ledger-report-status/{requestId}/v2',
  getCustomerLedgerDetails: '/payments/fofo/customer-summary/{customerAliasId}/v2',
  getDisplayInventorySummary: '/stores/{storeAliasId}/display-inventory-summary/v1',
  getDisplayInventoryList: '/stores/{storeAliasId}/display-inventory-list/v1',
  getRecommendationTaskSummary: '/task/fofo-display/process/{processAliasId}/v1',
  getDisplayInventoryRecommendationList: '/task/fofo-display/process/{processAliasId}/sku/v1',
  getDisplayInventoryListUrl: '/stores/{storeAliasId}/download-display-inventory/v1',
  completeTask: '/task/fofo-display/photon/task/{taskAliasId}/complete/v1',
  saveProgress: '/task/fofo-display/photon/task/{taskAliasId}/save/v1',
  getDisplayRequestListUrl: '/task/fofo-display/photon/process/{processAliasId}/display-request/download/v1',
  initiateCartPayment: '/carts/cart/payment/edc',
  initiateOrderPayments: '/oms/fofo/{orderReferenceId}/payment/edc',
  getEDCMachineList: '/stores/tid/v1',
  getInfluencerDetails: '/influencers/profile/v3',
  checkInventory: '/inventory/FOFO/checkAndCompareOnHandInventory',
  updateProjectDetails: '', // TODO add endpoint at integration
  getProjectList: '/customers/project/v1',
  getDealList: '/deals/list/v4',
  getDealDetails: '/deals/{dealAliasId}/v4',
  getDealDesignList: '/ams/asset/getAllDesigns/v1',
  setActiveDesign: '/ams/asset/setDesign/v1',
  deleteDesign: '/ams/asset/deleteDesignByName/v1',
  uploadDesign: '/ams/asset/uploadDesign/v1',
};

export default URL;
