/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import {
  CheckInventoryAction, CheckInventoryActionTypes, checkInventoryFailed, checkInventorySuccess,
} from '../actions/checkInventory';
import checkInventoryService from '../services/checkInventory';

const CheckInventoryEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CheckInventoryActionTypes.CHECK_INVENTORY)),
  mergeMap((action: CheckInventoryAction) => from(
    checkInventoryService(action.payload),
  ).pipe(
    map((response: any) => (
      checkInventorySuccess({
        inventoryInfo: response.data.checkInventory.data.inventoryInfo || [],
        skuId: action.payload.skuId,
        pageInfo: response.data.checkInventory.pageInfo,
      }))),
    catchError((error: any) => of(
      checkInventoryFailed({
        skuId: action.payload.skuId,
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CheckInventoryEpic;
