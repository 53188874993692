/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  createOrderPaymentSuccess,
  createOrderPaymentFailure,
  CreateOrderPaymentAction,
  CreateOrderPaymentActionTypes,
} from '../actions/CreateOrderPayment';
import createOrderPaymentService from '../services/CreateOrderPayment';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CreateOrderPaymentEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT)),
  mergeMap((action: CreateOrderPaymentAction) => from(
    createOrderPaymentService(action.payload.orderReferenceId, action.payload.paymentPayload),
  ).pipe(
    map((response: any) => (createOrderPaymentSuccess())),
    catchError((error: any) => of(
      createOrderPaymentFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CreateOrderPaymentEpic;
