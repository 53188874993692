import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_DOWNLOAD_TRANSACTION_STATUS = gql`
  query getDownloadTransactionStatus($path: String!) {
    getDownloadTransactionStatus @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getDownloadTransactionStatusService = (filter: IFilter[], storeAliasId: string) => {
  const url = constructUrlWithParams(URL.getDownloadTransactionStatus.replace('{storeAliasId}', storeAliasId), filter);
  return client.query({ query: GET_DOWNLOAD_TRANSACTION_STATUS, variables: { path: url } });
};

export default getDownloadTransactionStatusService;
