/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { combineEpics } from 'redux-observable';

import GetDisplayInventorySummaryEpic from './GetDisplayInventorySummary';
import GetDisplayInventoryListEpic from './GetDisplayInventoryList';
import GetRecommendationTaskSummaryEpic from './GetRecommendationTaskSummary';
import GetDisplayInventoryListUrlEpic from './GetDisplayInventoryListUrl';
import GetDisplayInventoryRecommendationListEpic from './GetDisplayInventoryRecommendationList';
import CompleteTaskEpic from './CompleteTask';
import SaveProgressEpic from './SaveProgress';
import GetDisplayRequestListUrlEpic from './GetDisplayRequestUrl';

const DisplayManagementEpics = combineEpics(
  GetDisplayInventorySummaryEpic,
  GetDisplayInventoryListEpic,
  GetRecommendationTaskSummaryEpic,
  GetDisplayInventoryListUrlEpic,
  GetDisplayInventoryRecommendationListEpic,
  CompleteTaskEpic,
  SaveProgressEpic,
  GetDisplayRequestListUrlEpic,
);

export default DisplayManagementEpics;
