import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { CancelOrderActions, CancelOrderActionTypes } from '../actions/CancelOrder';

export interface ICancelOrderState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialCancelOrderState: ICancelOrderState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const CancelOrderReducer: Reducer<ICancelOrderState, CancelOrderActions> = (
  state = initialCancelOrderState,
  action: CancelOrderActions,
): ICancelOrderState => {
  switch (action.type) {
    case CancelOrderActionTypes.CANCEL_ORDER_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CancelOrderActionTypes.CANCEL_ORDER_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CancelOrderActionTypes.CANCEL_ORDER_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CancelOrderActionTypes.CANCEL_ORDER_ACTION_RESET:
      return initialCancelOrderState;

    default:
      return state;
  }
};

export default CancelOrderReducer;
