import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IInitiateOrderPaymentPayload } from '../models/InitiateOrderPayment';

const INITIATE_ORDER_PAYMENT = gql`
  query initiateOrderPayment($path: String!, $payload: any) {
    initiateOrderPayment (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const initiateOrderPaymentService = (orderReferenceId: string, payload: IInitiateOrderPaymentPayload) => client.query({
  query: INITIATE_ORDER_PAYMENT,
  variables: {
    path: URL.initiateOrderPayments.replace('{orderReferenceId}', orderReferenceId),
    payload,
  },
});

export default initiateOrderPaymentService;
