import { IErrorActionData } from '../../../models/Error';

export interface IEWayBillUrlData {
  presignedInvoiceUrl?: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class EWayBillUrlData {
  eWayBillUrlData: Record<string, IEWayBillUrlData> = {};
  constructor(eWayBillUrlData: Record<string, IEWayBillUrlData>) {
    this.eWayBillUrlData = eWayBillUrlData;
  }
}

export const addPresignedUrlToExisting = (existingPresignedURL: Record<string, IEWayBillUrlData>,
  invoiceAliasId: string, eWayBillUrlData: IEWayBillUrlData) => (
  { ...existingPresignedURL, [invoiceAliasId]: eWayBillUrlData }
);
