/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  cancelCartSuccess,
  cancelCartFailure,
  CancelCartAction,
  CancelCartActionTypes,
} from '../actions/CancelCart';
import cancelCartService from '../services/CancelCart';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CancelCartEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CancelCartActionTypes.CANCEL_CART_ACTION)),
  mergeMap((action: CancelCartAction) => from(
    cancelCartService(action.payload.cartAliasId),
  ).pipe(
    map((response: any) => (cancelCartSuccess())),
    catchError((error: any) => of(
      cancelCartFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CancelCartEpic;
