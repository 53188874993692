export interface ICancelSkuListItem {
  productName: string;
  quantityOrdered: number;
  quantityCancelled: number;
  quantityInvoiced: number;
  quantityReturned: number,
  skuId: string;
  imageUrl: string;
  measurementUnitName: string;
  measurementUnitQuantity: string;
  uomDecimalPrecision: number;
  sellingPrice: number;
}

export interface ICancelSkuList {
  customerSupplyOrderAliasId: string;
  skuList: Array<ICancelSkuListItem>;
}

export class CancelSkuList {
  cancelSkuList = {} as ICancelSkuList;
  constructor(cancelSkuList: ICancelSkuList) {
    this.cancelSkuList = cancelSkuList;
  }
}
