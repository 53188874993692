/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import {
  filter, mergeMap, map, catchError,
} from 'rxjs/operators';
import { from, of } from 'rxjs';

import {
  uploadDesignSuccess,
  uploadDesignFailure,
  UploadDesignAction,
  UploadDesignActionTypes,
} from '../actions/UploadDesign';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import UploadDesignService from '../services/UploadDesign';

const UploadDesignEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(UploadDesignActionTypes.UPLOAD_DESIGN)),
  mergeMap((action: UploadDesignAction) => from(UploadDesignService(action.payload.fileData)).pipe(
    map((response: any) => {
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
      return uploadDesignSuccess();
    }),
    catchError((error: IErrorActionData) => of(
      uploadDesignFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
      }),
    )),
  )),
);

export default UploadDesignEpic;
