import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { SaveCartActions, SaveCartActionTypes } from '../actions/SaveCart';

export interface ISaveCartState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialSaveCartState: ISaveCartState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const SaveCartReducer: Reducer<ISaveCartState, SaveCartActions> = (
  state = initialSaveCartState,
  action: SaveCartActions,
): ISaveCartState => {
  switch (action.type) {
    case SaveCartActionTypes.SAVE_CART_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case SaveCartActionTypes.SAVE_CART_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case SaveCartActionTypes.SAVE_CART_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case SaveCartActionTypes.SAVE_CART_ACTION_RESET:
      return initialSaveCartState;

    default:
      return state;
  }
};

export default SaveCartReducer;
