import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_PROJECT_LIST = gql`
  query getProjectList($path: String!) {
    getProjectList @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
      pageInfo
    }
  }
`;

export const GetProjectListServices = (page?: IPage) => {
  const url = constructUrlWithParams(URL.getProjectList, undefined, undefined, page);
  return client.query({ query: GET_PROJECT_LIST, variables: { path: url } });
};

export default GetProjectListServices;
