export interface PriceInfo {
  amountPayable: number;
  deliveryChargeGst: number;
  deliveryChargesAndHandlingCharges: number;
  discounts: number;
  gst: number;
  productGst: number;
  roundOff: number;
  sellingPrice: number;
  totalValue: number;
  totalValueExcGst: number;
  totalSkuDiscount: number;
}

export interface ISku {
  imageUrl: string;
  measurementUnitName: string,
  measurementUnitQty: string,
  preferredDeliveryDate: number;
  priceInfo: PriceInfo;
  productName: string;
  skuId: string;
  status: string;
  quantity: number;
  cancelledQuantity: number;
  returnedQuantity: number;
  remarks: string;
}

export interface ISupplyOrder {
  supplyOrderId: number;
  customerSupplyOrderAliasId: string;
  state: string;
  cancellationReason: string;
  fulfilmentFailureCode: string;
  fulfilmentFailureReason: string;
  groupName: string;
  preferredDeliveryDate: number;
  quantity: number;
  skuList: ISku[];
  priceInfo: PriceInfo;
}

export interface ISupplyOrderList {
  groupedOrderList: ISupplyOrder[];
  skuList: ISku[];
}

export class SupplyOrderList {
  supplyOrderList = {} as ISupplyOrderList;
  constructor(supplyOrderList: ISupplyOrderList) {
    this.supplyOrderList = supplyOrderList;
  }
}
