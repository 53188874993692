import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IRecordRefundPayload } from '../models/RecordRefund';

export enum RecordRefundActionTypes {
  RECORD_REFUND = 'RECORD_REFUND',
  RECORD_REFUND_SUCCESS = 'RECORD_REFUND_SUCCESS',
  RECORD_REFUND_FAILED = 'RECORD_REFUND_FAILED',
  RECORD_REFUND_RESET = 'RECORD_REFUND_RESET',
}

export interface IRecordRefundActionData {
  recordRefundDetails: IRecordRefundPayload;
}

export const recordRefund = createAction(RecordRefundActionTypes.RECORD_REFUND)<IRecordRefundActionData>();
export const recordRefundSuccess = createAction(RecordRefundActionTypes.RECORD_REFUND_SUCCESS)();
export const recordRefundFailure = createAction(RecordRefundActionTypes.RECORD_REFUND_FAILED)<IErrorActionData>();
export const recordRefundReset = createAction(RecordRefundActionTypes.RECORD_REFUND_RESET)();

export type RecordRefundAction = ActionType<typeof recordRefund>;
type RecordRefundSuccessAction = ActionType<typeof recordRefundSuccess>;
type RecordRefundFailureAction = ActionType<typeof recordRefundFailure>;
type RecordRefundResetAction = ActionType<typeof recordRefundReset>;

export type RecordRefundActions = RecordRefundAction
| RecordRefundSuccessAction
| RecordRefundFailureAction
| RecordRefundResetAction;
