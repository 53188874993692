import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  otpContainer: {
    minWidth: 100,
    display: 'flex',
    justifyContent: 'space-between',
  },
  otpInput: {
    width: 46,
    height: 61,
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: 4,
  },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textAlign: 'start',
  },
  errorInput: {
    '& .MuiInput': {
      borderColor: theme.palette.IMError.main,
    },
  },
  error: {
    color: theme.palette.IMError.main,
  },
}));

export default useStyles;
