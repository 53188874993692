/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import getSupplyOrderListService from '../services/GetSupplyOrderList';
import {
  GetSupplyOrderListAction,
  GetSupplyOrderListActionTypes,
  getSupplyOrderListFailure,
  getSupplyOrderListSuccess,
} from '../actions/GetSupplyOrderList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetSupplyOrderListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST)),
  mergeMap((action: GetSupplyOrderListAction) => from(
    getSupplyOrderListService(action.payload.filter),
  ).pipe(
    map((response) => getSupplyOrderListSuccess({
      supplyOrderList: response.data.getSupplyOrderList.data || {},
    })),
    catchError((error: IErrorActionData) => of(
      getSupplyOrderListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetSupplyOrderListEpic;
