import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IAddEditCashClosurePayload } from '../models/AddEditCashClosure';

const ADD_CASH_CLOSURE = gql`
  query addCashClosure($path: String!, $payload: any) {
    addCashClosure (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const addCashClosureService = (payload: IAddEditCashClosurePayload) => client.query({
  query: ADD_CASH_CLOSURE,
  variables: {
    path: URL.addCashClosure,
    payload,
  },
});

export default addCashClosureService;
