import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { UpdatePaymentActions, UpdatePaymentActionTypes } from '../actions/UpdatePayment';
import { UpdatePayment, updatePaymentToExisting } from '../models/UpdatePayment';

export interface IUpdatePaymentState {
  updatedPayment: UpdatePayment;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialUpdatePaymentState: IUpdatePaymentState = {
  updatedPayment: new UpdatePayment({}),
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const UpdatePaymentReducer: Reducer<IUpdatePaymentState, UpdatePaymentActions> = (
  state = initialUpdatePaymentState,
  action: UpdatePaymentActions,
): IUpdatePaymentState => {
  switch (action.type) {
    case UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION: {
      const updatedPaymentStatus = updatePaymentToExisting(
        state.updatedPayment.updatePayment,
        action.payload.paymentAliasId,
        { isProcessing: true, isSuccess: false },
      );
      return { ...state, updatedPayment: new UpdatePayment(updatedPaymentStatus) };
    }

    case UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION_SUCCESS: {
      const updatedPaymentStatus = updatePaymentToExisting(
        state.updatedPayment.updatePayment,
        action.payload.paymentAliasId,
        { isProcessing: false, isSuccess: true },
      );
      return { ...state, updatedPayment: new UpdatePayment(updatedPaymentStatus) };
    }

    case UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION_FAILURE: {
      const updatedPaymentStatus = updatePaymentToExisting(
        state.updatedPayment.updatePayment,
        action.payload.paymentAliasId,
        {
          isProcessing: false,
          isSuccess: false,
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, updatedPayment: new UpdatePayment(updatedPaymentStatus) };
    }

    case UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION_RESET:
      return initialUpdatePaymentState;

    default:
      return state;
  }
};

export default UpdatePaymentReducer;
