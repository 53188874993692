import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_CUSTOMER_SAVED_CART_LIST_COUNT = gql`
  query getCustomerSavedCartListCount($path: String!) {
    getCustomerSavedCartListCount @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getCustomerSavedCartListCountService = (filter?: IFilter[]) => {
  const url = constructUrlWithParams(URL.getCustomerSavedCartListCount, filter, undefined, undefined);
  return client.query({ query: GET_CUSTOMER_SAVED_CART_LIST_COUNT, variables: { path: url } });
};

export default getCustomerSavedCartListCountService;
