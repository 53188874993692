import { Reducer } from 'redux';

import { RefundList, mergeTwoLists } from '../models/GetRefundList';
import { GetRefundListActionTypes, GetRefundListActions } from '../actions/GetRefundList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IRefundListState {
  refundList: RefundList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialRefundListState = {
  refundList: new RefundList([]),
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IRefundListState;

const GetRefundListReducer: Reducer<IRefundListState, GetRefundListActions> = (
  state = initialRefundListState,
  action: GetRefundListActions,
): IRefundListState => {
  switch (action.type) {
    case GetRefundListActionTypes.GET_REFUND_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetRefundListActionTypes.GET_REFUND_LIST_SUCCESS: {
      const newRefundList = mergeTwoLists(
        state.refundList.refundList,
        action.payload.refundList,
      );
      return {
        ...state,
        refundList: new RefundList(newRefundList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo?.pageSize,
          hasNext: action.payload.pageInfo?.hasNext,
          pageNumber: action.payload.pageInfo?.pageNumber,
          totalPages: action.payload.pageInfo?.totalPages,
          cursor: action.payload.pageInfo?.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetRefundListActionTypes.GET_REFUND_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetRefundListActionTypes.GET_REFUND_LIST_RESET:
      return initialRefundListState;

    default:
      return state;
  }
};

export default GetRefundListReducer;
