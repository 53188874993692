import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { INewCartPayload } from '../models/NewCartDetails';

export enum CreateCartActionTypes {
  CREATE_CART = 'CREATE_CART',
  CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS',
  CREATE_CART_FAILED = 'CREATE_CART_FAILED',
  CREATE_CART_RESET = 'CREATE_CART_RESET',
}

export interface ICreateCartActionData {
  createCartDetails: INewCartPayload;
}

export interface ICreateCartSuccessActionData {
  cartAliasId: string;
}

export const createCart = createAction(CreateCartActionTypes.CREATE_CART)<ICreateCartActionData>();
export const createCartSuccess = createAction(
  CreateCartActionTypes.CREATE_CART_SUCCESS,
)<ICreateCartSuccessActionData>();
export const createCartFailure = createAction(
  CreateCartActionTypes.CREATE_CART_FAILED,
)<IErrorActionData>();

export const createCartReset = createAction(CreateCartActionTypes.CREATE_CART_RESET)();

export type CreateCartAction = ActionType<typeof createCart>;
type CreateCartSuccessAction = ActionType<typeof createCartSuccess>;
type CreateCartFailureAction = ActionType<typeof createCartFailure>;
type CreateCartResetAction = ActionType<typeof createCartReset>;

export type CreateCartActions = CreateCartAction
| CreateCartSuccessAction
| CreateCartFailureAction
| CreateCartResetAction;
