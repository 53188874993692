export interface IGetProductListPayload {
  channel: string;
  locationType: string;
  storeAliasId: string;
  searchString: string;
  cursor?: number | string;
  pageSize: number;
}

interface ITaxonomy {
  level: number;
  name: string;
}

interface IUom {
  id: number;
  name: string;
  qtyFactor: number;
  decimalPrecision: number;
}

export interface IAttributeInfo {
  attributeName: string;
  attributeValue: string;
}

export interface IProduct {
  productAliasId: string
  brandName: string
  sku: string
  skuId: number
  isServiceable: boolean
  discountPercentage: number
  hsnCode: string
  isVariant: boolean
  name: string
  sellingPrice: number
  taxRate: number
  imageUrl: string
  variantAliasId: string
  category: string
  productCategoryAliasId: string,
  uom: {
    baseUom: IUom
    sellingUom: IUom[]
  }
  price: {
    imPrice: {
      priceWithGst: number
      priceWithOutGst: number
    }
    mrp: {
      priceWithGst: number
      priceWithOutGst: number
    }
    pricingConfig: {
      gstPricing: boolean
    }
  }
  taxonomy: ITaxonomy[],
  attributeData?: Array<IAttributeInfo>;
}

export class ProductList {
  productList: Array<IProduct> = [];

  constructor(productList: Array<IProduct>) {
    this.productList = productList;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<IProduct>,
  payloadList: Array<IProduct>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
