interface ISkuInfo {
  totalSku: number;
  selected: number;
}

interface ISmSelectedInfo {
  removal: ISkuInfo;
  addition: ISkuInfo;
}

export interface IRecommendationTaskDetails {
  requestId : number;
  requestedDate: number;
  completeBy: number;
  remainingDays: number;
  storeId: number;
  isSelectedBySm: ISmSelectedInfo;
  pendingTaskId: number;
}

export class RecommendationTaskSummaryDetails {
  recommendationTaskSummaryDetails = {} as IRecommendationTaskDetails;
  constructor(recommendationTaskSummaryDetails: IRecommendationTaskDetails) {
    this.recommendationTaskSummaryDetails = recommendationTaskSummaryDetails;
  }
}
