import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ISaveProgressPayload } from '../models/SaveProgress';

const SAVE_PROGRESS = gql`
  query saveProgress($path: String!, $payload: any) {
    saveProgress (path: $path, payload: $payload)
      @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const saveProgressService = (taskAliasId: string, payload: ISaveProgressPayload) => client.query({
  query: SAVE_PROGRESS,
  variables: {
    path: URL.saveProgress.replace('{taskAliasId}', taskAliasId),
    payload,
  },
});

export default saveProgressService;
