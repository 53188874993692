import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IOrderCustomerDetails } from '../models/OrderCustomerDetails';

export enum GetOrderCustomerDetailsActionTypes {
  GET_ORDER_CUSTOMER_DETAILS = 'GET_ORDER_CUSTOMER_DETAILS',
  GET_ORDER_CUSTOMER_DETAILS_SUCCESS = 'GET_ORDER_CUSTOMER_DETAILS_SUCCESS',
  GET_ORDER_CUSTOMER_DETAILS_FAILED = 'GET_ORDER_CUSTOMER_DETAILS_FAILED',
  GET_ORDER_CUSTOMER_DETAILS_RESET = 'GET_ORDER_CUSTOMER_DETAILS_RESET',
}

export interface IGetOrderCustomerDetailsActionData {
  orderReferenceId: string;
}

export interface IGetOrderCustomerDetailsSuccessActionData {
  orderCustomerDetails: IOrderCustomerDetails;
}

export const getOrderCustomerDetails = createAction(GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS)
<IGetOrderCustomerDetailsActionData>();
export const getOrderCustomerDetailsSuccess = createAction(GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS_SUCCESS)
<IGetOrderCustomerDetailsSuccessActionData>();
export const getOrderCustomerDetailsFailure = createAction(GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS_FAILED)
<IErrorActionData>();
export const getOrderCustomerDetailsReset = createAction(GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS_RESET)();

export type GetOrderCustomerDetailsAction = ActionType<typeof getOrderCustomerDetails>;
type GetOrderCustomerDetailsSuccessAction = ActionType<typeof getOrderCustomerDetailsSuccess>;
type GetOrderCustomerDetailsFailureAction = ActionType<typeof getOrderCustomerDetailsFailure>;
type GetOrderCustomerDetailsResetAction = ActionType<typeof getOrderCustomerDetailsReset>;

export type GetOrderCustomerDetailsActions = GetOrderCustomerDetailsAction
| GetOrderCustomerDetailsSuccessAction
| GetOrderCustomerDetailsFailureAction
| GetOrderCustomerDetailsResetAction;
