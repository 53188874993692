/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getAccountStatementInfoSuccess,
  getAccountStatementInfoFailure,
  GetAccountStatementInfoAction,
  GetAccountStatementInfoActionTypes,
} from '../actions/GetAccountStatement';
import getAccountStatementService from '../services/GetAccountStatement';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetAccountStatementEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO)),
  mergeMap((action: GetAccountStatementInfoAction) => from(
    getAccountStatementService(
      action.payload.customerAliasId,
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response: any) => getAccountStatementInfoSuccess({
      customerAliasId: response.data.getAccountStatement.data.customerAliasId,
      accountStatement: response.data.getAccountStatement.data.accountStatement || [],
      amountReceived: response.data.getAccountStatement.data.amountReceived || 0,
      openingBalance: response.data.getAccountStatement.data.openingBalance || 0,
      invoicedAmount: response.data.getAccountStatement.data.invoicedAmount || 0,
      balanceDue: response.data.getAccountStatement.data.balanceDue || 0,
      pageInfo: response.data.getAccountStatement.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getAccountStatementInfoFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetAccountStatementEpic;
