import { Reducer } from 'redux';

import { CreateCustomerActions, CreateCustomerActionTypes } from '../actions/CreateCustomer';
import { IErrorActionData } from '../../../models/Error';
import { INewCustomer, NewCustomer } from '../models/NewCustomer';

export interface ICreateCustomerState {
  newCustomerDetails: NewCustomer;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCreateCustomerState = {
  newCustomerDetails: new NewCustomer({} as INewCustomer),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICreateCustomerState;

const CreateCustomerReducer: Reducer<ICreateCustomerState, CreateCustomerActions> = (
  state = initialCreateCustomerState,
  action: CreateCustomerActions,
): ICreateCustomerState => {
  switch (action.type) {
    case CreateCustomerActionTypes.CREATE_CUSTOMER:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CreateCustomerActionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        newCustomerDetails: new NewCustomer(action.payload.newCustomerData),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CreateCustomerActionTypes.CREATE_CUSTOMER_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CreateCustomerActionTypes.CREATE_CUSTOMER_RESET:
      return initialCreateCustomerState;

    default:
      return state;
  }
};

export default CreateCustomerReducer;
