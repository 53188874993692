/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getOpenCashTransactionsSuccess,
  getOpenCashTransactionsFailure,
  GetOpenCashTransactionsAction,
  GetOpenCashTransactionsActionTypes,
} from '../actions/GetOpenCashTransactions';
import getOpenCashTransactionsService from '../services/GetOpenCashTransactions';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetOpenCashTransactionsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetOpenCashTransactionsActionTypes.GET_OPEN_CASH_TRANSACTIONS)),
  mergeMap((action: GetOpenCashTransactionsAction) => from(
    getOpenCashTransactionsService(
      action.payload.storeAliasId,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getOpenCashTransactionsSuccess({
      openCashTransactions: response.data.getOpenCashTransactions.data.transactions || [],
      pageInfo: response.data.getOpenCashTransactions.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getOpenCashTransactionsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetOpenCashTransactionsEpic;
