import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IRecommendationSkuInfo } from '../models/GetDisplayInventoryRecommendationList';

export enum GetDisplayInventoryRecommendationListActionTypes {
  GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST = 'GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST',
  GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_SUCCESS = 'GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_SUCCESS',
  GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_FAILED = 'GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_FAILED',
  GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_RESET = 'GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_RESET',
}

export enum AvailableFilterProperty {
  recommendationForSm = 'recommendationForSm',
  sku = 'sku',
}

export interface IGetDisplayInventoryRecommendationListActionData {
  processAliasId: string;
  filter?: IFilter[];
  page?: IPage;
}
export interface IGetDisplayInventoryRecommendationListSuccessActionData {
  skuList: Array<IRecommendationSkuInfo>;
  pageInfo: IPageInfo;
}

export const getDisplayInventoryRecommendationList = createAction(
  GetDisplayInventoryRecommendationListActionTypes.GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST,
)<IGetDisplayInventoryRecommendationListActionData>();
export const getDisplayInventoryRecommendationListSuccess = createAction(
  GetDisplayInventoryRecommendationListActionTypes.GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_SUCCESS,
)<IGetDisplayInventoryRecommendationListSuccessActionData>();
export const getDisplayInventoryRecommendationListFailure = createAction(
  GetDisplayInventoryRecommendationListActionTypes.GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_FAILED,
)<IErrorActionData>();
export const getDisplayInventoryRecommendationListReset = createAction(
  GetDisplayInventoryRecommendationListActionTypes.GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST_RESET,
)();

export type GetDisplayInventoryRecommendationListAction = ActionType<typeof getDisplayInventoryRecommendationList>;
type GetDisplayInventoryRecommendationListSuccessAction = ActionType<typeof getDisplayInventoryRecommendationListSuccess>;
type GetDisplayInventoryRecommendationListFailedAction = ActionType<typeof getDisplayInventoryRecommendationListFailure>;
type GetDisplayInventoryRecommendationListResetAction = ActionType<typeof getDisplayInventoryRecommendationListReset>;

export type GetDisplayInventoryRecommendationListActions =
  | GetDisplayInventoryRecommendationListAction
  | GetDisplayInventoryRecommendationListSuccessAction
  | GetDisplayInventoryRecommendationListFailedAction
  | GetDisplayInventoryRecommendationListResetAction;
