export interface IAuditLogsItem {
  title: string;
  auditDate: number;
  entityType: string;
  auditType: string;
  modifiedBy: string;
}

export interface IAuditLogs {
  id: string;
  entityState: string;
  auditLogs: Array<IAuditLogsItem>;
}

export class AuditLogs {
  auditLogs = {} as IAuditLogs;
  constructor(auditLogs: IAuditLogs) {
    this.auditLogs = auditLogs;
  }
}
