/* eslint-disable import/prefer-default-export */
type EnvType = 'DEV1' | 'QA' | 'STAGING' | 'PROD';

interface IFeatureFlags {
  [key: string]: boolean;
}

//  TODO: Improve and optimize it
export const auditFeatureFlags: IFeatureFlags = {
  orderDetails: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
};

export const orderFeatureFlags: IFeatureFlags = {
  updateSku: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
  editPreferredDeliveryDate: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
};

export const customerOverviewFeatureFlags: IFeatureFlags = {
  ledger: ['DEV1', 'QA', 'STAGING', 'PROD'].includes(process.env.REACT_APP_ENV as EnvType),
  downloadLedgerStatement: ['DEV1', 'QA', 'STAGING', 'PROD'].includes(process.env.REACT_APP_ENV as EnvType),
};

export const inventoryDisplayManagementFeatureFlags: IFeatureFlags = {
  finishFilter: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
  removeBtn: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
  addBtn: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
};

export const inventoryVisibilityFeatureFlags: IFeatureFlags = {
  checkAvailability: ['DEV1', 'QA', 'STAGING', 'PROD'].includes(process.env.REACT_APP_ENV as EnvType),
  availabilityInfo: ['DEV1', 'QA', 'STAGING', 'PROD'].includes(process.env.REACT_APP_ENV as EnvType),
};

export const customerOnboardingFeatureFlags: IFeatureFlags = {
  project: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
  addressLabel: ['DEV1'].includes(process.env.REACT_APP_ENV as EnvType),
};
