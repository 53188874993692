import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_DOWNLOAD_LEDGER_STATEMENT_STATUS = gql`
  query getDownloadLedgerStatementStatus($path: String!) {
    getDownloadLedgerStatementStatus @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getDownloadLedgerStatementStatusService = (requestId: string) => {
  const url = URL.getDownloadLedgerStatementStatus.replace('{requestId}', requestId);
  return client.query({ query: GET_DOWNLOAD_LEDGER_STATEMENT_STATUS, variables: { path: url } });
};

export default getDownloadLedgerStatementStatusService;
