import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum GetDownloadTransactionStatusActionTypes {
  GET_DOWNLOAD_TRANSACTION_STATUS = 'GET_DOWNLOAD_TRANSACTION_STATUS',
  GET_DOWNLOAD_TRANSACTION_STATUS_SUCCESS = 'GET_DOWNLOAD_TRANSACTION_STATUS_SUCCESS',
  GET_DOWNLOAD_TRANSACTION_STATUS_FAILED = 'GET_DOWNLOAD_TRANSACTION_STATUS_FAILED',
  GET_DOWNLOAD_TRANSACTION_STATUS_RESET = 'GET_DOWNLOAD_TRANSACTION_STATUS_RESET',
}

export enum AvailableFilterProperty {
  downloadRequestId = 'requestId',
}

export interface IGetDownloadTransactionStatusActionData {
  storeAliasId: string;
  filter: IFilter[];
}

export interface IGetDownloadTransactionStatusSuccessActionData {
  downloadReportStatus: string;
  downloadReportUrl: string;
}

export const getDownloadTransactionStatus = createAction(
  GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS,
)<IGetDownloadTransactionStatusActionData>();
export const getDownloadTransactionStatusSuccess = createAction(
  GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS_SUCCESS,
)<IGetDownloadTransactionStatusSuccessActionData>();
export const getDownloadTransactionStatusFailed = createAction(
  GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS_FAILED,
)<IErrorActionData>();
export const getDownloadTransactionStatusReset = createAction(
  GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS_RESET,
)();

export type GetDownloadTransactionStatusAction = ActionType<typeof getDownloadTransactionStatus>;
type GetDownloadTransactionStatusSuccessAction = ActionType<typeof getDownloadTransactionStatusSuccess>;
type GetDownloadTransactionStatusFailedAction = ActionType<typeof getDownloadTransactionStatusFailed>;
type GetDownloadTransactionStatusResetAction = ActionType<typeof getDownloadTransactionStatusReset>;

export type GetDownloadTransactionStatusActions =
  | GetDownloadTransactionStatusAction
  | GetDownloadTransactionStatusSuccessAction
  | GetDownloadTransactionStatusFailedAction
  | GetDownloadTransactionStatusResetAction;
