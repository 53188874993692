import { Reducer } from 'redux';

import { ICustomerLedgerDetails, CustomerLedgerDetails } from '../models/CustomerLedgerDetails';
import { GetCustomerLedgerDetailsActionTypes, GetCustomerLedgerDetailsActions } from '../actions/GetCustomerLedgerDetails';
import { IErrorActionData } from '../../../models/Error';

export interface IGetCustomerLedgerDetailsState {
  customerLedgerDetails: CustomerLedgerDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCustomerLedgerDetailsState = {
  customerLedgerDetails: new CustomerLedgerDetails({} as ICustomerLedgerDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetCustomerLedgerDetailsState;

const GetCustomerLedgerDetailsReducer: Reducer<IGetCustomerLedgerDetailsState, GetCustomerLedgerDetailsActions> = (
  state = initialCustomerLedgerDetailsState,
  action: GetCustomerLedgerDetailsActions,
): IGetCustomerLedgerDetailsState => {
  switch (action.type) {
    case GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS_SUCCESS: {
      return {
        ...state,
        customerLedgerDetails: new CustomerLedgerDetails(action.payload.customerLedgerDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS_RESET:
      return initialCustomerLedgerDetailsState;

    default:
      return state;
  }
};

export default GetCustomerLedgerDetailsReducer;
