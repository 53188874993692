import React, {
  FunctionComponent, useEffect, useMemo, useRef, useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

type ClassType = {
  input?: string;
  inputFocus?: string;
  container?: string;
  helperText?: string;
  error?: string;
  endIcon?: string;
};

export interface IIMOtpInputProps {
  id: string;
  value: string;
  numInputs: number;
  onChange: (value: string) => void;
  isInputNum?: boolean;
  isDisabled?: boolean;
  error?: string;
  helperText?: string;
  classes?: ClassType;
}

const IMOtpInput: FunctionComponent<IIMOtpInputProps> = (props) => {
  const classes = useStyles();
  const reg = new RegExp(/^\d+$/);

  const [activeInput, setActiveInput] = useState(0);

  useEffect(() => {
    const firstInputElement = document.querySelector('input:first-child') as HTMLInputElement;
    firstInputElement.focus();
  }, []);

  const otpItemsArray = useMemo(() => {
    const valueArray = props.value.split('');
    const otpItems = [] as Array<string>;
    for (let i = 0; i < props.numInputs; i += 1) {
      const char = valueArray[i];
      if (reg.test(char)) {
        otpItems.push(char);
      } else {
        otpItems.push('');
      }
    }
    return otpItems;
  }, [props.value, props.numInputs]);

  const getOtpValue = () => (props.value ? props.value.toString().split('') : []);

  const focusInput = (input: number) => {
    const updatedActiveInput = Math.max(Math.min(props.numInputs - 1, input), 0);
    setActiveInput(updatedActiveInput);
  };

  const focusPrevInput = (target: HTMLInputElement) => {
    focusInput(activeInput - 1);
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const focusNextInput = (target: HTMLInputElement, index: number) => {
    focusInput(activeInput + 1);
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join('');
    props.onChange(otpValue);
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getOtpValue() ?? [];
    otp[activeInput] = _.first(value) ?? '';
    handleOtpChange(otp);
  };

  const isInputValueValid = (value: string) => {
    const isTypeValid = props.isInputNum ? !Number.isNaN(parseInt(value, 10)) : typeof value === 'string';
    return isTypeValid && value.trim().length === 1;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const target = e.target as HTMLInputElement;
    if (e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput(target);
    } else if (e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('');
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput(target);
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput(target, index);
    } else if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
      e.preventDefault();
    }
  };

  const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (isInputValueValid(e.target.value)) {
      focusNextInput(e.target, index);
    }
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (props.isDisabled) {
      return;
    }
    const otp = getOtpValue();
    let nextActiveInput = activeInput;
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, props.numInputs - activeInput)
      .split('');
    for (let pos = 0; pos < props.numInputs; pos += 1) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput += 1;
      }
    }
    setActiveInput(nextActiveInput);
    focusInput(nextActiveInput);
    handleOtpChange(otp);
  };

  const handleOnFocus = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setActiveInput(index);
    e.target.select();
  };

  return (
    <Box className={clsx(classes.otpContainer, props.classes?.container)}>
      {otpItemsArray.map((digit: string, indx: number) => (
        <input
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={1}
          value={getOtpValue()[indx] ? getOtpValue()[indx] : ''}
          onChange={(e) => handleOnChange(e, indx)}
          onKeyDown={(e) => handleOnKeyDown(e, indx)}
          onFocus={(e) => handleOnFocus(e, indx)}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleOnInput(e, indx)}
          onPaste={handleOnPaste}
          className={clsx(classes.otpInput, activeInput === indx && props.classes?.inputFocus, props.classes?.input)}
        />
      ))}
      {!!props.helperText && (
        <Typography id={`${props.id}-helperText`} className={clsx(classes.text, props.classes?.helperText)}>
          {props.helperText}
        </Typography>
      )}
      {!!props.error && (
        <Typography id={`${props.id}-errorText`} className={clsx(classes.text, classes.error, props.classes?.error)}>
          {props.error}
        </Typography>
      )}
    </Box>
  );
};

export default IMOtpInput;
