import React, { FunctionComponent } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import IMInput from '../../../../../components/IMInput';
import IMResponse from '../../../../../components/IMResponse';
import Success from '../../../../../assets/images/Success';
import useStyles from '../styles';

interface ICredInputAreaProps {
  phoneNumber: number | string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  onSubmit: () => void;
  disableSubmit?: boolean;
  error?: string;
}

const CredInputArea: FunctionComponent<ICredInputAreaProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');

  const onKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      props.onSubmit();
    }
  };

  return (
    <Box className={classes.LoginContainer}>
      <IMResponse
        id="login"
        image={Success}
        title={t('photon')}
        subTitle={t('loginTitle')}
        classes={{
          container: classes.imageContainer,
          image: classes.image,
        }}
        hidePrimaryBtn
        hideSecondaryBtn
      />
      <IMInput
        required
        id="login-phoneNumber"
        value={props.phoneNumber}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyPress={onKeyPress}
        inputLabel={t('phoneNumber')}
        type="tel"
        name="phoneNumber"
        classes={{ container: classes.mobInput }}
        error={props.error
          ? t(`errorMessage.${props.error}`) : ''}
      />
      <Button
        id="login-button"
        variant="contained"
        disableElevation
        disableRipple
        onClick={props.onSubmit}
        className={classes.loginSubmitButton}
        disabled={props.disableSubmit}
      >
        {t('login')}
      </Button>
    </Box>
  );
};

export default CredInputArea;
