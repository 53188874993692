import { Reducer } from 'redux';

import { GetCartStateActions, GetCartStateActionTypes } from '../actions/GetCartState';
import { IErrorActionData } from '../../../models/Error';

export interface IGetCartStateState {
  cartState: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetCartStateState = {
  cartState: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetCartStateState;

const GetCartStateReducer: Reducer<IGetCartStateState, GetCartStateActions> = (
  state = initialGetCartStateState,
  action: GetCartStateActions,
): IGetCartStateState => {
  switch (action.type) {
    case GetCartStateActionTypes.GET_CART_STATE:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCartStateActionTypes.GET_CART_STATE_SUCCESS:
      return {
        ...state,
        cartState: action.payload.cartState,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetCartStateActionTypes.GET_CART_STATE_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCartStateActionTypes.GET_CART_STATE_RESET:
      return initialGetCartStateState;

    default:
      return state;
  }
};

export default GetCartStateReducer;
