import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';
import { IDeleteDesignPayload } from '../models/DesignInfo';

export enum DeleteDesignActionTypes {
  DELETE_KITCHEN = 'DELETE_KITCHEN',
  DELETE_KITCHEN_SUCCESS = 'DELETE_KITCHEN_SUCCESS',
  DELETE_KITCHEN_FAILURE = 'DELETE_KITCHEN_FAILURE',
  DELETE_KITCHEN_RESET = 'DELETE_KITCHEN_RESET',
}

export const deleteDesign = createAction(DeleteDesignActionTypes.DELETE_KITCHEN)<IDeleteDesignPayload>();
export const deleteDesignSuccess = createAction(DeleteDesignActionTypes.DELETE_KITCHEN_SUCCESS)();
export const deleteDesignFailure = createAction(DeleteDesignActionTypes.DELETE_KITCHEN_FAILURE)<IErrorActionData>();
export const deleteDesignReset = createAction(DeleteDesignActionTypes.DELETE_KITCHEN_RESET)();

export type DeleteDesignAction = ActionType<typeof deleteDesign>;
export type DeleteDesignSuccessAction = ActionType<typeof deleteDesignSuccess>;
export type DeleteDesignFailureAction = ActionType<typeof deleteDesignFailure>;
export type DeleteDesignResetAction = ActionType<typeof deleteDesignReset>;

export type DeleteDesignActions =
  | DeleteDesignAction
  | DeleteDesignSuccessAction
  | DeleteDesignFailureAction
  | DeleteDesignResetAction;
