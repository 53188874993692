/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  initiateOrderPaymentSuccess,
  initiateOrderPaymentFailure,
  InitiateOrderPaymentAction,
  InitiateOrderPaymentActionTypes,
} from '../actions/InitiateOrderPayment';
import initiateOrderPaymentService from '../services/InitiateOrderPayment';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const InitiateOrderPaymentEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(InitiateOrderPaymentActionTypes.INITIATE_ORDER_PAYMENT)),
  mergeMap((action: InitiateOrderPaymentAction) => from(
    initiateOrderPaymentService(action.payload.orderReferenceId, action.payload.paymentDetails),
  ).pipe(
    map((response) => (initiateOrderPaymentSuccess(response.data))),
    catchError((error: any) => of(
      initiateOrderPaymentFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default InitiateOrderPaymentEpic;
