import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IUpdateCartItemPayload } from '../models/UpdateCartItem';

const UPDATE_CART_ITEM = gql`
  query updateCartItem($path: String!, $payload: any) {
    updateCartItem (path: $path, payload: $payload)
      @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const updateCartItemService = (payload: IUpdateCartItemPayload, cartAliasId: string, cartItemAliasId: string) => client.query({
  query: UPDATE_CART_ITEM,
  variables: {
    path: URL.updateCartItem.replace('{cartAliasId}', cartAliasId).replace('{cartItemAliasId}', cartItemAliasId),
    payload,
  },
});

export default updateCartItemService;
