import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IGetCashClosureDetails } from '../models/GetCashClosureDetails';

export enum GetCashClosureDetailsActionTypes {
  GET_CASH_CLOSURE_DETAILS = 'GET_CASH_CLOSURE_DETAILS',
  GET_CASH_CLOSURE_DETAILS_SUCCESS = 'GET_CASH_CLOSURE_DETAILS_SUCCESS',
  GET_CASH_CLOSURE_DETAILS_FAILED = 'GET_CASH_CLOSURE_DETAILS_FAILED',
  GET_CASH_CLOSURE_DETAILS_RESET = 'GET_CASH_CLOSURE_DETAILS_RESET',
}

export interface IGetCashClosureDetailsActionData {
  closureAliasId: string;
}

export interface IGetCashClosureDetailsSuccessActionData {
  getCashClosureDetails: IGetCashClosureDetails;
}

export const getCashClosureDetails = createAction(GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS)
<IGetCashClosureDetailsActionData>();
export const getCashClosureDetailsSuccess = createAction(GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS_SUCCESS)
<IGetCashClosureDetailsSuccessActionData>();
export const getCashClosureDetailsFailed = createAction(GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS_FAILED)
<IErrorActionData>();
export const getCashClosureDetailsReset = createAction(GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS_RESET)();

export type GetCashClosureDetailsAction = ActionType<typeof getCashClosureDetails>;
type GetCashClosureDetailsSuccessAction = ActionType<typeof getCashClosureDetailsSuccess>;
type GetCashClosureDetailsFailedAction = ActionType<typeof getCashClosureDetailsFailed>;
type GetCashClosureDetailsResetAction = ActionType<typeof getCashClosureDetailsReset>;

export type GetCashClosureDetailsActions =
  | GetCashClosureDetailsAction
  | GetCashClosureDetailsSuccessAction
  | GetCashClosureDetailsFailedAction
  | GetCashClosureDetailsResetAction;
