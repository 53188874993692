/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getDownloadTransactionStatusSuccess,
  getDownloadTransactionStatusFailed,
  GetDownloadTransactionStatusAction,
  GetDownloadTransactionStatusActionTypes,
} from '../actions/GetDownloadTransactionStatus';
import getDownloadTransactionStatusService from '../services/GetDownloadTransactionStatus';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetDownloadTransactionStatusEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS)),
  mergeMap((action: GetDownloadTransactionStatusAction) => from(
    getDownloadTransactionStatusService(action.payload.filter, action.payload.storeAliasId),
  ).pipe(
    map((response: any) => getDownloadTransactionStatusSuccess({
      downloadReportStatus: response.data.getDownloadTransactionStatus.data.status || '',
      downloadReportUrl: response.data.getDownloadTransactionStatus.data.url || '',
    })),
    catchError((error: any) => of(
      getDownloadTransactionStatusFailed({
        errorCode: error.errorCode || error.networkError?.statusCode || 500,
        errorMessage: error.errorMessage || error?.networkError?.result?.error_message as string,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDownloadTransactionStatusEpic;
