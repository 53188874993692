/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  setActiveDesignSuccess,
  setActiveDesignFailure,
  SetActiveDesignAction,
  SetActiveDesignActionTypes,
} from '../actions/SetActiveDesign';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import setActiveDesignService from '../services/SetActiveDesign';

const SetActiveDesignEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(SetActiveDesignActionTypes.SET_ACTIVE_DESIGN)),
  mergeMap((action: SetActiveDesignAction) => from(
    setActiveDesignService(action.payload),
  ).pipe(
    map((response) => setActiveDesignSuccess()),
    catchError((error: IErrorActionData) => of(
      setActiveDesignFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default SetActiveDesignEpic;
