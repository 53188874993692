import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { ResendOtpActions, ResendOtpActionTypes } from '../actions/ResendOTP';

export interface IResendOtpState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialResendOtpState: IResendOtpState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const ResendOtpReducer: Reducer<IResendOtpState, ResendOtpActions> = (
  state = initialResendOtpState,
  action: ResendOtpActions,
): IResendOtpState => {
  switch (action.type) {
    case ResendOtpActionTypes.RESEND_OTP:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case ResendOtpActionTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case ResendOtpActionTypes.RESEND_OTP_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case ResendOtpActionTypes.RESEND_OTP_RESET:
      return initialResendOtpState;

    default:
      return state;
  }
};

export default ResendOtpReducer;
