import { Reducer } from 'redux';

import { CreateCartActionTypes, CreateCartActions } from '../actions/CreateCart';
import { IErrorActionData } from '../../../models/Error';

export interface ICreateCartState {
  cartAliasId: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCreateCartState = {
  cartAliasId: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICreateCartState;

const CreateCartReducer: Reducer<ICreateCartState, CreateCartActions> = (
  state = initialCreateCartState,
  action: CreateCartActions,
): ICreateCartState => {
  switch (action.type) {
    case CreateCartActionTypes.CREATE_CART:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CreateCartActionTypes.CREATE_CART_SUCCESS: {
      return {
        ...state,
        cartAliasId: action.payload.cartAliasId,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case CreateCartActionTypes.CREATE_CART_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CreateCartActionTypes.CREATE_CART_RESET:
      return initialCreateCartState;

    default:
      return state;
  }
};

export default CreateCartReducer;
