import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ILogoutActionData } from '../actions/Logout';

const LOGIN = gql`
  query logout($path: String!, $data: any) {
    logout(input: $data, path: $path) 
      @rest(method: "POST", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const logoutService = (payload: ILogoutActionData) => client.query({ query: LOGIN, variables: { data: payload, path: URL.logout } });

export default logoutService;
