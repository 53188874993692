import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  updateProjectDetailsSuccess,
  updateProjectDetailsFailure,
  UpdateProjectDetailsAction,
  UpdateProjectDetailsActionTypes,
} from '../actions/UpdateProjectDetails';
import updateProjectDetailsService from '../services/UpdateProjectDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const UpdateProjectDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS)),
  mergeMap((action: UpdateProjectDetailsAction) => from(
    updateProjectDetailsService(action.payload.projectId, action.payload.data),
  ).pipe(
    map((response) => updateProjectDetailsSuccess()),
    catchError((error: IErrorActionData) => of(
      updateProjectDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default UpdateProjectDetailsEpic;
