/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  startProcessingSupplyOrderSuccess,
  startProcessingSupplyOrderFailure,
  StartProcessingSupplyOrderAction,
  StartProcessingSupplyOrderActionTypes,
} from '../actions/StartProcessingSupplyOrder';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import startProcessingSupplyOrderService from '../services/StartProcessingSupplyOrder';

const StartProcessingSupplyOrderEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER)),
  mergeMap((action: StartProcessingSupplyOrderAction) => from(
    startProcessingSupplyOrderService(action.payload.supplyOrderId),
  ).pipe(
    map((response) => startProcessingSupplyOrderSuccess()),
    catchError((error: IErrorActionData) => of(
      startProcessingSupplyOrderFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default StartProcessingSupplyOrderEpic;
