import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const DELETE_CART_ITEM_DISCOUNT = gql`
  query deleteCartItemDiscount($path: String!, $payload: any) {
    deleteCartItemDiscount (path: $path, payload: $payload)
      @rest(method: "DELETE", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const deleteCartItemDiscountService = (cartAliasId: string, cartItemAliasId: string, payload: IFilter[]) => {
  const url = constructUrlWithParams(URL.deleteCartItemDiscount, payload)
    .replace('{cartAliasId}', cartAliasId).replace('{cartItemAliasId}', cartItemAliasId);
  return client.query({ query: DELETE_CART_ITEM_DISCOUNT, variables: { path: url, payload: {} } });
};

export default deleteCartItemDiscountService;
