/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getAuditLogsSuccess,
  getAuditLogsFailure,
  GetAuditLogsAction,
  GetAuditLogsActionTypes,
} from '../actions/GetAuditLogs';
import getAuditLogsService from '../services/GetAuditLogs';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetAuditLogsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetAuditLogsActionTypes.GET_AUDIT_LOGS)),
  mergeMap((action: GetAuditLogsAction) => from(
    getAuditLogsService(
      action.payload.filter,
    ),
  ).pipe(
    map((response) => getAuditLogsSuccess({
      auditLogDetails: response.auditLogDetails || {},
    })),
    catchError((error: IErrorActionData) => of(
      getAuditLogsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetAuditLogsEpic;
