import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum DeleteCartItemDiscountActionTypes {
  DELETE_CART_ITEM_DISCOUNT = 'DELETE_CART_ITEM_DISCOUNT',
  DELETE_CART_ITEM_DISCOUNT_SUCCESS = 'DELETE_CART_ITEM_DISCOUNT_SUCCESS',
  DELETE_CART_ITEM_DISCOUNT_FAILED = 'DELETE_CART_ITEM_DISCOUNT_FAILED',
  DELETE_CART_ITEM_DISCOUNT_RESET = 'DELETE_CART_ITEM_DISCOUNT_RESET',
}

export enum AvailableFilterProperty {
  discountType = 'discountType',
}

interface IDeleteCartItemDiscountActionData {
  cartAliasId: string;
  cartItemAliasId: string;
  payload: IFilter[];
}

export const deleteCartItemDiscount = createAction(DeleteCartItemDiscountActionTypes.DELETE_CART_ITEM_DISCOUNT)
<IDeleteCartItemDiscountActionData>();
export const deleteCartItemDiscountSuccess = createAction(DeleteCartItemDiscountActionTypes.DELETE_CART_ITEM_DISCOUNT_SUCCESS)();
export const deleteCartItemDiscountFailure = createAction(DeleteCartItemDiscountActionTypes.DELETE_CART_ITEM_DISCOUNT_FAILED)
<IErrorActionData>();

export const deleteCartItemDiscountReset = createAction(DeleteCartItemDiscountActionTypes.DELETE_CART_ITEM_DISCOUNT_RESET)();

export type DeleteCartItemDiscountAction = ActionType<typeof deleteCartItemDiscount>;
type DeleteCartItemDiscountSuccessAction = ActionType<typeof deleteCartItemDiscountSuccess>;
type DeleteCartItemDiscountFailureAction = ActionType<typeof deleteCartItemDiscountFailure>;
type DeleteCartItemDiscountResetAction = ActionType<typeof deleteCartItemDiscountReset>;

export type DeleteCartItemDiscountActions = DeleteCartItemDiscountAction
| DeleteCartItemDiscountSuccessAction
| DeleteCartItemDiscountFailureAction
| DeleteCartItemDiscountResetAction;
