import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IAddCartItemDiscountPayload } from '../models/AddCartItemDiscount';

export enum AddCartItemDiscountActionTypes {
  ADD_CART_ITEM_DISCOUNT = 'ADD_CART_ITEM_DISCOUNT',
  ADD_CART_ITEM_DISCOUNT_SUCCESS = 'ADD_CART_ITEM_DISCOUNT_SUCCESS',
  ADD_CART_ITEM_DISCOUNT_FAILED = 'ADD_CART_ITEM_DISCOUNT_FAILED',
  ADD_CART_ITEM_DISCOUNT_RESET = 'ADD_CART_ITEM_DISCOUNT_RESET',
}

export interface IAddCartItemDiscountActionData {
  cartAliasId: string;
  cartItemAliasId: string;
  payload: IAddCartItemDiscountPayload;
}

export const addCartItemDiscount = createAction(AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT)<IAddCartItemDiscountActionData>();
export const addCartItemDiscountSuccess = createAction(AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT_SUCCESS)();
export const addCartItemDiscountFailure = createAction(
  AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT_FAILED,
)<IErrorActionData>();
export const addCartItemDiscountReset = createAction(AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT_RESET)();

export type AddCartItemDiscountAction = ActionType<typeof addCartItemDiscount>;
type AddCartItemDiscountSuccessAction = ActionType<typeof addCartItemDiscountSuccess>;
type AddCartItemDiscountFailureAction = ActionType<typeof addCartItemDiscountFailure>;
type AddCartItemDiscountResetAction = ActionType<typeof addCartItemDiscountReset>;

export type AddCartItemDiscountActions = AddCartItemDiscountAction
| AddCartItemDiscountSuccessAction
| AddCartItemDiscountFailureAction
| AddCartItemDiscountResetAction;
