import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum UpdatePaymentActionTypes {
  UPDATE_PAYMENT_ACTION = 'UPDATE_PAYMENT_ACTION',
  UPDATE_PAYMENT_ACTION_SUCCESS = 'UPDATE_PAYMENT_ACTION_SUCCESS',
  UPDATE_PAYMENT_ACTION_FAILURE = 'UPDATE_PAYMENT_ACTION_FAILURE',
  UPDATE_PAYMENT_ACTION_RESET = 'UPDATE_PAYMENT_ACTION_RESET',
}

export interface IUpdatePaymentActionData {
  paymentAliasId: string;
  orderReferenceId: string;
  action: string;
}

export interface IUpdatePaymentActionSuccessData {
  paymentAliasId: string;
}

interface IUpdatePaymentErrorActionData extends IErrorActionData {
  paymentAliasId: string;
}

export const updatePayment = createAction(UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION)<IUpdatePaymentActionData>();
export const updatePaymentSuccess = createAction(UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION_SUCCESS)<IUpdatePaymentActionSuccessData>();
export const updatePaymentFailure = createAction(UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION_FAILURE)<IUpdatePaymentErrorActionData>();
export const updatePaymentReset = createAction(UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION_RESET)();

export type UpdatePaymentAction = ActionType<typeof updatePayment>;
type UpdatePaymentSuccessAction = ActionType<typeof updatePaymentSuccess>;
type UpdatePaymentFailureAction = ActionType<typeof updatePaymentFailure>;
type UpdatePaymentResetAction = ActionType<typeof updatePaymentReset>;

export type UpdatePaymentActions =
  | UpdatePaymentAction
  | UpdatePaymentSuccessAction
  | UpdatePaymentFailureAction
  | UpdatePaymentResetAction;
