import { Reducer } from 'redux';

import { CompleteTaskActionTypes, CompleteTaskActions } from '../actions/CompleteTask';
import { IErrorActionData } from '../../../models/Error';

export interface ICompleteTaskState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCompleteTaskState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICompleteTaskState;

const CompleteTaskReducer: Reducer<ICompleteTaskState, CompleteTaskActions> = (
  state = initialCompleteTaskState,
  action: CompleteTaskActions,
): ICompleteTaskState => {
  switch (action.type) {
    case CompleteTaskActionTypes.COMPLETE_TASK:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CompleteTaskActionTypes.COMPLETE_TASK_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case CompleteTaskActionTypes.COMPLETE_TASK_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CompleteTaskActionTypes.COMPLETE_TASK_RESET:
      return initialCompleteTaskState;

    default:
      return state;
  }
};

export default CompleteTaskReducer;
