import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_CUSTOMER_LEDGER_DETAILS = gql`
  query getCustomerLedgerDetails($path: String!) {
    getCustomerLedgerDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getCustomerLedgerDetailsService = (customerAliasId: string) => {
  const url = URL.getCustomerLedgerDetails.replace('{customerAliasId}', customerAliasId);
  return client.query({ query: GET_CUSTOMER_LEDGER_DETAILS, variables: { path: url } });
};

export default getCustomerLedgerDetailsService;
