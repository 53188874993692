import { IApiAutoCompleteOptionItem } from '../../../models/Common';

export class StoreEmployeeList {
  storeEmployeeList = [] as IApiAutoCompleteOptionItem[];
  constructor(storeEmployeeList: IApiAutoCompleteOptionItem[]) {
    this.storeEmployeeList = storeEmployeeList;
  }
}

export const mergeTwoOptionLists = (
  existingList: Array<IApiAutoCompleteOptionItem>,
  payloadList: Array<IApiAutoCompleteOptionItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
