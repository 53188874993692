/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getShipmentDetailsSuccess,
  getShipmentDetailsFailure,
  GetShipmentDetailsAction,
  GetShipmentDetailsActionTypes,
} from '../actions/GetShipmentDetails';
import getShipmentDetailsService from '../services/GetShipmentDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetShipmentDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS)),
  mergeMap((action: GetShipmentDetailsAction) => from(
    getShipmentDetailsService(action.payload.supplyOrderAliasId),
  ).pipe(
    map((response) => getShipmentDetailsSuccess({
      shipmentDetails: response.data.getShipmentDetails.data || [],
    })),
    catchError((error: IErrorActionData) => of(
      getShipmentDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetShipmentDetailsEpic;
