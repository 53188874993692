/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getReturnDetailsSuccess,
  getReturnDetailsFailure,
  GetReturnDetailsAction,
  GetReturnDetailsActionTypes,
} from '../actions/GetReturnDetails';
import getReturnDetailsService from '../services/GetReturnDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetReturnDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetReturnDetailsActionTypes.GET_RETURN_DETAILS)),
  mergeMap((action: GetReturnDetailsAction) => from(
    getReturnDetailsService(action.payload.returnAliasId),
  ).pipe(
    map((response: any) => getReturnDetailsSuccess({
      returnDetails: response.data.getReturnDetails.data || {},
    })),
    catchError((error: IErrorActionData) => of(
      getReturnDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetReturnDetailsEpic;
