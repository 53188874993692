import CreateCustomerReducer from './CreateCustomer';
import CreateProjectReducer from './CreateProject';
import GetCustomerAddressReducer from './GetCustomerAddress';
import GetCustomerDetailsReducer from './GetCustomerDetails';
import GetCustomerDetailsListReducer from './GetCustomerDetailsList';
import GetCustomerSavedCartListCountReducer from './GetCustomerSavedCartListCount';
import GetProjectListReducer from './GetProjectList';
import GetSearchCustomerListReducer from './GetSearchCustomerList';
import UpdateCustomerDetailsReducer from './UpdateCustomerDetails';
import UpdateProjectDetailsReducer from './UpdateProjectDetails';

const CustomerReducers = {
  createCustomer: CreateCustomerReducer,
  createProject: CreateProjectReducer,
  getCustomerAddress: GetCustomerAddressReducer,
  getCustomerDetails: GetCustomerDetailsReducer,
  customerDetailsList: GetCustomerDetailsListReducer,
  getCustomerSavedCartListCount: GetCustomerSavedCartListCountReducer,
  getSearchCustomerList: GetSearchCustomerListReducer,
  updateCustomerDetails: UpdateCustomerDetailsReducer,
  updateProjectDetails: UpdateProjectDetailsReducer,
  getProjectList: GetProjectListReducer,
};

export default CustomerReducers;
