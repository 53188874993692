import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { IInfluencerDetails } from '../models/InfluencerDetails';

export enum GetInfluencerDetailsActionTypes {
  GET_INFLUENCER_DETAILS = 'GET_INFLUENCER_DETAILS',
  GET_INFLUENCER_DETAILS_SUCCESS = 'GET_INFLUENCER_DETAILS_SUCCESS',
  GET_INFLUENCER_DETAILS_FAILED = 'GET_INFLUENCER_DETAILS_FAILED',
  GET_INFLUENCER_DETAILS_RESET = 'GET_INFLUENCER_DETAILS_RESET',
}

export interface IGetInfluencerDetailsActionData {
  filter?: IFilter[];
}

export enum AvailableFilterProperty {
  phoneNumber = 'phoneNumber',
}

export interface IGetInfluencerDetailsSuccessActionData {
  data: IInfluencerDetails;
}

export const getInfluencerDetails = createAction(GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS)
<IGetInfluencerDetailsActionData>();
export const getInfluencerDetailsSuccess = createAction(GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS_SUCCESS)
<IGetInfluencerDetailsSuccessActionData>();
export const getInfluencerDetailsFailed = createAction(GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS_FAILED)
<IErrorActionData>();
export const getInfluencerDetailsReset = createAction(GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS_RESET)();

export type GetInfluencerDetailsAction = ActionType<typeof getInfluencerDetails>;
type GetInfluencerDetailsSuccessAction = ActionType<typeof getInfluencerDetailsSuccess>;
type GetInfluencerDetailsFailedAction = ActionType<typeof getInfluencerDetailsFailed>;
type GetInfluencerDetailsResetAction = ActionType<typeof getInfluencerDetailsReset>;

export type GetInfluencerDetailsActions =
  | GetInfluencerDetailsAction
  | GetInfluencerDetailsSuccessAction
  | GetInfluencerDetailsFailedAction
  | GetInfluencerDetailsResetAction;
