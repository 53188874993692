import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_OPENING_CASH_BALANCE_DETAILS = gql`
  query getOpeningCashBalanceDetails($path: String!) {
    getOpeningCashBalanceDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getOpeningCashBalanceDetailsService = (storeAliasId: string) => client.query({
  query: GET_OPENING_CASH_BALANCE_DETAILS,
  variables: {
    path: URL.getOpeningCashBalance.replace('{storeAliasId}', storeAliasId),
  },
});

export default getOpeningCashBalanceDetailsService;
