import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum LogoutActionTypes {
  LOGOUT_ACTION = 'LOGOUT_ACTION',
  LOGOUT_ACTION_SUCCESS = 'LOGOUT_ACTION_SUCCESS',
  LOGOUT_ACTION_FAILURE = 'LOGOUT_ACTION_FAILURE',
  LOGOUT_ACTION_RESET = 'LOGOUT_ACTION_RESET',
}

export interface ILogoutActionData {
  info?: {
    LogoutAtTs: number
  }
}

export const logout = createAction(LogoutActionTypes.LOGOUT_ACTION)<ILogoutActionData>();
export const logoutSuccess = createAction(LogoutActionTypes.LOGOUT_ACTION_SUCCESS)();
export const logoutFailure = createAction(LogoutActionTypes.LOGOUT_ACTION_FAILURE)<IErrorActionData>();
export const logoutReset = createAction(LogoutActionTypes.LOGOUT_ACTION_RESET)();

export type LogoutAction = ActionType<typeof logout>;
type LogoutSuccessAction = ActionType<typeof logoutSuccess>;
type LogoutFailureAction = ActionType<typeof logoutFailure>;
type LogoutResetAction = ActionType<typeof logoutReset>;

export type LogoutActions =
  | LogoutAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | LogoutResetAction;
