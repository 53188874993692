import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { EditCashClosureActions, EditCashClosureActionTypes } from '../actions/EditCashClosure';

export interface IEditCashClosureState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialEditCashClosureState: IEditCashClosureState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const EditCashClosureReducer: Reducer<IEditCashClosureState, EditCashClosureActions> = (
  state = initialEditCashClosureState,
  action: EditCashClosureActions,
): IEditCashClosureState => {
  switch (action.type) {
    case EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION_RESET:
      return initialEditCashClosureState;

    default:
      return state;
  }
};

export default EditCashClosureReducer;
