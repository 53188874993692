import { Reducer } from 'redux';

import { OrderList, mergeTwoLists } from '../models/OrderList';
import { GetOrderListActionTypes, GetOrderListActions } from '../actions/GetOrderList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IOrderListState {
  orderList: OrderList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialOrderListState = {
  orderList: new OrderList([]),
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IOrderListState;

const GetOrderListReducer: Reducer<IOrderListState, GetOrderListActions> = (
  state = initialOrderListState,
  action: GetOrderListActions,
): IOrderListState => {
  switch (action.type) {
    case GetOrderListActionTypes.GET_ORDER_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetOrderListActionTypes.GET_ORDER_LIST_SUCCESS: {
      const newOrderList = mergeTwoLists(
        state.orderList.orderList,
        action.payload.orderList,
      );
      return {
        ...state,
        orderList: new OrderList(newOrderList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
          cursor: action.payload.pageInfo.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetOrderListActionTypes.GET_ORDER_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetOrderListActionTypes.GET_ORDER_LIST_RESET:
      return initialOrderListState;

    default:
      return state;
  }
};

export default GetOrderListReducer;
