import { Reducer } from 'redux';

import { addPresignedUrlToExisting, ProformaInvoiceUrlData } from '../models/ProformaInvoiceUrl';
import { GetProformaInvoiceUrlActionTypes, GetProformaInvoiceUrlActions } from '../actions/GetProformaInvoiceUrl';
import { IErrorActionData } from '../../../models/Error';

export interface IGetProformaInvoiceUrlState {
  proformaInvoiceUrlData: ProformaInvoiceUrlData;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProformaInvoiceUrlState = {
  proformaInvoiceUrlData: new ProformaInvoiceUrlData({}),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetProformaInvoiceUrlState;

const GetProformaInvoiceUrlReducer: Reducer<IGetProformaInvoiceUrlState, GetProformaInvoiceUrlActions> = (
  state = initialProformaInvoiceUrlState,
  action: GetProformaInvoiceUrlActions,
): IGetProformaInvoiceUrlState => {
  switch (action.type) {
    case GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL: {
      const updatedProformaInvoiceData = addPresignedUrlToExisting(
        state.proformaInvoiceUrlData.proformaInvoiceUrlData,
        action.payload.invoiceAliasId,
        { isProcessing: true, isSuccess: false },
      );
      return { ...state, proformaInvoiceUrlData: new ProformaInvoiceUrlData(updatedProformaInvoiceData) };
    }

    case GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL_SUCCESS: {
      const updatedProformaInvoiceData = addPresignedUrlToExisting(
        state.proformaInvoiceUrlData.proformaInvoiceUrlData,
        action.payload.invoiceAliasId,
        { isProcessing: false, isSuccess: true, proformaInvoiceUrl: action.payload.proformaInvoiceUrl },
      );
      return { ...state, proformaInvoiceUrlData: new ProformaInvoiceUrlData(updatedProformaInvoiceData) };
    }

    case GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL_FAILED: {
      const updatedProformaInvoiceData = addPresignedUrlToExisting(
        state.proformaInvoiceUrlData.proformaInvoiceUrlData,
        action.payload.invoiceAliasId,
        {
          isProcessing: false,
          isSuccess: false,
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, proformaInvoiceUrlData: new ProformaInvoiceUrlData(updatedProformaInvoiceData) };
    }

    case GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL_RESET:
      return initialProformaInvoiceUrlState;

    default:
      return state;
  }
};

export default GetProformaInvoiceUrlReducer;
