import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IOrderPaymentPayload } from '../models/OrderPayment';

const CREATE_ORDER_PAYMENT = gql`
  query createOrderPayment($path: String!, $payload: any) {
    createOrderPayment (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const createOrderPaymentService = (orderReferenceId: string, payload: IOrderPaymentPayload) => client.query({
  query: CREATE_ORDER_PAYMENT,
  variables: {
    path: URL.createOrderPayment.replace('{orderReferenceId}', orderReferenceId),
    payload,
  },
});

export default createOrderPaymentService;
