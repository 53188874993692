/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  completeTaskSuccess,
  completeTaskFailure,
  CompleteTaskAction,
  CompleteTaskActionTypes,
} from '../actions/CompleteTask';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import DisplayManagementActions from '../actions';
import completeTaskService from '../services/CompleteTask';

const CompleteTaskEpic = (action$: ActionsObservable<DisplayManagementActions>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CompleteTaskActionTypes.COMPLETE_TASK)),
  mergeMap((action: CompleteTaskAction) => from(
    completeTaskService(action.payload.taskAliasId, action.payload.payload),
  ).pipe(
    map(() => completeTaskSuccess()),
    catchError((error: IErrorActionData) => of(
      completeTaskFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CompleteTaskEpic;
