import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { GetDealDetailsActionTypes, GetDealDetailsActions } from '../actions/GetDealDetails';
import { DealDetails, IDealDetails } from '../models/GetDealDetails';

export interface IDealDetailsState {
  dealDetails: DealDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initailDealDetailsState = {
  dealDetails: new DealDetails({} as IDealDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDealDetailsState;

const GetDealDetailsReducer: Reducer<IDealDetailsState, GetDealDetailsActions> = (
  state = initailDealDetailsState,
  action: GetDealDetailsActions,
): IDealDetailsState => {
  switch (action.type) {
    case GetDealDetailsActionTypes.GET_DEAL_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDealDetailsActionTypes.GET_DEAL_DETAILS_SUCCESS:
      return {
        ...state,
        dealDetails: new DealDetails(action.payload.dealDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetDealDetailsActionTypes.GET_DEAL_DETAILS_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDealDetailsActionTypes.RESET_GET_DEAL_DETAILS:
      return initailDealDetailsState;

    default:
      return state;
  }
};

export default GetDealDetailsReducer;
