import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { AutoLoginActions, AutoLoginActionTypes } from '../actions/AutoLogin';

export interface IAutoLoginState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialAutoLoginState: IAutoLoginState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const AutoLoginReducer: Reducer<IAutoLoginState, AutoLoginActions> = (
  state = initialAutoLoginState,
  action: AutoLoginActions,
): IAutoLoginState => {
  switch (action.type) {
    case AutoLoginActionTypes.AUTO_LOGIN:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case AutoLoginActionTypes.AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case AutoLoginActionTypes.AUTO_LOGIN_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case AutoLoginActionTypes.AUTO_LOGIN_RESET:
      return initialAutoLoginState;

    default:
      return state;
  }
};

export default AutoLoginReducer;
