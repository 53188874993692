/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  addCartItemSuccess,
  addCartItemFailure,
  AddCartItemAction,
  AddCartItemActionTypes,
} from '../actions/AddCartItem';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import addCartItemService from '../services/AddCartItem';

const AddCartItemEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(AddCartItemActionTypes.ADD_CART_ITEM)),
  mergeMap((action: AddCartItemAction) => from(
    addCartItemService(
      action.payload.newCartItem,
      action.payload.cartAliasId,
    ),
  ).pipe(
    map((response) => addCartItemSuccess({ newCartItemDetails: response.data.addCartItem.data })),
    catchError((error: IErrorActionData) => of(
      addCartItemFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default AddCartItemEpic;
