import { Reducer } from 'redux';

import { GenerateQuotationActionTypes, GenerateQuotationActions } from '../actions/GenerateQuotation';
import { GenerateQuotation, IQuotation } from '../models/GenerateQuotation';
import { IErrorActionData } from '../../../models/Error';

export interface IGenerateQuotationState {
  generatedQuotation: GenerateQuotation
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGenerateQuotationState = {
  generatedQuotation: new GenerateQuotation({} as IQuotation),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGenerateQuotationState;

const GenerateQuotationReducer: Reducer<IGenerateQuotationState, GenerateQuotationActions> = (
  state = initialGenerateQuotationState,
  action: GenerateQuotationActions,
): IGenerateQuotationState => {
  switch (action.type) {
    case GenerateQuotationActionTypes.GENERATE_QUOTATION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GenerateQuotationActionTypes.GENERATE_QUOTATION_SUCCESS: {
      return {
        ...state,
        generatedQuotation: new GenerateQuotation({
          quotationAliasId: action.payload.quotationAliasId,
          quotationPdfUrl: action.payload.quotationPdfUrl,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GenerateQuotationActionTypes.GENERATE_QUOTATION_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GenerateQuotationActionTypes.GENERATE_QUOTATION_RESET:
      return initialGenerateQuotationState;

    default:
      return state;
  }
};

export default GenerateQuotationReducer;
