import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IOrderListItem } from '../models/OrderList';

export enum GetOrderListActionTypes {
  GET_ORDER_LIST = 'GET_ORDER_LIST',
  GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS',
  GET_ORDER_LIST_FAILED = 'GET_ORDER_LIST_FAILED',
  GET_ORDER_LIST_RESET = 'GET_ORDER_LIST_RESET',
}

export enum AvailableFilterProperty {
  phone = 'phone',
  orderAliasId = 'orderAliasId',
  supplyOrderId = 'supplyOrderId',
  storeAliasId = 'storeAliasId',
  orderState = 'orderState',
  supplyOrderState = 'supplyOrderState',
  orderStartDate = 'orderStartDate',
  orderEndDate = 'orderEndDate',
}

export interface IGetOrderListActionData {
  filter?: IFilter[];
  page?: IPage;
}
export interface IGetOrderListSuccessActionData {
  orderList: Array<IOrderListItem>;
  pageInfo: IPageInfo;
}

export const getOrderList = createAction(GetOrderListActionTypes.GET_ORDER_LIST)<IGetOrderListActionData>();
export const getOrderListSuccess = createAction(GetOrderListActionTypes.GET_ORDER_LIST_SUCCESS)
<IGetOrderListSuccessActionData>();
export const getOrderListFailure = createAction(GetOrderListActionTypes.GET_ORDER_LIST_FAILED)
<IErrorActionData>();
export const getOrderListReset = createAction(GetOrderListActionTypes.GET_ORDER_LIST_RESET)();

export type GetOrderListAction = ActionType<typeof getOrderList>;
type GetOrderListSuccessAction = ActionType<typeof getOrderListSuccess>;
type GetOrderListFailedAction = ActionType<typeof getOrderListFailure>;
type GetOrderListResetAction = ActionType<typeof getOrderListReset>;

export type GetOrderListActions =
  | GetOrderListAction
  | GetOrderListSuccessAction
  | GetOrderListFailedAction
  | GetOrderListResetAction;
