import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IDownloadCashClosureReportPayload } from '../models/DownloadCashClosureReport';

const DOWNLOAD_CASH_CLOSURE_REPORT = gql`
  query downloadCashClosureReport($path: String!, $payload: any) {
    downloadCashClosureReport (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const downloadCashClosureReportService = (storeAliasId: string, payloadDetails?: IDownloadCashClosureReportPayload) => {
  const url = URL.downloadCashClosureReport.replace('{storeAliasId}', storeAliasId);
  return client.query({ query: DOWNLOAD_CASH_CLOSURE_REPORT, variables: { path: url, payload: payloadDetails } });
};

export default downloadCashClosureReportService;
