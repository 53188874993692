import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { INewCustomer } from '../models/NewCustomer';

export enum CreateCustomerActionTypes {
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED',
  CREATE_CUSTOMER_RESET = 'CREATE_CUSTOMER_RESET',
}

export interface ICreateCustomerActionData {
  firstName: string;
  lastName: string;
  customerNumber: string;
  loginId: number;
  companyName?: string;
  gst?: string;
}

export interface ICreateCustomerSuccessActionData {
  newCustomerData: INewCustomer;
}

export const createCustomer = createAction(CreateCustomerActionTypes.CREATE_CUSTOMER)
<ICreateCustomerActionData>();
export const createCustomerSuccess = createAction(CreateCustomerActionTypes.CREATE_CUSTOMER_SUCCESS)
<ICreateCustomerSuccessActionData>();
export const createCustomerFailed = createAction(CreateCustomerActionTypes.CREATE_CUSTOMER_FAILED)
<IErrorActionData>();
export const createCustomerReset = createAction(CreateCustomerActionTypes.CREATE_CUSTOMER_RESET)();

export type CreateCustomerAction = ActionType<typeof createCustomer>;
type CreateCustomerSuccessAction = ActionType<typeof createCustomerSuccess>;
type CreateCustomerFailedAction = ActionType<typeof createCustomerFailed>;
type CreateCustomerResetAction = ActionType<typeof createCustomerReset>;

export type CreateCustomerActions =
  | CreateCustomerAction
  | CreateCustomerSuccessAction
  | CreateCustomerFailedAction
  | CreateCustomerResetAction;
