import { ReturnStatus } from '../../../constants/Strings';

export interface ReturnListItem {
  returnAliasId: string;
  status: ReturnStatus;
  initiatedDate: number;
  shipmentCode: string;
  customerMobileNumber: number;
  customerName: string;
  returnAmount: number;
  returnedDate: number;
}

export class ReturnList {
  returnList = [] as Array<ReturnListItem>;
  constructor(returnList: Array<ReturnListItem>) {
    this.returnList = returnList;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<ReturnListItem>,
  payloadList: Array<ReturnListItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
