import { ShipmentStatus } from '../../../constants/Strings';

export interface IInvoiceInfo {
  invoiceAliasId: string;
  invoiceCode: string;
  status: string;
}

export interface IShipmentItem {
  sku: string;
  quantity: number;
  productName: string;
  measurementUnitName: string;
  sellingPrice: number;
  totalPrice: number;
  imageUrl: string;
}

export interface IShipmentDetails {
  shipmentId: number;
  shipmentCode: string;
  shipmentStatus: ShipmentStatus;
  shipmentItems: Array<IShipmentItem>;
  invoiceInfo: IInvoiceInfo;
  ewaybillInfo: IInvoiceInfo;
}

export class ShipmentDetails {
  shipmentDetails = [] as Array<IShipmentDetails>;
  constructor(shipmentDetails: Array<IShipmentDetails>) {
    this.shipmentDetails = shipmentDetails;
  }
}
