/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getAddressListSuccess,
  getAddressListFailed,
  GetAddressListAction,
  GetAddressListActionTypes,
} from '../actions/GetAddressList';
import { IErrorActionData } from '../../../models/Error';
import getAddressListService from '../services/GetAddressList';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetAddressListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetAddressListActionTypes.GET_ADDRESS_LIST)),
  mergeMap((action: GetAddressListAction) => from(
    getAddressListService(action.payload.customerId),
  ).pipe(
    map((response: any) => getAddressListSuccess({ data: response.data.getAddressList.data || [] })),
    catchError((error: IErrorActionData) => of(
      getAddressListFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetAddressListEpic;
