import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { LedgerDownloadReportStates } from '../../../constants/Strings';

export enum GetDownloadLedgerStatementStatusActionTypes {
  GET_DOWNLOAD_LEDGER_STATEMENT_STATUS = 'GET_DOWNLOAD_LEDGER_STATEMENT_STATUS',
  GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_SUCCESS = 'GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_SUCCESS',
  GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_FAILED = 'GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_FAILED',
  GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_RESET = 'GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_RESET',
}

export interface IGetDownloadLedgerStatementStatusActionData {
  requestId: string;
}

export interface IGetDownloadLedgerStatementStatusSuccessActionData {
  message: LedgerDownloadReportStates;
  url: string;
}

export const getDownloadLedgerStatementStatus = createAction(
  GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS,
)<IGetDownloadLedgerStatementStatusActionData>();
export const getDownloadLedgerStatementStatusSuccess = createAction(
  GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_SUCCESS,
)<IGetDownloadLedgerStatementStatusSuccessActionData>();
export const getDownloadLedgerStatementStatusFailed = createAction(
  GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_FAILED,
)<IErrorActionData>();
export const getDownloadLedgerStatementStatusReset = createAction(
  GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_RESET,
)();

export type GetDownloadLedgerStatementStatusAction = ActionType<typeof getDownloadLedgerStatementStatus>;
type GetDownloadLedgerStatementStatusSuccessAction = ActionType<typeof getDownloadLedgerStatementStatusSuccess>;
type GetDownloadLedgerStatementStatusFailedAction = ActionType<typeof getDownloadLedgerStatementStatusFailed>;
type GetDownloadLedgerStatementStatusResetAction = ActionType<typeof getDownloadLedgerStatementStatusReset>;

export type GetDownloadLedgerStatementStatusActions =
  | GetDownloadLedgerStatementStatusAction
  | GetDownloadLedgerStatementStatusSuccessAction
  | GetDownloadLedgerStatementStatusFailedAction
  | GetDownloadLedgerStatementStatusResetAction;
