import { Reducer } from 'redux';

import { DeleteDesignActionTypes, DeleteDesignActions } from '../actions/DeleteDesign';
import { IErrorActionData } from '../../../models/Error';

export interface IDelteDesignState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDeleteDesignState: IDelteDesignState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
};

const DeleteDesignReducer: Reducer<IDelteDesignState, DeleteDesignActions> = (
  state = initialDeleteDesignState,
  action: DeleteDesignActions,
): IDelteDesignState => {
  switch (action.type) {
    case DeleteDesignActionTypes.DELETE_KITCHEN:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case DeleteDesignActionTypes.DELETE_KITCHEN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case DeleteDesignActionTypes.DELETE_KITCHEN_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case DeleteDesignActionTypes.DELETE_KITCHEN_RESET:
      return initialDeleteDesignState;

    default:
      return state;
  }
};

export default DeleteDesignReducer;
