interface IProductCategoryDetails {
  productCategoryAliasId: string;
  productCategoryName: string;
}

interface IPotentialQuantity {
  quantity: number;
  skuMeasurementUnitAliasId: string;
}

interface IDealStatus {
  reason: string;
  state: string;
}

interface IInfo {
  remarks: string;
}

interface ILastInteractionDetails {
  date: number;
  type: string;
}

interface IAssigneeDetails {
  lastInteractionTs: number;
  name: string;
  userAliasId: string;
}

interface ICustomerDetails {
  customerAliasId: string;
  name: string;
  gstin: string;
  phoneNumber: string;
}

interface IAddress {
  name: string;
  addressLine1: string;
  addressLine2: string;
  pincode: string;
  city: string;
  district: string;
  state: string;
  country: string;
}

interface ISiteDetails {
  siteAliasId: string;
  name: string;
  address: IAddress;
}

interface IConstructionDetails {
  lastUpdatedTs: number;
  stage: string;
}

interface IProjectDetails {
  constructionDetails: IConstructionDetails;
  location: string;
  name: string;
  constructionType: string;
  projectIdentifier: string;
}

export interface IDealDetails {
  creatorDetails: IAssigneeDetails;
  currentAssigneeDetails: IAssigneeDetails;
  dealAliasId: string;
  dealCompletion: string;
  dealStatus: IDealStatus;
  dealValidUntilTs: number;
  info: IInfo;
  lastInteraction: ILastInteractionDetails;
  potentialAmount: string;
  potentialQuantity: IPotentialQuantity;
  productCategoryDetails: IProductCategoryDetails;
}

export interface DealListItem {
  customerDetails: ICustomerDetails;
  siteDetails: ISiteDetails;
  projectDetails: IProjectDetails;
  dealDetails: IDealDetails;
}

export class DealList {
  dealList: Array<DealListItem> = [];
  constructor(dealList: Array<DealListItem> = []) {
    this.dealList = dealList;
  }
}
export const mergeTwoLists = (
  existingList: Array<DealListItem>,
  payloadList: Array<DealListItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
