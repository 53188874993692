/* eslint-disable import/prefer-default-export */
import { Observable } from 'rxjs';

export const downloadFileFromUrl = (url: string, fileName: string) => {
  fetch(url, { method: 'GET' })
    .then((res) => res.blob())
    .then((blob) => {
      const element = document.createElement('a');
      element.setAttribute('href', window.URL.createObjectURL(blob));
      element.setAttribute('download', fileName);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    })
    .catch((err) => {
      console.error('err: ', err);
    });
};

export const uploadFile = (url:string, payload: FormData) => {
  const userLoginToken = localStorage.getItem('userLoginToken');
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  if (!baseUrl) {
    throw new Error('API base URL is not defined');
  }

  if (!userLoginToken) {
    throw new Error('No user login token available');
  }

  const uploadUrl = `${baseUrl}${url}`;

  return new Observable<void>((observer) => {
    fetch(uploadUrl, {
      method: 'POST',
      body: payload,
      headers: {
        Authorization: userLoginToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('File upload failed');
        }
        observer.next();
        observer.complete();
      })
      .catch((error) => {
        observer.error(error);
      });
  });
};
