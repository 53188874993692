import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_RECOMMENDATION_TASK_SUMMARY = gql`
  query getRecommendationTaskSummary($path: String!) {
    getRecommendationTaskSummary @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getRecommendationTaskSummaryService = (processAliasId: string) => {
  const url = URL.getRecommendationTaskSummary.replace('{processAliasId}', processAliasId);
  return client.query({ query: GET_RECOMMENDATION_TASK_SUMMARY, variables: { path: url } });
};

export default getRecommendationTaskSummaryService;
