import { Reducer } from 'redux';

import { GetCustomerAddressActions, GetCustomerAddressActionTypes } from '../actions/GetCustomerAddress';
import { CustomerAddress, addCustomerAddressToExisting } from '../models/CustomerAddress';
import { IErrorActionData } from '../../../models/Error';

export interface IGetCustomerAddressState {
  customerAddress: CustomerAddress;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetCustomerAddressState = {
  customerAddress: new CustomerAddress({}),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetCustomerAddressState;

const GetCustomerAddressReducer: Reducer<IGetCustomerAddressState, GetCustomerAddressActions> = (
  state = initialGetCustomerAddressState,
  action: GetCustomerAddressActions,
): IGetCustomerAddressState => {
  switch (action.type) {
    case GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS: {
      const updatedCustomerAddress = addCustomerAddressToExisting(
        state.customerAddress.customerAddress,
        action.payload.addressAliasId,
        { isProcessing: true, isSuccess: false },
      );
      return { ...state, customerAddress: new CustomerAddress(updatedCustomerAddress) };
    }

    case GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS_SUCCESS: {
      const updatedCustomerAddress = addCustomerAddressToExisting(
        state.customerAddress.customerAddress,
        action.payload.addressAliasId,
        { isProcessing: false, isSuccess: true, customerAddress: action.payload.data },
      );
      return { ...state, customerAddress: new CustomerAddress(updatedCustomerAddress) };
    }

    case GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS_FAILED: {
      const updatedCustomerAddress = addCustomerAddressToExisting(
        state.customerAddress.customerAddress,
        action.payload.addressAliasId,
        {
          isProcessing: false,
          isSuccess: false,
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, customerAddress: new CustomerAddress(updatedCustomerAddress) };
    }

    case GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS_RESET:
      return initialGetCustomerAddressState;

    default:
      return state;
  }
};

export default GetCustomerAddressReducer;
