import { Reducer } from 'redux';

import { addPresignedUrlToExisting, LedgerTxnInvoiceUrlData } from '../models/GetLedgerTxnInvoiceUrl';
import { GetLedgerTxnInvoiceUrlActionTypes, GetLedgerTxnInvoiceUrlActions } from '../actions/GetLedgerTxnInvoiceUrl';
import { IErrorActionData } from '../../../models/Error';

export interface IGetLedgerTxnInvoiceUrlState {
  ledgerTxnInvoiceUrlData: LedgerTxnInvoiceUrlData;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialLedgerTxnInvoiceUrlState = {
  ledgerTxnInvoiceUrlData: new LedgerTxnInvoiceUrlData({}),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetLedgerTxnInvoiceUrlState;

const GetLedgerTxnInvoiceUrlReducer: Reducer<IGetLedgerTxnInvoiceUrlState, GetLedgerTxnInvoiceUrlActions> = (
  state = initialLedgerTxnInvoiceUrlState,
  action: GetLedgerTxnInvoiceUrlActions,
): IGetLedgerTxnInvoiceUrlState => {
  switch (action.type) {
    case GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL: {
      const updatedLedgerTxnInvoiceData = addPresignedUrlToExisting(
        state.ledgerTxnInvoiceUrlData.ledgerTxnInvoiceUrlData,
        action.payload.invoiceAliasId,
        { isProcessing: true, isSuccess: false },
      );
      return { ...state, ledgerTxnInvoiceUrlData: new LedgerTxnInvoiceUrlData(updatedLedgerTxnInvoiceData) };
    }

    case GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL_SUCCESS: {
      const updatedLedgerTxnInvoiceData = addPresignedUrlToExisting(
        state.ledgerTxnInvoiceUrlData.ledgerTxnInvoiceUrlData,
        action.payload.invoiceAliasId,
        { isProcessing: false, isSuccess: true, presignedInvoiceUrl: action.payload.preSignedUrl },
      );
      return { ...state, ledgerTxnInvoiceUrlData: new LedgerTxnInvoiceUrlData(updatedLedgerTxnInvoiceData) };
    }

    case GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL_FAILED: {
      const updatedShipmentInvoiceData = addPresignedUrlToExisting(
        state.ledgerTxnInvoiceUrlData.ledgerTxnInvoiceUrlData,
        action.payload.invoiceAliasId,
        {
          isProcessing: false,
          isSuccess: false,
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, ledgerTxnInvoiceUrlData: new LedgerTxnInvoiceUrlData(updatedShipmentInvoiceData) };
    }

    case GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL_RESET:
      return initialLedgerTxnInvoiceUrlState;

    default:
      return state;
  }
};

export default GetLedgerTxnInvoiceUrlReducer;
