import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_CART_ITEM_LIST = gql`
  query getCartItemList($path: String!) {
    getCartItemList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getCartItemListService = (cartAliasId: string) => client.query({
  query: GET_CART_ITEM_LIST,
  variables: { path: URL.getCartItemList.replace('{cartAliasId}', cartAliasId) },
});

export default getCartItemListService;
