import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum GetCustomerSavedCartListCountActionTypes {
  GET_CUSTOMER_SAVED_CART_LIST_COUNT = 'GET_CUSTOMER_SAVED_CART_LIST_COUNT',
  GET_CUSTOMER_SAVED_CART_LIST_COUNT_SUCCESS = 'GET_CUSTOMER_SAVED_CART_LIST_COUNT_SUCCESS',
  GET_CUSTOMER_SAVED_CART_LIST_COUNT_FAILED = 'GET_CUSTOMER_SAVED_CART_LIST_COUNT_FAILED',
  GET_CUSTOMER_SAVED_CART_LIST_COUNT_RESET = 'GET_CUSTOMER_SAVED_CART_LIST_COUNT_RESET',
}

export interface IGetCustomerSavedCartListCountActionData {
  filter?: IFilter[];
}

export enum AvailableFilterProperty {
  storeAliasId = 'storeAliasId',
  contactNumber = 'contactNumber',
}

export interface IGetCustomerSavedCartCountSuccessActionData {
  count: number;
}

export const getCustomerSavedCartListCount = createAction(GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT)
<IGetCustomerSavedCartListCountActionData>();
export const getCustomerSavedCartListCountSuccess = createAction(
  GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT_SUCCESS,
)
<IGetCustomerSavedCartCountSuccessActionData>();
export const getCustomerSavedCartListCountFailed = createAction(
  GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT_FAILED,
)
<IErrorActionData>();
export const getCustomerSavedCartListCountReset = createAction(
  GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT_RESET,
)();

export type GetCustomerSavedCartListCountAction = ActionType<typeof getCustomerSavedCartListCount>;
type GetCustomerSavedCartListCountSuccessAction = ActionType<typeof getCustomerSavedCartListCountSuccess>;
type GetCustomerSavedCartListCountFailedAction = ActionType<typeof getCustomerSavedCartListCountFailed>;
type GetCustomerSavedCartListCountResetAction = ActionType<typeof getCustomerSavedCartListCountReset>;

export type GetCustomerSavedCartListCountActions =
  | GetCustomerSavedCartListCountAction
  | GetCustomerSavedCartListCountSuccessAction
  | GetCustomerSavedCartListCountFailedAction
  | GetCustomerSavedCartListCountResetAction;
