import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IPage } from '../../../utils/sortFilterFramework';

const GET_STORE_LIST = gql`
  query getStoreList($path: String!) {
    getStoreList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getStoreListService = (page: IPage) => {
  const url = constructUrlWithParams(URL.getStoreList, undefined, undefined, page);
  return client.query({ query: GET_STORE_LIST, variables: { path: url } });
};

export default getStoreListService;
