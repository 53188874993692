import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { INewAddress, INewAddressPayload } from '../models/NewAddress';

export enum CreateAddressActionTypes {
  CREATE_ADDRESS = 'CREATE_ADDRESS',
  CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS',
  CREATE_ADDRESS_FAILED = 'CREATE_ADDRESS_FAILED',
  CREATE_ADDRESS_RESET = 'CREATE_ADDRESS_RESET',
}

export interface ICreateAddressActionData {
  newAddress: INewAddressPayload;
  customerId: string;
}

export interface ICreateAddressSuccessActionData {
  newAddressData: INewAddress;
}

export const createAddress = createAction(CreateAddressActionTypes.CREATE_ADDRESS)
<ICreateAddressActionData>();
export const createAddressSuccess = createAction(CreateAddressActionTypes.CREATE_ADDRESS_SUCCESS)
<ICreateAddressSuccessActionData>();
export const createAddressFailed = createAction(CreateAddressActionTypes.CREATE_ADDRESS_FAILED)
<IErrorActionData>();
export const createAddressReset = createAction(CreateAddressActionTypes.CREATE_ADDRESS_RESET)();

export type CreateAddressAction = ActionType<typeof createAddress>;
type CreateAddressSuccessAction = ActionType<typeof createAddressSuccess>;
type CreateAddressFailedAction = ActionType<typeof createAddressFailed>;
type CreateAddressResetAction = ActionType<typeof createAddressReset>;

export type CreateAddressActions =
  | CreateAddressAction
  | CreateAddressSuccessAction
  | CreateAddressFailedAction
  | CreateAddressResetAction;
