/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getEWayBillUrlSuccess,
  getEWayBillUrlFailure,
  GetEWayBillUrlAction,
  GetEWayBillUrlActionTypes,
} from '../actions/GetEWayBillUrl';
import getEWayBillUrlService from '../services/GetEWayBillUrl';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetEWayBillUrlEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL)),
  mergeMap((action: GetEWayBillUrlAction) => from(
    getEWayBillUrlService(action.payload.invoiceAliasId),
  ).pipe(
    map((response) => getEWayBillUrlSuccess({
      eWayBillPresignedUrl: response.data.getEWayBillUrl.data.preSignedUrl || '',
      eWayBillAliasId: action.payload.invoiceAliasId,
    })),
    catchError((error: IErrorActionData) => of(
      getEWayBillUrlFailure({
        invoiceAliasId: action.payload.invoiceAliasId,
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetEWayBillUrlEpic;
