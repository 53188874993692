import GetDealDesignListEpic from './GetDealDesignList';
import DeleteDesignEpic from './DeleteDesign';
import GetDealDetailsEpic from './GetDealDetails';
import GetDealListEpic from './GetDealList';
import SetActiveDesignEpic from './SetActiveDesign';
import UploadDesignEpic from './UploadDesign';

const DealEpics = [
  GetDealListEpic,
  GetDealDetailsEpic,
  GetDealDesignListEpic,
  SetActiveDesignEpic,
  DeleteDesignEpic,
  UploadDesignEpic,
];

export default DealEpics;
