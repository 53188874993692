/* eslint-disable import/prefer-default-export */
export enum ServerDataTypes {
  CustomersList = 'CustomersList',
}

export enum CustomerDataFields {
  stateField = 'state',
}

export enum Channel {
  FOFO = 'fofo',
}

export enum SortOrder {
  AZ = 'A-Z',
  ZA = 'Z-A',
  Highest = 'HIGHEST',
  Lowest = 'LOWEST',
  Oldest = 'OLDEST',
  Latest = 'LATEST',
}

export enum GSTInfo {
  cgstPercentage = 9.0,
  sgstPercentage = 9.0,
  igstPercentage = 0.0,
}

export enum AddressTypes {
  SHIPPING = 'shipping',
  BILLING = 'billing',
}

export enum PaymentModes {
  CASH = 'cash',
  CHEQUE = 'cheque',
  IMPS = 'imps',
  NEFT = 'neft',
  RTGS = 'rtgs',
  CARD = 'card',
  UPI = 'upi',
  CREDIT = 'credit',
  CREDIT_NOTE = 'credit_note',
  AVAILABLE_CREDIT = 'credit_available',
  REVERTED = 'customer_ledger_revert',
  NONE = 'none',
}

export enum PaymentStates {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
}

export enum CartState {
  NEW = 'new',
  SAVED = 'saved',
  COMPLETE = 'complete',
  ORDER_CREATION_INITIATED = 'order_creation_initiated',
  FAILED_TO_CREATE_ORDER = 'failed_to_create_order',
  CLONED = 'cloned',
  OBSOLETE = 'obsolete',
}

export enum OrderStatus {
  PARKED = 'parked',
  PROCESSING_IN_PROCESS = 'processing_in_process',
  IN_PROCESS = 'in_process',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum CashTransactionsFilterDateBy {
  TRANSACTION_DATE = 'transactionDate',
  DEPOSIT_DATE = 'depositDate',
  CLOSING_DATE = 'closingDate',
}

export enum DateFilter {
  YESTERDAY = 'Yesterday',
  LAST_WEEK = 'Last 7 Days',
  MONTH = 'This Month',
  CUSTOM_DATE = 'Custom',
}

export enum DateLedgerFilter {
  FINANCIAL_YEAR = 'Financial Year',
  LAST_WEEK = 'This Week',
  MONTH = 'This Month',
  CUSTOM_DATE = 'Custom',
}

export enum CashClosureFilterDateBy {
  DEPOSIT_DATE = 'depositDate',
  CLOSING_DATE = 'closingDate',
}

export enum ShipmentStatus {
  CREATED = 'created',
  SHIPPED = 'shipped',
  CNA_AT = 'cna_at',
  DISPATCHED = 'dispatched',
  READY_TO_SHIP = 'ready_to_ship',
  DELIVERED = 'delivered',
  RETURNED = 'returned',
}

export enum CancellationStatus {
  INITIATED = 'initiated',
  FAILED = 'failed',
  ERROR = 'error',
  CANCELLED = 'cancelled',
  USER_ACTION_NEEDED = 'user_action_needed',
  FULFILMENT_UPDATE = 'fulfilment_update',
  ORDER_UPDATE = 'order_update',
  COMPLETED = 'completed',
  IN_PROCESS = 'in_process',
  PARTIALLY_COMPLETED = 'partially_completed',
}

export const CancellationStatusMap = new Map([
  ['initiated', 'IN PROCESS'],
  ['failed', 'FAILED'],
  ['error', 'ERROR'],
  ['cancelled', 'REQUEST CANCELLED'],
  ['user_action_needed', 'IN PROCESS'],
  ['fulfilment_update', 'IN PROCESS'],
  ['order_update', 'IN PROCESS'],
  ['completed', 'COMPLETED'],
  ['in_process', 'IN PROCESS'],
  ['partially_completed', 'COMPLETED'],
]);

export enum SkuCancellationStatus {
  INITIATED = 'initiated',
  FAILED = 'failed',
  ERROR = 'error',
  CANCELLED = 'cancelled',
  USER_ACTION_NEEDED = 'user_action_needed',
  FULFILMENT_UPDATE = 'fulfilment_update',
  ORDER_UPDATE = 'order_update',
  COMPLETED = 'completed',
}

export const SkuCancellationStatusMap = new Map([
  ['initiated', 'IN PROCESS'],
  ['failed', 'FAILED'],
  ['error', 'ERROR'],
  ['cancelled', 'REQUEST CANCELLED'],
  ['user_action_needed', 'IN PROCESS'],
  ['fulfilment_update', 'IN PROCESS'],
  ['order_update', 'IN PROCESS'],
  ['completed', 'COMPLETED'],
]);

export const ShipmentStatusMap = new Map([
  ['created', 'CREATED'],
  ['shipped', 'SHIPPED'],
  ['cna_at', 'RE-ATTEMPT DELIVERY'],
  ['dispatched', 'SHIPPED'],
  ['ready_to_ship', 'RTS'],
  ['delivered', 'DELIVERED'],
  ['returned', 'RETURNED'],
]);

export const OrderStatusMap = new Map([
  ['parked', 'ORDER PARKED'],
  ['processing_in_process', 'PROCESSING...'],
  ['in_process', 'ORDER PROCESSING'],
  ['completed', 'COMPLETED'],
  ['cancelled', 'CANCELLED'],
]);

export const PaymentStatesMap = new Map([
  ['pending', 'PENDING'],
  ['confirmed', 'CONFIRMED'],
]);

export enum ChannelNames {
  D2I = 'd2i',
  FOFO = 'fofo',
}

export const ChannelNameMap = new Map([
  ['d2i', 'D2I'],
  ['fofo', 'FOFO'],
]);

export enum SupplyOrderStatus {
  PROCESSING_IN_PROCESS = 'processing_in_process',
  CREATED = 'created',
  AWAITING_PAYMENT = 'awaiting_payment',
  PROCESSING_AWAITING_PAYMENT = 'processing_awaiting_payment',
  PAYMENT_REALISED = 'payment_realised',
  IN_PROCESS = 'in_process',
  PARTIALLY_SHIPPED = 'partially_shipped',
  SHIPPED = 'shipped',
  PARTIALLY_DELIVERED = 'partially_delivered',
  DELIVERED_WITHOUT_PAYMENT = 'delivered_without_payment',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  RETURNED = 'returned',
}

export const SupplyOrderStatusMap = new Map([
  ['processing_in_process', 'PROCESSING'],
  ['created', 'CREATED'],
  ['awaiting_payment', 'AWAITING PAYMENT'],
  ['processing_awaiting_payment', 'PROCESSING PAYMENT'],
  ['payment_realised', 'PAYMENT REALISED'],
  ['in_process', 'ORDER PROCESSING'],
  ['partially_shipped', 'PARTIALLY SHIPPED'],
  ['shipped', 'SHIPPED'],
  ['partially_delivered', 'PARTIALLY DELIVERED'],
  ['delivered_without_payment', 'DELIVERED WITHOUT PAYMENT'],
  ['delivered', 'DELIVERED'],
  ['completed', 'COMPLETED'],
  ['cancelled', 'CANCELLED'],
  ['returned', 'RETURNED'],
]);

export enum OnlineBankTransferModes {
  IMPS = 'imps',
  NEFT = 'neft',
  RTGS = 'rtgs',
}

export enum EdcMachinePaymentMethods {
  CARD = 'card',
  UPI_QR = 'upi',
}

export enum ActionBarButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum MenuItemActions {
  CANCEL = 'cancel',
}

export enum DiscountType {
  CATALOG = 'catalog',
  MARKETING = 'marketing',
  CART_LEVEL = 'cart_level',
}

export enum DiscountCategory {
  LINE = 'line',
  CART = 'cart',
}

export enum DiscountAggregationType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export enum SupplyOrderOptions {
  CANCEL_SKU = 'cancel_sku',
  CANCEL_QUANTITY = 'cancel_quantity',
  FULL_RETURN = 'full_return',
  RETURN_QUANTITY = 'return_quantity',
}

export enum InvoiceStatus {
  NOT_GENERATED = 'not_generated',
  INITIATED = 'initiate',
  CREATED = 'created',
  FAILED = 'failed',
  GENERATED = 'generated',
}

export enum UserDepartment {
  SALES = 'sales',
}

export const SupplyOrderFulfilmentFailureMap = new Map([
  ['INVALID_CHANNEL_NAME', 'shipping pincode outside of store state'],
  ['INVALID_ITEM_TYPE', 'product not available in Unicommerce'],
]);

export enum AuditEntities {
  ORDER = 'order',
  PAYMENT = 'payment',
  IM_OWNED = 'im_owned',
  NON_IM_OWNED = 'non_im_owned',
}

export enum SupplyOrderCategoryFilter {
  ALL = 'all',
  IM_OWNED = 'im_owned',
  NON_IM_OWNED = 'non_im_owned',
}

export type AuditEntityStatus = SupplyOrderStatus | OrderStatus | PaymentStates;

export enum DownloadReportStates {
  IN_PROCESS = 'in_process',
  FAILED = 'failed',
  NO_RECORD = 'no_record',
  UPLOADED = 'uploaded',
}

export enum RefundStatus {
  INITIATED = 'initiated',
  REFUNDED = 'refunded',
  CANCELLED = 'cancelled',
}

export const RefundStatusMap = new Map([
  ['initiated', 'INITIATED'],
  ['refunded', 'REFUNDED'],
  ['cancelled', 'CANCELLED'],
]);

export enum SkuStatus {
  CANCELLED = 'cancelled',
  PARTIALLY_CANCELLED = 'partially_cancelled',
  RETURNED = 'returned',
  PARTIALLY_RETURNED = 'partially_returned',
}

export const SkuStatusMap = new Map([
  ['cancelled', 'CANCELLED'],
  ['partially_cancelled', 'PARTIALLY CANCELLED'],
  ['returned', 'RETURNED'],
  ['partially_returned', 'PARTIALLY RETURNED'],
]);

export enum EDCInstrumentType {
  PAYTM = 'paytm',
  PINELABS = 'pine_labs',
}

export const EDCInstrumentTypeMap = new Map([
  ['paytm', 'PAYTM'],
  ['pine_labs', 'PINE LABS'],
]);

export const GroupTypeMap = new Map([
  ['NON-IM', 'Non-IM Owned'],
  ['IM', 'IM Owned'],
]);

export enum ReturnStatus {
  INITIATED = 'initiated',
  CREATED = 'created',
  IN_PROCESS = 'wms_requested',
  FULFILMENT_UPDATE = 'fulfilment_update',
  ORDER_UPDATE = 'order_update',
  CREDIT_NOTE_UPDATE = 'credit_note_update',
  USER_ACTION_NEEDED = 'user_action_needed',
  RETURNED = 'completed',
  REQUEST_CANCELLED = 'cancelled',
  FAILED = 'error',
  ACTIVE = 'active',
}

export const ReturnStatusMap = new Map([
  ['initiated', 'INITIATED'],
  ['created', 'CREATED'],
  ['wms_requested', 'IN PROCESS'],
  ['fulfilment_update', 'IN PROCESS'],
  ['order_update', 'IN PROCESS'],
  ['credit_note_update', 'IN PROCESS'],
  ['user_action_needed', 'IN PROCESS'],
  ['completed', 'RETURNED'],
  ['cancelled', 'REQUEST CANCELLED'],
  ['error', 'FAILED'],
  ['active', 'ACTIVE'],
]);

export enum ReturnFilterDateBy {
  RETURN_INITIATED_DATE = 'returnInitiatedDate',
  RETURNED_DATE = 'returnedDate',
}

export enum ReturnStatusList {
  INITIATED = 'initiated',
  IN_PROCESS = 'in_process',
  RETURNED = 'returned',
  REQUEST_CANCELLED = 'request_cancelled',
  FAILED = 'failed',
}

export enum CreditNoteStatus {
  CREDIT_NOTE = 'credit_note',
  CREDIT_MEMO = 'credit_memo',
  FAILED = 'failed',
}

export const CreditNoteStatusMap = new Map([
  ['credit_note', 'CREDIT NOTE'],
  ['credit_memo', 'CREDIT MEMO'],
  ['failed', 'FAILED'],
]);

export enum InventoryStatus {
  IN_STOCK = 'in_stock',
  OUT_OF_STOCK = 'out_of_stock',
}

export const InventoryStatusMap = new Map([
  ['in_stock', 'IN STOCK'],
  ['out_of_stock', 'OUT OF STOCK'],
]);

export enum TemplateTabs {
  surfaces = 'Surfaces',
  others = 'others',
}

export enum InventoryDisplaySkuFilterFinish {
  GLOSSY = 'glossy',
  MATT = 'matt',
}

export enum OptimisationAlertStatus {
  COMPLETED = 'completed',
  OPTIMISED = 'optimised',
  IN_PROCESS = 'in_process',
  NOT_AVAILABLE = 'not_available',
}

export enum TemplateTypes {
  SurfacePrimary = 'surfacePrimary',
  SurfaceSecondary = 'surfaceSecondary',
  OthersPrimary = 'othersPrimary',
  OthersSecondary = 'othersSecondary',
}

export enum PaymentType {
  ORDER_PAYMENT = 'ORDER_PAYMENT',
  CART_PAYMENT = 'CART_PAYMENT',
}

export enum PaymentStatus {
  Processing = 'processing',
  Failed = 'failed',
  Completed = 'completed',
}

export enum KYCStatus {
  verified = 'verified',
}

export enum CashDepositMethod {
  BANK_DEPOSIT = 'BANK',
  ATM_DEPOSIT = 'CDM',
  NONE = 'NONE',
}

export enum LedgerDownloadReportStates {
  SUCCESS = 'success',
  FAILED = 'failed',
  NO_RECORD = 'no_record',
  IN_PROCESS = 'in_process',
}

export enum DealStatus {
  New = 'new',
  Won = 'won',
  Lost = 'lost',
  RFQ = 'rfq',
  Quote = 'quote',
  Negotiation = 'negotiation',
  RevisedQuote = 'revised_quote',
}

export const DealStatusMap = new Map([
  ['new', 'New'],
  ['lost', 'Lost'],
  ['quote', 'Quote'],
  ['rfq', 'RFQ'],
  ['negotiation', 'Negotiation'],
  ['revised_quote', 'Revised Quote'],
  ['won', 'Won'],
]);

export enum ClosureType {
  ReverseCashClosure = 'reverse_cash_closure',
  CashClosure = 'cash_closure',
}

export enum CashTransactionState {
  Open = 'open',
  InProcess = 'in_process',
  Deposited = 'deposited',
}

export enum DealDetailsTabs {
  DESIGN = 'design',
  DETAILS = 'details',
}

export enum CustomerType {
  Company = 'company',
  IHB = 'individual_home_builder',
}

export const CustomerTypeMap = new Map([
  ['company', 'Company'],
  ['individual_home_builder', 'IHB'],
]);
