export interface ISkuList {
  productName: string
  quantityOrdered: number;
  quantityCancelled: number;
  quantityInvoiced: number;
  skuId: string;
  imageUrl: string;
  measurementUnitName: string;
  sellingPrice: number;
  status: string;
  cancellationErrorReason: string;
}

export interface IReasonDetails {
  cancellationReason: string;
  cancellationSubreason: string;
  cancellationRemarks: string;
}

export interface ICancellationRequests {
  cancellationRequestId: string;
  status: string;
  amount: number;
  reasonDetails: IReasonDetails;
  skuList: ISkuList[];
}
export interface IReturnRequests {
  returnRequestId: string;
  status: string;
}

export interface ISOCancellationDetails {
  customerSupplyOrderAliasId: string;
  totalQty: number,
  invoicedQty: number,
  returnedQty: number,
  cancelledQty: number,
  cancellationRequests: ICancellationRequests[];
  returnRequests: IReturnRequests[];
}

export class SOCancellationDetails {
  cancellationSODetails = [] as Array<ISOCancellationDetails>;
  constructor(cancellationSODetails: Array<ISOCancellationDetails>) {
    this.cancellationSODetails = cancellationSODetails;
  }
}
