/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import getDisplayInventoryRecommendationListService from '../services/GetDisplayInventoryRecommendationList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import {
  GetDisplayInventoryRecommendationListAction,
  GetDisplayInventoryRecommendationListActionTypes,
  getDisplayInventoryRecommendationListFailure,
  getDisplayInventoryRecommendationListSuccess,
} from '../actions/GetDisplayInventoryRecommendationList';

const GetDisplayInventoryRecommendationListEpic = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  filter(isOfType(GetDisplayInventoryRecommendationListActionTypes.GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST)),
  mergeMap((action: GetDisplayInventoryRecommendationListAction) => from(
    getDisplayInventoryRecommendationListService(
      action.payload.processAliasId,
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getDisplayInventoryRecommendationListSuccess({
      skuList: response.data.getDisplayInventoryRecommendationList.data || [],
      pageInfo: response.data.getDisplayInventoryRecommendationList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getDisplayInventoryRecommendationListFailure(
        {
          errorCode: error.errorCode || 500,
          errorMessage: error.errorMessage,
          callBack: error.callBack,
        },
      ),
    )),
  )),
);

export default GetDisplayInventoryRecommendationListEpic;
