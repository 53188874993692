import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ICancelRefundPayload } from '../actions/CancelRefund';

const CANCEL_REFUND = gql`
  query cancelRefund($path: String!, $data: any) {
    cancelRefund(input: $data, path: $path) 
      @rest(method: "PUT", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const cancelRefundService = (refundAliasId: string, payload: ICancelRefundPayload) => {
  const url = URL.cancelRefund.replace('{refundAliasId}', refundAliasId);
  return client.query({ query: CANCEL_REFUND, variables: { path: url, data: payload } });
};

export default cancelRefundService;
