import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum DeleteAddressActionTypes {
  DELETE_ADDRESS = 'DELETE_ADDRESS',
  DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAILED = 'DELETE_ADDRESS_FAILED',
  DELETE_ADDRESS_RESET = 'DELETE_ADDRESS_RESET',
}

interface IDeleteAddressActionData {
  customerId: string;
  addressId: string;
}

export const deleteAddress = createAction(DeleteAddressActionTypes.DELETE_ADDRESS)<IDeleteAddressActionData>();
export const deleteAddressSuccess = createAction(DeleteAddressActionTypes.DELETE_ADDRESS_SUCCESS)();
export const deleteAddressFailure = createAction(DeleteAddressActionTypes.DELETE_ADDRESS_FAILED)<IErrorActionData>();
export const deleteAddressReset = createAction(DeleteAddressActionTypes.DELETE_ADDRESS_RESET)();

export type DeleteAddressAction = ActionType<typeof deleteAddress>;
type DeleteAddressSuccessAction = ActionType<typeof deleteAddressSuccess>;
type DeleteAddressFailureAction = ActionType<typeof deleteAddressFailure>;
type DeleteAddressResetAction = ActionType<typeof deleteAddressReset>;

export type DeleteAddressActions = DeleteAddressAction
| DeleteAddressSuccessAction
| DeleteAddressFailureAction
| DeleteAddressResetAction;
