import { gql } from '@apollo/client';
import { cloneDeep } from 'lodash';

import client from '../../../config/apolloClient';
import { IFilter, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';
import { IGetAuditLogsSuccessActionData } from '../actions/GetAuditLogs';
import { OrderStatus } from '../../../constants/Strings';

const auditLogData = {
  auditLogDetails: {
    id: '3',
    entityState: OrderStatus.IN_PROCESS,
    auditLogs: [{
      title: 'Sent to unicommerce',
      auditDate: 1666715363,
      entityType: 'parentOrder',
      auditType: 'Order processed',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Non-IM SO dispatched from photon',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'parentOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'SO dispatched from photon',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'parentOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Payment received by UPI •  ₹ 10,360 ',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'parentOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Sent to unicommerce',
      auditDate: 1666715363,
      auditType: 'Order processed',
      entityType: 'payment',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Non-IM SO dispatched from photon',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'payment',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Sent to unicommerce',
      auditDate: 1666715363,
      auditType: 'Order processed',
      entityType: 'payment',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Non-IM SO dispatched from photon',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'supplyOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Payment received by UPI •  ₹ 10,360 ',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'supplyOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Sent to unicommerce',
      auditDate: 1666715363,
      auditType: 'Order processed',
      entityType: 'supplyOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Non-IM SO dispatched from photon',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'supplyOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }, {
      title: 'Payment received by UPI •  ₹ 10,360 ',
      auditDate: 1666715363,
      auditType: '',
      entityType: 'supplyOrder',
      modifiedBy: 'Krishna Rajendera Jain',
    }],
  },
};

const GET_AUDIT_LOGS = gql`
  query getAuditLogs($path: String!) {
    getAuditLogs @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getAuditLogsService = (filter?: IFilter[]) : Promise<IGetAuditLogsSuccessActionData> => {
  const url = constructUrlWithParams(URL.getAuditLogs, filter);
  // return client.query({ query: GET_AUDIT_LOGS, variables: { path: url } })
  const temp = cloneDeep(auditLogData);
  if (filter?.length) {
    temp.auditLogDetails.auditLogs = temp.auditLogDetails.auditLogs
      .filter((item) => item.entityType === filter?.[0]?.value);
  }
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(temp);
    });
  });
};

export default getAuditLogsService;
