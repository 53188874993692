/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  createCartSuccess,
  createCartFailure,
  CreateCartAction,
  CreateCartActionTypes,
} from '../actions/CreateCart';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import createCartService from '../services/CreateCart';

const CreateCartEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CreateCartActionTypes.CREATE_CART)),
  mergeMap((action: CreateCartAction) => from(
    createCartService(action.payload.createCartDetails),
  ).pipe(
    map((response) => createCartSuccess({ cartAliasId: response.data.createCart.data.cartAliasId })),
    catchError((error: IErrorActionData) => of(
      createCartFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CreateCartEpic;
