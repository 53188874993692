import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IAddEditCashClosurePayload } from '../models/AddEditCashClosure';

export enum EditCashClosureActionTypes {
  EDIT_CASH_CLOSURE_ACTION = 'EDIT_CASH_CLOSURE_ACTION',
  EDIT_CASH_CLOSURE_ACTION_SUCCESS = 'EDIT_CASH_CLOSURE_ACTION_SUCCESS',
  EDIT_CASH_CLOSURE_ACTION_FAILURE = 'EDIT_CASH_CLOSURE_ACTION_FAILURE',
  EDIT_CASH_CLOSURE_ACTION_RESET = 'EDIT_CASH_CLOSURE_ACTION_RESET',
}

export interface IEditCashClosureActionData {
  closureAliasId: string;
  editCashClosureDetails: IAddEditCashClosurePayload;
}

export const editCashClosure = createAction(EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION)
<IEditCashClosureActionData>();
export const editCashClosureSuccess = createAction(EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION_SUCCESS)();
export const editCashClosureFailure = createAction(EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION_FAILURE)
<IErrorActionData>();
export const editCashClosureReset = createAction(EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION_RESET)();

export type EditCashClosureAction = ActionType<typeof editCashClosure>;
type EditCashClosureSuccessAction = ActionType<typeof editCashClosureSuccess>;
type EditCashClosureFailureAction = ActionType<typeof editCashClosureFailure>;
type EditCashClosureResetAction = ActionType<typeof editCashClosureReset>;

export type EditCashClosureActions =
  | EditCashClosureAction
  | EditCashClosureSuccessAction
  | EditCashClosureFailureAction
  | EditCashClosureResetAction;
