import { ChannelNames } from '../../../constants/Strings';
import { ICartInfluencer, ISalesPerson } from './UpdateCart';

interface ICustomerInfo {
  customerId: number,
  contactNumber: number,
  firstName: string,
  lastName: string,
}

interface ICartInfo {
  count: number,
  channelName: ChannelNames,
  totalCartValue: number,
  cartAliasId: string,
  lastUpdatedOn: number,
  quotationAliasId: string,
}

export interface ISavedCartItem {
  customerInfo: ICustomerInfo;
  cartInfo: ICartInfo;
  referralInfo?: {
    salesperson?: ISalesPerson[];
    influencer?: ICartInfluencer;
  }
}

export class SavedCartItemList {
  savedCartItemList = [] as Array<ISavedCartItem>;
  constructor(savedCartItemList: Array<ISavedCartItem>) {
    this.savedCartItemList = savedCartItemList;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<ISavedCartItem>,
  payloadList: Array<ISavedCartItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
