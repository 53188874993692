import { Reducer } from 'redux';

import { RecordRefundActionTypes, RecordRefundActions } from '../actions/RecordRefund';
import { IErrorActionData } from '../../../models/Error';

export interface IRecordRefundState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialRecordRefundState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IRecordRefundState;

const RecordRefundReducer: Reducer<IRecordRefundState, RecordRefundActions> = (
  state = initialRecordRefundState,
  action: RecordRefundActions,
): IRecordRefundState => {
  switch (action.type) {
    case RecordRefundActionTypes.RECORD_REFUND:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case RecordRefundActionTypes.RECORD_REFUND_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case RecordRefundActionTypes.RECORD_REFUND_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case RecordRefundActionTypes.RECORD_REFUND_RESET:
      return initialRecordRefundState;

    default:
      return state;
  }
};

export default RecordRefundReducer;
