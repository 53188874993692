/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getStoreEmployeeListSuccess,
  getStoreEmployeeListFailed,
  GetStoreEmployeeListAction,
  GetStoreEmployeeListActionTypes,
} from '../actions/GetStoreEmployeeList';
import { IErrorActionData } from '../../../models/Error';
import getStoreEmployeeListService from '../services/GetStoreEmployeeList';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import { getApiAutoCompleteOptionData } from '../../../utils/common';

type EmployeeResponseType = {
  userAliasId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const formatEmployeeList = (list: EmployeeResponseType[]) => list.map((item) => ({
  userAliasId: item.userAliasId,
  name: `${item.firstName} ${item.lastName}`,
  phoneNumber: item.phoneNumber,
}));

const GetStoreEmployeeListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST)),
  mergeMap((action: GetStoreEmployeeListAction) => from(
    getStoreEmployeeListService(action.payload.storeAliasId, action.payload.filter, action.payload.page),
  ).pipe(
    map((response: any) => getStoreEmployeeListSuccess({
      storeEmployeeList: getApiAutoCompleteOptionData(
        formatEmployeeList(response.data.getStoreEmployeeList.data || []),
        'userAliasId',
        'name',
        'phoneNumber',
        '',
      ),
      pageInfo: response.data.getStoreEmployeeList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getStoreEmployeeListFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetStoreEmployeeListEpic;
