import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_SHIPMENT_SKU_LIST = gql`
  query getShipmentSkuList($path: String!) {
    getShipmentSkuList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getShipmentSkuListService = (shipmentAliasId: string) => {
  const url = URL.getShipmentSkuList.replace('{shipmentAliasId}', shipmentAliasId);
  return client.query({ query: GET_SHIPMENT_SKU_LIST, variables: { path: url } });
};

export default getShipmentSkuListService;
