import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter, IPage } from '../../../utils/sortFilterFramework';

const GET_STORE_EMPLOYEE_LIST = gql`
  query getStoreEmployeeList($path: String!) {
    getStoreEmployeeList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data,
        pageInfo,
      }
  }
`;

const getStoreEmployeeListService = (storeAliasId: string, filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getStoreEmployeeList, filter, undefined, page);
  return client.query({ query: GET_STORE_EMPLOYEE_LIST, variables: { path: url.replace('{storeAliasId}', storeAliasId) } });
};

export default getStoreEmployeeListService;
