/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCustomerDetailsSuccess,
  getCustomerDetailsFailed,
  GetCustomerDetailsAction,
  GetCustomerDetailsActionTypes,
} from '../actions/GetCustomerDetails';
import { IErrorActionData } from '../../../models/Error';
import getCustomerDetailsService from '../services/GetCustomerDetails';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCustomerDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS)),
  mergeMap((action: GetCustomerDetailsAction) => from(
    getCustomerDetailsService(action.payload.customerAliasId),
  ).pipe(
    map((response: any) => getCustomerDetailsSuccess({
      data: response.data,
    })),
    catchError((error: IErrorActionData) => of(
      getCustomerDetailsFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCustomerDetailsEpic;
