import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { ICustomerLedgerDetails } from '../models/CustomerLedgerDetails';

export enum GetCustomerLedgerDetailsActionTypes {
  GET_CUSTOMER_LEDGER_DETAILS = 'GET_CUSTOMER_LEDGER_DETAILS',
  GET_CUSTOMER_LEDGER_DETAILS_SUCCESS = 'GET_CUSTOMER_LEDGER_DETAILS_SUCCESS',
  GET_CUSTOMER_LEDGER_DETAILS_FAILED = 'GET_CUSTOMER_LEDGER_DETAILS_FAILED',
  GET_CUSTOMER_LEDGER_DETAILS_RESET = 'GET_CUSTOMER_LEDGER_DETAILS_RESET',
}

export interface IGetCustomerLedgerDetailsActionData {
  customerAliasId: string;
}

export interface IGetCustomerLedgerDetailsSuccessActionData {
  customerLedgerDetails: ICustomerLedgerDetails;
}

export const getCustomerLedgerDetails = createAction(GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS)
<IGetCustomerLedgerDetailsActionData>();
export const getCustomerLedgerDetailsSuccess = createAction(GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS_SUCCESS)
<IGetCustomerLedgerDetailsSuccessActionData>();
export const getCustomerLedgerDetailsFailure = createAction(GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS_FAILED)
<IErrorActionData>();
export const getCustomerLedgerDetailsReset = createAction(GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS_RESET)();

export type GetCustomerLedgerDetailsAction = ActionType<typeof getCustomerLedgerDetails>;
type GetCustomerLedgerDetailsSuccessAction = ActionType<typeof getCustomerLedgerDetailsSuccess>;
type GetCustomerLedgerDetailsFailureAction = ActionType<typeof getCustomerLedgerDetailsFailure>;
type GetCustomerLedgerDetailsResetAction = ActionType<typeof getCustomerLedgerDetailsReset>;

export type GetCustomerLedgerDetailsActions = GetCustomerLedgerDetailsAction
| GetCustomerLedgerDetailsSuccessAction
| GetCustomerLedgerDetailsFailureAction
| GetCustomerLedgerDetailsResetAction;
