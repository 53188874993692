/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  cancelRefundSuccess,
  cancelRefundFailure,
  CancelRefundAction,
  CancelRefundActionTypes,
} from '../actions/CancelRefund';
import cancelRefundService from '../services/CancelRefund';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CancelRefundEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CancelRefundActionTypes.CANCEL_REFUND_ACTION)),
  mergeMap((action: CancelRefundAction) => from(
    cancelRefundService(action.payload.refundAliasId, action.payload.payload),
  ).pipe(
    map((response: any) => (cancelRefundSuccess())),
    catchError((error: any) => of(
      cancelRefundFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CancelRefundEpic;
