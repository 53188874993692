/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  generateQuotationSuccess,
  generateQuotationFailure,
  GenerateQuotationAction,
  GenerateQuotationActionTypes,
} from '../actions/GenerateQuotation';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import generateQuotationService from '../services/GenerateQuotation';

const GenerateQuotationEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GenerateQuotationActionTypes.GENERATE_QUOTATION)),
  mergeMap((action: GenerateQuotationAction) => from(
    generateQuotationService(
      action.payload.cartAliasId,
      action.payload.customerId,
      action.payload.storeAliasId,
    ),
  ).pipe(
    map((response) => generateQuotationSuccess({
      quotationAliasId: response.data.quotationAliasId,
      quotationPdfUrl: response.data.quotationPdfUrl,
    })),
    catchError((error: IErrorActionData) => of(
      generateQuotationFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GenerateQuotationEpic;
