import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { DisplayInventorySummaryDetails, IDisplayInventorySummaryDetails } from '../models/GetDisplayInventorySummary';
import { GetDisplayInventorySummaryActionTypes, GetDisplayInventorySummaryActions } from '../actions/GetDisplayInventorySummary';

export interface IDisplayInventorySummaryState {
  displayInventorySummaryDetails: DisplayInventorySummaryDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDisplayInventorySummaryState = {
  displayInventorySummaryDetails: new DisplayInventorySummaryDetails({} as IDisplayInventorySummaryDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDisplayInventorySummaryState;

const GetDisplayInventorySummaryReducer: Reducer<IDisplayInventorySummaryState, GetDisplayInventorySummaryActions> = (
  state = initialDisplayInventorySummaryState,
  action: GetDisplayInventorySummaryActions,
): IDisplayInventorySummaryState => {
  switch (action.type) {
    case GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY_SUCCESS: {
      return {
        ...state,
        displayInventorySummaryDetails: new DisplayInventorySummaryDetails(action.payload.data),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY_RESET:
      return initialDisplayInventorySummaryState;

    default:
      return state;
  }
};

export default GetDisplayInventorySummaryReducer;
