export interface ICustomerDetails {
  customerAliasId: string;
}

export class Customer {
  customerDetails = {} as ICustomerDetails;
  constructor(customerDetails: ICustomerDetails) {
    this.customerDetails = customerDetails;
  }
}
