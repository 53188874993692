/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getReturnListSuccess,
  getReturnListFailure,
  GetReturnListAction,
  GetReturnListActionTypes,
} from '../actions/GetReturnList';
import getReturnListService from '../services/GetReturnList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetReturnListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetReturnListActionTypes.GET_RETURN_LIST)),
  mergeMap((action: GetReturnListAction) => from(
    getReturnListService(action.payload.filter, action.payload.page),
  ).pipe(
    map((response: any) => getReturnListSuccess({
      returnList: response.data.getReturnList.data || [],
      pageInfo: response.data.getReturnList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getReturnListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetReturnListEpic;
