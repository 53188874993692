import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { ICustomerDetailsList } from '../models/CustomerDetailsList';

export enum GetCustomerDetailsListActionTypes {
  GET_CUSTOMER_DETAILS_LIST = 'GET_CUSTOMER_DETAILS_LIST',
  GET_CUSTOMER_DETAILS_LIST_SUCCESS = 'GET_CUSTOMER_DETAILS_LIST_SUCCESS',
  GET_CUSTOMER_DETAILS_LIST_FAILED = 'GET_CUSTOMER_DETAILS_LIST_FAILED',
  GET_CUSTOMER_DETAILS_LIST_RESET = 'GET_CUSTOMER_DETAILS_LIST_RESET',
}

export interface IGetCustomerDetailsListActionData {
  filter?: IFilter[];
  customerId?: string;
}

export enum AvailableFilterProperty {
  phone = 'phone',
  customerId = 'customerid',
}

export interface IGetCustomerDetailsListSuccessActionData {
  data: ICustomerDetailsList[];
}

export const getCustomerDetailsList = createAction(GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST)
<IGetCustomerDetailsListActionData>();
export const getCustomerDetailsListSuccess = createAction(GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST_SUCCESS)
<IGetCustomerDetailsListSuccessActionData>();
export const getCustomerDetailsListFailed = createAction(GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST_FAILED)
<IErrorActionData>();
export const getCustomerDetailsListReset = createAction(GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST_RESET)();

export type GetCustomerDetailsListAction = ActionType<typeof getCustomerDetailsList>;
type GetCustomerDetailsListSuccessAction = ActionType<typeof getCustomerDetailsListSuccess>;
type GetCustomerDetailsListFailedAction = ActionType<typeof getCustomerDetailsListFailed>;
type GetCustomerDetailsListResetAction = ActionType<typeof getCustomerDetailsListReset>;

export type GetCustomerDetailsListActions =
  | GetCustomerDetailsListAction
  | GetCustomerDetailsListSuccessAction
  | GetCustomerDetailsListFailedAction
  | GetCustomerDetailsListResetAction;
