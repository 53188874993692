import { createAction, ActionType } from 'typesafe-actions';

import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IPageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { ISavedCartItem } from '../models/SavedCartItemList';

export enum GetSavedCartItemListActionTypes {
  GET_SAVED_CART_ITEM_LIST = 'GET_SAVED_CART_ITEM_LIST',
  GET_SAVED_CART_ITEM_LIST_SUCCESS = 'GET_SAVED_CART_ITEM_LIST_SUCCESS',
  GET_SAVED_CART_ITEM_LIST_FAILURE = 'GET_SAVED_CART_ITEM_LIST_FAILURE',
  GET_SAVED_CART_ITEM_LIST_RESET = 'GET_SAVED_CART_ITEM_LIST_RESET',
}

export enum AvailableFilterProperty {
  storeAliasId = 'storeAliasId',
  contactNumber = 'contactNumber',
  lastUpdatedFrom = 'lastUpdatedFrom',
  lastUpdatedTo = 'lastUpdatedTo',
}

export interface IGetSavedCartItemListActionData {
  filter?: IFilter[];
  page?: IPage;
}

export interface IGetSavedCartListSuccessActionData {
  savedCartItemList: Array<ISavedCartItem>;
  pageInfo: IPageInfo;
}

export const getSavedCartItemList = createAction(GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST)
<IGetSavedCartItemListActionData>();
export const getSavedCartItemListSuccess = createAction(GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST_SUCCESS)
<IGetSavedCartListSuccessActionData>();
export const getSavedCartItemListFailure = createAction(GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST_FAILURE)
<IErrorActionData>();
export const getSavedCartItemListReset = createAction(GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST_RESET)();

export type GetSavedCartItemListAction = ActionType<typeof getSavedCartItemList>;
type GetSavedCartItemListSuccessAction = ActionType<typeof getSavedCartItemListSuccess>;
type GetSavedCartItemListFailureAction = ActionType<typeof getSavedCartItemListFailure>;
type GetSavedCartItemListResetAction = ActionType<typeof getSavedCartItemListReset>;

export type GetSavedCartItemListActions =
  | GetSavedCartItemListAction
  | GetSavedCartItemListSuccessAction
  | GetSavedCartItemListFailureAction
  | GetSavedCartItemListResetAction;
