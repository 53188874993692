/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  addCashClosureSuccess,
  addCashClosureFailure,
  AddCashClosureAction,
  AddCashClosureActionTypes,
} from '../actions/AddCashClosure';
import addCashClosureService from '../services/AddCashClosure';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const AddCashClosureEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION)),
  mergeMap((action: AddCashClosureAction) => from(
    addCashClosureService(action.payload.addCashClosureDetails),
  ).pipe(
    map((response: any) => (addCashClosureSuccess())),
    catchError((error: any) => of(
      addCashClosureFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default AddCashClosureEpic;
