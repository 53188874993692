import { Reducer } from 'redux';

import { ShipmentSkuList, IShipmentSkuList } from '../models/GetShipmentSkuList';
import { GetShipmentSkuListActionTypes, GetShipmentSkuListActions } from '../actions/GetShipmentSkuList';
import { IErrorActionData } from '../../../models/Error';

export interface IShipmentSkuListState {
  skuList: ShipmentSkuList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialSkuListState = {
  skuList: new ShipmentSkuList({} as IShipmentSkuList),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IShipmentSkuListState;

const GetShipmentSkuListReducer: Reducer<IShipmentSkuListState, GetShipmentSkuListActions> = (
  state = initialSkuListState,
  action: GetShipmentSkuListActions,
): IShipmentSkuListState => {
  switch (action.type) {
    case GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST_SUCCESS: {
      return {
        ...state,
        skuList: new ShipmentSkuList(action.payload.skuListDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST_RESET:
      return initialSkuListState;

    default:
      return state;
  }
};

export default GetShipmentSkuListReducer;
