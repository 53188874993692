import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 56px)',
    marginTop: 56,
    backgroundColor: theme.palette.IMSecondary.background,
    overflowY: 'hidden',
  },
  offlineMain: {
    height: 'calc(100vh - 100px)',
    marginTop: 100,
  },
  appbar: {
    flexGrow: 1,
    maxHeight: 56,
    minHeight: 56,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.IMPrimary.contrastText,
    boxShadow: 'inset 0px -1px 0px rgba(80, 80, 80, 0.15)',
  },
  toolbar: {
    maxHeight: 56,
    minHeight: 56,
    padding: theme.spacing(0, 4),
  },
  title: {
    flexGrow: 1,
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  iconBtn: {
    width: 28,
    height: 28,
    margin: theme.spacing(0, 0, 0, 3.5),
    color: theme.palette.text.secondary,
  },
  menuContainer: {
    minWidth: 240,
    padding: 0,
  },
  storeMenuContainer: {
    minWidth: 300,
    maxHeight: 230,
    padding: 0,
  },
  storeMenuList: {
    minHeight: 44,
    padding: theme.spacing(0, 4),
  },
  storeInfoBtn: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.IMInfo.textDark,
    backgroundColor: theme.palette.IMPrimary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.IMPrimary.contrastText,
    },
  },
  noStoreAssigned: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.IMInfo.textDark,
  },
  networkErrorToastContainer: {
    width: '100vw',
    height: 44,
    position: 'absolute',
    top: 56,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 6),
    backgroundColor: theme.palette.IMError.lightBg,
  },
  offlineIcon: {
    height: 17,
    width: 17,
    color: theme.palette.IMError.main,
  },
  toastSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    flex: 1,
    color: theme.palette.IMError.textDark,
    marginLeft: theme.spacing(2.5),
  },
  toastTryAgainButton: {
    height: 30,
    width: 'fit-content',
    fontSize: 13,
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    color: theme.palette.IMError.textDark,
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  progress: {
    color: theme.palette.IMWarning.main,
  },
  storeContainer: {
    maxWidth: 300,
    minWidth: 300,
  },
  logoutContainer: {
    minHeight: 87,
    minWidth: 240,
    display: 'flex',
    flexDirection: 'column',
  },
  userTitle: {
    width: 210,
    fontSize: 15,
    fontWeight: 400,
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  userSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  logoutButton: {
    minHeight: 43,
    minWidth: 240,
    fontSize: 15,
    fontWeight: 400,
    color: theme.palette.IMPrimary.contrastText,
    borderRadius: 0,
    backgroundColor: theme.palette.IMPrimary.main,
    '&:hover': {
      backgroundColor: theme.palette.IMPrimary.main,
    },
  },
}));

export default useStyles;
