import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { INewInfluencer } from '../models/NewInfluencer';

export enum CreateInfluencerActionTypes {
  CREATE_INFLUENCER = 'CREATE_INFLUENCER',
  CREATE_INFLUENCER_SUCCESS = 'CREATE_INFLUENCER_SUCCESS',
  CREATE_INFLUENCER_FAILED = 'CREATE_INFLUENCER_FAILED',
  CREATE_INFLUENCER_RESET = 'CREATE_INFLUENCER_RESET',
}

export interface ICreateInfluencerActionData {
  name: string;
  phoneNumber: string;
  type: string;
  languagePreference: string;
}

export interface ICreateInfluencerSuccessActionData {
  newInfluencerData: INewInfluencer;
}

export const createInfluencer = createAction(CreateInfluencerActionTypes.CREATE_INFLUENCER)
<ICreateInfluencerActionData>();
export const createInfluencerSuccess = createAction(CreateInfluencerActionTypes.CREATE_INFLUENCER_SUCCESS)
<ICreateInfluencerSuccessActionData>();
export const createInfluencerFailed = createAction(CreateInfluencerActionTypes.CREATE_INFLUENCER_FAILED)
<IErrorActionData>();
export const createInfluencerReset = createAction(CreateInfluencerActionTypes.CREATE_INFLUENCER_RESET)();

export type CreateInfluencerAction = ActionType<typeof createInfluencer>;
type CreateInfluencerSuccessAction = ActionType<typeof createInfluencerSuccess>;
type CreateInfluencerFailedAction = ActionType<typeof createInfluencerFailed>;
type CreateInfluencerResetAction = ActionType<typeof createInfluencerReset>;

export type CreateInfluencerActions =
  | CreateInfluencerAction
  | CreateInfluencerSuccessAction
  | CreateInfluencerFailedAction
  | CreateInfluencerResetAction;
