import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_CASH_CLOSURE_DETAILS = gql`
  query getCashClosureDetails($path: String!) {
    getCashClosureDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getCashClosureDetailsService = (closureAliasId: string) => {
  const url = URL.getCashClosureDetails.replace('{closureAliasId}', closureAliasId);
  return client.query({ query: GET_CASH_CLOSURE_DETAILS, variables: { path: url } });
};

export default getCashClosureDetailsService;
