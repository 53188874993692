import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IGetCustomerCreditBalanceDetails } from '../models/GetCustomerCreditBalanceDetails';

export enum GetCustomerCreditBalanceDetailsActionTypes {
  GET_CUSTOMER_CREDIT_BALANCE_DETAILS = 'GET_CUSTOMER_CREDIT_BALANCE_DETAILS',
  GET_CUSTOMER_CREDIT_BALANCE_DETAILS_SUCCESS = 'GET_CUSTOMER_CREDIT_BALANCE_DETAILS_SUCCESS',
  GET_CUSTOMER_CREDIT_BALANCE_DETAILS_FAILED = 'GET_CUSTOMER_CREDIT_BALANCE_DETAILS_FAILED',
  GET_CUSTOMER_CREDIT_BALANCE_DETAILS_RESET = 'GET_CUSTOMER_CREDIT_BALANCE_DETAILS_RESET',
}

export interface IGetCustomerCreditBalanceDetailsActionData {
  customerId: string;
}

export interface IGetCustomerCreditBalanceDetailsSuccessActionData {
  getCustomerCreditBalanceDetails: IGetCustomerCreditBalanceDetails;
}

export const getCustomerCreditBalanceDetails = createAction(
  GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS,
)<IGetCustomerCreditBalanceDetailsActionData>();
export const getCustomerCreditBalanceDetailsSuccess = createAction(
  GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS_SUCCESS,
)<IGetCustomerCreditBalanceDetailsSuccessActionData>();
export const getCustomerCreditBalanceDetailsFailed = createAction(
  GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS_FAILED,
)<IErrorActionData>();
export const getCustomerCreditBalanceDetailsReset = createAction(
  GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS_RESET,
)();

export type GetCustomerCreditBalanceDetailsAction = ActionType<typeof getCustomerCreditBalanceDetails>;
type GetCustomerCreditBalanceDetailsSuccessAction = ActionType<typeof getCustomerCreditBalanceDetailsSuccess>;
type GetCustomerCreditBalanceDetailsFailedAction = ActionType<typeof getCustomerCreditBalanceDetailsFailed>;
type GetCustomerCreditBalanceDetailsResetAction = ActionType<typeof getCustomerCreditBalanceDetailsReset>;

export type GetCustomerCreditBalanceDetailsActions =
  | GetCustomerCreditBalanceDetailsAction
  | GetCustomerCreditBalanceDetailsSuccessAction
  | GetCustomerCreditBalanceDetailsFailedAction
  | GetCustomerCreditBalanceDetailsResetAction;
