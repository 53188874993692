import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { ICashTransactionListItem } from '../models/CashTransactionList';

export enum GetCashTransactionListActionTypes {
  GET_CASH_TRANSACTION_LIST = 'GET_CASH_TRANSACTION_LIST',
  GET_CASH_TRANSACTION_LIST_SUCCESS = 'GET_CASH_TRANSACTION_LIST_SUCCESS',
  GET_CASH_TRANSACTION_LIST_FAILED = 'GET_CASH_TRANSACTION_LIST_FAILED',
  GET_CASH_TRANSACTION_LIST_RESET = 'GET_CASH_TRANSACTION_LIST_RESET',
}

export enum AvailableFilterProperty {
  mobileNumber = 'mobileNumber',
  closureId = 'closureId',
  transactionStartDate = 'transactionStartDate',
  transactionEndDate = 'transactionEndDate',
  depositStartDate = 'depositStartDate',
  depositEndDate = 'depositEndDate',
  closingStartDate = 'closingStartDate',
  closingEndDate = 'closingEndDate',
}

export interface IGetCashTransactionListActionData {
  storeAliasId: string;
  filter?: IFilter[];
  page?: IPage;
}

export interface IGetCashTransactionListSuccessActionData {
  cashTransactionsList: Array<ICashTransactionListItem>;
  pageInfo: IPageInfo;
}

export const getCashTransactionList = createAction(GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST)
<IGetCashTransactionListActionData>();
export const getCashTransactionListSuccess = createAction(GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST_SUCCESS)
<IGetCashTransactionListSuccessActionData>();
export const getCashTransactionListFailure = createAction(GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST_FAILED)
<IErrorActionData>();
export const getCashTransactionListReset = createAction(GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST_RESET)();

export type GetCashTransactionListAction = ActionType<typeof getCashTransactionList>;
type GetCashTransactionListSuccessAction = ActionType<typeof getCashTransactionListSuccess>;
type GetCashTransactionListFailedAction = ActionType<typeof getCashTransactionListFailure>;
type GetCashTransactionListResetAction = ActionType<typeof getCashTransactionListReset>;

export type GetCashTransactionListActions =
  | GetCashTransactionListAction
  | GetCashTransactionListSuccessAction
  | GetCashTransactionListFailedAction
  | GetCashTransactionListResetAction;
