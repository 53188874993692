import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum EditCmsSlipActionTypes {
  EDIT_CMS_SLIP_ACTION = 'EDIT_CMS_SLIP_ACTION',
  EDIT_CMS_SLIP_ACTION_SUCCESS = 'EDIT_CMS_SLIP_ACTION_SUCCESS',
  EDIT_CMS_SLIP_ACTION_FAILURE = 'EDIT_CMS_SLIP_ACTION_FAILURE',
  EDIT_CMS_SLIP_ACTION_RESET = 'EDIT_CMS_SLIP_ACTION_RESET',
}

export interface IEditCmsSlipDetails {
  slipAliasId: string;
  cmsSlipNumber: string;
  depositDate: number;
  remarks?: string;
}

export interface IEditCmsSlipPayload {
  cmsSlipDetails: Array<IEditCmsSlipDetails>;
}

export interface IEditCmsSlipActionData {
  closureAliasId: string;
  editCmsSlipDetails: IEditCmsSlipPayload;
}

export const editCmsSlip = createAction(EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION)<IEditCmsSlipActionData>();
export const editCmsSlipSuccess = createAction(EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION_SUCCESS)();
export const editCmsSlipFailure = createAction(EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION_FAILURE)<IErrorActionData>();
export const editCmsSlipReset = createAction(EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION_RESET)();

export type EditCmsSlipAction = ActionType<typeof editCmsSlip>;
type EditCmsSlipSuccessAction = ActionType<typeof editCmsSlipSuccess>;
type EditCmsSlipFailureAction = ActionType<typeof editCmsSlipFailure>;
type EditCmsSlipResetAction = ActionType<typeof editCmsSlipReset>;

export type EditCmsSlipActions =
  | EditCmsSlipAction
  | EditCmsSlipSuccessAction
  | EditCmsSlipFailureAction
  | EditCmsSlipResetAction;
