const analyticsEvents = {
  searchClick: 'search_click',
  addToCart: 'add_to_cart',
  srpScrollProducts: 'srp_scroll_products',
  srpExit: 'srp_exit',
  checkAvailability: 'PP_srp_check_availability ',
  user: {
    userProfileClick: 'PP_user_profile_click',
    logoutConfirmBtnClick: 'PP_user_logout_confirm',
    loginSubmitBtnClick: 'PP_user_login_otp_submit',
    storeSelectorClick: 'PP_user_store_selector',
  },
  labelManagement: {
    add: 'PP_label_add',
    previewPrint: 'PP_label_preview_&_print',
  },
  orders: {
    newOrderBtnClick: 'PP_order_listing_new_order',
    addPaymentBtnClick: 'PP_order_add_payment',
    awaitingPaymentBtnClick: 'PP_order_awaiting_payment',
    confirmAwaitingPaymentBtnClick: 'PP_order_confirm_await_payment',
    cancelAwaitingPaymentBtnClick: 'PP_order_cancel_await_payment',
    orderPaymentHistoryBtnClick: 'PP_order_pay_history',
    orderListingLineDetailsClick: 'PP_order_listing_line_details',
    orderListFilterApplyBtnClick: 'PP_order_listing_apply_filter',
    unusedCreditCancelBtnClick: 'PP_order_cancel_unused_credit_drawer',
    editOrderBtnClick: 'PP_order_edit_order',
    cancelOrderBtnClick: 'PP_order_cancel_po',
    startProcessingBtnClick: 'PP_order_start_processing',
    orderRealiseBtnClick: 'PP_order_realise_payment',
    cancelSOBtnClick: 'PP_order_cancel_so',
    returnSOBtnClick: 'PP_order_return_so',
    confirmReturnInitiateBtnClick: 'PP_order_confirm_return_initiate',
    confirmCancelInitiateBtnClick: 'PP_order_confirm_cancel_initiate',
    cancellationAlertViewBtnClick: 'PP_order_alert_cancel',
    returnAlertViewBtnClick: 'PP_order_alert_return',
    skuLevelLineClick: 'PP_order_sku_details',
    downloadPerfomaInvoice: 'PP_order_donwload_pi',
    regeneratePeromaInvoice: 'PP_order_regenrate_pi',
    newOrderNextBtnClick: 'PP_order_new_order_cust',
    newOrderAddressNextBtnClick: 'PP_order_new_order_cust_address',
  },
  carts: {
    saveCartBtnClick: 'PP_cart_save_cart',
    deleteCartBtnClick: 'PP_cart_delete_cart',
    influencerReferralBtnClick: 'PP_cart_influencer_no',
    payBtnClick: 'PP_cart_pay_click',
    generateQuoteBtnClick: 'PP_cart_generate_quote',
    submitBtnClick: 'PP_cart_submit_remarks',
    addRemarksBtnClick: 'PP_cart_add_remarks',
    addAdditionalDiscountBtnClick: 'PP_cart_add_addi_disc',
    applyAdditionalDiscountBtnClick: 'PP_cart_apply_addi_disc',
    addReferralBtnClick: 'PP_cart_add_referral',
    addShippingAddressBtnClick: 'PP_cart_add_shipping',
    addBillingAddressBtnClick: 'PP_cart_add_billing',
    editShippingAddressBtnClick: 'PP_cart_edit_shipping',
    editBillingAddressBtnClick: 'PP_cart_edit_billing',
    saveAddressBtnClick: 'PP_cart_save_address',
    editCustomerSubmitBtnClick: 'PP_cart_cust_edit_submit',
    markBillingSameAsShippingChange: 'PP_cart_bill_same_shipp',
    influencerReferralSelectionChange: 'PP_cart_influencer_no',
    placeOrderBtnClick: 'PP_cart_place_order',
    cartInfluencerSubmit: 'PP_cart_influencer_mob_submit',
    cartDelChargesSaveBtnClick: 'PP_cart_enter_del_charges_save',
  },
  savedCarts: {
    selectSavedCartListDetails: 'PP_cart_listing_cart_line',
    savedCartApplyFilterBtnClick: 'PP_cart_listing_apply_filter',
    savedCartNewOrderBtnClick: 'PP_cart_listing_new_order',
    deleteCart: 'PP_cart_listing_delete_cart',
  },
  cashClosure: {
    dailyClosing: 'PP_cash_closure_daily_closing',
    confirmBtnClick: 'PP_cash_closure_confirm_closure_create',
    bankDepositMethodClick: 'PP_cash_closure_bank_deposit',
    atmMachineMethodClick: 'PP_cash_closure_ATM_machine_deposit',
    downloadCashTransactionBtnClick: 'PP_cash_closure_export_cash_transaction_report',
    downloadCashClosureBtnClick: 'PP_cash_closure_export_cash_closure_report',
    cashTxnFilterApplyBtnClick: 'PP_cash_closure_apply_filter_cash_txn',
    cashClosureFilterApplyBtnClick: 'PP_cash_closure_apply_filter_cash_closure',
  },
  cartListingDownloadQuote: 'PP_cart_listing_download_quote',
  initiatePayment: 'PP_pay_initiate',
  exportLedger: 'PP_ledger_export_statement',
  downloadLedger: 'PP_ledger_download_statement',
  displayManagement: {
    clickTask: 'PP_dis_mgmt_click_task',
    taskPage: 'PP_dis_mgmt_task_page',
    completeTask: 'PP_dis_mgmt_complete_task',
    saveProgress: 'PP_dis_mgmt_save_progress',
    displayManagementPage: 'PP_dis_mgmt_page',
    displayManagementDownload: 'PP_dis_mgmt_download_disp',
    downloadAdditionOptimisedList: 'PP_dis_mgmt_download_add',
    downloadRemovalOptimisedList: 'PP_dis_mgmt_download_rem',
    displayManagementSearch: 'PP_dis_mgmt_Search',
  },
  customerLedger: {
    customerSuggestionClick: 'PP_ledger_cust_suggestion_click',
    ledgerStatementLineDetails: 'PP_ledger_Statement_line_detail',
    applyBtnClick: 'PP_ledger_apply_filter',
    pendingAmountIconClick: 'PP_ledger_pending_amount',
    unusedCreditIconClick: 'PP_ledger_unused_credit',
  },
  customerRefund: {
    customerSuggestionClick: 'PP_Refund_cust_suggestion_click',
    initiateRefundBtnClick: 'PP_Refund_initiate_refund',
    confirmRefundBtnClick: 'PP_Refund_confirm_refund',
    cancelRefundBtnClick: 'PP_Refund_cancel_refund',
    refundLineDetailsClick: 'PP_Refund_refund_line_details',
    applyBtnClick: 'PP_Refund_apply_filter',
  },
  return: {
    returnLineDetailsClick: 'PP_Return_line_details',
    applyBtnClick: 'PP_Return_apply_filter',
  },
  mkDeal: {
    dealTab: 'MK_homepage_mk_deal',
    newDeal: 'MK_list_new_deal',
    dealListSearchSubmit: 'MK_list_search_submit',
    dealListFilterClick: 'MK_list_filter_click',
    paginationChange: 'MK_list_pagi_change',
    dealListApplyFilter: 'MK_list_apply_filter',
    nextAfterNumber: 'MK_deal_next_number',
    backAfterNumber: 'MK_deal_back_number',
    addSite: 'MK_deal_add_site',
    addProject: 'MK_deal_add_proj',
    saveSite: 'MK_deal_save_site',
    saveProject: 'MK_deal_save_proj',
    nextSiteProject: 'MK_deal_next_site_proj',
    nextCategory: 'MK_deal_next_cate',
    nextPotential: 'MK_deal_next_potential',
    editSite: 'MK_deal_edit_site',
    editProject: 'MK_deal_edit_proj',
    reassignDeal: 'MK_deal_det_reassign',
    updateStatus: 'MK_deal_det_update',
    goToPhotonStudio: 'MK_deal_det_go_to_PS',
    detailsTab: 'MK_deal_det_details_tab',
    designTab: 'MK_deal_det_design_tab',
    uploadCTA: 'MK_deal_det_upload',
    clickUpload: 'MK_deal_det_click_upload',
    cancelUpload: 'MK_deal_det_cancel_upload',
    cancelUploadProgress: 'MK_deal_det_cancel_upload_progress',
    closeUploadProgress: 'MK_deal_det_close_upload_progress',
    finishUpload: 'MK_deal_det_finish_upload',
    deleteUpload: 'MK_deal_det_delete_upload',
    designAction: 'MK_deal_det_design_action',
    viewVRDesign: 'MK_deal_det_view_vr_design',
    deleteDesign: 'MK_deal_det_delete_design',
    reassignSubmit: 'MK_deal_det_reassign_submit',
    updateSubmit: 'MK_deal_det_update_submit',
  },
};

export default analyticsEvents;
