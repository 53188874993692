import { IErrorActionData } from '../../../models/Error';

export interface ICustomerAddress {
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2: string
  addressType: string,
  city: string,
  country: string,
  createdAt: string,
  createdBy: number,
  customerId: number,
  deletedAt: string,
  id: number,
  info: string,
  isActive: boolean,
  modifiedBy: number,
  state: string,
  updatedAt: string,
  zip: number,
}

export interface ICustomerAddressStateDetails {
  customerAddress?: ICustomerAddress;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class CustomerAddress {
  customerAddress: Record<string, ICustomerAddressStateDetails>;
  constructor(customerAddressMetaDetails: Record<string, ICustomerAddressStateDetails>) {
    this.customerAddress = customerAddressMetaDetails;
  }
}

export const addCustomerAddressToExisting = (existingCustomerAddress: Record<string, ICustomerAddressStateDetails>,
  addressAliasId: string, customerAddress: ICustomerAddressStateDetails) => (
  { ...existingCustomerAddress, [addressAliasId]: customerAddress }
);
