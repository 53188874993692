import { Reducer } from 'redux';

import { GetPincodeInfoActionTypes, GetPincodeInfoActions } from '../actions/getPincodeInfo';
import { IPincodeInfo } from '../models/getPincodeInfo';
import { IErrorActionData } from '../../../models/Error';

export interface IGetPincodeInfoState {
  data: IPincodeInfo;
  isLoading: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialPincodeInfoState: IGetPincodeInfoState = {
  data: {} as IPincodeInfo,
  isLoading: false,
  isSuccess: false,
  error: undefined,
};

const GetPincodeInfoReducer: Reducer<IGetPincodeInfoState, GetPincodeInfoActions> = (
  state = initialPincodeInfoState,
  action: GetPincodeInfoActions,
): IGetPincodeInfoState => {
  switch (action.type) {
    case GetPincodeInfoActionTypes.GET_PINCODE_INFO:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: undefined,
      };

    case GetPincodeInfoActionTypes.GET_PINCODE_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isSuccess: true,
      };

    case GetPincodeInfoActionTypes.GET_PINCODE_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetPincodeInfoActionTypes.GET_PINCODE_INFO_RESET:
      return initialPincodeInfoState;

    default:
      return state;
  }
};

export default GetPincodeInfoReducer;
