/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  confirmRefundSuccess,
  confirmRefundFailure,
  ConfirmRefundAction,
  ConfirmRefundActionTypes,
} from '../actions/ConfirmRefund';
import confirmRefundService from '../services/ConfirmRefund';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const ConfirmRefundEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(ConfirmRefundActionTypes.CONFIRM_REFUND_ACTION)),
  mergeMap((action: ConfirmRefundAction) => from(
    confirmRefundService(action.payload.refundAliasId, action.payload.payload),
  ).pipe(
    map((response: any) => (confirmRefundSuccess())),
    catchError((error: any) => of(
      confirmRefundFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default ConfirmRefundEpic;
