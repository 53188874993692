import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { CancelCartActions, CancelCartActionTypes } from '../actions/CancelCart';

export interface ICancelCartState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialCancelCartState: ICancelCartState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const CancelCartReducer: Reducer<ICancelCartState, CancelCartActions> = (
  state = initialCancelCartState,
  action: CancelCartActions,
): ICancelCartState => {
  switch (action.type) {
    case CancelCartActionTypes.CANCEL_CART_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CancelCartActionTypes.CANCEL_CART_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CancelCartActionTypes.CANCEL_CART_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CancelCartActionTypes.CANCEL_CART_ACTION_RESET:
      return initialCancelCartState;

    default:
      return state;
  }
};

export default CancelCartReducer;
