import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 15,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
