import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { LoginActions, LoginActionTypes } from '../actions/Login';
import LoginData from '../models/Login';

export interface ILoginState {
  loginData: LoginData;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialLoginState: ILoginState = {
  loginData: new LoginData(''),
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const LoginReducer: Reducer<ILoginState, LoginActions> = (
  state = initialLoginState,
  action: LoginActions,
): ILoginState => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case LoginActionTypes.LOGIN_ACTION_SUCCESS:
      return {
        ...state,
        loginData: new LoginData(
          action.payload.loginID,
          action.payload.otpTimeout,
        ),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case LoginActionTypes.LOGIN_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case LoginActionTypes.LOGIN_ACTION_RESET:
      return initialLoginState;

    default:
      return state;
  }
};

export default LoginReducer;
