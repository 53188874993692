/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import {
  EditCashClosureAction,
  EditCashClosureActionTypes,
  editCashClosureFailure,
  editCashClosureSuccess,
} from '../actions/EditCashClosure';
import editCashClosureService from '../services/EditCashClosure';

const EditCashClosureEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(EditCashClosureActionTypes.EDIT_CASH_CLOSURE_ACTION)),
  mergeMap((action: EditCashClosureAction) => from(
    editCashClosureService(
      action.payload.closureAliasId,
      action.payload.editCashClosureDetails,
    ),
  ).pipe(
    map((response: any) => (editCashClosureSuccess())),
    catchError((error: any) => of(
      editCashClosureFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default EditCashClosureEpic;
