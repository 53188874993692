import { Reducer } from 'redux';

import { addPresignedUrlToExisting, EWayBillUrlData } from '../models/GetEWayBillUrl';
import { GetEWayBillUrlActionTypes, GetEWayBillUrlActions } from '../actions/GetEWayBillUrl';
import { IErrorActionData } from '../../../models/Error';

export interface IGetEWayBillUrlState {
  eWayBillUrlData: EWayBillUrlData;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProformaInvoiceUrlState = {
  eWayBillUrlData: new EWayBillUrlData({}),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetEWayBillUrlState;

const GetEWayBillUrlReducer: Reducer<IGetEWayBillUrlState, GetEWayBillUrlActions> = (
  state = initialProformaInvoiceUrlState,
  action: GetEWayBillUrlActions,
): IGetEWayBillUrlState => {
  switch (action.type) {
    case GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL: {
      const updatedEWayBillData = addPresignedUrlToExisting(
        state.eWayBillUrlData.eWayBillUrlData,
        action.payload.invoiceAliasId,
        { isProcessing: true, isSuccess: false },
      );
      return { ...state, eWayBillUrlData: new EWayBillUrlData(updatedEWayBillData) };
    }

    case GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL_SUCCESS: {
      const updatedEWayBillData = addPresignedUrlToExisting(
        state.eWayBillUrlData.eWayBillUrlData,
        action.payload.eWayBillAliasId,
        { isProcessing: false, isSuccess: true, presignedInvoiceUrl: action.payload.eWayBillPresignedUrl },
      );
      return { ...state, eWayBillUrlData: new EWayBillUrlData(updatedEWayBillData) };
    }

    case GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL_FAILED: {
      const updatedShipmentInvoiceData = addPresignedUrlToExisting(
        state.eWayBillUrlData.eWayBillUrlData,
        action.payload.invoiceAliasId,
        {
          isProcessing: false,
          isSuccess: false,
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, eWayBillUrlData: new EWayBillUrlData(updatedShipmentInvoiceData) };
    }

    case GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL_RESET:
      return initialProformaInvoiceUrlState;

    default:
      return state;
  }
};

export default GetEWayBillUrlReducer;
