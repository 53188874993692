import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_ADDRESS = gql`
  query getCustomerAddress($path: String!) {
    getCustomerAddress @rest(method: "GET", path: $path, endpoint: "photon") {
      data
    }
  }
`;

const getCustomerAddressService = (customerId: string, addressId: string) => client.query(
  {
    query: GET_ADDRESS,
    variables: {
      path: URL.getCustomerAddress.replace('{customerId}', customerId).replace('{addressId}', addressId),
    },
  },
);

export default getCustomerAddressService;
