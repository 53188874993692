import { ChannelNames, PaymentStatus } from '../../../constants/Strings';
import { ICartInfluencer, ISalesPerson } from './UpdateCart';

interface IPaymentInfo {
  amount: number,
  mode: string,
  status: PaymentStatus,
  extraInfo: string,
}

export interface ICartSummary {
  chargesInfo: {
    deliveryCharge: number;
    roundOff: number;
    subTotal: number;
    totalPrice: number;
    totalPriceBeforeRoundOff: number;
    totalProductValue: number;
  },
  customerInfo: {
    customerId: number;
    contactNumber: string;
    firstName: string;
    lastName: string;
  },
  discountInfo: {
    catalogDiscount?: number;
    marketingDiscount?: number;
    totalCartDiscount?: number;
    totalDiscounts: number;
    totalLineDiscount: number;
  },
  otherInfo: {
    channelName: ChannelNames;
    shippingAddrId: number;
    billingAddrId: number;
    deliveryAtTs: number;
    numOfItems: number;
    cartComment: string;
    storeId: number;
    totalGst: number;
    cartState: string;
    orderReferenceId: string;
    gstNumber: string;
  },
  referralInfo?: {
    salesperson?: ISalesPerson[];
    influencer?: ICartInfluencer;
  }
  paymentInfo: IPaymentInfo,
}

export class CartSummary {
  cartSummary = {} as ICartSummary;
  constructor(cartSummary: ICartSummary) {
    this.cartSummary = cartSummary;
  }
}
