import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

import useStyles from './styles';

type ClassType = {
  container?: string;
  itemWrapper?: string;
};
interface IProps<T> {
  id: string;
  data: T[];
  hasMore: boolean;
  renderItem: (item: T, index: number, key: string) => React.ReactNode;
  onClick:(val: T) => void;
  keyExtractor: (item: T) => string;
  fetchMoreData: () => void;
  loadingComponent?: React.ReactNode;
  classes?: ClassType;
}

const IMFlatList = <T extends (object | unknown)>(props: IProps<T>) => {
  const classes = useStyles();
  const onPress = useCallback((val: T) => () => props.onClick(val), []);
  return (
    <Box className={clsx(classes.container, props.classes?.container)} id={props.id}>
      <InfiniteScroll
        dataLength={props.data.length}
        hasMore={props.hasMore}
        next={props.fetchMoreData}
        scrollableTarget={props.id}
        loader={props.loadingComponent || <h4>Loading...</h4>}
      >
        {props.data.map((item, index) => (
          <Box onClick={onPress(item)} key={props.keyExtractor(item)} className={props.classes?.itemWrapper}>
            {props.renderItem(item, index, props.keyExtractor(item))}
          </Box>
        ))}
      </InfiniteScroll>
    </Box>
  );
};

export default IMFlatList;
