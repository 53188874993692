import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { ISOCancellationDetails } from '../models/SOCancellationDetails';

export enum GetSOCancellationDetailsActionTypes {
  GET_SO_CANCELLATION_DETAILS = 'GET_SO_CANCELLATION_DETAILS',
  GET_SO_CANCELLATION_DETAILS_SUCCESS = 'GET_SO_CANCELLATION_DETAILS_SUCCESS',
  GET_SO_CANCELLATION_DETAILS_FAILED = 'GET_SO_CANCELLATION_DETAILS_FAILED',
  GET_SO_CANCELLATION_DETAILS_RESET = 'GET_SO_CANCELLATION_DETAILS_RESET',
}

export const AvailableFilterProperty = {
  customerSupplyOrderAliasIds: 'customerSupplyOrderAliasIds',
};

export interface IGetSOCancellationDetailsActionData {
  filter: IFilter[]
}

export interface IGetSOCancellationDetailsSuccessActionData {
  cancellationSODetails: ISOCancellationDetails[];
}

export const getSOCancellationDetails = createAction(GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS)
<IGetSOCancellationDetailsActionData>();
export const getSOCancellationDetailsSuccess = createAction(GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS_SUCCESS)
<IGetSOCancellationDetailsSuccessActionData>();
export const getSOCancellationDetailsFailure = createAction(GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS_FAILED)
<IErrorActionData>();
export const getSOCancellationDetailsReset = createAction(GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS_RESET)();

export type GetSOCancellationDetailsAction = ActionType<typeof getSOCancellationDetails>;
type GetSOCancellationDetailsSuccessAction = ActionType<typeof getSOCancellationDetailsSuccess>;
type GetSOCancellationDetailsFailureAction = ActionType<typeof getSOCancellationDetailsFailure>;
type GetSOCancellationDetailsResetAction = ActionType<typeof getSOCancellationDetailsReset>;

export type GetSOCancellationDetailsActions = GetSOCancellationDetailsAction
| GetSOCancellationDetailsSuccessAction
| GetSOCancellationDetailsFailureAction
| GetSOCancellationDetailsResetAction;
