import { last } from 'lodash';

import { defaultCursorValue } from '../../../constants/Common';

export interface IProjectListItem {
  projectIdentifier: string;
  projectName: string;
  projectLocation: string;
  projectType: string;
  updatedAt: number;
  address: {
    city: string;
  }
}

export class ProjectList {
  projectLists: Array<IProjectListItem> = [];
  constructor(projectLists: Array<IProjectListItem>) {
    this.projectLists = projectLists;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<IProjectListItem>,
  payloadList: Array<IProjectListItem>,
  cursor: string | number | undefined,
) => {
  if (cursor === defaultCursorValue || !existingList.length) {
    return payloadList;
  }
  if (!payloadList.length || last(existingList)?.projectIdentifier === last(payloadList)?.projectIdentifier) {
    return existingList;
  }
  return existingList.concat(payloadList);
};
