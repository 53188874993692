import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const DELETE_CART_ITEM = gql`
  query deleteCartItem($path: String!, $payload: any) {
    deleteCartItem (path: $path, payload: $payload)
      @rest(method: "DELETE", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const deleteCartItemService = (cartAliasId: string, cartItemAliasId: string) => {
  const url = URL.deleteCartItem.replace('{cartAliasId}', cartAliasId).replace('{cartItemAliasId}', cartItemAliasId);
  return client.query({ query: DELETE_CART_ITEM, variables: { path: url, payload: {} } });
};

export default deleteCartItemService;
