import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_INFLUENCER_DETAILS = gql`
  query getInfluencerDetails($path: String!) {
    getInfluencerDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getInfluencerDetailsService = (filter?: IFilter[]) => {
  const url = constructUrlWithParams(URL.getInfluencerDetails, filter, undefined, undefined);
  return client.query({ query: GET_INFLUENCER_DETAILS, variables: { path: url } });
};

export default getInfluencerDetailsService;
