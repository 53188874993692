export interface IAdditionalCashListItem {
  remainingCashAliasId: string;
  amount: number;
  transactionDate: number;
}

export class AdditionalCashList {
  additionalCashList = [] as Array<IAdditionalCashListItem>;
  constructor(additionalCashList: Array<IAdditionalCashListItem>) {
    this.additionalCashList = additionalCashList;
  }
}

/**
   * Merging Two Lists
   * @param existingList - existingList
   * @param payloadList - Action Payload List
   */
export const mergeTwoLists = (
  existingList: Array<IAdditionalCashListItem>,
  payloadList: Array<IAdditionalCashListItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
