import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum GetDisplayInventoryListUrlActionTypes {
  GET_DISPLAY_INVENTORY_LIST_URL = 'GET_DISPLAY_INVENTORY_LIST_URL',
  GET_DISPLAY_INVENTORY_LIST_URL_SUCCESS = 'GET_DISPLAY_INVENTORY_LIST_URL_SUCCESS',
  GET_DISPLAY_INVENTORY_LIST_URL_FAILED = 'GET_DISPLAY_INVENTORY_LIST_URL_FAILED',
  GET_DISPLAY_INVENTORY_LIST_URL_RESET = 'GET_DISPLAY_INVENTORY_LIST_URL_RESET',
}

export interface IGetDisplayInventoryListUrlActionData {
  storeAliasId: string;
  filter?: IFilter[];
}

export interface IGetDisplayInventoryListUrlSuccessActionData {
  data: string;
}

export const getDisplayInventoryListUrl = createAction(GetDisplayInventoryListUrlActionTypes.GET_DISPLAY_INVENTORY_LIST_URL)
<IGetDisplayInventoryListUrlActionData>();
export const getDisplayInventoryListUrlSuccess = createAction(GetDisplayInventoryListUrlActionTypes.GET_DISPLAY_INVENTORY_LIST_URL_SUCCESS)
<IGetDisplayInventoryListUrlSuccessActionData>();
export const getDisplayInventoryListUrlFailure = createAction(GetDisplayInventoryListUrlActionTypes.GET_DISPLAY_INVENTORY_LIST_URL_FAILED)
<IErrorActionData>();
export const getDisplayInventoryListUrlReset = createAction(GetDisplayInventoryListUrlActionTypes.GET_DISPLAY_INVENTORY_LIST_URL_RESET)();

export type GetDisplayInventoryListUrlAction = ActionType<typeof getDisplayInventoryListUrl>;
type GetDisplayInventoryListUrlSuccessAction = ActionType<typeof getDisplayInventoryListUrlSuccess>;
type GetDisplayInventoryListUrlFailureAction = ActionType<typeof getDisplayInventoryListUrlFailure>;
type GetDisplayInventoryListUrlResetAction = ActionType<typeof getDisplayInventoryListUrlReset>;

export type GetDisplayInventoryListUrlActions = GetDisplayInventoryListUrlAction
| GetDisplayInventoryListUrlSuccessAction
| GetDisplayInventoryListUrlFailureAction
| GetDisplayInventoryListUrlResetAction;
