/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getProformaInvoiceUrlSuccess,
  getProformaInvoiceUrlFailure,
  GetProformaInvoiceUrlAction,
  GetProformaInvoiceUrlActionTypes,
} from '../actions/GetProformaInvoiceUrl';
import getProformaInvoiceUrlService from '../services/GetProformaInvoiceUrl';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetProformaInvoiceUrlEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL)),
  mergeMap((action: GetProformaInvoiceUrlAction) => from(
    getProformaInvoiceUrlService(action.payload.invoiceAliasId),
  ).pipe(
    map((response) => getProformaInvoiceUrlSuccess({
      proformaInvoiceUrl: response.data.getProformaInvoiceUrl.data.preSignedUrl || '',
      invoiceAliasId: action.payload.invoiceAliasId,
    })),
    catchError((error: IErrorActionData) => of(
      getProformaInvoiceUrlFailure({
        invoiceAliasId: action.payload.invoiceAliasId,
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetProformaInvoiceUrlEpic;
