import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const EDIT_SKU_DELIVERY_DATE = gql`
  query editSKUDeliveryDate($path: String!, $payload: any) {
    editSKUDeliveryDate (path: $path, payload: $payload)
      @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const editSKUDeliveryDateService = (orderReferenceId: string, skuId: string, preferredDate: number): Promise<number> => {
  const editSKUDeliveryDate = 16789091;
  // return client.query({
  //     query: EDIT_SKU_DELIVERY_DATE,
  //     variables: {
  //       path: URL.editSKUDeliveryDate.replace('{orderReferenceId}', orderReferenceId).replace('{skuId}', skuId),
  //       payload: { preferredDate },
  //     }),
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(editSKUDeliveryDate);
    });
  });
};

export default editSKUDeliveryDateService;
