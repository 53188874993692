import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IDownloadCashTransactionsReportPayload } from '../models/DownloadCashTransactionsReport';

const DOWNLOAD_CASH_TRANSACTIONS_REPORT = gql`
  query downloadCashTransactionsReport($path: String!, $payload: any) {
    downloadCashTransactionsReport (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload" endpoint: "pheonix") {
        data
      }
  }
`;

const downloadCashTransactionsReportService = (storeAliasId: string, payloadDetails?: IDownloadCashTransactionsReportPayload) => {
  const url = URL.downloadCashTransactionsReport.replace('{storeAliasId}', storeAliasId);
  return client.query({ query: DOWNLOAD_CASH_TRANSACTIONS_REPORT, variables: { path: url, payload: payloadDetails } });
};

export default downloadCashTransactionsReportService;
