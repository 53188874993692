import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IAddEditCashClosurePayload } from '../models/AddEditCashClosure';

export enum AddCashClosureActionTypes {
  ADD_CASH_CLOSURE_ACTION = 'ADD_CASH_CLOSURE_ACTION',
  ADD_CASH_CLOSURE_ACTION_SUCCESS = 'ADD_CASH_CLOSURE_ACTION_SUCCESS',
  ADD_CASH_CLOSURE_ACTION_FAILURE = 'ADD_CASH_CLOSURE_ACTION_FAILURE',
  ADD_CASH_CLOSURE_ACTION_RESET = 'ADD_CASH_CLOSURE_ACTION_RESET',
}

export interface IAddCashClosureActionData {
  addCashClosureDetails: IAddEditCashClosurePayload;
}

export const addCashClosure = createAction(AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION)
<IAddCashClosureActionData>();
export const addCashClosureSuccess = createAction(AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION_SUCCESS)();
export const addCashClosureFailure = createAction(AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION_FAILURE)
<IErrorActionData>();
export const addCashClosureReset = createAction(AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION_RESET)();

export type AddCashClosureAction = ActionType<typeof addCashClosure>;
type AddCashClosureSuccessAction = ActionType<typeof addCashClosureSuccess>;
type AddCashClosureFailureAction = ActionType<typeof addCashClosureFailure>;
type AddCashClosureResetAction = ActionType<typeof addCashClosureReset>;

export type AddCashClosureActions =
  | AddCashClosureAction
  | AddCashClosureSuccessAction
  | AddCashClosureFailureAction
  | AddCashClosureResetAction;
