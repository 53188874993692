import { Reducer } from 'redux';

import { AuditLogs, IAuditLogs } from '../models/AuditLogs';
import { GetAuditLogsActionTypes, GetAuditLogsActions } from '../actions/GetAuditLogs';
import { IErrorActionData } from '../../../models/Error';

export interface IAuditLogsState {
  auditLogs: AuditLogs;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialAuditLogsState = {
  auditLogs: new AuditLogs({} as IAuditLogs),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IAuditLogsState;

const GetAuditLogsReducer: Reducer<IAuditLogsState, GetAuditLogsActions> = (
  state = initialAuditLogsState,
  action: GetAuditLogsActions,
): IAuditLogsState => {
  switch (action.type) {
    case GetAuditLogsActionTypes.GET_AUDIT_LOGS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetAuditLogsActionTypes.GET_AUDIT_LOGS_SUCCESS: {
      return {
        ...state,
        auditLogs: new AuditLogs(action.payload.auditLogDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetAuditLogsActionTypes.GET_AUDIT_LOGS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetAuditLogsActionTypes.GET_AUDIT_LOGS_RESET:
      return initialAuditLogsState;

    default:
      return state;
  }
};

export default GetAuditLogsReducer;
