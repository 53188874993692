/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getProductListSuccess,
  getProductListFailure,
  GetProductListAction,
  GetProductListActionTypes,
} from '../actions/GetProductList';
import getProductListService from '../services/GetProductList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetProductListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetProductListActionTypes.GET_PRODUCT_LIST)),
  mergeMap((action: GetProductListAction) => from(
    getProductListService(
      action.payload.payload,
    ),
  ).pipe(
    map((response) => getProductListSuccess({
      productList: response.data.getProductList.data || [],
      pageInfo: response.data.getProductList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getProductListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetProductListEpic;
