import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { AddCashClosureActions, AddCashClosureActionTypes } from '../actions/AddCashClosure';

export interface IAddCashClosureState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialAddCashClosureState: IAddCashClosureState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const AddCashClosureReducer: Reducer<IAddCashClosureState, AddCashClosureActions> = (
  state = initialAddCashClosureState,
  action: AddCashClosureActions,
): IAddCashClosureState => {
  switch (action.type) {
    case AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case AddCashClosureActionTypes.ADD_CASH_CLOSURE_ACTION_RESET:
      return initialAddCashClosureState;

    default:
      return state;
  }
};

export default AddCashClosureReducer;
