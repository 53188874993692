import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IUpdateCustomerDetailsPayload } from '../models/UpdateCustomerDetails';

export enum UpdateCustomerDetailsActionTypes {
  UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS',
  UPDATE_CUSTOMER_DETAILS_SUCCESS = 'UPDATE_CUSTOMER_DETAILS_SUCCESS',
  UPDATE_CUSTOMER_DETAILS_FAILURE = 'UPDATE_CUSTOMER_DETAILS_FAILURE',
  UPDATE_CUSTOMER_DETAILS_RESET = 'UPDATE_CUSTOMER_DETAILS_RESET',
}

interface IUpdateCustomerDetails {
  customerId: string;
  data: IUpdateCustomerDetailsPayload;
}

export const updateCustomerDetails = createAction(UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS)<IUpdateCustomerDetails>();
export const updateCustomerDetailsSuccess = createAction(UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS_SUCCESS)();
export const updateCustomerDetailsFailure = createAction(UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS_FAILURE)<
IErrorActionData
>();
export const updateCustomerDetailsReset = createAction(UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS_RESET)();

export type UpdateCustomerDetailsAction = ActionType<typeof updateCustomerDetails>;
type UpdateCustomerDetailsSuccessAction = ActionType<typeof updateCustomerDetailsSuccess>;
type UpdateCustomerDetailsFailureAction = ActionType<typeof updateCustomerDetailsFailure>;
type UpdateCustomerDetailsResetAction = ActionType<typeof updateCustomerDetailsReset>;

export type UpdateCustomerDetailsActions =
  | UpdateCustomerDetailsAction
  | UpdateCustomerDetailsSuccessAction
  | UpdateCustomerDetailsFailureAction
  | UpdateCustomerDetailsResetAction;
