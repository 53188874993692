import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: 0,
    backgroundColor: theme.palette.IMSecondary.background,
  },
  LoginContainer: {
    minWidth: 480,
    maxWidth: 480,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(22.5, 20, 0, 20),
    backgroundColor: theme.palette.IMSecondary.contrastText,
  },
  imageContainer: {
    width: 'inherit',
    minHeight: 'inherit',
    position: 'inherit',
    padding: theme.spacing(10, 13),
  },
  image: {
    width: 200,
    height: 200,
  },
  mobInput: {
    marginTop: 0,
  },
  loginSubmitButton: {
    height: 42,
    fontSize: 15,
    fontWeight: 600,
    borderRadius: 5,
    margin: theme.spacing(2, 4, 0, 4),
    color: theme.palette.IMPrimary.contrastText,
    backgroundColor: theme.palette.IMPrimary.main,
    '&:hover': {
      backgroundColor: theme.palette.IMPrimary.main,
    },
  },
  otpContainer: {
    justifyContent: 'space-between',
  },
  otpInputBox: {
    width: '46px !important',
    height: 61,
    fontSize: 20,
    fontWeight: 500,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: theme.palette.IMOther.stroke,
  },
  otpInputBoxFocus: {
    outline: 'none',
    borderWidth: 2,
    borderColor: theme.palette.IMPrimary.main,
  },
  backBtn: {
    padding: 0,
    color: theme.palette.IMPrimary.main,
  },
  resendOtp: {
    height: 30,
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.IMPrimary.main,
    borderColor: theme.palette.IMPrimary.border,
    '&:hover': {
      borderColor: theme.palette.IMPrimary.border,
    },
  },
  resendOtpDisabled: {
    color: theme.palette.grey[400],
    borderColor: theme.palette.IMOther.divider,
    '&:hover': {
      borderColor: theme.palette.IMOther.divider,
    },
  },
  resendOtpTimer: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.disabled,
  },
  errorText: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 400,
    color: theme.palette.IMError.main,
    textAlign: 'start',
    marginTop: theme.spacing(1),
  },
  otpBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: theme.spacing(8),
  },
  resendBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default useStyles;
