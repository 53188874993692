/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  addCartItemDiscountSuccess,
  addCartItemDiscountFailure,
  AddCartItemDiscountAction,
  AddCartItemDiscountActionTypes,
} from '../actions/AddCartItemDiscount';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import addCartItemDiscountService from '../services/AddCartItemDiscount';

const AddCartItemDiscountEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT)),
  mergeMap((action: AddCartItemDiscountAction) => from(
    addCartItemDiscountService(action.payload.cartAliasId, action.payload.cartItemAliasId, action.payload.payload),
  ).pipe(
    map((response) => addCartItemDiscountSuccess()),
    catchError((error: IErrorActionData) => of(
      addCartItemDiscountFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default AddCartItemDiscountEpic;
