/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getStoreDetailsSuccess,
  getStoreDetailsFailed,
  GetStoreDetailsAction,
  GetStoreDetailsActionTypes,
} from '../actions/GetStoreDetails';
import { IErrorActionData } from '../../../models/Error';
import getStoreDetailsService from '../services/GetStoreDetails';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import { getFormattedStoreDetails } from '../models/StoreDetails';

const GetStoreDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetStoreDetailsActionTypes.GET_STORE_DETAILS)),
  mergeMap((action: GetStoreDetailsAction) => from(
    getStoreDetailsService(action.payload.storeAliasId),
  ).pipe(
    map((response: any) => getStoreDetailsSuccess({
      storeDetails: getFormattedStoreDetails(response.data.getStoreDetails.data || {}),
    })),
    catchError((error: IErrorActionData) => of(
      getStoreDetailsFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetStoreDetailsEpic;
