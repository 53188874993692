import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IFilter, constructUrlWithParams } from '../../../utils/sortFilterFramework';

const GET_EDC_MACHINE_LIST = gql`
  query getEDCMachineList($path: String!) {
    getEDCMachineList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getEDCMachineListService = (filter: IFilter[]) => {
  const url = constructUrlWithParams(URL.getEDCMachineList, filter);
  return client.query({ query: GET_EDC_MACHINE_LIST, variables: { path: url } });
};

export default getEDCMachineListService;
