/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCustomerAddressSuccess,
  getCustomerAddressFailed,
  GetCustomerAddressAction,
  GetCustomerAddressActionTypes,
} from '../actions/GetCustomerAddress';
import { IErrorActionData } from '../../../models/Error';
import getCustomerAddressService from '../services/GetCustomerAddress';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCustomerAddressEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS)),
  mergeMap((action: GetCustomerAddressAction) => from(
    getCustomerAddressService(action.payload.customerId, action.payload.addressAliasId),
  ).pipe(
    map((response: any) => getCustomerAddressSuccess({
      data: response.data.getCustomerAddress.data[0] || {},
      addressAliasId: action.payload.addressAliasId,
    })),
    catchError((error: IErrorActionData) => of(
      getCustomerAddressFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
        addressAliasId: action.payload.addressAliasId,
      }),
    )),
  )),
);

export default GetCustomerAddressEpic;
