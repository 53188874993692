export interface IQuotation {
  quotationAliasId: string;
  quotationPdfUrl: string;
}

export class GenerateQuotation {
  quotation = {} as IQuotation;
  constructor(quotation: IQuotation) {
    this.quotation = quotation;
  }
}
