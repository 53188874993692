import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { ICompleteTaskPayload } from '../models/CompleteTask';

export enum CompleteTaskActionTypes {
  COMPLETE_TASK = 'COMPLETE_TASK',
  COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS',
  COMPLETE_TASK_FAILED = 'COMPLETE_TASK_FAILED',
  COMPLETE_TASK_RESET = 'COMPLETE_TASK_RESET',
}

export interface ICompleteTaskActionData {
  taskAliasId: string;
  payload: ICompleteTaskPayload;
}

export const completeTask = createAction(CompleteTaskActionTypes.COMPLETE_TASK)<ICompleteTaskActionData>();
export const completeTaskSuccess = createAction(CompleteTaskActionTypes.COMPLETE_TASK_SUCCESS)();
export const completeTaskFailure = createAction(CompleteTaskActionTypes.COMPLETE_TASK_FAILED)<IErrorActionData>();
export const completeTaskReset = createAction(CompleteTaskActionTypes.COMPLETE_TASK_RESET)();

export type CompleteTaskAction = ActionType<typeof completeTask>;
type CompleteTaskSuccessAction = ActionType<typeof completeTaskSuccess>;
type CompleteTaskFailureAction = ActionType<typeof completeTaskFailure>;
type CompleteTaskResetAction = ActionType<typeof completeTaskReset>;

export type CompleteTaskActions = CompleteTaskAction
| CompleteTaskSuccessAction
| CompleteTaskFailureAction
| CompleteTaskResetAction;
