/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getOrderCustomerDetailsSuccess,
  getOrderCustomerDetailsFailure,
  GetOrderCustomerDetailsAction,
  GetOrderCustomerDetailsActionTypes,
} from '../actions/GetOrderCustomerDetails';
import getOrderCustomerDetailsService from '../services/GetOrderCustomerDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetOrderCustomerDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS)),
  mergeMap((action: GetOrderCustomerDetailsAction) => from(
    getOrderCustomerDetailsService(action.payload.orderReferenceId),
  ).pipe(
    map((response) => getOrderCustomerDetailsSuccess({
      orderCustomerDetails: response.data.getOrderCustomerDetails.data || {},
    })),
    catchError((error: IErrorActionData) => of(
      getOrderCustomerDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetOrderCustomerDetailsEpic;
