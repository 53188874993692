/* eslint-disable @typescript-eslint/no-unsafe-return */
import { fetchAndActivate, getRemoteConfig, getAll } from 'firebase/remote-config';

import firebase from '../../../utils/firebase';
import { FETCH_INTERVAL } from '../../../constants/RemoteConfig';

const fetchRemoteConfig = async () => {
  const remoteConfig = getRemoteConfig(firebase);

  // Set the minimum fetch interval (in milliseconds)
  remoteConfig.settings.minimumFetchIntervalMillis = FETCH_INTERVAL;

  return fetchAndActivate(remoteConfig)
    .then(() => getAll(remoteConfig))
    .then((snapshot: any) => snapshot);
};

export default fetchRemoteConfig;
