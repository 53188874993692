import { Reducer } from 'redux';

import { CreateOrderPaymentActionTypes, CreateOrderPaymentActions } from '../actions/CreateOrderPayment';
import { IErrorActionData } from '../../../models/Error';

export interface ICreateOrderPaymentState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialOrderPaymentDetailsState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICreateOrderPaymentState;

const CreateOrderPaymentReducer: Reducer<ICreateOrderPaymentState, CreateOrderPaymentActions> = (
  state = initialOrderPaymentDetailsState,
  action: CreateOrderPaymentActions,
): ICreateOrderPaymentState => {
  switch (action.type) {
    case CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CreateOrderPaymentActionTypes.CREATE_ORDER_PAYMENT_RESET:
      return initialOrderPaymentDetailsState;

    default:
      return state;
  }
};

export default CreateOrderPaymentReducer;
