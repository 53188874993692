import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IAddEditCashClosurePayload } from '../models/AddEditCashClosure';

const EDIT_CASH_CLOSURE = gql`
  query editCashClosure($path: String!, $payload: any) {
    editCashClosure (path: $path, payload: $payload)
      @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const editCashClosureService = (closureAliasId: string, payload: IAddEditCashClosurePayload) => {
  const url = URL.editCashClosure.replace('{closureAliasId}', closureAliasId);
  return client.query({ query: EDIT_CASH_CLOSURE, variables: { path: url, payload } });
};

export default editCashClosureService;
