import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const START_PROCESSING_SUPPLY_ORDER = gql`
  query startProcessingSupplyOrder($path: String!, $payload: any) {
    startProcessingSupplyOrder (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const startProcessingSupplyOrderService = (supplyOrderId: number) => client.query({
  query: START_PROCESSING_SUPPLY_ORDER,
  variables: { path: URL.startProcessingSupplyOrder, payload: { supplyOrderId, success: true } },
});

export default startProcessingSupplyOrderService;
