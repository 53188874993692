import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IEditCmsSlipPayload } from '../actions/EditCmsSlip';
import URL from '../../../constants/ApiEndPoints';

const EDIT_CMS_SLIP = gql`
  query editCmsSlip($path: String!, $data: any) {
    editCmsSlip(input: $data, path: $path) 
      @rest(method: "PUT", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const editCmsSlipService = (closureAliasId: string, payload: IEditCmsSlipPayload) => {
  const url = URL.editCmsSlip.replace('{closureAliasId}', closureAliasId);
  return client.query({ query: EDIT_CMS_SLIP, variables: { data: payload, path: url } });
};

export default editCmsSlipService;
