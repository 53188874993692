/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  createCustomerSuccess,
  createCustomerFailed,
  CreateCustomerAction,
  CreateCustomerActionTypes,
} from '../actions/CreateCustomer';
import { IErrorActionData } from '../../../models/Error';
import createCustomerService from '../services/CreateCustomer';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CreateCustomerEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CreateCustomerActionTypes.CREATE_CUSTOMER)),
  mergeMap((action: CreateCustomerAction) => from(
    createCustomerService(
      action.payload.firstName,
      action.payload.lastName,
      action.payload.customerNumber,
      action.payload.loginId,
      action.payload.gst,
      action.payload.companyName,
    ),
  ).pipe(
    map((response) => createCustomerSuccess({
      newCustomerData: response.data.createCustomer.data,
    })),
    catchError((error: IErrorActionData) => of(
      createCustomerFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CreateCustomerEpic;
