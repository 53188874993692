import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { IOrderPaymentDetails } from '../models/OrderPaymentDetails';

export enum GetOrderPaymentDetailsActionTypes {
  GET_ORDER_PAYMENT_DETAILS = 'GET_ORDER_PAYMENT_DETAILS',
  GET_ORDER_PAYMENT_DETAILS_SUCCESS = 'GET_ORDER_PAYMENT_DETAILS_SUCCESS',
  GET_ORDER_PAYMENT_DETAILS_FAILED = 'GET_ORDER_PAYMENT_DETAILS_FAILED',
  GET_ORDER_PAYMENT_DETAILS_RESET = 'GET_ORDER_PAYMENT_DETAILS_RESET',
}

export interface IGetOrderPaymentDetailsActionData {
  orderReferenceId: string;
}

export interface IGetOrderPaymentDetailsSuccessActionData {
  orderPaymentDetails: IOrderPaymentDetails;
}

export const getOrderPaymentDetails = createAction(GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS)
<IGetOrderPaymentDetailsActionData>();
export const getOrderPaymentDetailsSuccess = createAction(GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS_SUCCESS)
<IGetOrderPaymentDetailsSuccessActionData>();
export const getOrderPaymentDetailsFailure = createAction(GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS_FAILED)
<IErrorActionData>();
export const getOrderPaymentDetailsReset = createAction(GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS_RESET)();

export type GetOrderPaymentDetailsAction = ActionType<typeof getOrderPaymentDetails>;
type GetOrderPaymentDetailsSuccessAction = ActionType<typeof getOrderPaymentDetailsSuccess>;
type GetOrderPaymentDetailsFailureAction = ActionType<typeof getOrderPaymentDetailsFailure>;
type GetOrderPaymentDetailsResetAction = ActionType<typeof getOrderPaymentDetailsReset>;

export type GetOrderPaymentDetailsActions = GetOrderPaymentDetailsAction
| GetOrderPaymentDetailsSuccessAction
| GetOrderPaymentDetailsFailureAction
| GetOrderPaymentDetailsResetAction;
