import { Reducer } from 'redux';

import { CreateAddressActions, CreateAddressActionTypes } from '../actions/CreateAddress';
import { IErrorActionData } from '../../../models/Error';
import { INewAddress, NewAddress } from '../models/NewAddress';

export interface ICreateAddressState {
  newAddressDetails: NewAddress;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCreateAddressState = {
  newAddressDetails: new NewAddress({} as INewAddress),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICreateAddressState;

const CreateAddressReducer: Reducer<ICreateAddressState, CreateAddressActions> = (
  state = initialCreateAddressState,
  action: CreateAddressActions,
): ICreateAddressState => {
  switch (action.type) {
    case CreateAddressActionTypes.CREATE_ADDRESS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CreateAddressActionTypes.CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        newAddressDetails: new NewAddress(action.payload.newAddressData),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CreateAddressActionTypes.CREATE_ADDRESS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CreateAddressActionTypes.CREATE_ADDRESS_RESET:
      return initialCreateAddressState;

    default:
      return state;
  }
};

export default CreateAddressReducer;
