import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { MarkWrongTransactionActions, MarkWrongTransactionActionTypes } from '../actions/MarkWrongTransaction';

export interface IMarkWrongTransactionState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialMarkWrongTransactionState: IMarkWrongTransactionState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const MarkWrongTransactionReducer: Reducer<IMarkWrongTransactionState, MarkWrongTransactionActions> = (
  state = initialMarkWrongTransactionState,
  action: MarkWrongTransactionActions,
): IMarkWrongTransactionState => {
  switch (action.type) {
    case MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION_RESET:
      return initialMarkWrongTransactionState;

    default:
      return state;
  }
};

export default MarkWrongTransactionReducer;
