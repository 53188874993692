/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
import { useTypedSelector } from '../config/store';

const FeatureFlagValueMap = new Map([
  ['true', true],
  ['false', false],
]);

const useHasFeatureFlag = (feature: string) => {
  const remoteConfigState = useTypedSelector((state) => state.fetchRemoteConfig);
  const remoteConfigData = remoteConfigState.configData;

  if (remoteConfigState.isSuccess && remoteConfigData.hasOwnProperty(feature)) {
    const featureFlagValue = remoteConfigData[feature]._value;
    return FeatureFlagValueMap.get(featureFlagValue) ?? featureFlagValue;
  }

  return false;
};

export default useHasFeatureFlag;
