// Data is locally cached for FETCH_INTERVAL in milliseconds
export const FETCH_INTERVAL = 1000;

// Feature names constants, use them to reference to a feature
enum FeatureFlagKeys {
  MAX_CART_DISCOUNT_PERCENTAGE = 'maxCartDiscountPercentage',
  DEAL_FEATURE = 'dealFeature',
  DEAL_SEARCH_FEATURE = 'dealSearchFeature',
  DEAL_V2_FEATURES = 'dealV2Features',
}

export default FeatureFlagKeys;
