import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum AutoLoginActionTypes {
  AUTO_LOGIN = 'AUTO_LOGIN',
  AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS',
  AUTO_LOGIN_FAILURE = 'AUTO_LOGIN_FAILURE',
  AUTO_LOGIN_RESET = 'AUTO_LOGIN_RESET',
}

export interface IAutoLoginActionData {
  info?: {
    device: {
      deviceName: string;
    }
  }
}

export const autoLogin = createAction(AutoLoginActionTypes.AUTO_LOGIN)<IAutoLoginActionData>();
export const autoLoginSuccess = createAction(AutoLoginActionTypes.AUTO_LOGIN_SUCCESS)();
export const autoLoginFailure = createAction(AutoLoginActionTypes.AUTO_LOGIN_FAILURE)<IErrorActionData>();
export const autoLoginReset = createAction(AutoLoginActionTypes.AUTO_LOGIN_RESET)();

export type AutoLoginAction = ActionType<typeof autoLogin>;
type AutoLoginSuccessAction = ActionType<typeof autoLoginSuccess>;
type AutoLoginFailureAction = ActionType<typeof autoLoginFailure>;
type AutoLoginResetAction = ActionType<typeof autoLoginReset>;

export type AutoLoginActions = AutoLoginAction | AutoLoginSuccessAction | AutoLoginFailureAction | AutoLoginResetAction;
