import React, { FunctionComponent } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

type ClassType = {
  container?: string
  title?: string
  subtitle?: string
};

interface IInfoPair {
  title: string;
  subtitle: string;
  id?: string;
  showTitleToolTip?: boolean;
  showSubtitleToolTip?: boolean;
  variant?: 'vertical' | 'horizontal',
  wrapTitle?: boolean;
  wrapSubTitle?: boolean;
  classes?: ClassType;
}

const IMInfoPair: FunctionComponent<IInfoPair> = (props) => {
  const classes = useStyles();
  const id = props.id || `IMInfo${Math.round(Math.random() * 10000)}`;

  return (
    <Box className={clsx(classes.container, props.variant === 'horizontal' && classes.horizontal, props.classes?.container)}>
      <Tooltip id={`${id}-title-tooltip`} placement="bottom" title={props.showTitleToolTip ? props.title : ''} arrow>
        <Typography
          id={`${id}-title`}
          className={clsx(classes.title, props.classes?.title)}
          noWrap={!props.wrapTitle}
        >
          {props.title}
        </Typography>
      </Tooltip>
      <Tooltip id={`${id}-subTitle-tooltip`} placement="bottom" title={props.showSubtitleToolTip ? props.subtitle : ''} arrow>
        <Typography
          id={`${id}-subTitle`}
          className={clsx(classes.subtitle, props.classes?.subtitle)}
          noWrap={!props.wrapSubTitle}
        >
          {props.subtitle}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default IMInfoPair;
