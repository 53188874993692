import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, Fade } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';

import { logout, logoutReset } from '../../feature/auth/actions/Logout';
import { useTypedSelector } from '../../config/store';
import IMInfoListItem from '../../components/IMInfoListItem';
import { AnalyticsPlatform, analytics } from '../../config/analytics';
import analyticsEvents from '../../constants/Analytics';
import useStyles from './styles';

const LoggedInUserDetails: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('auth');

  const logoutState = useTypedSelector((state) => state.logoutState);
  const userProfileState = useTypedSelector((state) => state.userProfile);
  const getStoreDetailsState = useTypedSelector((state) => state.getStoreDetails);

  const userProfileData = userProfileState.userProfileData.userData;
  const storeDetailsData = getStoreDetailsState.storeDetails.storeDetails;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const userBtnClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    analytics.trackEvent({
      type: AnalyticsPlatform.mixpanel,
      eventName: analyticsEvents.user.userProfileClick,
      eventProperties: {
        storeName: storeDetailsData.name,
      },
    });
    setAnchorEl(event.currentTarget);
  };

  const logoutHandler = () => {
    analytics.trackEvent({
      type: AnalyticsPlatform.mixpanel,
      eventName: analyticsEvents.user.logoutConfirmBtnClick,
      eventProperties: {
        storeName: storeDetailsData.name,
      },
    });
    dispatch(logout({ info: { LogoutAtTs: dayjs().unix() } }));
  };

  useEffect(() => {
    if (logoutState.isSuccess) {
      navigate('/login');
    }
    return () => {
      dispatch(logoutReset());
    };
  }, [logoutState.isSuccess]);

  return (
    <>
      <IconButton
        id="header-button-profileIcon"
        edge="end"
        aria-label="accountInfoBtn"
        onClick={userBtnClickHandler}
        className={classes.iconBtn}
      >
        <AccountCircleOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ list: classes.menuContainer }}
      >
        <IMInfoListItem
          id="profile-button-logout"
          title={userProfileData.phoneNumber ?? '-'}
          subtitle={`${userProfileData.firstName} ${userProfileData.lastName}`}
          hideLeftIcon
          rightIconProps={{ id: 'profile-button-title', title: t('logout'), action: logoutHandler }}
          classes={{
            container: classes.logoutContainer,
            title: classes.userTitle,
            subtitle: classes.userSubtitle,
            rightIconLabel: classes.logoutButton,
          }}
        />
      </Menu>
    </>
  );
};

export default LoggedInUserDetails;
