import { Reducer } from 'redux';

import { NewCartItemList, mergeTwoLists } from '../models/NewCartItem';
import { GetCartItemListActionTypes, GetCartItemListActions } from '../actions/GetCartItemList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize, defaultCursorValue } from '../../../constants/Common';

export interface ICartItemListState {
  cartItemList: NewCartItemList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCartItemListState = {
  cartItemList: new NewCartItemList([]),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICartItemListState;

const GetCartItemListReducer: Reducer<ICartItemListState, GetCartItemListActions> = (
  state = initialCartItemListState,
  action: GetCartItemListActions,
): ICartItemListState => {
  switch (action.type) {
    case GetCartItemListActionTypes.GET_CART_ITEM_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCartItemListActionTypes.GET_CART_ITEM_LIST_SUCCESS: {
      return {
        ...state,
        cartItemList: new NewCartItemList(action.payload.cartItemList),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetCartItemListActionTypes.GET_CART_ITEM_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCartItemListActionTypes.GET_CART_ITEM_LIST_RESET:
      return initialCartItemListState;

    default:
      return state;
  }
};

export default GetCartItemListReducer;
