import { Reducer } from 'redux';

import { GetReturnListActionTypes, GetReturnListActions } from '../actions/GetReturnList';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';
import { PageInfo } from '../../../models/PageInfo';
import { mergeTwoLists, ReturnList } from '../models/ReturnList';

export interface IGetReturnListState {
  returnList: ReturnList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialReturnListState = {
  returnList: new ReturnList([]),
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetReturnListState;

const GetReturnListReducer: Reducer<IGetReturnListState, GetReturnListActions> = (
  state = initialReturnListState,
  action: GetReturnListActions,
): IGetReturnListState => {
  switch (action.type) {
    case GetReturnListActionTypes.GET_RETURN_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetReturnListActionTypes.GET_RETURN_LIST_SUCCESS: {
      const newReturnList = mergeTwoLists(
        state.returnList.returnList,
        action.payload.returnList,
      );
      return {
        ...state,
        returnList: new ReturnList(newReturnList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
          cursor: action.payload.pageInfo.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetReturnListActionTypes.GET_RETURN_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetReturnListActionTypes.GET_RETURN_LIST_RESET:
      return initialReturnListState;

    default:
      return state;
  }
};

export default GetReturnListReducer;
