import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum EditOrderActionTypes {
  EDIT_ORDER_ACTION = 'EDIT_ORDER_ACTION',
  EDIT_ORDER_ACTION_SUCCESS = 'EDIT_ORDER_ACTION_SUCCESS',
  EDIT_ORDER_ACTION_FAILURE = 'EDIT_ORDER_ACTION_FAILURE',
  EDIT_ORDER_ACTION_RESET = 'EDIT_ORDER_ACTION_RESET',
}

export interface IEditOrderActionData {
  orderReferenceId: string;
}

export interface IEditOrderSuccessActionData {
  cartAliasId: string;
}

export const editOrder = createAction(EditOrderActionTypes.EDIT_ORDER_ACTION)<IEditOrderActionData>();
export const editOrderSuccess = createAction(EditOrderActionTypes.EDIT_ORDER_ACTION_SUCCESS)<IEditOrderSuccessActionData>();
export const editOrderFailure = createAction(EditOrderActionTypes.EDIT_ORDER_ACTION_FAILURE)<IErrorActionData>();
export const editOrderReset = createAction(EditOrderActionTypes.EDIT_ORDER_ACTION_RESET)();

export type EditOrderAction = ActionType<typeof editOrder>;
type EditOrderSuccessAction = ActionType<typeof editOrderSuccess>;
type EditOrderFailureAction = ActionType<typeof editOrderFailure>;
type EditOrderResetAction = ActionType<typeof editOrderReset>;

export type EditOrderActions =
  | EditOrderAction
  | EditOrderSuccessAction
  | EditOrderFailureAction
  | EditOrderResetAction;
