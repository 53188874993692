import { Reducer } from 'redux';

import { SetActiveDesignActionTypes, SetActiveDesignActions } from '../actions/SetActiveDesign';
import { IErrorActionData } from '../../../models/Error';

export interface ISetActiveDesignState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialActiveDesignState: ISetActiveDesignState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
};

const SetActiveDesignReducer: Reducer<ISetActiveDesignState, SetActiveDesignActions> = (
  state = initialActiveDesignState,
  action: SetActiveDesignActions,
): ISetActiveDesignState => {
  switch (action.type) {
    case SetActiveDesignActionTypes.SET_ACTIVE_DESIGN:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case SetActiveDesignActionTypes.SET_ACTIVE_DESIGN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case SetActiveDesignActionTypes.SET_ACTIVE_DESIGN_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case SetActiveDesignActionTypes.SET_ACTIVE_DESIGN_RESET:
      return initialActiveDesignState;

    default:
      return state;
  }
};

export default SetActiveDesignReducer;
