export interface IStore {
  name: string;
  storeAliasId: string;
  storeType: string;
  paymentProcessing: number;
  paymentDispatch: number;
}

export class StoreListData {
  storeListData = [] as IStore[];

  constructor(storeListData: IStore[]) {
    this.storeListData = storeListData;
  }
}

export const mergeTwoLists = (
  existingList: Array<IStore>,
  payloadList: Array<IStore>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
