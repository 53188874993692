import { Reducer } from 'redux';
import { defaultPageSize } from '../../../constants/Common';

import { IErrorActionData } from '../../../models/Error';
import { PageInfo } from '../../../models/PageInfo';
import { GetStoreListActions, GetStoreListActionTypes } from '../actions/GetStoreList';
import { mergeTwoLists, StoreListData } from '../models/StoreList';

export interface IGetStoreListState {
  storeListData: StoreListData;
  pageInfo: PageInfo;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialGetStoreListState: IGetStoreListState = {
  storeListData: new StoreListData([]),
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const GetStoreListReducer: Reducer<IGetStoreListState, GetStoreListActions> = (
  state = initialGetStoreListState,
  action: GetStoreListActions,
): IGetStoreListState => {
  switch (action.type) {
    case GetStoreListActionTypes.GET_SORE_LIST_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetStoreListActionTypes.GET_SORE_LIST_ACTION_SUCCESS: {
      const newStoreList = mergeTwoLists(
        state.storeListData.storeListData,
        action.payload.storeListData,
      );

      return {
        ...state,
        storeListData: new StoreListData(newStoreList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
          cursor: action.payload.pageInfo.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetStoreListActionTypes.GET_SORE_LIST_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetStoreListActionTypes.GET_SORE_LIST_ACTION_RESET:
      return initialGetStoreListState;

    default:
      return state;
  }
};

export default GetStoreListReducer;
