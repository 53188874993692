import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_DISPLAY_INVENTORY_LIST = gql`
  query getDisplayInventoryList($path: String!) {
    getDisplayInventoryList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getDisplayInventoryListService = (storeAliasId: string, filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getDisplayInventoryList, filter, undefined, page);
  return client.query({ query: GET_DISPLAY_INVENTORY_LIST, variables: { path: url.replace('{storeAliasId}', storeAliasId) } });
};

export default getDisplayInventoryListService;
