import { createAction, ActionType } from 'typesafe-actions';

import { DownloadReportStates } from '../../../constants/Strings';
import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum GetDownloadCashClosureStatusActionTypes {
  GET_DOWNLOAD_CASH_CLOSURE_STATUS = 'GET_DOWNLOAD_CASH_CLOSURE_STATUS',
  GET_DOWNLOAD_CASH_CLOSURE_STATUS_SUCCESS = 'GET_DOWNLOAD_CASH_CLOSURE_STATUS_SUCCESS',
  GET_DOWNLOAD_CASH_CLOSURE_STATUS_FAILED = 'GET_DOWNLOAD_CASH_CLOSURE_STATUS_FAILED',
  GET_DOWNLOAD_CASH_CLOSURE_STATUS_RESET = 'GET_DOWNLOAD_CASH_CLOSURE_STATUS_RESET',
}

export enum AvailableFilterProperty {
  downloadRequestId = 'requestId',
}

export interface IGetDownloadCashClosureStatusActionData {
  storeAliasId: string;
  filter: IFilter[];
}

export interface IGetDownloadCashClosureStatusSuccessActionData {
  downloadReportStatus: DownloadReportStates;
  downloadReportUrl: string;
}

export const getDownloadCashClosureStatus = createAction(GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS)
<IGetDownloadCashClosureStatusActionData>();
export const getDownloadCashClosureStatusSuccess = createAction(
  GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS_SUCCESS,
)<IGetDownloadCashClosureStatusSuccessActionData>();
export const getDownloadCashClosureStatusFailed = createAction(
  GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS_FAILED,
)<IErrorActionData>();
export const getDownloadCashClosureStatusReset = createAction(
  GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS_RESET,
)();

export type GetDownloadCashClosureStatusAction = ActionType<typeof getDownloadCashClosureStatus>;
type GetDownloadCashClosureStatusSuccessAction = ActionType<typeof getDownloadCashClosureStatusSuccess>;
type GetDownloadCashClosureStatusFailedAction = ActionType<typeof getDownloadCashClosureStatusFailed>;
type GetDownloadCashClosureStatusResetAction = ActionType<typeof getDownloadCashClosureStatusReset>;

export type GetDownloadCashClosureStatusActions =
  | GetDownloadCashClosureStatusAction
  | GetDownloadCashClosureStatusSuccessAction
  | GetDownloadCashClosureStatusFailedAction
  | GetDownloadCashClosureStatusResetAction;
