/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCartStateSuccess,
  getCartStateFailed,
  GetCartStateAction,
  GetCartStateActionTypes,
} from '../actions/GetCartState';
import { IErrorActionData } from '../../../models/Error';
import getCartStateService from '../services/GetCartState';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCartStateEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCartStateActionTypes.GET_CART_STATE)),
  mergeMap((action: GetCartStateAction) => from(
    getCartStateService(action.payload.cartAliasId),
  ).pipe(
    map((response: any) => getCartStateSuccess({ cartState: response.data.getCartState.data.cartState })),
    catchError((error: IErrorActionData) => of(
      getCartStateFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCartStateEpic;
