import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { INewOrderPayload } from '../models/NewOrder';

const CREATE_CART = gql`
  query createOrder($path: String!, $payload: any) {
    createOrder (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const createOrderService = (payload: INewOrderPayload) => client.query({
  query: CREATE_CART,
  variables: {
    path: URL.createOrder,
    payload,
  },
});

export default createOrderService;
