import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_CUSTOMER_CREDIT_BALANCE_DETAILS = gql`
  query getCustomerCreditBalanceDetails($path: String!) {
    getCustomerCreditBalanceDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getCustomerCreditBalanceDetailsService = (customerId: string) => {
  const url = URL.getCustomerCreditBalanceDetails.replace('{customerId}', customerId);
  return client.query({ query: GET_CUSTOMER_CREDIT_BALANCE_DETAILS, variables: { path: url } });
};

export default getCustomerCreditBalanceDetailsService;
