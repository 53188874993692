import { RefundStatus } from '../../../constants/Strings';

export interface IRefundListItem {
  refundAliasId: string;
  refundInitiationDate: number;
  mobileNumber: string;
  customerName: string;
  refundAmount: number;
  refundDate: number;
  updatedAt: number;
  refundStatus: RefundStatus;
}

export class RefundList {
  refundList = [] as Array<IRefundListItem>;
  constructor(refundList: Array<IRefundListItem>) {
    this.refundList = refundList;
  }
}

/**
* Merging Two Lists
* @param existingList - existingList
* @param payloadList - Action Payload List
*/
export const mergeTwoLists = (
  existingList: Array<IRefundListItem>,
  payloadList: Array<IRefundListItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
