export interface ICashDepositMode {
  mode: string;
  roundOffValue: number;
}

export interface IGetOpeningCashBalanceDetails {
  openingBalance: number;
  cashCollected: number;
  cashDepositModes: ICashDepositMode[];
}

export class GetOpeningCashBalanceDetails {
  getOpeningCashBalanceDetails = {} as IGetOpeningCashBalanceDetails;
  constructor(getOpeningCashBalanceDetails: IGetOpeningCashBalanceDetails) {
    this.getOpeningCashBalanceDetails = getOpeningCashBalanceDetails;
  }
}
