import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IShipmentDetails } from '../models/ShipmentDetails';

export enum GetShipmentDetailsActionTypes {
  GET_SHIPMENT_DETAILS = 'GET_SHIPMENT_DETAILS',
  GET_SHIPMENT_DETAILS_SUCCESS = 'GET_SHIPMENT_DETAILS_SUCCESS',
  GET_SHIPMENT_DETAILS_FAILED = 'GET_SHIPMENT_DETAILS_FAILED',
  GET_SHIPMENT_DETAILS_RESET = 'GET_SHIPMENT_DETAILS_RESET',
}

export interface IGetShipmentDetailsActionData {
  supplyOrderAliasId: string;
}

export interface IGetShipmentDetailsSuccessActionData {
  shipmentDetails: Array<IShipmentDetails>;
}

export const getShipmentDetails = createAction(GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS)
<IGetShipmentDetailsActionData>();
export const getShipmentDetailsSuccess = createAction(GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS_SUCCESS)
<IGetShipmentDetailsSuccessActionData>();
export const getShipmentDetailsFailure = createAction(GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS_FAILED)
<IErrorActionData>();
export const getShipmentDetailsReset = createAction(GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS_RESET)();

export type GetShipmentDetailsAction = ActionType<typeof getShipmentDetails>;
type GetShipmentDetailsSuccessAction = ActionType<typeof getShipmentDetailsSuccess>;
type GetShipmentDetailsFailureAction = ActionType<typeof getShipmentDetailsFailure>;
type GetShipmentDetailsResetAction = ActionType<typeof getShipmentDetailsReset>;

export type GetShipmentDetailsActions = GetShipmentDetailsAction
| GetShipmentDetailsSuccessAction
| GetShipmentDetailsFailureAction
| GetShipmentDetailsResetAction;
