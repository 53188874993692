import AddCartItemReducer from './AddCartItem';
import AddCartItemDiscountReducer from './AddCartItemDiscount';
import CancelCartReducer from './CancelCart';
import CreateAddressReducer from './CreateAddress';
import CreateCartReducer from './CreateCart';
import DeleteAddressReducer from './DeleteAddress';
import DeleteCartItemReducer from './DeleteCartItem';
import DeleteCartItemDiscountReducer from './DeleteCartItemDiscount';
import GetAddressListReducer from './GetAddressList';
import GetCartItemListReducer from './GetCartItemList';
import GetCartStateReducer from './GetCartState';
import GetCartSummaryReducer from './GetCartSummary';
import GetSavedCartItemListReducer from './GetSavedCartItemList';
import InitiateCartPaymentReducer from './InitiateCartPayment';
import SaveCartReducer from './SaveCart';
import UpdateAddressReducer from './UpdateAddress';
import UpdateCartReducer from './UpdateCart';
import UpdateCartItemReducer from './UpdateCartItem';

const CartReducers = {
  createCart: CreateCartReducer,
  addCartItem: AddCartItemReducer,
  addCartItemDiscount: AddCartItemDiscountReducer,
  cancelCart: CancelCartReducer,
  createAddress: CreateAddressReducer,
  deleteAddress: DeleteAddressReducer,
  deleteCartItem: DeleteCartItemReducer,
  deleteCartItemDiscount: DeleteCartItemDiscountReducer,
  getAddressList: GetAddressListReducer,
  getCartItemList: GetCartItemListReducer,
  getCartState: GetCartStateReducer,
  getCartSummary: GetCartSummaryReducer,
  getSavedCartItemList: GetSavedCartItemListReducer,
  initiateCartPayment: InitiateCartPaymentReducer,
  saveCart: SaveCartReducer,
  updateAddress: UpdateAddressReducer,
  updateCart: UpdateCartReducer,
  updateCartItem: UpdateCartItemReducer,
};

export default CartReducers;
