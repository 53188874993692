/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  createOrderSuccess,
  createOrderFailure,
  CreateOrderAction,
  CreateOrderActionTypes,
} from '../actions/CreateOrder';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import createOrderService from '../services/CreateOrder';

const CreateOrderEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CreateOrderActionTypes.CREATE_ORDER)),
  mergeMap((action: CreateOrderAction) => from(
    createOrderService(action.payload.createOrderDetails),
  ).pipe(
    map((response) => createOrderSuccess({ orderAliasId: response.data.createOrder.data.orderAliasId })),
    catchError((error: IErrorActionData) => of(
      createOrderFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CreateOrderEpic;
