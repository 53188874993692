import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IDownloadCashTransactionsReportPayload } from '../models/DownloadCashTransactionsReport';

export enum DownloadCashTransactionsReportActionTypes {
  DOWNLOAD_CASH_TRANSACTIONS_REPORT = 'DOWNLOAD_CASH_TRANSACTIONS_REPORT',
  DOWNLOAD_CASH_TRANSACTIONS_REPORT_SUCCESS = 'DOWNLOAD_CASH_TRANSACTIONS_REPORT_SUCCESS',
  DOWNLOAD_CASH_TRANSACTIONS_REPORT_FAILED = 'DOWNLOAD_CASH_TRANSACTIONS_REPORT_FAILED',
  DOWNLOAD_CASH_TRANSACTIONS_REPORT_RESET = 'DOWNLOAD_CASH_TRANSACTIONS_REPORT_RESET',
}

export enum AvailableFilterProperty {
  storeAliasId = 'storeAliasId',
  transactionStartDate = 'transactionStartDate',
  transactionEndDate = 'transactionEndDate',
  depositStartDate = 'depositStartDate',
  depositEndDate = 'depositEndDate',
  closingStartDate = 'closingStartDate',
  closingEndDate = 'closingEndDate',
}

export interface IDownloadCashTransactionsReportActionData {
  storeAliasId: string;
  payloadDetails?: IDownloadCashTransactionsReportPayload;
}
export interface IDownloadCashTransactionsReportSuccessActionData {
  requestId: string;
}

export const downloadCashTransactionsReport = createAction(DownloadCashTransactionsReportActionTypes.DOWNLOAD_CASH_TRANSACTIONS_REPORT)
<IDownloadCashTransactionsReportActionData>();
export const
  downloadCashTransactionsReportSuccess = createAction(DownloadCashTransactionsReportActionTypes.DOWNLOAD_CASH_TRANSACTIONS_REPORT_SUCCESS)
  <IDownloadCashTransactionsReportSuccessActionData>();
export const
  downloadCashTransactionsReportFailure = createAction(DownloadCashTransactionsReportActionTypes.DOWNLOAD_CASH_TRANSACTIONS_REPORT_FAILED)
  <IErrorActionData>();
export const
  downloadCashTransactionsReportReset = createAction(DownloadCashTransactionsReportActionTypes.DOWNLOAD_CASH_TRANSACTIONS_REPORT_RESET)();

export type DownloadCashTransactionsReportAction = ActionType<typeof downloadCashTransactionsReport>;
type DownloadCashTransactionsReportSuccessAction = ActionType<typeof downloadCashTransactionsReportSuccess>;
type DownloadCashTransactionsReportFailedAction = ActionType<typeof downloadCashTransactionsReportFailure>;
type DownloadCashTransactionsReportResetAction = ActionType<typeof downloadCashTransactionsReportReset>;

export type DownloadCashTransactionsReportActions =
  | DownloadCashTransactionsReportAction
  | DownloadCashTransactionsReportSuccessAction
  | DownloadCashTransactionsReportFailedAction
  | DownloadCashTransactionsReportResetAction;
