import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_ORDER_CUSTOMER_DETAILS = gql`
  query getOrderCustomerDetails($path: String!) {
    getOrderCustomerDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getOrderCustomerDetailsService = (orderReferenceId: string) => client.query({
  query: GET_ORDER_CUSTOMER_DETAILS,
  variables: {
    path: URL.getOrderCustomerDetails.replace('{orderReferenceId}', orderReferenceId),
  },
});

export default getOrderCustomerDetailsService;
