/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getSearchCustomerListSuccess,
  getSearchCustomerListFailure,
  GetSearchCustomerListAction,
  GetSearchCustomerListActionTypes,
} from '../actions/GetSearchCustomerList';
import getSearchCustomerListService from '../services/GetSearchCustomerList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetSearchCustomerListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST)),
  mergeMap((action: GetSearchCustomerListAction) => from(
    getSearchCustomerListService(
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getSearchCustomerListSuccess({
      searchCustomerList: response.data.getSearchCustomerList.data || [],
      pageInfo: response.data.getSearchCustomerList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getSearchCustomerListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetSearchCustomerListEpic;
