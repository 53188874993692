import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_REFUND_LIST = gql`
  query getRefundList($path: String!) {
    getRefundList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getRefundListService = (storeAliasId: string, filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getRefundList.replace('{storeAliasId}', storeAliasId), filter, undefined, page);
  return client.query({ query: GET_REFUND_LIST, variables: { path: url } });
};

export default getRefundListService;
