export interface ICmsSlipDetails {
  cmsSlipNumber: string;
  actualCashDeposited: number;
  depositDate: number;
  remarks: string;
  slipAliasId?: string;
  isValid?: boolean;
}

export interface ICashClosureListItem {
  cashCollected: number;
  cashDeposited: number;
  actualCashDeposited: number;
  closureId: string;
  closedTransactions: number;
  closingBalance: number;
  closureAliasId: string;
  cmsSlipNumber: string;
  depositDate: number;
  closingDate: number;
  openingBalance: number;
  remainingCash: number;
  isDummyClosure: boolean;
  cmsSlipDetails: Array<ICmsSlipDetails>;
  closureType: string;
}

export class CashClosureList {
  cashClosureList = [] as Array<ICashClosureListItem>;
  constructor(cashClosureList: Array<ICashClosureListItem>) {
    this.cashClosureList = cashClosureList;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<ICashClosureListItem>,
  payloadList: Array<ICashClosureListItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
