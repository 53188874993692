import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IReturnRequestPayload } from '../models/RequestReturn';

export enum RequestReturnActionTypes {
  REQUEST_RETURN = 'REQUEST_RETURN',
  REQUEST_RETURN_SUCCESS = 'REQUEST_RETURN_SUCCESS',
  REQUEST_RETURN_FAILED = 'REQUEST_RETURN_FAILED',
  REQUEST_RETURN_RESET = 'REQUEST_RETURN_RESET',
}

export interface IRequestReturnActionData {
  shipmentAliasId: string;
  requestReturnDetails: IReturnRequestPayload;
}

export const requestReturn = createAction(RequestReturnActionTypes.REQUEST_RETURN)<IRequestReturnActionData>();
export const requestReturnSuccess = createAction(RequestReturnActionTypes.REQUEST_RETURN_SUCCESS)();
export const requestReturnFailure = createAction(RequestReturnActionTypes.REQUEST_RETURN_FAILED)<IErrorActionData>();
export const requestReturnReset = createAction(RequestReturnActionTypes.REQUEST_RETURN_RESET)();

export type RequestReturnAction = ActionType<typeof requestReturn>;
type RequestReturnSuccessAction = ActionType<typeof requestReturnSuccess>;
type RequestReturnFailureAction = ActionType<typeof requestReturnFailure>;
type RequestReturnResetAction = ActionType<typeof requestReturnReset>;

export type RequestReturnActions = RequestReturnAction
| RequestReturnSuccessAction
| RequestReturnFailureAction
| RequestReturnResetAction;
