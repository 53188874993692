import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IUpdateProjectDetailsPayload } from '../models/UpdateProjectDetails';

export enum UpdateProjectDetailsActionTypes {
  UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS',
  UPDATE_PROJECT_DETAILS_SUCCESS = 'UPDATE_PROJECT_DETAILS_SUCCESS',
  UPDATE_PROJECT_DETAILS_FAILURE = 'UPDATE_PROJECT_DETAILS_FAILURE',
  UPDATE_PROJECT_DETAILS_RESET = 'UPDATE_PROJECT_DETAILS_RESET',
}

interface IUpdateProjectDetails {
  projectId: string;
  data: IUpdateProjectDetailsPayload;
}

export const updateProjectDetails = createAction(UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS)<IUpdateProjectDetails>();
export const updateProjectDetailsSuccess = createAction(UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS_SUCCESS)();
export const updateProjectDetailsFailure = createAction(UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS_FAILURE)<
IErrorActionData
>();
export const updateProjectDetailsReset = createAction(UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS_RESET)();

export type UpdateProjectDetailsAction = ActionType<typeof updateProjectDetails>;
type UpdateProjectDetailsSuccessAction = ActionType<typeof updateProjectDetailsSuccess>;
type UpdateProjectDetailsFailureAction = ActionType<typeof updateProjectDetailsFailure>;
type UpdateProjectDetailsResetAction = ActionType<typeof updateProjectDetailsReset>;

export type UpdateProjectDetailsActions =
  | UpdateProjectDetailsAction
  | UpdateProjectDetailsSuccessAction
  | UpdateProjectDetailsFailureAction
  | UpdateProjectDetailsResetAction;
