import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IUpdateCartPayload } from '../models/UpdateCart';
import URL from '../../../constants/ApiEndPoints';

const UPDATE_CART = gql`
  query updateCart($path: String!, $data: any) {
    updateCart(input: $data, path: $path) 
      @rest(method: "PUT", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const updateCartService = (cartAliasId: string, payload: IUpdateCartPayload) => {
  const url = URL.cartSummary.replace('{cartAliasId}', cartAliasId);
  return client.query({ query: UPDATE_CART, variables: { data: payload, path: url } });
};

export default updateCartService;
