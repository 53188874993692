import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { INewCartItemDetails, NewCartItemDetails } from '../models/NewCartItemDetails';

const ADD_CART_ITEM = gql`
  query addCartItem($path: String!, $payload: any) {
    addCartItem (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const addCartItemService = (payload: INewCartItemDetails, cartAliasId: string) => client.query({
  query: ADD_CART_ITEM,
  variables: {
    path: URL.addCartItem.replace('{cartAliasId}', cartAliasId),
    payload,
  },
});

export default addCartItemService;
