import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    borderRadius: 0,
    minHeight: 67,
  },
  leftIconContainer: {
    minHeight: 24,
    minWidth: 24,
    marginRight: theme.spacing(4),
  },
  icon: {
    height: 24,
    width: 24,
    verticalAlign: 'middle',
    color: theme.palette.grey[600],
  },
  avatar: {
    width: 40,
    height: 40,
  },
  rightIconBtn: {
    padding: 0,
  },
  rightIcon: {
    color: theme.palette.IMPrimary.main,
  },
  clickableMousePointer: {
    cursor: 'pointer',
  },
  buttonTitle: {
    fontWeight: 600,
    marginLeft: theme.spacing(2),
  },
  rightIconLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  titleContainer: {
    flex: 1,
  },
}));

export default useStyles;
