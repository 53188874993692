import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Menu, Fade, Button, Typography, CircularProgress, Box,
} from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import { useTypedSelector } from '../../config/store';
import IMInfoListItem from '../../components/IMInfoListItem';
import { getStoreDetails } from '../../feature/retailStore/actions/GetStoreDetails';
import { getStoreList, getStoreListReset } from '../../feature/retailStore/actions/GetStoreList';
import { IStore } from '../../feature/retailStore/models/StoreList';
import IMFlatList from '../../components/IMFlatList';
import { defaultCursorValue, initialPageSize, defaultPageSize } from '../../constants/Common';
import { AnalyticsPlatform, analytics } from '../../config/analytics';
import analyticsEvents from '../../constants/Analytics';
import useStyles from './styles';

const StoreList: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['auth', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const storeListState = useTypedSelector((state) => state.getStoreList);
  const getStoreDetailsState = useTypedSelector((state) => state.getStoreDetails);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { storeListData } = storeListState.storeListData;

  const fetchMore = (pageSize: number = defaultPageSize, cursor?: number | string) => {
    const newSortFilter = {
      page: {
        pageSize,
        cursor: cursor || storeListState.pageInfo.cursor || defaultCursorValue,
      },
    };
    dispatch(getStoreList(newSortFilter));
  };

  useEffect(() => {
    fetchMore(initialPageSize, defaultCursorValue);
    return () => {
      dispatch(getStoreListReset());
    };
  }, []);

  useEffect(() => {
    if (storeListState.isSuccess) {
      let storeAliasId = localStorage.getItem('storeId');
      if (!storeAliasId) {
        storeAliasId = storeListData[0]?.storeAliasId;
        localStorage.setItem('storeId', storeListData[0]?.storeAliasId);
      }
      dispatch(getStoreDetails({ storeAliasId }));
    } else if (storeListState.error) {
      enqueueSnackbar(t(storeListState.error.errorMessage || 'errorMessage.someThingWentWrong'), { variant: 'error' });
    }
  }, [storeListState]);

  useEffect(() => {
    if (getStoreDetailsState.error) {
      enqueueSnackbar(t(getStoreDetailsState.error.errorMessage || 'errorMessage.someThingWentWrong'), { variant: 'error' });
    }
  }, [getStoreDetailsState]);

  const userBtnClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    analytics.trackEvent({
      type: AnalyticsPlatform.mixpanel,
      eventName: analyticsEvents.user.storeSelectorClick,
    });
    setAnchorEl(event.currentTarget);
  };

  const storeChangehandler = (store: IStore) => {
    dispatch(getStoreDetails({ storeAliasId: store.storeAliasId }));
    localStorage.setItem('storeId', store.storeAliasId);
    navigate('/orders');
    setAnchorEl(null);
  };

  const getLoadingComponent = () => (
    <Box className={classes.progressContainer}>
      <CircularProgress thickness={3} size={20} className={classes.progress} />
    </Box>
  );

  const renderStoreItemList = (storeItem: IStore) => (
    <IMInfoListItem
      id={`storeLists-button-${_.camelCase(storeItem.name)}`}
      key={storeItem.storeAliasId}
      clickable
      title={storeItem.name}
      onClick={() => storeChangehandler(storeItem)}
      hideLeftIcon
      classes={{ container: classes.storeMenuList }}
    />
  );

  return (
    <>
      {storeListData.length > 0 && (
        <Button
          id="header-dropDown-store"
          variant="text"
          disableRipple
          startIcon={<StorefrontIcon />}
          className={classes.storeInfoBtn}
          onClick={userBtnClickHandler}
        >
          {getStoreDetailsState.storeDetails.storeDetails?.name || '-'}
        </Button>
      )}
      {getStoreDetailsState.isSuccess && !storeListData.length && (
        <Typography className={classes.noStoreAssigned}>
          {t('noStoreAssigned')}
        </Typography>
      )}
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <IMFlatList
          id="store-list-storeItems"
          data={storeListData}
          hasMore={storeListState.pageInfo.hasNext}
          renderItem={renderStoreItemList}
          keyExtractor={(item:IStore) => String(item.storeAliasId)}
          loadingComponent={getLoadingComponent()}
          fetchMoreData={fetchMore}
          onClick={() => { }}
          classes={{ container: classes.storeContainer }}
        />
      </Menu>
    </>
  );
};

export default StoreList;
