export const initialPageSize = 20;
export const defaultPageSize = 10;
export const defaultMaxPageSize = 1000;
export const defaultCursorValue = '0';
export const defaultPageNumber = 1;
export const maxPageSize = 1000;
export const defaultQuantity = 1;
export const defaultStoreId = 105;

export const defaultOTPTimeout = 30000;
export const OneMbFileSize = 1024 * 1024;
export const fileUploadInterval = 500;

export const phoneNumberRegex = /^[6-9]\d{9}$/;

export enum LoginMode {
  password = 'password',
  otp = 'otp',
  login = 'login',
}

export enum LoginType {
  phoneno = 'phoneno',
  email = 'email',
}

export enum LocalStorageFilter {
  OrderListFilter = 'orderListFilter',
  SavedCartListFilter = 'savedCartListFilter',
  CashClosureFilter = 'cashClosureFilter',
}

export type CountryCodes = 'IN' | 'US';

export enum CurrencyCodes {
  IN = '\u20B9',
  US = '\u0024',
}

export enum TimeConversion {
  DayInSeconds = 86400,
  ISTOffsetInSeconds = 19800,
}

export enum DrawerMode {
  Add = 'Add',
  Edit = 'Edit',
}

export enum FileType {
  design = 'glb',
}

interface IOrderCancellationSecondaryReason {
  id: string;
  label: string;
  description?: string;
}

interface IOrderCancellationSecondaryReasons {
  [key: string] : IOrderCancellationSecondaryReason[],
}

interface IOrderCancellationReason {
  id: string;
  label: string;
}

export interface IPaymentCardInfo {
  mode: string;
  amount: number;
  availableAmount?: number;
  isValid?: boolean;
  extraInfo?: {
    panNumber?: string; // TODO: Remove panNumber from extra info
    chequeNumber?: string;
    bankName?: string;
    authorisedSignatoryName?: string;
    date?: number;
    uniqueTransactionNumber?: string;
    creditNoteNumber?: string;
  }
}

export const OrderCancellationReasons: IOrderCancellationReason[] = [
  {
    id: 'customerCancellations',
    label: 'Customer cancellations',
  }, {
    id: 'wrongOrderPunching',
    label: 'Wrong order punching',
  }, {
    id: 'stockNotAvailable',
    label: 'Stock not available',
  }, {
    id: 'otherReasons',
    label: 'Other reasons',
  },
];

export const OrderCancellationSecondaryReasons: IOrderCancellationSecondaryReasons = {
  customerCancellations: [
    {
      id: 'pricing',
      label: 'Pricing',
      description: 'Customer found cheaper products elsewhere',
    }, {
      id: 'noMoreNeed',
      label: 'No more need',
      description: "Customer don't need the products anymore",
    }, {
      id: 'delayedDelivery',
      label: 'Delayed delivery',
      description: 'Customer wanted an urgent delivery, but IM is unable to fulfil it at the expected time',
    }, {
      id: 'productExchange',
      label: 'Product exchange',
      description: 'Customer had a change of mind and wants to place a new order with a different product',
    }, {
      id: 'other',
      label: 'Other',
      description: 'Enter remarks',
    },
  ],
  wrongOrderPunching: [
    {
      id: 'wrongSkuCode',
      label: 'Wrong SKU code',
    }, {
      id: 'wrongQty',
      label: 'Wrong quantity',
    }, {
      id: 'wrongPrice',
      label: 'Wrong price',
    }, {
      id: 'wrongAddress',
      label: 'Wrong address',
    }, {
      id: 'wrongCustomerName',
      label: 'Wrong customer name',
    }, {
      id: 'wrongPaymentEntry',
      label: 'Wrong payment entry',
    }, {
      id: 'duplicateOrder',
      label: 'Duplicate order',
    }, {
      id: 'other',
      label: 'Other',
      description: 'Enter remarks',
    },
  ],
  stockNotAvailable: [],
  otherReasons: [],
};
