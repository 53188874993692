/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  saveProgressSuccess,
  saveProgressFailure,
  SaveProgressAction,
  SaveProgressActionTypes,
} from '../actions/SaveProgress';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import DisplayManagementActions from '../actions';
import saveProgressService from '../services/SaveProgress';

const SaveProgressEpic = (action$: ActionsObservable<DisplayManagementActions>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(SaveProgressActionTypes.SAVE_PROGRESS)),
  mergeMap((action: SaveProgressAction) => from(
    saveProgressService(action.payload.taskAliasId, action.payload.payload),
  ).pipe(
    map(() => saveProgressSuccess()),
    catchError((error: IErrorActionData) => of(
      saveProgressFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default SaveProgressEpic;
