import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IMarkWrongTransactionPayload } from '../models/MarkWrongTransaction';

export enum MarkWrongTransactionActionTypes {
  MARK_WRONG_TRANSACTION = 'MARK_WRONG_TRANSACTION',
  MARK_WRONG_TRANSACTION_SUCCESS = 'MARK_WRONG_TRANSACTION_SUCCESS',
  MARK_WRONG_TRANSACTION_FAILURE = 'MARK_WRONG_TRANSACTION_FAILURE',
  MARK_WRONG_TRANSACTION_RESET = 'MARK_WRONG_TRANSACTION_RESET',
}

export interface IMarkWrongTransactionActionData {
  markWrongTransaction: IMarkWrongTransactionPayload;
}

export const markWrongTransaction = createAction(MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION)
<IMarkWrongTransactionActionData>();
export const markWrongTransactionSuccess = createAction(MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION_SUCCESS)();
export const markWrongTransactionFailure = createAction(MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION_FAILURE)
<IErrorActionData>();
export const markWrongTransactionReset = createAction(MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION_RESET)();

export type MarkWrongTransactionAction = ActionType<typeof markWrongTransaction>;
type MarkWrongTransactionSuccessAction = ActionType<typeof markWrongTransactionSuccess>;
type MarkWrongTransactionFailureAction = ActionType<typeof markWrongTransactionFailure>;
type MarkWrongTransactionResetAction = ActionType<typeof markWrongTransactionReset>;

export type MarkWrongTransactionActions =
  | MarkWrongTransactionAction
  | MarkWrongTransactionSuccessAction
  | MarkWrongTransactionFailureAction
  | MarkWrongTransactionResetAction;
