import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IGetProductListPayload, IProduct } from '../models/ProductList';

export enum GetProductListActionTypes {
  GET_PRODUCT_LIST = 'GET_PRODUCT_LIST',
  GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS',
  GET_PRODUCT_LIST_FAILED = 'GET_PRODUCT_LIST_FAILED',
  GET_PRODUCT_LIST_RESET = 'GET_PRODUCT_LIST_RESET',
}

export enum AvailableFilterProperty {
  searchTerm = 'searchTerm',
  storeAliasId = 'storeAliasId',
}

interface IGetProductListActionData {
  payload: IGetProductListPayload;
}

interface IGetProductsSuccessActionData {
  productList: Array<IProduct>;
  pageInfo: IPageInfo;
}

export const getProductList = createAction(GetProductListActionTypes.GET_PRODUCT_LIST)<IGetProductListActionData>();
export const getProductListSuccess = createAction(
  GetProductListActionTypes.GET_PRODUCT_LIST_SUCCESS,
)<IGetProductsSuccessActionData>();
export const getProductListFailure = createAction(
  GetProductListActionTypes.GET_PRODUCT_LIST_FAILED,
)<IErrorActionData>();

export const getProductListReset = createAction(GetProductListActionTypes.GET_PRODUCT_LIST_RESET)();

export type GetProductListAction = ActionType<typeof getProductList>;
type GetProductListSuccessAction = ActionType<typeof getProductListSuccess>;
type GetProductListFailureAction = ActionType<typeof getProductListFailure>;
type GetProductListResetAction = ActionType<typeof getProductListReset>;

export type GetProductListActions = GetProductListAction
| GetProductListSuccessAction
| GetProductListFailureAction
| GetProductListResetAction;
