import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 227,
    flexShrink: 0,
    padding: theme.spacing(1.5),
  },
  offlineDrawer: {
    marginBottom: theme.spacing(5.5),
  },
  listItem: {
    minHeight: 41,
    paddingLeft: theme.spacing(2),
    borderRadius: 4,
  },
  listItemIconArea: {
    minWidth: 29,
  },
  listItemIcon: {
    width: 22,
    height: 22,
  },
  nested: {
    paddingLeft: theme.spacing(10),
    borderRadius: 4,
  },
  activeListItem: {
    backgroundColor: theme.palette.IMPrimary.background,
    color: theme.palette.IMPrimary.main,
  },
  primaryContainer: {
    width: 170,
  },
}));

export default useStyles;
