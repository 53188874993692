import { Reducer } from 'redux';

import { DownloadCashClosureReportActions, DownloadCashClosureReportActionTypes } from '../actions/DownloadCashClosureReport';
import { IErrorActionData } from '../../../models/Error';

export interface IDownloadCashClosureReportState {
  requestId: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDownloadCashClosureReportState = {
  requestId: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDownloadCashClosureReportState;

const DownloadCashClosureReportReducer: Reducer<IDownloadCashClosureReportState, DownloadCashClosureReportActions> = (
  state = initialDownloadCashClosureReportState,
  action: DownloadCashClosureReportActions,
): IDownloadCashClosureReportState => {
  switch (action.type) {
    case DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT_SUCCESS:
      return {
        ...state,
        requestId: action.payload.requestId,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT_RESET:
      return initialDownloadCashClosureReportState;

    default:
      return state;
  }
};

export default DownloadCashClosureReportReducer;
