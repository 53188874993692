import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum AddCmsSlipActionTypes {
  ADD_CMS_SLIP_ACTION = 'ADD_CMS_SLIP_ACTION',
  ADD_CMS_SLIP_ACTION_SUCCESS = 'ADD_CMS_SLIP_ACTION_SUCCESS',
  ADD_CMS_SLIP_ACTION_FAILURE = 'ADD_CMS_SLIP_ACTION_FAILURE',
  ADD_CMS_SLIP_ACTION_RESET = 'ADD_CMS_SLIP_ACTION_RESET',
}

export interface IAddCmsSlipDetails {
  actualCashDeposited: number;
  cmsSlipNumber: string;
  depositDate: number;
  remarks?: string;
}

export interface IAddCmsSlipPayload {
  cmsSlipDetails: Array<IAddCmsSlipDetails>;
  remainingCash: number;
}

export interface IAddCmsSlipActionData {
  closureAliasId: string;
  addCmsSlipDetails: IAddCmsSlipPayload;
}

export const addCmsSlip = createAction(AddCmsSlipActionTypes.ADD_CMS_SLIP_ACTION)
<IAddCmsSlipActionData>();
export const addCmsSlipSuccess = createAction(AddCmsSlipActionTypes.ADD_CMS_SLIP_ACTION_SUCCESS)();
export const addCmsSlipFailure = createAction(AddCmsSlipActionTypes.ADD_CMS_SLIP_ACTION_FAILURE)
<IErrorActionData>();
export const addCmsSlipReset = createAction(AddCmsSlipActionTypes.ADD_CMS_SLIP_ACTION_RESET)();

export type AddCmsSlipAction = ActionType<typeof addCmsSlip>;
type AddCmsSlipSuccessAction = ActionType<typeof addCmsSlipSuccess>;
type AddCmsSlipFailureAction = ActionType<typeof addCmsSlipFailure>;
type AddCmsSlipResetAction = ActionType<typeof addCmsSlipReset>;

export type AddCmsSlipActions =
  | AddCmsSlipAction
  | AddCmsSlipSuccessAction
  | AddCmsSlipFailureAction
  | AddCmsSlipResetAction;
