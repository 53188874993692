import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  deleteAddressSuccess,
  deleteAddressFailure,
  DeleteAddressAction,
  DeleteAddressActionTypes,
} from '../actions/DeleteAddress';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import deleteAddressService from '../services/DeleteAddress';

const DeleteAddressEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(DeleteAddressActionTypes.DELETE_ADDRESS)),
  mergeMap((action: DeleteAddressAction) => from(
    deleteAddressService(
      action.payload.customerId,
      action.payload.addressId,
    ),
  ).pipe(
    map((response) => deleteAddressSuccess()),
    catchError((error: IErrorActionData) => of(
      deleteAddressFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default DeleteAddressEpic;
