import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter, IPage } from '../../../utils/sortFilterFramework';

const GET_SAVED_CART_ITEM_LIST = gql`
  query getSavedCartItemList($path: String!) {
    getSavedCartItemList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getSavedCartItemListService = (filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getSavedCartItemList, filter, undefined, page);
  return client.query({ query: GET_SAVED_CART_ITEM_LIST, variables: { path: url } });
};

export default getSavedCartItemListService;
