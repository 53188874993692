import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IInitiateCartPaymentActionData, IInitiateCartPaymentSuccessActionData } from '../models/InitiateCartPayment';

export enum IInitiateCartPaymentActionTypes {
  INITIATE_CART_PAYMENT = 'INITIATE_CART_PAYMENT',
  INITIATE_CART_PAYMENT_SUCCESS = 'INITIATE_CART_PAYMENT_SUCCESS',
  INITIATE_CART_PAYMENT_FAILED = 'INITIATE_CART_PAYMENT_FAILED',
  INITIATE_CART_PAYMENT_RESET = 'INITIATE_CART_PAYMENT_RESET',
}

export const initiateCartPayment = createAction(IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT)<IInitiateCartPaymentActionData>();
export const initiateCartPaymentSuccess = createAction(
  IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT_SUCCESS,
)<IInitiateCartPaymentSuccessActionData>();
export const initiateCartPaymentFailure = createAction(
  IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT_FAILED,
)<IErrorActionData>();
export const initiateCartPaymentReset = createAction(IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT_RESET)();

export type InitiateCartPaymentAction = ActionType<typeof initiateCartPayment>;
type InitiateCartPaymentSuccessAction = ActionType<typeof initiateCartPaymentSuccess>;
type InitiateCartPaymentFailureAction = ActionType<typeof initiateCartPaymentFailure>;
type InitiateCartPaymentResetAction = ActionType<typeof initiateCartPaymentReset>;

export type InitiateCartPaymentActions = InitiateCartPaymentAction
| InitiateCartPaymentSuccessAction
| InitiateCartPaymentFailureAction
| InitiateCartPaymentResetAction;
