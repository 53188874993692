/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  addCmsSlipSuccess,
  addCmsSlipFailure,
  AddCmsSlipAction,
  AddCmsSlipActionTypes,
} from '../actions/AddCmsSlip';
import addCmsSlipService from '../services/AddCmsSlip';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const AddCmsSlipEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(AddCmsSlipActionTypes.ADD_CMS_SLIP_ACTION)),
  mergeMap((action: AddCmsSlipAction) => from(
    addCmsSlipService(action.payload.closureAliasId, action.payload.addCmsSlipDetails),
  ).pipe(
    map((response: any) => (addCmsSlipSuccess())),
    catchError((error: any) => of(
      addCmsSlipFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default AddCmsSlipEpic;
