import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_CUSTOMER_DETAILS_LIST = gql`
  query getCustomerDetailsList($path: String!) {
    getCustomerDetailsList @rest(method: "GET", path: $path, endpoint: "photon") {
        data
      }
  }
`;

const getCustomerDetailsListService = (filter?: IFilter[], customerId?: string) => {
  let url = constructUrlWithParams(URL.getCustomerDetailsList, filter, undefined, undefined);
  if (customerId && customerId !== '') url = url.replace('{customerId}', customerId);
  else url = url.replace('{customerId}/', '');
  return client.query({ query: GET_CUSTOMER_DETAILS_LIST, variables: { path: url } });
};

export default getCustomerDetailsListService;
