import { Reducer } from 'redux';

import { ProductList, mergeTwoLists } from '../models/ProductList';
import { GetProductListActionTypes, GetProductListActions } from '../actions/GetProductList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IProductListState {
  productList: ProductList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProductListState = {
  productList: new ProductList([]),
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: 0, hasNext: true }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IProductListState;

const GetProductListReducer: Reducer<IProductListState, GetProductListActions> = (
  state = initialProductListState,
  action: GetProductListActions,
): IProductListState => {
  switch (action.type) {
    case GetProductListActionTypes.GET_PRODUCT_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetProductListActionTypes.GET_PRODUCT_LIST_SUCCESS: {
      const newProductList = mergeTwoLists(
        state.productList.productList,
        action.payload.productList,
      );
      return {
        ...state,
        productList: new ProductList(newProductList),
        pageInfo: new PageInfo({
          cursor: action.payload.pageInfo.cursor,
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetProductListActionTypes.GET_PRODUCT_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetProductListActionTypes.GET_PRODUCT_LIST_RESET:
      return initialProductListState;

    default:
      return state;
  }
};

export default GetProductListReducer;
