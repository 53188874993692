import { ActionType, createAction } from 'typesafe-actions';

import IConfigData from '../models/remoteConfig';
import { IErrorActionData } from '../../../models/Error';

export enum FetchRemoteConfigActionTypes {
  GET_REMOTE_CONFIG = 'GET_REMOTE_CONFIG',
  GET_REMOTE_CONFIG_SUCCESS = 'GET_REMOTE_CONFIG_SUCCESS',
  GET_REMOTE_CONFIG_FAILURE = 'GET_REMOTE_CONFIG_FAILURE ',
  GET_REMOTE_CONFIG_RESET = 'GET_REMOTE_CONFIG_RESET',
}

export const fetchRemoteConfig = createAction(FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG)();
export const fetchRemoteConfigSuccess = createAction(FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG_SUCCESS)<IConfigData>();
export const fetchRemoteConfigFailure = createAction(FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG_FAILURE)<IErrorActionData>();
export const fetchRemoteConfigReset = createAction(FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG_RESET)();

export type FetchRemoteConfig = ActionType<typeof fetchRemoteConfig>;
type FetchRemoteConfigSuccessAction = ActionType<typeof fetchRemoteConfigSuccess>;
type FetchRemoteConfigFailureAction = ActionType<typeof fetchRemoteConfigFailure>;
type FetchRemoteConfigResetAction = ActionType<typeof fetchRemoteConfigReset>;

export type FetchRemoteConfigActions =
  | FetchRemoteConfig
  | FetchRemoteConfigSuccessAction
  | FetchRemoteConfigFailureAction
  | FetchRemoteConfigResetAction;
