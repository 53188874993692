import { Reducer } from 'redux';

import { GetCashClosureDetailsActions, GetCashClosureDetailsActionTypes } from '../actions/GetCashClosureDetails';
import { IErrorActionData } from '../../../models/Error';
import { IGetCashClosureDetails, GetCashClosureDetails } from '../models/GetCashClosureDetails';

export interface IGetCashClosureDetailsState {
  getCashClosureDetails: GetCashClosureDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetCashClosureDetailsState = {
  getCashClosureDetails: new GetCashClosureDetails({} as IGetCashClosureDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetCashClosureDetailsState;

const GetCashClosureDetailsReducer: Reducer<IGetCashClosureDetailsState, GetCashClosureDetailsActions> = (
  state = initialGetCashClosureDetailsState,
  action: GetCashClosureDetailsActions,
): IGetCashClosureDetailsState => {
  switch (action.type) {
    case GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS_SUCCESS:
      return {
        ...state,
        getCashClosureDetails: new GetCashClosureDetails(action.payload.getCashClosureDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS_RESET:
      return initialGetCashClosureDetailsState;

    default:
      return state;
  }
};

export default GetCashClosureDetailsReducer;
