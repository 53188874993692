import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_ORDER_LIST = gql`
  query getOrderList($path: String!) {
    getOrderList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getOrderListService = (filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getOrderList, filter, undefined, page);
  return client.query({ query: GET_ORDER_LIST, variables: { path: url } });
};

export default getOrderListService;
