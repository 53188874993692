import { gql } from '@apollo/client';
import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IFilter, constructUrlWithParams } from '../../../utils/sortFilterFramework';

const GET_DEAL_DESIGN_LIST = gql`
  query getDealDesignList($path: String!) {
    dealDesignList @rest(method: "GET", path: $path, endpoint: "photon") {
      models
    }
  }
`;

const getDealDesignListService = (filter: IFilter[]) => {
  const url = constructUrlWithParams(URL.getDealDesignList, filter, undefined, undefined);
  return client.query({ query: GET_DEAL_DESIGN_LIST, variables: { path: url } });
};

export default getDealDesignListService;
