/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  editCmsSlipSuccess,
  editCmsSlipFailure,
  EditCmsSlipAction,
  EditCmsSlipActionTypes,
} from '../actions/EditCmsSlip';
import editCmsSlipService from '../services/EditCmsSlip';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const EditCmsSlipEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION)),
  mergeMap((action: EditCmsSlipAction) => from(
    editCmsSlipService(action.payload.closureAliasId, action.payload.editCmsSlipDetails),
  ).pipe(
    map((response: any) => (editCmsSlipSuccess())),
    catchError((error: any) => of(
      editCmsSlipFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default EditCmsSlipEpic;
