import { Reducer } from 'redux';

import { mergeTwoOptionLists, StoreEmployeeList } from '../models/StoreEmployee';
import { GetStoreEmployeeListActions, GetStoreEmployeeListActionTypes } from '../actions/GetStoreEmployeeList';
import { IErrorActionData } from '../../../models/Error';
import { PageInfo } from '../../../models/PageInfo';
import { defaultPageSize } from '../../../constants/Common';
import { IApiAutoCompleteOptionItem } from '../../../models/Common';

export interface IGetStoreEmployeeListState {
  storeEmployeeList: StoreEmployeeList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialStoreEmployeeState = {
  storeEmployeeList: new StoreEmployeeList([] as IApiAutoCompleteOptionItem[]),
  pageInfo: new PageInfo({ pageSize: defaultPageSize, pageNumber: 0, hasNext: false }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetStoreEmployeeListState;

const GetStoreEmployeeListReducer: Reducer<IGetStoreEmployeeListState, GetStoreEmployeeListActions> = (
  state = initialStoreEmployeeState,
  action: GetStoreEmployeeListActions,
): IGetStoreEmployeeListState => {
  switch (action.type) {
    case GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST_SUCCESS: {
      const newStoreEmployeeList = mergeTwoOptionLists(
        state.storeEmployeeList.storeEmployeeList,
        action.payload.storeEmployeeList,
      );
      return {
        ...state,
        storeEmployeeList: new StoreEmployeeList(newStoreEmployeeList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }
    case GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST_RESET:
      return initialStoreEmployeeState;

    default:
      return state;
  }
};

export default GetStoreEmployeeListReducer;
