import { Dict, OverridedMixpanel } from 'mixpanel-browser';
import mixpanelLayer from './mixpanel';

export enum AnalyticsPlatform {
  mixpanel = 'mixpanel',
}

interface UserAttributeInterface {
  type: AnalyticsPlatform;
  distinctKey: string;
  userName: string;
  userPhone: string;
}

interface TrackEventInterface {
  type: AnalyticsPlatform;
  eventName: string;
  eventProperties?: Dict;
}

class Analytics {
  setUser = (props: UserAttributeInterface) => {
    switch (props.type) {
      case AnalyticsPlatform.mixpanel:
        mixpanelLayer.setUser(props.distinctKey, props.userName, props.userPhone);
        break;
      default:
        console.warn('please provide the analytics vendor type');
    }
  };

  trackEvent = (props: TrackEventInterface) => {
    switch (props.type) {
      case AnalyticsPlatform.mixpanel:
        mixpanelLayer.eventTrack(props.eventName, props?.eventProperties);
        break;
      default:
        console.warn('please provide the analytics vendor type');
    }
  };
}

export const analytics = new Analytics();
