/* eslint-disable import/prefer-default-export */
import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENV === 'PROD' ? 'FOFO-PROD' : process.env.REACT_APP_ENV,
  // FOFO-PROD is used in sentry production to identify FOFO logs
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ),
  })],
  tracesSampler: () => {
    if (window.location.hostname === 'localhost') {
      return 0;
    }
    return process.env.REACT_APP_ENV === 'PROD' ? 0.3 : 0.1;
  },
  beforeSend(event: any) {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return event;
  },
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
