import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { ISupplyOrderList } from '../models/SupplyOrderList';

export enum GetSupplyOrderListActionTypes {
  GET_SUPPLY_ORDER_LIST = 'GET_SUPPLY_ORDER_LIST',
  GET_SUPPLY_ORDER_LIST_SUCCESS = 'GET_SUPPLY_ORDER_LIST_SUCCESS',
  GET_SUPPLY_ORDER_LIST_FAILED = 'GET_SUPPLY_ORDER_LIST_FAILED',
  GET_SUPPLY_ORDER_LIST_RESET = 'GET_SUPPLY_ORDER_LIST_RESET',
}

export const AvailableFilterProperty = {
  orderRefId: 'orderRefId',
};

export interface IGetSupplyOrderListActionData {
  filter: IFilter[]
}
export interface IGetSupplyOrderListSuccessActionData {
  supplyOrderList: ISupplyOrderList;
}

export const getSupplyOrderList = createAction(GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST)<IGetSupplyOrderListActionData>();
export const getSupplyOrderListSuccess = createAction(GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST_SUCCESS)
<IGetSupplyOrderListSuccessActionData>();
export const getSupplyOrderListFailure = createAction(GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST_FAILED)
<IErrorActionData>();
export const getSupplyOrderListReset = createAction(GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST_RESET)();

export type GetSupplyOrderListAction = ActionType<typeof getSupplyOrderList>;
type GetSupplyOrderListSuccessAction = ActionType<typeof getSupplyOrderListSuccess>;
type GetSupplyOrderListFailedAction = ActionType<typeof getSupplyOrderListFailure>;
type GetSupplyOrderListResetAction = ActionType<typeof getSupplyOrderListReset>;

export type GetSupplyOrderListActions =
  | GetSupplyOrderListAction
  | GetSupplyOrderListSuccessAction
  | GetSupplyOrderListFailedAction
  | GetSupplyOrderListResetAction;
