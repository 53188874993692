import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IAdditionalCashListItem } from '../models/AdditionalCashList';

export enum GetAdditionalCashListActionTypes {
  GET_ADDITIONAL_CASH_LIST = 'GET_ADDITIONAL_CASH_LIST',
  GET_ADDITIONAL_CASH_LIST_SUCCESS = 'GET_ADDITIONAL_CASH_LIST_SUCCESS',
  GET_ADDITIONAL_CASH_LIST_FAILED = 'GET_ADDITIONAL_CASH_LIST_FAILED',
  GET_ADDITIONAL_CASH_LIST_RESET = 'GET_ADDITIONAL_CASH_LIST_RESET',
}

export interface IGetAdditionalCashListActionData {
  storeAliasId: string;
}

export interface IGetAdditionalCashListSuccessActionData {
  additionalCashList: Array<IAdditionalCashListItem>;
}

export const getAdditionalCashList = createAction(GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST)
<IGetAdditionalCashListActionData>();
export const getAdditionalCashListSuccess = createAction(GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST_SUCCESS)
<IGetAdditionalCashListSuccessActionData>();
export const getAdditionalCashListFailure = createAction(GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST_FAILED)
<IErrorActionData>();
export const getAdditionalCashListReset = createAction(GetAdditionalCashListActionTypes.GET_ADDITIONAL_CASH_LIST_RESET)();

export type GetAdditionalCashListAction = ActionType<typeof getAdditionalCashList>;
type GetAdditionalCashListSuccessAction = ActionType<typeof getAdditionalCashListSuccess>;
type GetAdditionalCashListFailureAction = ActionType<typeof getAdditionalCashListFailure>;
type GetAdditionalCashListResetAction = ActionType<typeof getAdditionalCashListReset>;

export type GetAdditionalCashListActions = GetAdditionalCashListAction
| GetAdditionalCashListSuccessAction
| GetAdditionalCashListFailureAction
| GetAdditionalCashListResetAction;
