import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IUpdateAddressPayload } from '../models/UpdateAddress';

export enum UpdateAddressActionTypes {
  UPDATE_ADDRESS_ACTION = 'UPDATE_ADDRESS_ACTION',
  UPDATE_ADDRESS_ACTION_SUCCESS = 'UPDATE_ADDRESS_ACTION_SUCCESS',
  UPDATE_ADDRESS_ACTION_FAILURE = 'UPDATE_ADDRESS_ACTION_FAILURE',
  UPDATE_ADDRESS_ACTION_RESET = 'UPDATE_ADDRESS_ACTION_RESET',
}

export interface IUpdateAddressActionData {
  customerId: string;
  addressId: string;
  address: IUpdateAddressPayload;
}

export const updateAddress = createAction(UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION)<IUpdateAddressActionData>();
export const updateAddressSuccess = createAction(UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION_SUCCESS)();
export const updateAddressFailure = createAction(UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION_FAILURE)<IErrorActionData>();
export const updateAddressReset = createAction(UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION_RESET)();

export type UpdateAddressAction = ActionType<typeof updateAddress>;
type UpdateAddressSuccessAction = ActionType<typeof updateAddressSuccess>;
type UpdateAddressFailureAction = ActionType<typeof updateAddressFailure>;
type UpdateAddressResetAction = ActionType<typeof updateAddressReset>;

export type UpdateAddressActions =
  | UpdateAddressAction
  | UpdateAddressSuccessAction
  | UpdateAddressFailureAction
  | UpdateAddressResetAction;
