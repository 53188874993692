import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';

const GET_ACCOUNT_STATEMENT = gql`
  query getAccountStatement($path: String!) {
    getAccountStatement @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getAccountStatementService = (customerAliasId: string, filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getAccountStatement.replace('{customerAliasId}', customerAliasId), filter, undefined, page);
  return client.query({ query: GET_ACCOUNT_STATEMENT, variables: { path: url } });
};

export default getAccountStatementService;
