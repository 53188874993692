import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IAddCartItemDiscountPayload } from '../models/AddCartItemDiscount';

const ADD_CART_ITEM_DISCOUNT = gql`
  query addCartItemDiscount($path: String!, $payload: any) {
    addCartItemDiscount (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const addCartItemDiscountService = (cartAliasId: string, cartItemAliasId: string, payload: IAddCartItemDiscountPayload) => client.query({
  query: ADD_CART_ITEM_DISCOUNT,
  variables: {
    path: URL.addCartItemDiscount.replace('{cartAliasId}', cartAliasId).replace('{cartItemAliasId}', cartItemAliasId),
    payload,
  },
});

export default addCartItemDiscountService;
