import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum StartProcessingOrderActionTypes {
  START_PROCESSING_ORDER = 'START_PROCESSING_ORDER',
  START_PROCESSING_ORDER_SUCCESS = 'START_PROCESSING_ORDER_SUCCESS',
  START_PROCESSING_ORDER_FAILED = 'START_PROCESSING_ORDER_FAILED',
  START_PROCESSING_ORDER_RESET = 'START_PROCESSING_ORDER_RESET',
}

export interface IStartProcessingOrderActionData {
  orderReferenceId: string;
}

export const startProcessingOrder = createAction(StartProcessingOrderActionTypes.START_PROCESSING_ORDER)<IStartProcessingOrderActionData>();
export const startProcessingOrderSuccess = createAction(StartProcessingOrderActionTypes.START_PROCESSING_ORDER_SUCCESS)();
export const startProcessingOrderFailure = createAction(StartProcessingOrderActionTypes.START_PROCESSING_ORDER_FAILED)<IErrorActionData>();
export const startProcessingOrderReset = createAction(StartProcessingOrderActionTypes.START_PROCESSING_ORDER_RESET)();

export type StartProcessingOrderAction = ActionType<typeof startProcessingOrder>;
type StartProcessingOrderSuccessAction = ActionType<typeof startProcessingOrderSuccess>;
type StartProcessingOrderFailureAction = ActionType<typeof startProcessingOrderFailure>;
type StartProcessingOrderResetAction = ActionType<typeof startProcessingOrderReset>;

export type StartProcessingOrderActions = StartProcessingOrderAction
| StartProcessingOrderSuccessAction
| StartProcessingOrderFailureAction
| StartProcessingOrderResetAction;
