import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { ICustomerAddress } from '../models/CustomerAddress';

export enum GetCustomerAddressActionTypes {
  GET_CUSTOMER_ADDRESS = 'GET_CUSTOMER_ADDRESS',
  GET_CUSTOMER_ADDRESS_SUCCESS = 'GET_CUSTOMER_ADDRESS_SUCCESS',
  GET_CUSTOMER_ADDRESS_FAILED = 'GET_CUSTOMER_ADDRESS_FAILED',
  GET_CUSTOMER_ADDRESS_RESET = 'GET_CUSTOMER_ADDRESS_RESET',
}

interface IGetCustomerAddressActionData {
  customerId: string;
  addressAliasId: string;
}
interface IGetCustomerAddressSuccessActionData {
  data: ICustomerAddress;
  addressAliasId: string;
}

interface IGetCustomerAddressErrorActionData extends IErrorActionData {
  addressAliasId: string;
}

export const getCustomerAddress = createAction(GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS)<IGetCustomerAddressActionData>();
export const getCustomerAddressSuccess = createAction(GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS_SUCCESS)
<IGetCustomerAddressSuccessActionData>();
export const getCustomerAddressFailed = createAction(GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS_FAILED)
<IGetCustomerAddressErrorActionData>();
export const getCustomerAddressReset = createAction(GetCustomerAddressActionTypes.GET_CUSTOMER_ADDRESS_RESET)();

export type GetCustomerAddressAction = ActionType<typeof getCustomerAddress>;
type GetCustomerAddressSuccessAction = ActionType<typeof getCustomerAddressSuccess>;
type GetCustomerAddressFailedAction = ActionType<typeof getCustomerAddressFailed>;
type GetCustomerAddressResetAction = ActionType<typeof getCustomerAddressReset>;

export type GetCustomerAddressActions =
  | GetCustomerAddressAction
  | GetCustomerAddressSuccessAction
  | GetCustomerAddressFailedAction
  | GetCustomerAddressResetAction;
