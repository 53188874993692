export interface ICashTransactionListItem {
  cashDeposited: number;
  closingDate: number;
  closureId: string;
  customerName: string;
  depositDate: number;
  cashTransAliasId: string;
  mobileNumber: string;
  orderReferenceId: string;
  transactionDate: number;
  transactionState: string;
}

export class CashTransactionList {
  cashTransactions = [] as Array<ICashTransactionListItem>;
  constructor(cashTransactions: Array<ICashTransactionListItem>) {
    this.cashTransactions = cashTransactions;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<ICashTransactionListItem>,
  payloadList: Array<ICashTransactionListItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
