import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IRecommendationTaskDetails } from '../models/GetRecommendationTaskSummary';

export enum GetRecommendationTaskSummaryActionTypes {
  GET_RECOMMENDATION_TASK_SUMMARY = 'GET_RECOMMENDATION_TASK_SUMMARY',
  GET_RECOMMENDATION_TASK_SUMMARY_SUCCESS = 'GET_RECOMMENDATION_TASK_SUMMARY_SUCCESS',
  GET_RECOMMENDATION_TASK_SUMMARY_FAILED = 'GET_RECOMMENDATION_TASK_SUMMARY_FAILED',
  GET_RECOMMENDATION_TASK_SUMMARY_RESET = 'GET_RECOMMENDATION_TASK_SUMMARY_RESET',
}

export interface IGetRecommendationTaskSummaryActionData {
  processAliasId: string;
}

export interface IGetRecommendationTaskSummarySuccessActionData {
  data: IRecommendationTaskDetails;
}

export const getRecommendationTaskSummary = createAction(
  GetRecommendationTaskSummaryActionTypes.GET_RECOMMENDATION_TASK_SUMMARY,
)<IGetRecommendationTaskSummaryActionData>();
export const getRecommendationTaskSummarySuccess = createAction(
  GetRecommendationTaskSummaryActionTypes.GET_RECOMMENDATION_TASK_SUMMARY_SUCCESS,
)<IGetRecommendationTaskSummarySuccessActionData>();
export const getRecommendationTaskSummaryFailure = createAction(
  GetRecommendationTaskSummaryActionTypes.GET_RECOMMENDATION_TASK_SUMMARY_FAILED,
)<IErrorActionData>();
export const getRecommendationTaskSummaryReset = createAction(
  GetRecommendationTaskSummaryActionTypes.GET_RECOMMENDATION_TASK_SUMMARY_RESET,
)();

export type GetRecommendationTaskSummaryAction = ActionType<typeof getRecommendationTaskSummary>;
type GetRecommendationTaskSummarySuccessAction = ActionType<typeof getRecommendationTaskSummarySuccess>;
type GetRecommendationTaskSummaryFailureAction = ActionType<typeof getRecommendationTaskSummaryFailure>;
type GetRecommendationTaskSummaryResetAction = ActionType<typeof getRecommendationTaskSummaryReset>;

export type GetRecommendationTaskSummaryActions = GetRecommendationTaskSummaryAction
| GetRecommendationTaskSummarySuccessAction
| GetRecommendationTaskSummaryFailureAction
| GetRecommendationTaskSummaryResetAction;
