import { Reducer } from 'redux';

import { GetProjectListActions, GetProjectListActionTypes } from '../actions/GetProjectList';
import { ProjectList, mergeTwoLists } from '../models/GetProjectList';
import { defaultPageSize, defaultCursorValue } from '../../../constants/Common';
import { IErrorActionData } from '../../../models/Error';
import { PageInfo } from '../../../models/PageInfo';

export interface IGetProjectListState {
  projectList: ProjectList;
  pageInfo: PageInfo;
  isLoading: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProjectListState: IGetProjectListState = {
  projectList: new ProjectList([]),
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: defaultCursorValue, hasNext: false }),
  isLoading: false,
  isSuccess: false,
};

const GetProjectListReducer: Reducer<IGetProjectListState, GetProjectListActions> = (
  state = initialProjectListState,
  action: GetProjectListActions,
): IGetProjectListState => {
  switch (action.type) {
    case GetProjectListActionTypes.GET_PROJECT_LIST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    case GetProjectListActionTypes.GET_PROJECT_LIST_SUCCESS: {
      const projectLists = mergeTwoLists(
        state.projectList.projectLists,
        action.payload.data,
        action.payload.pageInfo?.cursor,
      );
      return {
        ...state,
        projectList: new ProjectList(projectLists),
        pageInfo: new PageInfo(action.payload.pageInfo),
        isLoading: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetProjectListActionTypes.GET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetProjectListActionTypes.GET_PROJECT_LIST_RESET:
      return initialProjectListState;

    default:
      return state;
  }
};

export default GetProjectListReducer;
