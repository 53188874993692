import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IRecordRefundPayload } from '../models/RecordRefund';

const RECORD_REFUND = gql`
  query recordRefund($path: String!, $payload: any) {
    recordRefund (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const recordRefundService = (payload: IRecordRefundPayload) => client.query({
  query: RECORD_REFUND,
  variables: {
    path: URL.recordRefund,
    payload,
  },
});

export default recordRefundService;
