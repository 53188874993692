import { Reducer } from 'redux';

import { CashClosureList, mergeTwoLists } from '../models/CashClosureList';
import { GetCashClosureListActionTypes, GetCashClosureListActions } from '../actions/GetCashClosureList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface ICashClosureListState {
  cashClosureList: CashClosureList;
  isOpenClosureAvailable: boolean;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCashClosureListState = {
  cashClosureList: new CashClosureList([]),
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isOpenClosureAvailable: false,
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICashClosureListState;

const GetCashClosureListReducer: Reducer<ICashClosureListState, GetCashClosureListActions> = (
  state = initialCashClosureListState,
  action: GetCashClosureListActions,
): ICashClosureListState => {
  switch (action.type) {
    case GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST_SUCCESS: {
      const newCashClosureList = mergeTwoLists(
        state.cashClosureList.cashClosureList,
        action.payload.cashClosureList,
      );
      return {
        ...state,
        cashClosureList: new CashClosureList(newCashClosureList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo?.pageSize,
          hasNext: action.payload.pageInfo?.hasNext,
          pageNumber: action.payload.pageInfo?.pageNumber,
          totalPages: action.payload.pageInfo?.totalPages,
          cursor: action.payload.pageInfo?.cursor,
        }),
        isOpenClosureAvailable: action.payload.isOpenClosureAvailable,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST_RESET:
      return initialCashClosureListState;

    default:
      return state;
  }
};

export default GetCashClosureListReducer;
