import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { SkuListInfo } from '../models/GetDisplayInventoryList';

export enum GetDisplayInventoryListActionTypes {
  GET_DISPLAY_INVENTORY_LIST = 'GET_DISPLAY_INVENTORY_LIST',
  GET_DISPLAY_INVENTORY_LIST_SUCCESS = 'GET_DISPLAY_INVENTORY_LIST_SUCCESS',
  GET_DISPLAY_INVENTORY_LIST_FAILED = 'GET_DISPLAY_INVENTORY_LIST_FAILED',
  GET_DISPLAY_INVENTORY_LIST_RESET = 'GET_DISPLAY_INVENTORY_LIST_RESET',
}

export enum AvailableFilterProperty {
  finish = 'finish',
  displayStartDate = 'displayStartDate',
  displayEndDate = 'displayEndDate',
  displayAgeingFrom = 'displayAgeingFrom',
  displayAgeingTo = 'displayAgeingTo',
  orderBy = 'orderBy',
  leafNodeCategoryId = 'leafNodeCategoryId',
  sku = 'sku',
}

export interface IGetDisplayInventoryListActionData {
  storeAliasId: string;
  filter?: IFilter[];
  page?: IPage;
}
export interface IGetDisplayInventoryListSuccessActionData {
  skuList: Array<SkuListInfo>;
  pageInfo: IPageInfo;
}

export const getDisplayInventoryList = createAction(GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST)
<IGetDisplayInventoryListActionData>();
export const getDisplayInventoryListSuccess = createAction(GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST_SUCCESS)
<IGetDisplayInventoryListSuccessActionData>();
export const getDisplayInventoryListFailure = createAction(GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST_FAILED)
<IErrorActionData>();
export const getDisplayInventoryListReset = createAction(GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST_RESET)();

export type GetDisplayInventoryListAction = ActionType<typeof getDisplayInventoryList>;
type GetDisplayInventoryListSuccessAction = ActionType<typeof getDisplayInventoryListSuccess>;
type GetDisplayInventoryListFailedAction = ActionType<typeof getDisplayInventoryListFailure>;
type GetDisplayInventoryListResetAction = ActionType<typeof getDisplayInventoryListReset>;

export type GetDisplayInventoryListActions =
  | GetDisplayInventoryListAction
  | GetDisplayInventoryListSuccessAction
  | GetDisplayInventoryListFailedAction
  | GetDisplayInventoryListResetAction;
