import { Reducer } from 'redux';

import { CreateProjectActions, CreateProjectActionTypes } from '../actions/CreateProject';
import { IErrorActionData } from '../../../models/Error';
import { IProjectDetails } from '../models/CreateProject';

export interface ICreateAddressState {
  projectDetails: IProjectDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCreateAddressState = {
  projectDetails: {} as IProjectDetails,
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICreateAddressState;

const CreateProjectReducer: Reducer<ICreateAddressState, CreateProjectActions> = (
  state = initialCreateAddressState,
  action: CreateProjectActions,
): ICreateAddressState => {
  switch (action.type) {
    case CreateProjectActionTypes.CREATE_PROJECT:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CreateProjectActionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectDetails: action.payload.projectDetails,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CreateProjectActionTypes.CREATE_PROJECT_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CreateProjectActionTypes.CREATE_PROJECT_RESET:
      return initialCreateAddressState;

    default:
      return state;
  }
};

export default CreateProjectReducer;
