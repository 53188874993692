export interface IOpenCashTransactionItem {
  cashTransAliasId: string;
  amount: number;
  orderReferenceId: string;
  customerName: string;
  customerMobileNumber: string;
  transactionDate: number;
}

export class OpenCashTransactions {
  openCashTransactions = [] as Array<IOpenCashTransactionItem>;
  constructor(openCashTransactions: Array<IOpenCashTransactionItem>) {
    this.openCashTransactions = openCashTransactions;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<IOpenCashTransactionItem>,
  payloadList: Array<IOpenCashTransactionItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
