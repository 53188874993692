import { Reducer } from 'redux';

import {
  GetDownloadLedgerStatementStatusActions,
  GetDownloadLedgerStatementStatusActionTypes,
} from '../actions/GetDownloadLedgerStatementStatus';
import { IErrorActionData } from '../../../models/Error';

export interface IGetDownloadLedgerStatementStatusState {
  message: string,
  url: string,
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetDownloadLedgerStatementStatusState = {
  message: '',
  url: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetDownloadLedgerStatementStatusState;

const GetDownloadLedgerStatementStatusReducer: Reducer<IGetDownloadLedgerStatementStatusState, GetDownloadLedgerStatementStatusActions> = (
  state = initialGetDownloadLedgerStatementStatusState,
  action: GetDownloadLedgerStatementStatusActions,
): IGetDownloadLedgerStatementStatusState => {
  switch (action.type) {
    case GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        url: action.payload.url,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS_RESET:
      return initialGetDownloadLedgerStatementStatusState;

    default:
      return state;
  }
};

export default GetDownloadLedgerStatementStatusReducer;
