import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import {
  DownloadLedgerAccountStatementActions,
  DownloadLedgerAccountStatementActionTypes,
}
  from '../actions/DownloadLedgerAccountStatement';

export interface IDownloadLedgerAccountStatementState {
  requestId: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDownloadLedgerAccountStatementState = {
  requestId: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDownloadLedgerAccountStatementState;

const DownloadLedgerAccountStatementReducer: Reducer<IDownloadLedgerAccountStatementState, DownloadLedgerAccountStatementActions> = (
  state = initialDownloadLedgerAccountStatementState,
  action: DownloadLedgerAccountStatementActions,
) : IDownloadLedgerAccountStatementState => {
  switch (action.type) {
    case DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_SUCCESS:
      return {
        ...state,
        requestId: action.payload.requestId,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_RESET:
      return initialDownloadLedgerAccountStatementState;

    default:
      return state;
  }
};

export default DownloadLedgerAccountStatementReducer;
