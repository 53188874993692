import { IErrorActionData } from '../../../models/Error';

export interface IProformaInvoiceUrlData {
  proformaInvoiceUrl?: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class ProformaInvoiceUrlData {
  proformaInvoiceUrlData: Record<string, IProformaInvoiceUrlData> = {};
  constructor(proformaInvoiceUrlData: Record<string, IProformaInvoiceUrlData>) {
    this.proformaInvoiceUrlData = proformaInvoiceUrlData;
  }
}

export const addPresignedUrlToExisting = (existingPresignedURL: Record<string, IProformaInvoiceUrlData>,
  invoiceId: string, proformaInvoiceUrlData: IProformaInvoiceUrlData) => (
  { ...existingPresignedURL, [invoiceId]: proformaInvoiceUrlData }
);
