/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  createProjectSuccess,
  createProjectFailed,
  CreateProjectAction,
  CreateProjectActionTypes,
} from '../actions/CreateProject';
import { IErrorActionData } from '../../../models/Error';
import createProjectService from '../services/CreateProject';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CreateProjectEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CreateProjectActionTypes.CREATE_PROJECT)),
  mergeMap((action: CreateProjectAction) => from(
    createProjectService(action.payload),
  ).pipe(
    map((response) => createProjectSuccess({
      projectDetails: response.data.createProject.data,
    })),
    catchError((error: IErrorActionData) => of(
      createProjectFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CreateProjectEpic;
