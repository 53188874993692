import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IMarkWrongTransactionPayload } from '../models/MarkWrongTransaction';

const MARK_WRONG_TRANSACTION = gql`
  query markWrongTransaction($path: String!, $payload: any) {
    markWrongTransaction (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const markWrongTransactionService = (payload: IMarkWrongTransactionPayload) => client.query({
  query: MARK_WRONG_TRANSACTION,
  variables: {
    path: URL.markWrongTransaction,
    payload,
  },
});

export default markWrongTransactionService;
