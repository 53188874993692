import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { ICartSummary } from '../models/CartSummary';

export enum GetCartSummaryActionTypes {
  GET_CART_SUMMARY = 'GET_CART_SUMMARY',
  GET_CART_SUMMARY_SUCCESS = 'GET_CART_SUMMARY_SUCCESS',
  GET_CART_SUMMARY_FAILED = 'GET_CART_SUMMARY_FAILED',
  GET_CART_SUMMARY_RESET = 'GET_CART_SUMMARY_RESET',
}

export enum AvailableFilterProperty {
  resume = 'resume',
  priceListId = 'priceListId',
}

export interface IGetCartSummaryActionData {
  cartAliasId: string;
  filter?: IFilter[];
}

export interface IGetCartSummarySuccessActionData {
  cartSummary: ICartSummary;
}

export const getCartSummary = createAction(GetCartSummaryActionTypes.GET_CART_SUMMARY)
<IGetCartSummaryActionData>();
export const getCartSummarySuccess = createAction(GetCartSummaryActionTypes.GET_CART_SUMMARY_SUCCESS)
<IGetCartSummarySuccessActionData>();
export const getCartSummaryFailed = createAction(GetCartSummaryActionTypes.GET_CART_SUMMARY_FAILED)
<IErrorActionData>();
export const getCartSummaryReset = createAction(GetCartSummaryActionTypes.GET_CART_SUMMARY_RESET)();

export type GetCartSummaryAction = ActionType<typeof getCartSummary>;
type GetCartSummarySuccessAction = ActionType<typeof getCartSummarySuccess>;
type GetCartSummaryFailedAction = ActionType<typeof getCartSummaryFailed>;
type GetCartSummaryResetAction = ActionType<typeof getCartSummaryReset>;

export type GetCartSummaryActions =
  | GetCartSummaryAction
  | GetCartSummarySuccessAction
  | GetCartSummaryFailedAction
  | GetCartSummaryResetAction;
