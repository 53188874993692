import { IErrorActionData } from '../../../models/Error';

export interface IUpdatePaymentStateDetails {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class UpdatePayment {
  updatePayment: Record<string, IUpdatePaymentStateDetails>;
  constructor(updatePaymentMetaDetails: Record<string, IUpdatePaymentStateDetails>) {
    this.updatePayment = updatePaymentMetaDetails;
  }
}

export const updatePaymentToExisting = (existingPaymentStatus: Record<string, IUpdatePaymentStateDetails>,
  paymentAliasId: string, updatePayment: IUpdatePaymentStateDetails) => (
  { ...existingPaymentStatus, [paymentAliasId]: updatePayment }
);
