import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { DealListItem } from '../models/GetDealList';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IPageInfo } from '../../../models/PageInfo';

export enum GetDealListActionTypes {
  GET_DEAL_LIST = 'GET_DEAL_LIST',
  GET_DEAL_LIST_SUCCESS = 'GET_DEAL_LIST_SUCCESS',
  GET_DEAL_LIST_FAILURE = 'GET_DEAL_LIST_FAILURE',
  GET_DEAL_LIST_RESET = 'GET_DEAL_LIST_RESET',
}

interface IGetDealListActionData {
  filterValues: IFilter[];
  page?: IPage;
}

export enum AvailableFilterProperty {
  addressId = 'addressId',
  state = 'State',
  assignedTo = 'assignedTo',
  CreatedAtEpochGe = 'createdAtEpochGe',
  CreatedAtEpochLe = 'createdAtEpochLe',
  FollowUpDateEpochGe = 'followUpDateEpochGe',
  FollowUpDateEpochLe = 'followUpDateEpochLe',
}

interface IGetDealListSuccessActionData {
  data: Array<DealListItem>;
  pageInfo?: IPageInfo;
}

export const getDealList = createAction(GetDealListActionTypes.GET_DEAL_LIST)<IGetDealListActionData>();
export const getDealListSuccess = createAction(GetDealListActionTypes.GET_DEAL_LIST_SUCCESS)<IGetDealListSuccessActionData>();
export const getDealListFailure = createAction(GetDealListActionTypes.GET_DEAL_LIST_FAILURE)<IErrorActionData>();
export const getDealListReset = createAction(GetDealListActionTypes.GET_DEAL_LIST_RESET)();

export type GetDealListAction = ActionType<typeof getDealList>;
type GetDealListSuccessAction = ActionType<typeof getDealListSuccess>;
type GetDealListFailureAction = ActionType<typeof getDealListFailure>;
type GetDealListResetAction = ActionType<typeof getDealListReset>;

export type GetDealListActions =
  | GetDealListAction
  | GetDealListSuccessAction
  | GetDealListFailureAction
  | GetDealListResetAction;
