import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  updateInfluencerSuccess,
  updateInfluencerFailure,
  UpdateInfluencerAction,
  UpdateInfluencerActionTypes,
} from '../actions/UpdateInfluencer';
import updateInfluencerService from '../services/UpdateInfluencer';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const UpdateInfluencerEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(UpdateInfluencerActionTypes.UPDATE_INFLUENCER)),
  mergeMap((action: UpdateInfluencerAction) => from(
    updateInfluencerService(action.payload.influencerAliasId, action.payload.data),
  ).pipe(
    map(() => updateInfluencerSuccess()),
    catchError((error: IErrorActionData) => of(
      updateInfluencerFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default UpdateInfluencerEpic;
