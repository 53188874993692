import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_DISPLAY_INVENTORY_LIST_URL = gql`
  query getDisplayInventoryListUrl($path: String!) {
    getDisplayInventoryListUrl @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getDisplayInventoryListUrlService = (storeAliasId: string, filter?: IFilter[]) => {
  let url = URL.getDisplayInventoryListUrl.replace('{storeAliasId}', storeAliasId);
  url = constructUrlWithParams(url, filter, undefined, undefined);
  return client.query({ query: GET_DISPLAY_INVENTORY_LIST_URL, variables: { path: url } });
};

export default getDisplayInventoryListUrlService;
