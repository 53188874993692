import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IDeleteDesignPayload } from '../models/DesignInfo';

const DELETE_KITCHEN = gql`
  query deleteDesign($path: String!, $payload: any) {
    deleteDesign (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
      }
  }
`;

const deleteDesignService = (payload: IDeleteDesignPayload) => client.query({
  query: DELETE_KITCHEN,
  variables: {
    path: URL.deleteDesign,
    payload,
  },
});

export default deleteDesignService;
