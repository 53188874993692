/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import {
  GetRecommendationTaskSummaryAction,
  GetRecommendationTaskSummaryActionTypes,
  getRecommendationTaskSummaryFailure,
  getRecommendationTaskSummarySuccess,
} from '../actions/GetRecommendationTaskSummary';
import getRecommendationTaskSummaryService from '../services/GetRecommendationTaskSummary';

const GetRecommendationTaskSummaryEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetRecommendationTaskSummaryActionTypes.GET_RECOMMENDATION_TASK_SUMMARY)),
  mergeMap((action: GetRecommendationTaskSummaryAction) => from(
    getRecommendationTaskSummaryService(
      action.payload.processAliasId,
    ),
  ).pipe(
    map((response) => getRecommendationTaskSummarySuccess({ data: response.data.getRecommendationTaskSummary.data || {} })),
    catchError((error: IErrorActionData) => of(
      getRecommendationTaskSummaryFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetRecommendationTaskSummaryEpic;
