/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getEDCMachineListSuccess,
  getEDCMachineListFailure,
  GetEDCMachineListAction,
  GetEDCMachineListActionTypes,
} from '../actions/GetEDCMachineList';
import getEDCMachineListService from '../services/GetEDCMachineList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetEDCMachineListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST)),
  mergeMap((action: GetEDCMachineListAction) => from(
    getEDCMachineListService(action.payload.filter),
  ).pipe(
    map((response) => getEDCMachineListSuccess({
      data: response.data.getEDCMachineList.data || {},
    })),
    catchError((error: IErrorActionData) => of(
      getEDCMachineListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetEDCMachineListEpic;
