import { IOrderPaymentInfo } from '../../payment/models/OrderPaymentDetails';

interface ICustomerPendingAmountInfo {
  totalPendingAmount: number;
  refundPendingAmount: number;
  paymentPendingAmount: number;
  paymentPending: IOrderPaymentInfo[];
}

export interface ICreditNote {
  creditNoteId: string;
  amount: number;
  creditNoteDate: number;
}

export interface IWalletDetails {
  walletAliasId: string;
  amount: number;
}

export interface ICustomerUnusedCreditInfo {
  totalUnusedAmount: number;
  creditNoteTotalAmount: number;
  creditNotes: ICreditNote[];
  walletDetails: IWalletDetails;
}

export interface ICustomerLedgerDetails {
  customerAliasId: string;
  outstandingReceivables: number;
  pendingAmount: ICustomerPendingAmountInfo;
  unusedCredit: ICustomerUnusedCreditInfo;
}

export class CustomerLedgerDetails {
  customerLedgerDetails = {} as ICustomerLedgerDetails;
  constructor(customerLedgerDetails: ICustomerLedgerDetails) {
    this.customerLedgerDetails = customerLedgerDetails;
  }
}
