/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  verifyOtpSuccess,
  verifyOtpFailure,
  VerifyOtpAction,
  VerifyOtpActionTypes,
} from '../actions/VerifyOTP';
import { getUserProfileSuccess } from '../actions/GetUserProfile';
import verifyOtpService from '../services/VerifyOTP';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const VerifyOtpEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(VerifyOtpActionTypes.VERIFY_OTP)),
  mergeMap((action: VerifyOtpAction) => from(
    verifyOtpService(action.payload),
  ).pipe(
    mergeMap((response: any) => of(
      verifyOtpSuccess(),
      getUserProfileSuccess({
        authorisationToken: response.data.verifyOtp.token || '',
        userData: response.data.verifyOtp.data,
        permissions: response.data.verifyOtp.data.userPermissionAndEntityPayload?.permissions || [],
      }),
    )),
    catchError((error: any) => of(
      verifyOtpFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default VerifyOtpEpic;
