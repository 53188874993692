/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getShipmentSkuListSuccess,
  getShipmentSkuListFailure,
  GetShipmentSkuListAction,
  GetShipmentSkuListActionTypes,
} from '../actions/GetShipmentSkuList';
import getShipmentSkuListService from '../services/GetShipmentSkuList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetShipmentSkuListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST)),
  mergeMap((action: GetShipmentSkuListAction) => from(
    getShipmentSkuListService(
      action.payload.shipmentAliasId,
    ),
  ).pipe(
    map((response: any) => getShipmentSkuListSuccess({
      skuListDetails: response.data.getShipmentSkuList.data ?? {},
    })),
    catchError((error: IErrorActionData) => of(
      getShipmentSkuListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetShipmentSkuListEpic;
