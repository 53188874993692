import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { ReturnListItem } from '../models/ReturnList';

export enum GetReturnListActionTypes {
  GET_RETURN_LIST = 'GET_RETURN_LIST',
  GET_RETURN_LIST_SUCCESS = 'GET_RETURN_LIST_SUCCESS',
  GET_RETURN_LIST_FAILED = 'GET_RETURN_LIST_FAILED',
  GET_RETURN_LIST_RESET = 'GET_RETURN_LIST_RESET',
}

export enum AvailableFilterProperty {
  customerId = 'customerId',
  returnAliasId = 'returnAliasId',
  returnInitiatedStartDate = 'returnInitiatedStartDate',
  returnInitiatedEndDate = 'returnInitiatedEndDate',
  returnedStartDate = 'returnedStartDate',
  returnedEndDate = 'returnedEndDate',
  returnStatus = 'returnStatus',
  storeAliasId = 'storeAliasId',
}

export interface IGetReturnListActionData {
  filter: IFilter[];
  page: IPage;
}

export interface IGetReturnListSuccessActionData {
  returnList: Array<ReturnListItem>;
  pageInfo: IPageInfo;
}

export const getReturnList = createAction(GetReturnListActionTypes.GET_RETURN_LIST)
<IGetReturnListActionData>();
export const getReturnListSuccess = createAction(GetReturnListActionTypes.GET_RETURN_LIST_SUCCESS)
<IGetReturnListSuccessActionData>();
export const getReturnListFailure = createAction(GetReturnListActionTypes.GET_RETURN_LIST_FAILED)
<IErrorActionData>();
export const getReturnListReset = createAction(GetReturnListActionTypes.GET_RETURN_LIST_RESET)();

export type GetReturnListAction = ActionType<typeof getReturnList>;
type GetReturnListSuccessAction = ActionType<typeof getReturnListSuccess>;
type GetReturnListFailureAction = ActionType<typeof getReturnListFailure>;
type GetReturnListResetAction = ActionType<typeof getReturnListReset>;

export type GetReturnListActions = GetReturnListAction
| GetReturnListSuccessAction
| GetReturnListFailureAction
| GetReturnListResetAction;
