import { Reducer } from 'redux';

import { GetDownloadCashClosureStatusActions, GetDownloadCashClosureStatusActionTypes } from '../actions/GetDownloadCashClosureStatus';
import { IErrorActionData } from '../../../models/Error';

export interface IGetDownloadCashClosureStatusState {
  downloadReportStatus: string;
  downloadReportUrl: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetDownloadCashClosureStatusState = {
  downloadReportStatus: '',
  downloadReportUrl: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetDownloadCashClosureStatusState;

const GetDownloadCashClosureStatusReducer: Reducer<IGetDownloadCashClosureStatusState, GetDownloadCashClosureStatusActions> = (
  state = initialGetDownloadCashClosureStatusState,
  action: GetDownloadCashClosureStatusActions,
): IGetDownloadCashClosureStatusState => {
  switch (action.type) {
    case GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS_SUCCESS:
      return {
        ...state,
        downloadReportStatus: action.payload.downloadReportStatus,
        downloadReportUrl: action.payload.downloadReportUrl,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS_RESET:
      return initialGetDownloadCashClosureStatusState;

    default:
      return state;
  }
};

export default GetDownloadCashClosureStatusReducer;
