import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { INewCartItem } from '../models/NewCartItem';

export enum GetCartItemListActionTypes {
  GET_CART_ITEM_LIST = 'GET_CART_ITEM_LIST',
  GET_CART_ITEM_LIST_SUCCESS = 'GET_CART_ITEM_LIST_SUCCESS',
  GET_CART_ITEM_LIST_FAILED = 'GET_CART_ITEM_LIST_FAILED',
  GET_CART_ITEM_LIST_RESET = 'GET_CART_ITEM_LIST_RESET',
}

interface IGetCartItemListActionData {
  cartAliasId: string;
}

interface IGetCartItemSuccessActionData {
  cartItemList: Array<INewCartItem>;
}

export const getCartItemList = createAction(GetCartItemListActionTypes.GET_CART_ITEM_LIST)<IGetCartItemListActionData>();
export const getCartItemListSuccess = createAction(
  GetCartItemListActionTypes.GET_CART_ITEM_LIST_SUCCESS,
)<IGetCartItemSuccessActionData>();
export const getCartItemListFailure = createAction(
  GetCartItemListActionTypes.GET_CART_ITEM_LIST_FAILED,
)<IErrorActionData>();

export const getCartItemListReset = createAction(GetCartItemListActionTypes.GET_CART_ITEM_LIST_RESET)();

export type GetCartItemListAction = ActionType<typeof getCartItemList>;
type GetCartItemListSuccessAction = ActionType<typeof getCartItemListSuccess>;
type GetCartItemListFailureAction = ActionType<typeof getCartItemListFailure>;
type GetCartItemListResetAction = ActionType<typeof getCartItemListReset>;

export type GetCartItemListActions = GetCartItemListAction
| GetCartItemListSuccessAction
| GetCartItemListFailureAction
| GetCartItemListResetAction;
