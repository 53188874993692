/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCustomerSavedCartListCountSuccess,
  getCustomerSavedCartListCountFailed,
  GetCustomerSavedCartListCountAction,
  GetCustomerSavedCartListCountActionTypes,
} from '../actions/GetCustomerSavedCartListCount';
import { IErrorActionData } from '../../../models/Error';
import getCustomerSavedCartListCountService from '../services/GetCustomerSavedCartListCount';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCustomerSavedCartListCountEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT)),
  mergeMap((action: GetCustomerSavedCartListCountAction) => from(
    getCustomerSavedCartListCountService(action.payload.filter),
  ).pipe(
    map((response: any) => getCustomerSavedCartListCountSuccess({
      count: response.data.getCustomerSavedCartListCount.data?.cartList?.length || 0,
    })),
    catchError((error: IErrorActionData) => of(
      getCustomerSavedCartListCountFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCustomerSavedCartListCountEpic;
