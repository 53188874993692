import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const CREATE_CUSTOMER = gql`
  query createCustomer($path: String!, $payload: any) {
    createCustomer (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "photon") {
        data
      }
  }
`;

const createCustomerService = (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  loginId: number,
  gst?: string,
  companyName?: string,
) => client.query({
  query: CREATE_CUSTOMER,
  variables: {
    path: URL.createCustomer,
    payload: {
      createdBy: loginId,
      firstName,
      lastName,
      phoneNumber,
      gst,
      companyName,
    },
  },
});

export default createCustomerService;
