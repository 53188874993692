import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ICancelSKUActionData, ICancelSKUPayload } from '../actions/CancelSKU';

const CANCEL_SKU = gql`
  query cancelSKU($path: String!, $data: any) {
    cancelSKU(input: $data, path: $path) 
      @rest(method: "POST", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const cancelSKUService = (supplyOrderAliasId: string, payload: ICancelSKUPayload) => {
  const url = URL.cancelSKU.replace('{supplyOrderAliasId}', supplyOrderAliasId);
  return client.query({ query: CANCEL_SKU, variables: { path: url, data: payload } });
};

export default cancelSKUService;
