import React, { FunctionComponent } from 'react';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';

import useStyles from './styles';

type ClassType = {
  container?: string;
  image?: string;
  title?: string;
  subTitle?: string;
  primaryBtn?: string;
  secondaryBtn?: string;
};

export interface IIMResponse {
  id: string;
  image: React.ElementType;
  title: string;
  subTitle: string;
  hidePrimaryBtn?: boolean;
  disablePrimaryBtn?: boolean;
  primaryBtnTitle?: string;
  primaryBtnAction?: () => void;
  hideSecondaryBtn?: boolean;
  disableSecondaryBtn?: boolean;
  secondaryBtnTitle?: string;
  secondaryBtnAction?: () => void;
  classes?: ClassType;
}

const IMResponse: FunctionComponent<IIMResponse> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.classes?.container)}>
      <props.image id={`${props.id}-info-image`} className={clsx(classes.image, props.classes?.image)} />
      <Typography id={`${props.id}-info-title`} className={clsx(classes.title, props.classes?.title)}>
        {props.title}
      </Typography>
      <Typography
        id={`${props.id}-info-subTitle`}
        className={clsx(classes.subTitle, props.classes?.subTitle)}
      >
        {props.subTitle}
      </Typography>
      <Box className={classes.btnContainer}>
        { !props.hideSecondaryBtn && (
          <Button
            id={`${props.id}-button-${_.camelCase(props.secondaryBtnTitle)}`}
            disabled={props.disableSecondaryBtn}
            onClick={props.secondaryBtnAction}
            className={clsx(classes.secondaryBtn, props.classes?.secondaryBtn)}
          >
            {props.secondaryBtnTitle}
          </Button>
        )}
        { !props.hidePrimaryBtn && (
          <Button
            id={`${props.id}-button-${_.camelCase(props.primaryBtnTitle)}`}
            disableFocusRipple
            disabled={props.disablePrimaryBtn}
            onClick={props.primaryBtnAction}
            className={clsx(classes.primaryBtn, props.classes?.primaryBtn)}
          >
            {props.primaryBtnTitle}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default IMResponse;
