/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  markWrongTransactionSuccess,
  markWrongTransactionFailure,
  MarkWrongTransactionAction,
  MarkWrongTransactionActionTypes,
} from '../actions/MarkWrongTransaction';
import markWrongTransactionService from '../services/MarkWrongTransaction';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const MarkWrongTransactionEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(MarkWrongTransactionActionTypes.MARK_WRONG_TRANSACTION)),
  mergeMap((action: MarkWrongTransactionAction) => from(
    markWrongTransactionService(action.payload.markWrongTransaction),
  ).pipe(
    map((response: any) => (markWrongTransactionSuccess())),
    catchError((error: any) => of(
      markWrongTransactionFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default MarkWrongTransactionEpic;
