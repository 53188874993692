import CreateCustomerEpic from './CreateCustomer';
import CreateProjectEpic from './CreateProject';
import GetCustomerAddressEpic from './GetCustomerAddress';
import GetCustomerDetailsEpic from './GetCustomerDetails';
import GetCustomerDetailsListEpic from './GetCustomerDetailsList';
import GetCustomerSavedCartListCountEpic from './GetCustomerSavedCartListCount';
import GetProjectListEpic from './GetProjectList';
import GetSearchCustomerListEpic from './GetSearchCustomerList';
import UpdateCustomerDetailsEpic from './UpdateCustomerDetails';
import UpdateProjectDetailsEpic from './UpdateProjectDetails';

const CustomerEpics = [
  CreateCustomerEpic,
  CreateProjectEpic,
  GetCustomerAddressEpic,
  GetCustomerDetailsEpic,
  GetCustomerDetailsListEpic,
  GetCustomerSavedCartListCountEpic,
  GetSearchCustomerListEpic,
  UpdateCustomerDetailsEpic,
  UpdateProjectDetailsEpic,
  GetProjectListEpic,
];

export default CustomerEpics;
