import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_SHIPMENT_LIST = gql`
  query getShipmentList($path: String!) {
    getShipmentList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getShipmentListService = (supplyOrderAliasId: string) => client.query({
  query: GET_SHIPMENT_LIST,
  variables: {
    path: URL.getShipmentList.replace('{supplyOrderAliasId}', supplyOrderAliasId),
  },
});

export default getShipmentListService;
