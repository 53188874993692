/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCashClosureDetailsSuccess,
  getCashClosureDetailsFailed,
  GetCashClosureDetailsAction,
  GetCashClosureDetailsActionTypes,
} from '../actions/GetCashClosureDetails';
import getCashClosureDetailsService from '../services/GetCashClosureDetails';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCashClosureDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCashClosureDetailsActionTypes.GET_CASH_CLOSURE_DETAILS)),
  mergeMap((action: GetCashClosureDetailsAction) => from(
    getCashClosureDetailsService(action.payload.closureAliasId),
  ).pipe(
    map((response: any) => getCashClosureDetailsSuccess({
      getCashClosureDetails: response.data.getCashClosureDetails.data || {},
    })),
    catchError((error: any) => of(
      getCashClosureDetailsFailed({
        errorCode: error.errorCode || error.networkError?.statusCode || 500,
        errorMessage: error.errorMessage || error?.networkError?.result?.error_message as string,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCashClosureDetailsEpic;
