import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { ISaveProgressPayload } from '../models/SaveProgress';

export enum SaveProgressActionTypes {
  SAVE_PROGRESS = 'SAVE_PROGRESS',
  SAVE_PROGRESS_SUCCESS = 'SAVE_PROGRESS_SUCCESS',
  SAVE_PROGRESS_FAILED = 'SAVE_PROGRESS_FAILED',
  SAVE_PROGRESS_RESET = 'SAVE_PROGRESS_RESET',
}

export interface ISaveProgressActionData {
  taskAliasId: string;
  payload: ISaveProgressPayload;
}

export const saveProgress = createAction(SaveProgressActionTypes.SAVE_PROGRESS)<ISaveProgressActionData>();
export const saveProgressSuccess = createAction(SaveProgressActionTypes.SAVE_PROGRESS_SUCCESS)();
export const saveProgressFailure = createAction(SaveProgressActionTypes.SAVE_PROGRESS_FAILED)<IErrorActionData>();
export const saveProgressReset = createAction(SaveProgressActionTypes.SAVE_PROGRESS_RESET)();

export type SaveProgressAction = ActionType<typeof saveProgress>;
type SaveProgressSuccessAction = ActionType<typeof saveProgressSuccess>;
type SaveProgressFailureAction = ActionType<typeof saveProgressFailure>;
type SaveProgressResetAction = ActionType<typeof saveProgressReset>;

export type SaveProgressActions = SaveProgressAction
| SaveProgressSuccessAction
| SaveProgressFailureAction
| SaveProgressResetAction;
