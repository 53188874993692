import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 360,
    minHeight: 440,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: theme.palette.IMPrimary.contrastText,
    padding: theme.spacing(17, 18),
  },
  image: {
    width: 200,
    height: 200,
  },
  title: {
    fontSize: 20,
    lineHeight: 1.3,
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(4),
    wordBreak: 'break-word',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: 12,
    lineHeight: 1.4,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    wordBreak: 'break-word',
    textAlign: 'center',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  primaryBtn: {
    height: 28,
    borderRadius: 4,
    borderWidth: 1,
    padding: theme.spacing(1, 2.5),
    margin: theme.spacing(0, 1),
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.IMPrimary.contrastText,
    backgroundColor: theme.palette.IMPrimary.main,
    '&:hover': {
      backgroundColor: theme.palette.IMPrimary.main,
    },
  },
  secondaryBtn: {
    height: 28,
    borderRadius: 4,
    borderWidth: 1,
    padding: theme.spacing(1, 2.5),
    margin: theme.spacing(0, 1),
    borderStyle: 'solid',
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.IMPrimary.main,
    borderColor: theme.palette.IMPrimary.main,
  },
}));

export default useStyles;
