import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { LoginMode, LoginType } from '../../../constants/Common';

export enum ResendOtpActionTypes {
  RESEND_OTP = 'RESEND_OTP',
  RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE',
  RESEND_OTP_RESET = 'RESEND_OTP_RESET',
}

export interface IResendOtpActionData {
  loginID: string;
  mode: LoginMode;
  loginType: LoginType;
}

export const resendOtp = createAction(ResendOtpActionTypes.RESEND_OTP)<IResendOtpActionData>();
export const resendOtpSuccess = createAction(ResendOtpActionTypes.RESEND_OTP_SUCCESS)();
export const resendOtpFailure = createAction(ResendOtpActionTypes.RESEND_OTP_FAILURE)<IErrorActionData>();
export const resendOtpReset = createAction(ResendOtpActionTypes.RESEND_OTP_RESET)();

export type ResendOtpAction = ActionType<typeof resendOtp>;
type ResendOtpSuccessAction = ActionType<typeof resendOtpSuccess>;
type ResendOtpFailureAction = ActionType<typeof resendOtpFailure>;
type ResendOtpResetAction = ActionType<typeof resendOtpReset>;

export type ResendOtpActions =
  | ResendOtpAction
  | ResendOtpSuccessAction
  | ResendOtpFailureAction
  | ResendOtpResetAction;
