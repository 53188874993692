import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { ICustomerAddressList } from '../../../models/AddressList';

export enum GetAddressListActionTypes {
  GET_ADDRESS_LIST = 'GET_ADDRESS_LIST',
  GET_ADDRESS_LIST_SUCCESS = 'GET_ADDRESS_LIST_SUCCESS',
  GET_ADDRESS_LIST_FAILED = 'GET_ADDRESS_LIST_FAILED',
  GET_ADDRESS_LIST_RESET = 'GET_ADDRESS_LIST_RESET',
}

export interface IGetAddressListActionData {
  customerId: string;
}
export interface IGetAddressListSuccessActionData {
  data: Array<ICustomerAddressList>;
}

export const getAddressList = createAction(GetAddressListActionTypes.GET_ADDRESS_LIST)<IGetAddressListActionData>();
export const getAddressListSuccess = createAction(GetAddressListActionTypes.GET_ADDRESS_LIST_SUCCESS)
<IGetAddressListSuccessActionData>();
export const getAddressListFailed = createAction(GetAddressListActionTypes.GET_ADDRESS_LIST_FAILED)
<IErrorActionData>();
export const getAddressListReset = createAction(GetAddressListActionTypes.GET_ADDRESS_LIST_RESET)();

export type GetAddressListAction = ActionType<typeof getAddressList>;
type GetAddressListSuccessAction = ActionType<typeof getAddressListSuccess>;
type GetAddressListFailedAction = ActionType<typeof getAddressListFailed>;
type GetAddressListResetAction = ActionType<typeof getAddressListReset>;

export type GetAddressListActions =
  | GetAddressListAction
  | GetAddressListSuccessAction
  | GetAddressListFailedAction
  | GetAddressListResetAction;
