/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getOrderListSuccess,
  getOrderListFailure,
  GetOrderListAction,
  GetOrderListActionTypes,
} from '../actions/GetOrderList';
import getOrderListService from '../services/GetOrderList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetOrderListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetOrderListActionTypes.GET_ORDER_LIST)),
  mergeMap((action: GetOrderListAction) => from(
    getOrderListService(
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getOrderListSuccess({
      orderList: response.data.getOrderList.data.orderList || [],
      pageInfo: response.data.getOrderList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getOrderListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetOrderListEpic;
