import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';

export enum UploadDesignActionTypes {
  UPLOAD_DESIGN = 'UPLOAD_DESIGN',
  UPLOAD_DESIGN_SUCCESS = 'UPLOAD_DESIGN_SUCCESS',
  UPLOAD_DESIGN_FAILURE = 'UPLOAD_DESIGN_FAILURE',
  UPLOAD_DESIGN_RESET = 'UPLOAD_DESIGN_RESET',
}

interface IUploadKitchenActionData {
  fileData: FormData;
  onSuccess?: () => void;
}

export const uploadDesign = createAction(UploadDesignActionTypes.UPLOAD_DESIGN)<IUploadKitchenActionData>();
export const uploadDesignSuccess = createAction(UploadDesignActionTypes.UPLOAD_DESIGN_SUCCESS)();
export const uploadDesignFailure = createAction(UploadDesignActionTypes.UPLOAD_DESIGN_FAILURE)<IErrorActionData>();
export const uploadDesignReset = createAction(UploadDesignActionTypes.UPLOAD_DESIGN_RESET)();

export type UploadDesignAction = ActionType<typeof uploadDesign>;
type UploadDesignSuccessAction = ActionType<typeof uploadDesignSuccess>;
type UploadDesignFailureAction = ActionType<typeof uploadDesignFailure>;
type UploadDesignResetAction = ActionType<typeof uploadDesignReset>;

export type UploadDesignActions =
  | UploadDesignAction
  | UploadDesignSuccessAction
  | UploadDesignFailureAction
  | UploadDesignResetAction;
