/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import {
  GetDisplayRequestListUrlAction,
  GetDisplayRequestListUrlActionTypes,
  getDisplayRequestListUrlFailure,
  getDisplayRequestListUrlSuccess,
} from '../actions/GetDisplayRequestListUrl';
import getDisplayRequestListUrlService from '../services/GetDisplayRequestUrl';
import DisplayManagementActions from '../actions';

const GetDisplayRequestListUrlEpic = (action$: ActionsObservable<DisplayManagementActions>,
  state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL)),
  mergeMap((action: GetDisplayRequestListUrlAction) => from(
    getDisplayRequestListUrlService(
      action.payload.processAliasId,
      action.payload.filter,
    ),
  ).pipe(
    map((response) => getDisplayRequestListUrlSuccess({
      data: response.data.getDisplayRequestListUrl.data,
    })),
    catchError((error: IErrorActionData) => of(
      getDisplayRequestListUrlFailure(
        {
          errorCode: error.errorCode || 500,
          errorMessage: error.errorMessage,
          callBack: error.callBack,
        },
      ),
    )),
  )),
);

export default GetDisplayRequestListUrlEpic;
