import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_SUPPLY_ORDER_LIST = gql`
  query getSupplyOrderList($path: String!) {
    getSupplyOrderList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const GetSupplyOrderListService = (filter: IFilter[]) => {
  const url = constructUrlWithParams(URL.getSupplyOrderList, filter);
  return client.query({
    query: GET_SUPPLY_ORDER_LIST,
    variables: { path: url },
  });
};

export default GetSupplyOrderListService;
