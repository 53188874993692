import common_en from './en/common.json';
import auth_en from './en/auth.json';
import router_en from './en/router.json';
import cart_en from './en/cart.json';
import dashboard_en from './en/dashboard.json';
import orders_en from './en/orders.json';
import customer_en from './en/customer.json';
import payment_en from './en/payment.json';
import cash_en from './en/cash.json';
import ledger_en from './en/ledger.json';
import refund_en from './en/refund.json';
import return_en from './en/return.json';
import labelManagement_en from './en/labelManagement.json';
import displayManagement_en from './en/displayManagement.json';
import deal_en from './en/deal.json';

const langSetupOptions = {
  resources: {
    en: {
      common: common_en,
      auth: auth_en,
      router: router_en,
      cart: cart_en,
      dashboard: dashboard_en,
      orders: orders_en,
      customer: customer_en,
      payment: payment_en,
      cash: cash_en,
      ledger: ledger_en,
      refund: refund_en,
      return: return_en,
      labelManagement: labelManagement_en,
      displayManagement: displayManagement_en,
      deal: deal_en,
    },
  },
  ns: [
    'common',
    'auth',
    'dashboard',
    'router',
    'orders',
    'customer',
    'cart',
    'payment',
    'cash',
    'ledger',
    'refund',
    'return',
    'labelManagement',
    'displayManagement',
    'deal',
  ],
  defaultNS: 'common',
};

export default langSetupOptions;
