/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';

interface IGenerateLabelProps {
  width?: number;
  height?: number;
  className?: string;
}

const GenerateLabelIcon : FunctionComponent<IGenerateLabelProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 60}
    height={props.height ?? 60}
    fill="none"
    className={props.className}
  >
    <g clipPath="url(#a)">
      <path
        fill="#212121"
        d="M5.833.5h-2.5A3.333 3.333 0 0 0 0 3.833v2.5a3.333 3.333 0 0 0 3.333 3.334h2.5a3.333 3.333 0 0 0 3.334-3.334v-2.5A3.333 3.333 0 0 0 5.833.5ZM7.5 6.333A1.667 1.667 0 0 1 5.833 8h-2.5a1.667 1.667 0 0 1-1.666-1.667v-2.5a1.667 1.667 0 0 1 1.666-1.666h2.5A1.667 1.667 0 0 1 7.5 3.833v2.5Z"
      />
      <path
        fill="#F15927"
        d="M16.667.5h-2.5a3.333 3.333 0 0 0-3.334 3.333v2.5a3.333 3.333 0 0 0 3.334 3.334h2.5A3.333 3.333 0 0 0 20 6.333v-2.5A3.333 3.333 0 0 0 16.667.5Zm1.666 5.833A1.667 1.667 0 0 1 16.667 8h-2.5A1.667 1.667 0 0 1 12.5 6.333v-2.5a1.667 1.667 0 0 1 1.667-1.666h2.5a1.667 1.667 0 0 1 1.666 1.666v2.5Z"
      />
      <path
        fill="#212121"
        d="M5.833 11.333h-2.5A3.333 3.333 0 0 0 0 14.667v2.5A3.333 3.333 0 0 0 3.333 20.5h2.5a3.333 3.333 0 0 0 3.334-3.333v-2.5a3.333 3.333 0 0 0-3.334-3.334ZM7.5 17.167a1.667 1.667 0 0 1-1.667 1.666h-2.5a1.667 1.667 0 0 1-1.666-1.666v-2.5A1.667 1.667 0 0 1 3.333 13h2.5A1.667 1.667 0 0 1 7.5 14.667v2.5ZM16.667 11.333h-2.5a3.333 3.333 0 0 0-3.334 3.334v2.5a3.333 3.333 0 0 0 3.334 3.333h2.5A3.333 3.333 0 0 0 20 17.167v-2.5a3.333 3.333 0 0 0-3.333-3.334Zm1.666 5.834a1.667 1.667 0 0 1-1.666 1.666h-2.5a1.667 1.667 0 0 1-1.667-1.666v-2.5A1.667 1.667 0 0 1 14.167 13h2.5a1.667 1.667 0 0 1 1.666 1.667v2.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default GenerateLabelIcon;
