import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter, IPage } from '../../../utils/sortFilterFramework';

const GET_RETURN_LIST = gql`
  query getReturnList($path: String!) {
    getReturnList @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
      pageInfo
    }
  }
`;

const getReturnListService = (filter: IFilter[], page: IPage) => {
  const url = constructUrlWithParams(URL.getReturnList, filter, undefined, page);
  return client.query({ query: GET_RETURN_LIST, variables: { path: url } });
};

export default getReturnListService;
