/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCustomerCreditBalanceDetailsSuccess,
  getCustomerCreditBalanceDetailsFailed,
  GetCustomerCreditBalanceDetailsAction,
  GetCustomerCreditBalanceDetailsActionTypes,
} from '../actions/GetCustomerCreditBalanceDetails';
import getCustomerCreditBalanceDetailsService from '../services/GetCustomerCreditBalanceDetails';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCustomerCreditBalanceDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS)),
  mergeMap((action: GetCustomerCreditBalanceDetailsAction) => from(
    getCustomerCreditBalanceDetailsService(action.payload.customerId),
  ).pipe(
    map((response: any) => getCustomerCreditBalanceDetailsSuccess({
      getCustomerCreditBalanceDetails: response.data.getCustomerCreditBalanceDetails.data || {},
    })),
    catchError((error: any) => of(
      getCustomerCreditBalanceDetailsFailed({
        errorCode: error.errorCode || error.networkError?.statusCode || 500,
        errorMessage: error.errorMessage || error?.networkError?.result?.error_message as string,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCustomerCreditBalanceDetailsEpic;
