export interface INewCartItemDetails {
  sellingPrice: number,
  mrp: number,
  taxRate: number,
  productAliasId: string,
  productName: string,
  qty: number,
  variantAliasId: string,
  isActive: boolean,
  skuId: string,
  imageUrl: string,
  taxClass: string,
  measurementUnitName: string,
  measurementUnitId: number,
  measurementUnitQty: string,
  uomDecimalPrecision: number,
  hsnCode: string,
  brandName: string,
  productCategoryName: string,
  productCategoryAliasId: string,
  selectedUom: {
    id: number,
    name: string,
    quantityConversionFactor: number,
  },
}

export class NewCartItemDetails {
  newCartItem = {} as INewCartItemDetails;
  constructor(newCartItem: INewCartItemDetails) {
    this.newCartItem = newCartItem;
  }
}
