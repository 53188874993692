import { combineReducers } from 'redux';

import LoginReducer from '../../feature/auth/reducers/Login';
import AutoLoginReducer from '../../feature/auth/reducers/AutoLogin';
import VerifyOtpReducer from '../../feature/auth/reducers/VerifyOTP';
import ResendOtpReducer from '../../feature/auth/reducers/ResendOTP';
import LogoutReducer from '../../feature/auth/reducers/Logout';
import GetUserProfileReducer from '../../feature/auth/reducers/GetUserProfile';
import GetProductListReducer from '../../feature/product/reducers/GetProductList';
import GetOrderListReducer from '../../feature/order/reducers/GetOrderList';
import CreateOrderReducer from '../../feature/order/reducers/CreateOrder';
import CancelOrderReducer from '../../feature/order/reducers/CancelOrder';
import GetOrderDetailsReducer from '../../feature/order/reducers/GetOrderDetails';
import GetProformaInvoiceUrlReducer from '../../feature/invoice/reducers/GetProformaInvoiceUrl';
import UpdatePaymentReducer from '../../feature/payment/reducers/UpdatePayment';
import GetStoreListReducer from '../../feature/retailStore/reducers/GetStoreList';
import GetStoreDetailsReducer from '../../feature/retailStore/reducers/GetStoreDetails';
import GetOrderPaymentDetailsReducer from '../../feature/payment/reducers/GetOrderPaymentDetails';
import CreateOrderPaymentReducer from '../../feature/payment/reducers/CreateOrderPayment';
import GenerateInvoiceReducer from '../../feature/invoice/reducers/GenerateInvoice';
import GenerateQuotationReducer from '../../feature/quotation/reducers/GenerateQuotation';
import GetQuotationUrlReducer from '../../feature/quotation/reducers/GetQuotationUrl';
import CreateInfluencerReducer from '../../feature/influencer/reducers/CreateInfluencer';
import UpdateInfluencerReducer from '../../feature/influencer/reducers/UpdateInfluencer';
import GetStoreEmployeeListReducer from '../../feature/users/reducers/GetStoreEmployeeList';
import DispatchSupplyOrderReducer from '../../feature/order/reducers/DispatchSupplyOrder';
import EditOrderReducer from '../../feature/order/reducers/EditOrder';
import StartProcessingOrderReducer from '../../feature/order/reducers/StartProcessingOrder';
import StartProcessingSupplyOrderReducer from '../../feature/order/reducers/StartProcessingSupplyOrder';
import GetOrderCustomerDetailsReducer from '../../feature/order/reducers/GetOrderCustomerDetails';
import GetSupplyOrderListReducer from '../../feature/order/reducers/GetSupplyOrderList';
import GetShipmentDetailsReducer from '../../feature/order/reducers/GetShipmentDetails';
import GetShipmentInvoiceUrlReducer from '../../feature/invoice/reducers/GetShipmentInvoiceUrl';
import GetOpeningCashBalanceDetailsReducer from '../../feature/cash/reducers/GetOpeningCashBalanceDetails';
import GetOpenCashTransactionsReducer from '../../feature/cash/reducers/GetOpenCashTransactions';
import AddCmsSlipReducer from '../../feature/cash/reducers/AddCmsSlip';
import EditCmsSlipReducer from '../../feature/cash/reducers/EditCmsSlip';
import GetCashClosureListReducer from '../../feature/cash/reducers/GetCashClosureList';
import AddCashClosureReducer from '../../feature/cash/reducers/AddCashClosure';
import GetCashClosureDetailsReducer from '../../feature/cash/reducers/GetCashClosureDetails';
import EditCashClosureReducer from '../../feature/cash/reducers/EditCashClosure';
import GetCashTransactionListReducer from '../../feature/cash/reducers/GetCashTransactionList';
import DownloadCashClosureReportReducer from '../../feature/cash/reducers/DownloadCashClosureReport';
import DownloadCashTransactionsReportReducer from '../../feature/cash/reducers/DownloadCashTransactionsReport';
import GetAdditionalCashListReducer from '../../feature/cash/reducers/GetAdditionalCashList';
import GetDownloadCashClosureStatusReducer from '../../feature/cash/reducers/GetDownloadCashClosureStatus';
import GetDownloadTransactionStatusReducer from '../../feature/cash/reducers/GetDownloadTransactionStatus';
import GetAuditLogsReducer from '../../feature/order/reducers/GetAuditLogs';
import EditSKUDeliveryDateReducer from '../../feature/order/reducers/EditSKUDeliveryDate';
import GetRefundListReducer from '../../feature/refund/reducers/GetRefundList';
import ConfirmRefundReducer from '../../feature/refund/reducers/ConfirmRefund';
import CancelRefundReducer from '../../feature/refund/reducers/CancelRefund';
import RecordRefundReducer from '../../feature/refund/reducers/RecordRefund';
import GetCustomerCreditBalanceDetailsReducer from '../../feature/refund/reducers/GetCustomerCreditBalanceDetails';
import CancelSKUReducer from '../../feature/order/reducers/CancelSKU';
import RequestReturnReducer from '../../feature/return/reducers/RequestReturn';
import GetSOCancellationDetailsReducer from '../../feature/order/reducers/GetSOCancellationDetails';
import GetShipmentSkuListReducer from '../../feature/return/reducers/GetShipmentSkuList';
import GetShipmentListReducer from '../../feature/return/reducers/GetShipmentList';
import GetCancelSkuListReducer from '../../feature/order/reducers/GetCancelSkuList';
import GetEWayBillUrlReducer from '../../feature/invoice/reducers/GetEWayBillUrl';
import GetReturnDetailsReducer from '../../feature/return/reducers/GetReturnDetails';
import GetReturnListReducer from '../../feature/return/reducers/GetReturnList';
import GetCustomerLedgerDetailsReducer from '../../feature/ledger/reducers/GetCustomerLedgerDetails';
import GetAccountStatementReducer from '../../feature/ledger/reducers/GetAccountStatement';
import GetLedgerTxnInvoiceUrlReducer from '../../feature/ledger/reducers/GetLedgerTxnInvoiceUrl';
import DownloadLedgerAccountStatementReducer from '../../feature/ledger/reducers/DownloadLedgerAccountStatement';
import GetDownloadLedgerStatementStatusReducer from '../../feature/ledger/reducers/GetDownloadLedgerStatementStatus';
import DisplayManagementReducers from '../../feature/displayManagement/reducers';
import InitiateOrderPaymentReducer from '../../feature/payment/reducers/InitiateOrderPayment';
import GetEDCMachineListReducer from '../../feature/payment/reducers/GetEDCMachineList';
import GetInfluencerDetailsReducer from '../../feature/influencer/reducers/GetInfluencerDetails';
import CheckInventoryReducer from '../../feature/inventory/reducers/checkInventory';
import CartReducers from '../../feature/cart/reducers';
import CustomerReducers from '../../feature/customer/reducers';
import MarkWrongTransactionReducer from '../../feature/cash/reducers/MarkWrongTransaction';
import LocationReducers from '../../feature/location/reducers';
import FetchRemoteConfigReducer from '../../feature/auth/reducers/fetchRemoteConfig';
import DealReducers from '../../feature/deal/reducers';

const RootReducer = combineReducers({
  loginState: LoginReducer,
  autoLogin: AutoLoginReducer,
  verifyOtp: VerifyOtpReducer,
  resendOtp: ResendOtpReducer,
  fetchRemoteConfig: FetchRemoteConfigReducer,
  logoutState: LogoutReducer,
  userProfile: GetUserProfileReducer,
  productList: GetProductListReducer,
  getOrderList: GetOrderListReducer,
  createOrder: CreateOrderReducer,
  cancelOrder: CancelOrderReducer,
  getOrderDetails: GetOrderDetailsReducer,
  getProformaInvoiceUrl: GetProformaInvoiceUrlReducer,
  updatePayment: UpdatePaymentReducer,
  getStoreList: GetStoreListReducer,
  getStoreDetails: GetStoreDetailsReducer,
  getOrderPaymentDetails: GetOrderPaymentDetailsReducer,
  createOrderPayment: CreateOrderPaymentReducer,
  generateInvoice: GenerateInvoiceReducer,
  generateQuotation: GenerateQuotationReducer,
  getQuotationUrl: GetQuotationUrlReducer,
  createInfluencer: CreateInfluencerReducer,
  updateInfluencer: UpdateInfluencerReducer,
  getStoreEmployeeList: GetStoreEmployeeListReducer,
  dispatchSupplyOrder: DispatchSupplyOrderReducer,
  editOrder: EditOrderReducer,
  startProcessingOrder: StartProcessingOrderReducer,
  startProcessingSupplyOrder: StartProcessingSupplyOrderReducer,
  getOrderCustomerDetails: GetOrderCustomerDetailsReducer,
  getSupplyOrderList: GetSupplyOrderListReducer,
  getShipmentDetails: GetShipmentDetailsReducer,
  getShipmentInvoiceUrl: GetShipmentInvoiceUrlReducer,
  getOpeningCashBalanceDetails: GetOpeningCashBalanceDetailsReducer,
  getOpenCashTransactions: GetOpenCashTransactionsReducer,
  addCmsSlip: AddCmsSlipReducer,
  editCmsSlip: EditCmsSlipReducer,
  getCashClosureList: GetCashClosureListReducer,
  addCashClosure: AddCashClosureReducer,
  getCashClosureDetails: GetCashClosureDetailsReducer,
  editCashClosure: EditCashClosureReducer,
  downloadCashClosureReport: DownloadCashClosureReportReducer,
  downloadCashTransactionsReport: DownloadCashTransactionsReportReducer,
  getCashTransactionList: GetCashTransactionListReducer,
  getAdditionalCashList: GetAdditionalCashListReducer,
  getDownloadCashClosureStatus: GetDownloadCashClosureStatusReducer,
  getDownloadTransactionStatus: GetDownloadTransactionStatusReducer,
  getAuditLogs: GetAuditLogsReducer,
  editSKUDeliveryDate: EditSKUDeliveryDateReducer,
  getRefundList: GetRefundListReducer,
  confirmRefund: ConfirmRefundReducer,
  cancelRefund: CancelRefundReducer,
  cancelSKU: CancelSKUReducer,
  getCustomerCreditBalanceDetails: GetCustomerCreditBalanceDetailsReducer,
  recordRefund: RecordRefundReducer,
  requestReturn: RequestReturnReducer,
  getSOCancellationDetails: GetSOCancellationDetailsReducer,
  getShipmentSkuList: GetShipmentSkuListReducer,
  getShipmentList: GetShipmentListReducer,
  getCancelSkuList: GetCancelSkuListReducer,
  getEWayBillUrl: GetEWayBillUrlReducer,
  getReturnDetails: GetReturnDetailsReducer,
  getReturnList: GetReturnListReducer,
  getCustomerLedgerDetails: GetCustomerLedgerDetailsReducer,
  getAccountStatement: GetAccountStatementReducer,
  getLedgerTxnInvoiceUrl: GetLedgerTxnInvoiceUrlReducer,
  downloadLedgerAccountStatement: DownloadLedgerAccountStatementReducer,
  downloadLedgerStatementStatus: GetDownloadLedgerStatementStatusReducer,
  displayManagement: DisplayManagementReducers,
  initiateOrderPayments: InitiateOrderPaymentReducer,
  getEDCMachineList: GetEDCMachineListReducer,
  getInfluencerDetails: GetInfluencerDetailsReducer,
  checkInventoryState: CheckInventoryReducer,
  ...CartReducers,
  ...CustomerReducers,
  ...LocationReducers,
  ...DealReducers,
  markWrongTransaction: MarkWrongTransactionReducer,
});

export default RootReducer;
