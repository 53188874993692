import { ReturnStatus } from '../../../constants/Strings';

export interface ISkuListItem {
  productName: string;
  quantityReturned: number;
  skuId: string;
  imageUrl: string;
  measurementUnitName: string;
  sellingPrice: number;
}

interface ICreditNote {
  creditNoteId: string;
  erpCreditNoteId: string;
  creditNoteAmount: number;
  creditNoteStatus: string;
}

interface IReturnReasons {
  returnReason: string;
  returnRemarks: string;
}

export interface IReturnDetails {
  returnAliasId: string;
  status: ReturnStatus;
  initiatedDate: number;
  supplyOrderId: number;
  shipmentCode: string;
  returnAmount: number;
  returnedDate: number;
  returnReasons: IReturnReasons;
  creditNote: ICreditNote;
  skuList: ISkuListItem[];
}

export class ReturnDetails {
  returnDetails = {} as IReturnDetails;
  constructor(returnDetails: IReturnDetails) {
    this.returnDetails = returnDetails;
  }
}
