export interface ISearchCustomerList {
  companyName: string;
  customerId: number;
  phoneNumber: string;
  firstName: string;
  gst: string;
  lastName?: string;
}

export class SearchCustomerList {
  searchCustomerList = [] as Array<ISearchCustomerList>;
  constructor(searchCustomerList: Array<ISearchCustomerList>) {
    this.searchCustomerList = searchCustomerList;
  }
}

/**
* Merging Two Lists
* @param existingList - existingList
* @param payloadList - Action Payload List
*/
export const mergeTwoLists = (
  existingList: Array<ISearchCustomerList>,
  payloadList: Array<ISearchCustomerList>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
