import { Reducer } from 'redux';

import { mergeTwoLists, SearchCustomerList } from '../models/GetSearchCustomerList';
import { GetSearchCustomerListActionTypes, GetSearchCustomerListActions } from '../actions/GetSearchCustomerList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface ISearchCustomerListState {
  searchCustomerList: SearchCustomerList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialSearchCustomerListState = {
  searchCustomerList: new SearchCustomerList([]),
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ISearchCustomerListState;

const GetSearchCustomerListReducer: Reducer<ISearchCustomerListState, GetSearchCustomerListActions> = (
  state = initialSearchCustomerListState,
  action: GetSearchCustomerListActions,
): ISearchCustomerListState => {
  switch (action.type) {
    case GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST_SUCCESS: {
      const newSearchCustomerList = mergeTwoLists(
        state.searchCustomerList.searchCustomerList,
        action.payload.searchCustomerList,
      );
      return {
        ...state,
        searchCustomerList: new SearchCustomerList(newSearchCustomerList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo?.pageSize,
          hasNext: action.payload.pageInfo?.hasNext,
          pageNumber: action.payload.pageInfo?.pageNumber,
          totalPages: action.payload.pageInfo?.totalPages,
          cursor: action.payload.pageInfo?.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST_RESET:
      return initialSearchCustomerListState;

    default:
      return state;
  }
};

export default GetSearchCustomerListReducer;
