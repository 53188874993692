interface ICategoryItem {
  count: number;
  id: number;
  name: string;
}

interface IOptimisedListDetails {
  status: string;
  dateOfCompletion: number;
  remainingDays: number;
  processId: number,
  pendingTaskId: number,
}

export interface IDisplayInventorySummaryDetails {
  noOfProducts: number;
  noOfCategories: number;
  lastSyncedOn: number,
  noOfDaySinceLastSynced: number;
  categories: Array<ICategoryItem>;
  displayOptimizationRequest: IOptimisedListDetails;
}

export class DisplayInventorySummaryDetails {
  displayInventorySummaryDetails = {} as IDisplayInventorySummaryDetails;
  constructor(displayInventorySummaryDetails: IDisplayInventorySummaryDetails) {
    this.displayInventorySummaryDetails = displayInventorySummaryDetails;
  }
}
