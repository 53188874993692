/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  updateCartSuccess,
  updateCartFailure,
  UpdateCartAction,
  UpdateCartActionTypes,
} from '../actions/UpdateCart';
import updateCartService from '../services/UpdateCart';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const UpdateCartEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(UpdateCartActionTypes.UPDATE_CART_ACTION)),
  mergeMap((action: UpdateCartAction) => from(
    updateCartService(action.payload.cartAliasId, action.payload.payload),
  ).pipe(
    map((response: any) => (updateCartSuccess({ stateUpdate: !!action.payload.payload.state }))),
    catchError((error: any) => of(
      updateCartFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default UpdateCartEpic;
