import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GetLedgerTxnInvoiceUrlActionTypes {
  GET_LEDGER_TXN_INVOICE_URL = 'GET_LEDGER_TXN_INVOICE_URL',
  GET_LEDGER_TXN_INVOICE_URL_SUCCESS = 'GET_LEDGER_TXN_INVOICE_URL_SUCCESS',
  GET_LEDGER_TXN_INVOICE_URL_FAILED = 'GET_LEDGER_TXN_INVOICE_URL_FAILED',
  GET_LEDGER_TXN_INVOICE_URL_RESET = 'GET_LEDGER_TXN_INVOICE_URL_RESET',
}

export interface IGetLedgerTxnInvoiceUrlActionData {
  invoiceAliasId: string;
}

export interface IGetLedgerTxnInvoiceUrlSuccessActionData {
  invoiceAliasId: string;
  invoiceSerialNumber: string;
  invoiceType: string;
  preSignedUrl: string;
}

interface IGetLedgerTxnInvoiceUrlErrorActionData extends IErrorActionData {
  invoiceAliasId: string;
}

export const getLedgerTxnInvoiceUrl = createAction(GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL)
<IGetLedgerTxnInvoiceUrlActionData>();
export const getLedgerTxnInvoiceUrlSuccess = createAction(GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL_SUCCESS)
<IGetLedgerTxnInvoiceUrlSuccessActionData>();
export const getLedgerTxnInvoiceUrlFailure = createAction(GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL_FAILED)
<IGetLedgerTxnInvoiceUrlErrorActionData>();
export const getLedgerTxnInvoiceUrlReset = createAction(GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL_RESET)();

export type GetLedgerTxnInvoiceUrlAction = ActionType<typeof getLedgerTxnInvoiceUrl>;
type GetLedgerTxnInvoiceUrlSuccessAction = ActionType<typeof getLedgerTxnInvoiceUrlSuccess>;
type GetLedgerTxnInvoiceUrlFailureAction = ActionType<typeof getLedgerTxnInvoiceUrlFailure>;
type GetLedgerTxnInvoiceUrlResetAction = ActionType<typeof getLedgerTxnInvoiceUrlReset>;

export type GetLedgerTxnInvoiceUrlActions =
| GetLedgerTxnInvoiceUrlAction
| GetLedgerTxnInvoiceUrlSuccessAction
| GetLedgerTxnInvoiceUrlFailureAction
| GetLedgerTxnInvoiceUrlResetAction;
