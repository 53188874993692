/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getDisplayInventoryListSuccess,
  getDisplayInventoryListFailure,
  GetDisplayInventoryListAction,
  GetDisplayInventoryListActionTypes,
} from '../actions/GetDisplayInventoryList';
import getDisplayInventoryListService from '../services/GetDisplayInventoryList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetDisplayInventoryListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST)),
  mergeMap((action: GetDisplayInventoryListAction) => from(
    getDisplayInventoryListService(
      action.payload.storeAliasId,
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getDisplayInventoryListSuccess({
      skuList: response.data.getDisplayInventoryList.data || [],
      pageInfo: response.data.getDisplayInventoryList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getDisplayInventoryListFailure(
        {
          errorCode: error.errorCode || 500,
          errorMessage: error.errorMessage,
          callBack: error.callBack,
        },
      ),
    )),
  )),
);

export default GetDisplayInventoryListEpic;
