import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_CART_STATE = gql`
  query getCartState($path: String!) {
    getCartState @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getCartStateService = (cartAliasId: string) => client.query(
  {
    query: GET_CART_STATE,
    variables: {
      path: URL.getCartState.replace('{cartAliasId}', cartAliasId),
    },
  },
);

export default getCartStateService;
