import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { GetDisplayRequestListUrlActionTypes, GetDisplayRequestListUrlActions } from '../actions/GetDisplayRequestListUrl';

export interface IDisplayRequestListUrlState {
  data: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDisplayRequestSummaryState = {
  data: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDisplayRequestListUrlState;

const GetDisplayRequestListUrlReducer: Reducer<IDisplayRequestListUrlState, GetDisplayRequestListUrlActions> = (
  state = initialDisplayRequestSummaryState,
  action: GetDisplayRequestListUrlActions,
): IDisplayRequestListUrlState => {
  switch (action.type) {
    case GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL_RESET:
      return initialDisplayRequestSummaryState;

    default:
      return state;
  }
};

export default GetDisplayRequestListUrlReducer;
