import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IUpdateCustomerDetailsPayload } from '../models/UpdateCustomerDetails';

const UPDATE_CUSTOMER_DETAILS = gql`
query updateCustomerDetails($path: String!, $payload: any) {
  updateCustomerDetails(path: $path, payload: $payload) 
    @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "photon") {
      data
    }
  }
`;

const updateCustomerDetailsService = (customerId: string, payload: IUpdateCustomerDetailsPayload) => {
  const url = URL.updateCustomerDetails.replace('{customerId}', customerId);
  return client.query({ query: UPDATE_CUSTOMER_DETAILS, variables: { path: url, payload } });
};

export default updateCustomerDetailsService;
