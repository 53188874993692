import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum CancelRefundActionTypes {
  CANCEL_REFUND_ACTION = 'CANCEL_REFUND_ACTION',
  CANCEL_REFUND_ACTION_SUCCESS = 'CANCEL_REFUND_ACTION_SUCCESS',
  CANCEL_REFUND_ACTION_FAILURE = 'CANCEL_REFUND_ACTION_FAILURE',
  CANCEL_REFUND_ACTION_RESET = 'CANCEL_REFUND_ACTION_RESET',
}

export interface ICancelRefundPayload {
  cancellationReason: string;
}

export interface ICancelRefundActionData {
  refundAliasId: string;
  payload: ICancelRefundPayload;
}

export const cancelRefund = createAction(CancelRefundActionTypes.CANCEL_REFUND_ACTION)<ICancelRefundActionData>();
export const cancelRefundSuccess = createAction(CancelRefundActionTypes.CANCEL_REFUND_ACTION_SUCCESS)();
export const cancelRefundFailure = createAction(CancelRefundActionTypes.CANCEL_REFUND_ACTION_FAILURE)<IErrorActionData>();
export const cancelRefundReset = createAction(CancelRefundActionTypes.CANCEL_REFUND_ACTION_RESET)();

export type CancelRefundAction = ActionType<typeof cancelRefund>;
type CancelRefundSuccessAction = ActionType<typeof cancelRefundSuccess>;
type CancelRefundFailureAction = ActionType<typeof cancelRefundFailure>;
type CancelRefundResetAction = ActionType<typeof cancelRefundReset>;

export type CancelRefundActions =
  | CancelRefundAction
  | CancelRefundSuccessAction
  | CancelRefundFailureAction
  | CancelRefundResetAction;
