/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  loginSuccess,
  loginFailure,
  LoginAction,
  LoginActionTypes,
} from '../actions/Login';
import loginService from '../services/Login';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import { defaultOTPTimeout } from '../../../constants/Common';

const LoginEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(LoginActionTypes.LOGIN_ACTION)),
  mergeMap((action: LoginAction) => from(
    loginService(action.payload),
  ).pipe(
    map((response: any) => {
      let otpTimeout = defaultOTPTimeout;
      if (response.data.login.data.otpTimeout) {
        otpTimeout = response.data.login.data.otpTimeout;
      }
      return loginSuccess({ loginID: action.payload.loginID, otpTimeout });
    }),
    catchError((error: any) => of(
      loginFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default LoginEpic;
