import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_ADDITIONAL_CASH_LIST = gql`
  query getAdditionalCashList($path: String!) {
    getAdditionalCashList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getAdditionalCashListService = (storeAliasId: string) => {
  const url = URL.getAdditionalCashList.replace('{storeAliasId}', storeAliasId);
  return client.query({ query: GET_ADDITIONAL_CASH_LIST, variables: { path: url } });
};

export default getAdditionalCashListService;
