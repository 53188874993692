import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { ICancelSkuList } from '../models/GetCancelSkuList';

export enum GetCancelSkuListActionTypes {
  GET_CANCEL_SKU_LIST = 'GET_CANCEL_SKU_LIST',
  GET_CANCEL_SKU_LIST_SUCCESS = 'GET_CANCEL_SKU_LIST_SUCCESS',
  GET_CANCEL_SKU_LIST_FAILED = 'GET_CANCEL_SKU_LIST_FAILED',
  GET_CANCEL_SKU_LIST_RESET = 'GET_CANCEL_SKU_LIST_RESET',
}

export interface IGetCancelSkuListActionData {
  supplyOrderAliasId: string;
}

export interface IGetCancelSkuListSuccessActionData {
  cancelSkuListDetails: ICancelSkuList;
}

export const getCancelSkuList = createAction(GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST)<IGetCancelSkuListActionData>();
export const getCancelSkuListSuccess = createAction(GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST_SUCCESS)
<IGetCancelSkuListSuccessActionData>();
export const getCancelSkuListFailure = createAction(GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST_FAILED)
<IErrorActionData>();
export const getCancelSkuListReset = createAction(GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST_RESET)();

export type GetCancelSkuListAction = ActionType<typeof getCancelSkuList>;
type GetCancelSkuListSuccessAction = ActionType<typeof getCancelSkuListSuccess>;
type GetCancelSkuListFailedAction = ActionType<typeof getCancelSkuListFailure>;
type GetCancelSkuListResetAction = ActionType<typeof getCancelSkuListReset>;

export type GetCancelSkuListActions =
  | GetCancelSkuListAction
  | GetCancelSkuListSuccessAction
  | GetCancelSkuListFailedAction
  | GetCancelSkuListResetAction;
