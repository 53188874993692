/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getOrderDetailsSuccess,
  getOrderDetailsFailure,
  GetOrderDetailsAction,
  GetOrderDetailsActionTypes,
} from '../actions/GetOrderDetails';
import getOrderDetailsService from '../services/GetOrderDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetOrderDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetOrderDetailsActionTypes.GET_ORDER_DETAILS)),
  mergeMap((action: GetOrderDetailsAction) => from(
    getOrderDetailsService(action.payload.orderReferenceId),
  ).pipe(
    map((response) => getOrderDetailsSuccess({
      orderDetails: response.data.getOrderDetails.data || {},
    })),
    catchError((error: IErrorActionData) => of(
      getOrderDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetOrderDetailsEpic;
