import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { IAuditLogs, IAuditLogsItem } from '../models/AuditLogs';

export enum GetAuditLogsActionTypes {
  GET_AUDIT_LOGS = 'GET_AUDIT_LOGS',
  GET_AUDIT_LOGS_SUCCESS = 'GET_AUDIT_LOGS_SUCCESS',
  GET_AUDIT_LOGS_FAILED = 'GET_AUDIT_LOGS_FAILED',
  GET_AUDIT_LOGS_RESET = 'GET_AUDIT_LOGS_RESET',
}

export enum AvailableFilterProperty {
  entityType = 'entitytype',
}

export interface IGetAuditLogsActionData {
  filter?: IFilter[];
}
export interface IGetAuditLogsSuccessActionData {
  auditLogDetails: IAuditLogs;
}

export const getAuditLogs = createAction(GetAuditLogsActionTypes.GET_AUDIT_LOGS)<IGetAuditLogsActionData>();
export const getAuditLogsSuccess = createAction(GetAuditLogsActionTypes.GET_AUDIT_LOGS_SUCCESS)
<IGetAuditLogsSuccessActionData>();
export const getAuditLogsFailure = createAction(GetAuditLogsActionTypes.GET_AUDIT_LOGS_FAILED)
<IErrorActionData>();
export const getAuditLogsReset = createAction(GetAuditLogsActionTypes.GET_AUDIT_LOGS_RESET)();

export type GetAuditLogsAction = ActionType<typeof getAuditLogs>;
type GetAuditLogsSuccessAction = ActionType<typeof getAuditLogsSuccess>;
type GetAuditLogsFailedAction = ActionType<typeof getAuditLogsFailure>;
type GetAuditLogsResetAction = ActionType<typeof getAuditLogsReset>;

export type GetAuditLogsActions =
  | GetAuditLogsAction
  | GetAuditLogsSuccessAction
  | GetAuditLogsFailedAction
  | GetAuditLogsResetAction;
