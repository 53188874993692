import { Reducer } from 'redux';

import { ShipmentList } from '../models/ShipmentList';
import { GetShipmentListActionTypes, GetShipmentListActions } from '../actions/GetShipmentList';
import { IErrorActionData } from '../../../models/Error';

export interface IGetShipmentListState {
  shipmentList: ShipmentList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialShipmentDetailsState = {
  shipmentList: new ShipmentList([]),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetShipmentListState;

const GetShipmentListReducer: Reducer<IGetShipmentListState, GetShipmentListActions> = (
  state = initialShipmentDetailsState,
  action: GetShipmentListActions,
): IGetShipmentListState => {
  switch (action.type) {
    case GetShipmentListActionTypes.GET_SHIPMENT_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetShipmentListActionTypes.GET_SHIPMENT_LIST_SUCCESS: {
      return {
        ...state,
        shipmentList: new ShipmentList(action.payload.shipmentList),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetShipmentListActionTypes.GET_SHIPMENT_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetShipmentListActionTypes.GET_SHIPMENT_LIST_RESET:
      return initialShipmentDetailsState;

    default:
      return state;
  }
};

export default GetShipmentListReducer;
