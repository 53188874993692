import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const DELETE_ADDRESS = gql`
  query deleteAddress($path: String!) {
    deleteAddress (path: $path)
      @rest(method: "DELETE", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const deleteAddressService = (customerId: string, addressId: string) => {
  const url = URL.deleteAddress.replace('{customerId}', customerId).replace('{addressId}', addressId);
  return client.query({ query: DELETE_ADDRESS, variables: { path: url } });
};

export default deleteAddressService;
