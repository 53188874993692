import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum ConfirmRefundActionTypes {
  CONFIRM_REFUND_ACTION = 'CONFIRM_REFUND_ACTION',
  CONFIRM_REFUND_ACTION_SUCCESS = 'CONFIRM_REFUND_ACTION_SUCCESS',
  CONFIRM_REFUND_ACTION_FAILURE = 'CONFIRM_REFUND_ACTION_FAILURE',
  CONFIRM_REFUND_ACTION_RESET = 'CONFIRM_REFUND_ACTION_RESET',
}

export interface IConfirmRefundPayload {
  refundReferenceId: string;
  refundTransactionId: string;
}

export interface IConfirmRefundActionData {
  refundAliasId: string;
  payload: IConfirmRefundPayload;
}

export const confirmRefund = createAction(ConfirmRefundActionTypes.CONFIRM_REFUND_ACTION)<IConfirmRefundActionData>();
export const confirmRefundSuccess = createAction(ConfirmRefundActionTypes.CONFIRM_REFUND_ACTION_SUCCESS)();
export const confirmRefundFailure = createAction(ConfirmRefundActionTypes.CONFIRM_REFUND_ACTION_FAILURE)<IErrorActionData>();
export const confirmRefundReset = createAction(ConfirmRefundActionTypes.CONFIRM_REFUND_ACTION_RESET)();

export type ConfirmRefundAction = ActionType<typeof confirmRefund>;
type ConfirmRefundSuccessAction = ActionType<typeof confirmRefundSuccess>;
type ConfirmRefundFailureAction = ActionType<typeof confirmRefundFailure>;
type ConfirmRefundResetAction = ActionType<typeof confirmRefundReset>;

export type ConfirmRefundActions =
  | ConfirmRefundAction
  | ConfirmRefundSuccessAction
  | ConfirmRefundFailureAction
  | ConfirmRefundResetAction;
