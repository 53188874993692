import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_ADDRESS_LIST = gql`
  query getAddressList($path: String!) {
    getAddressList @rest(method: "GET", path: $path, endpoint: "photon") {
      data
    }
  }
`;

const getAddressListService = (customerId: string) => client.query(
  {
    query: GET_ADDRESS_LIST,
    variables: {
      path: URL.getAddressList.replace('{customerId}', customerId),
    },
  },
);

export default getAddressListService;
