/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getOpeningCashBalanceDetailsSuccess,
  getOpeningCashBalanceDetailsFailed,
  GetOpeningCashBalanceDetailsAction,
  GetOpeningCashBalanceDetailsActionTypes,
} from '../actions/GetOpeningCashBalanceDetails';
import getOpeningCashBalanceDetailsService from '../services/GetOpeningCashBalanceDetails';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetOpeningCashBalanceDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS)),
  mergeMap((action: GetOpeningCashBalanceDetailsAction) => from(
    getOpeningCashBalanceDetailsService(action.payload.storeAliasId),
  ).pipe(
    map((response: any) => getOpeningCashBalanceDetailsSuccess({
      getOpeningCashBalanceDetails: response.data.getOpeningCashBalanceDetails.data || {},
    })),
    catchError((error: any) => of(
      getOpeningCashBalanceDetailsFailed({
        errorCode: error.errorCode || error.networkError?.statusCode || 500,
        errorMessage: error.errorMessage || error?.networkError?.result?.error_message as string,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetOpeningCashBalanceDetailsEpic;
