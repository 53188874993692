import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_PROFORMA_INVOICE_URL = gql`
  query getProformaInvoiceUrl($path: String!) {
    getProformaInvoiceUrl @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getProformaInvoiceUrlService = (invoiceAliasId: string) => client.query({
  query: GET_PROFORMA_INVOICE_URL,
  variables: {
    path: URL.getProformaInvoiceUrl.replace('{invoiceAliasId}', invoiceAliasId),
  },
});

export default getProformaInvoiceUrlService;
