import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GenerateQuotationActionTypes {
  GENERATE_QUOTATION = 'GENERATE_QUOTATION',
  GENERATE_QUOTATION_SUCCESS = 'GENERATE_QUOTATION_SUCCESS',
  GENERATE_QUOTATION_FAILED = 'GENERATE_QUOTATION_FAILED',
  GENERATE_QUOTATION_RESET = 'GENERATE_QUOTATION_RESET',
}

interface IGenerateQuotationActionData {
  cartAliasId: string;
  customerId: number;
  storeAliasId: string;
}

interface IGenerateQuotationSuccessData {
  quotationAliasId: string;
  quotationPdfUrl: string;
}

export const generateQuotation = createAction(GenerateQuotationActionTypes.GENERATE_QUOTATION)<IGenerateQuotationActionData>();
export const generateQuotationSuccess = createAction(GenerateQuotationActionTypes.GENERATE_QUOTATION_SUCCESS)
<IGenerateQuotationSuccessData>();
export const generateQuotationFailure = createAction(GenerateQuotationActionTypes.GENERATE_QUOTATION_FAILED)<IErrorActionData>();
export const generateQuotationReset = createAction(GenerateQuotationActionTypes.GENERATE_QUOTATION_RESET)();

export type GenerateQuotationAction = ActionType<typeof generateQuotation>;
type GenerateQuotationSuccessAction = ActionType<typeof generateQuotationSuccess>;
type GenerateQuotationFailureAction = ActionType<typeof generateQuotationFailure>;
type GenerateQuotationResetAction = ActionType<typeof generateQuotationReset>;

export type GenerateQuotationActions = GenerateQuotationAction
| GenerateQuotationSuccessAction
| GenerateQuotationFailureAction
| GenerateQuotationResetAction;
