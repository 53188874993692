import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IFilter, constructUrlWithParams } from '../../../utils/sortFilterFramework';

const DOWNLOAD_LEDGER_ACCOUNT_STATEMENT = gql`
query downloadLedgerAccountStatement($path: String!, $payload: any) {
    downloadLedgerAccountStatement (path: $path, payload: $payload)
      @rest(method: "GET", path: $path, bodyKey: "payload" endpoint: "pheonix") {
        data
      }
  }`;

const downloadLedgerAccountStatementService = (customerAliasId: string, filter?: IFilter[]) => {
  const url = constructUrlWithParams(URL.downloadLedgerAccountStatement
    .replace('{customerAliasId}', customerAliasId), filter, undefined, undefined);
  return client.query({ query: DOWNLOAD_LEDGER_ACCOUNT_STATEMENT, variables: { path: url } });
};

export default downloadLedgerAccountStatementService;
