/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  saveCartSuccess,
  saveCartFailure,
  SaveCartAction,
  SaveCartActionTypes,
} from '../actions/SaveCart';
import saveCartService from '../services/SaveCart';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const SaveCartEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(SaveCartActionTypes.SAVE_CART_ACTION)),
  mergeMap((action: SaveCartAction) => from(
    saveCartService(action.payload.cartState, action.payload.cartAliasId),
  ).pipe(
    map((response: any) => (saveCartSuccess())),
    catchError((error: any) => of(
      saveCartFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default SaveCartEpic;
