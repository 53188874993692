import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';

const GET_DEAL_LIST = gql`
  query getDealList($path: String!) {
    getDealList (path: $path)
    @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const GetDealListServices = (filterValues: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getDealList, filterValues, undefined, page);
  return client.query({ query: GET_DEAL_LIST, variables: { path: url } });
};

export default GetDealListServices;
