import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ICompleteTaskPayload } from '../models/CompleteTask';

const COMPLETE_TASK = gql`
  query completeTask($path: String!, $payload: any) {
    completeTask (path: $path, payload: $payload)
      @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const completeTaskService = (taskAliasId: string, payload: ICompleteTaskPayload) => client.query({
  query: COMPLETE_TASK,
  variables: {
    path: URL.completeTask.replace('{taskAliasId}', taskAliasId),
    payload,
  },
});

export default completeTaskService;
