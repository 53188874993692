import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GetProformaInvoiceUrlActionTypes {
  GET_PROFORMA_INVOICE_URL = 'GET_PROFORMA_INVOICE_URL',
  GET_PROFORMA_INVOICE_URL_SUCCESS = 'GET_PROFORMA_INVOICE_URL_SUCCESS',
  GET_PROFORMA_INVOICE_URL_FAILED = 'GET_PROFORMA_INVOICE_URL_FAILED',
  GET_PROFORMA_INVOICE_URL_RESET = 'GET_PROFORMA_INVOICE_URL_RESET',
}

export interface IGetProformaInvoiceUrlActionData {
  invoiceAliasId: string;
}

export interface IGetProformaInvoiceUrlSuccessActionData {
  invoiceAliasId: string;
  proformaInvoiceUrl: string;
}
interface IGetProformaInvoiceUrlErrorActionData extends IErrorActionData {
  invoiceAliasId: string;
}

export const getProformaInvoiceUrl = createAction(GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL)
<IGetProformaInvoiceUrlActionData>();
export const getProformaInvoiceUrlSuccess = createAction(GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL_SUCCESS)
<IGetProformaInvoiceUrlSuccessActionData>();
export const getProformaInvoiceUrlFailure = createAction(GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL_FAILED)
<IGetProformaInvoiceUrlErrorActionData>();
export const getProformaInvoiceUrlReset = createAction(GetProformaInvoiceUrlActionTypes.GET_PROFORMA_INVOICE_URL_RESET)();

export type GetProformaInvoiceUrlAction = ActionType<typeof getProformaInvoiceUrl>;
type GetProformaInvoiceUrlSuccessAction = ActionType<typeof getProformaInvoiceUrlSuccess>;
type GetProformaInvoiceUrlFailureAction = ActionType<typeof getProformaInvoiceUrlFailure>;
type GetProformaInvoiceUrlResetAction = ActionType<typeof getProformaInvoiceUrlReset>;

export type GetProformaInvoiceUrlActions = GetProformaInvoiceUrlAction
| GetProformaInvoiceUrlSuccessAction
| GetProformaInvoiceUrlFailureAction
| GetProformaInvoiceUrlResetAction;
