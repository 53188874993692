/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getShipmentListSuccess,
  getShipmentListFailure,
  GetShipmentListAction,
  GetShipmentListActionTypes,
} from '../actions/GetShipmentList';
import getShipmentListService from '../services/GetShipmentList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetShipmentListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetShipmentListActionTypes.GET_SHIPMENT_LIST)),
  mergeMap((action: GetShipmentListAction) => from(
    getShipmentListService(action.payload.supplyOrderAliasId),
  ).pipe(
    map((response: any) => getShipmentListSuccess({
      shipmentList: response.data.getShipmentList.data.shipments,
    })),
    catchError((error: IErrorActionData) => of(
      getShipmentListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetShipmentListEpic;
