export interface ICashTransactions {
  cashTransAliasId: string;
  amount: number;
  orderReferenceId: string;
  customerName: string;
  customerMobileNumber: string;
  transactionDate: number;
}

export interface IRemainingCash {
  remainingCashAliasId: string;
  amount: number;
  transactionDate: number;
}
interface ICashDepositMode {
  mode: string;
  roundOffValue: number;
}

interface ICashDepositModeInfo {
  cashDepositMode: ICashDepositMode;
}

export interface IGetCashClosureDetails {
  closureAliasId: string;
  openingBalance: number;
  cashCollected: number;
  cashDeposited: number;
  closingBalance: number;
  cmsSlipNumber: string;
  closingDateTs: number;
  depositDate: number;
  closedTransactions: number;
  actualCashDeposited: number;
  cashTransactions: ICashTransactions[];
  remainingCash: IRemainingCash[];
  info: ICashDepositModeInfo;
  closureType: string;
}

export class GetCashClosureDetails {
  getCashClosureDetails = {} as IGetCashClosureDetails;
  constructor(getCashClosureDetails: IGetCashClosureDetails) {
    this.getCashClosureDetails = getCashClosureDetails;
  }
}
