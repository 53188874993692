/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  createAddressSuccess,
  createAddressFailed,
  CreateAddressAction,
  CreateAddressActionTypes,
} from '../actions/CreateAddress';
import { IErrorActionData } from '../../../models/Error';
import createAddressService from '../services/CreateAddress';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CreateAddressEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CreateAddressActionTypes.CREATE_ADDRESS)),
  mergeMap((action: CreateAddressAction) => from(
    createAddressService(
      action.payload.newAddress,
      action.payload.customerId,
    ),
  ).pipe(
    map((response) => createAddressSuccess({
      newAddressData: response.data.createAddress.data,
    })),
    catchError((error: IErrorActionData) => of(
      createAddressFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CreateAddressEpic;
