import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_DEAL_DETAILS = gql`
  query getDealDetails($path: String!) {
    getDealDetails @rest(method: "GET", path: $path, endpoint: "photon") {
      data
    }
  }
`;

const GetDealDetailsService = (dealAliasId: string) => {
  const url = URL.getDealDetails.replace('{dealAliasId}', dealAliasId);
  return client.query({ query: GET_DEAL_DETAILS, variables: { path: url } });
};

export default GetDealDetailsService;
