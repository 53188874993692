import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { ISearchCustomerList } from '../models/GetSearchCustomerList';

export enum GetSearchCustomerListActionTypes {
  GET_SEARCH_CUSTOMER_LIST = 'GET_SEARCH_CUSTOMER_LIST',
  GET_SEARCH_CUSTOMER_LIST_SUCCESS = 'GET_SEARCH_CUSTOMER_LIST_SUCCESS',
  GET_SEARCH_CUSTOMER_LIST_FAILED = 'GET_SEARCH_CUSTOMER_LIST_FAILED',
  GET_SEARCH_CUSTOMER_LIST_RESET = 'GET_SEARCH_CUSTOMER_LIST_RESET',
}

export interface IGetSearchCustomerListActionData {
  filter?: IFilter[];
  page?: IPage;
}

export enum AvailableFilterProperty {
  phoneNumber = 'phoneNumber',
}

export interface IGetSearchCustomerListSuccessActionData {
  searchCustomerList: Array<ISearchCustomerList>;
  pageInfo: IPageInfo;
}

export const getSearchCustomerList = createAction(GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST)
<IGetSearchCustomerListActionData>();
export const getSearchCustomerListSuccess = createAction(GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST_SUCCESS)
<IGetSearchCustomerListSuccessActionData>();
export const getSearchCustomerListFailure = createAction(GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST_FAILED)
<IErrorActionData>();
export const getSearchCustomerListReset = createAction(GetSearchCustomerListActionTypes.GET_SEARCH_CUSTOMER_LIST_RESET)();

export type GetSearchCustomerListAction = ActionType<typeof getSearchCustomerList>;
type GetSearchCustomerListSuccessAction = ActionType<typeof getSearchCustomerListSuccess>;
type GetSearchCustomerListFailedAction = ActionType<typeof getSearchCustomerListFailure>;
type GetSearchCustomerListResetAction = ActionType<typeof getSearchCustomerListReset>;

export type GetSearchCustomerListActions =
  | GetSearchCustomerListAction
  | GetSearchCustomerListSuccessAction
  | GetSearchCustomerListFailedAction
  | GetSearchCustomerListResetAction;
