import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_PIN_CODE_INFO = gql`
  query getPinCodeInfo($path: String!) {
    getPincodeInfo @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const GetPincodeInfoService = (filter: IFilter[]) => {
  const url = constructUrlWithParams(URL.getPincodeInfo, filter);
  return client.query({ query: GET_PIN_CODE_INFO, variables: { path: url } });
};

export default GetPincodeInfoService;
