import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_ORDER_PAYMENT_DETAILS = gql`
  query getOrderPaymentDetails($path: String!) {
    getOrderPaymentDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getOrderPaymentDetailsService = (orderReferenceId: string) => {
  const url = URL.getOrderPaymentDetails.replace('{orderReferenceId}', orderReferenceId);
  return client.query({ query: GET_ORDER_PAYMENT_DETAILS, variables: { path: url } });
};

export default getOrderPaymentDetailsService;
