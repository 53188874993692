import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { EditCmsSlipActions, EditCmsSlipActionTypes } from '../actions/EditCmsSlip';

export interface IEditCmsSlipState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialEditCmsSlipState: IEditCmsSlipState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const EditCmsSlipReducer: Reducer<IEditCmsSlipState, EditCmsSlipActions> = (
  state = initialEditCmsSlipState,
  action: EditCmsSlipActions,
): IEditCmsSlipState => {
  switch (action.type) {
    case EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case EditCmsSlipActionTypes.EDIT_CMS_SLIP_ACTION_RESET:
      return initialEditCmsSlipState;

    default:
      return state;
  }
};

export default EditCmsSlipReducer;
