import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IShipmentListItem } from '../models/ShipmentList';

export enum GetShipmentListActionTypes {
  GET_SHIPMENT_LIST = 'GET_SHIPMENT_LIST',
  GET_SHIPMENT_LIST_SUCCESS = 'GET_SHIPMENT_LIST_SUCCESS',
  GET_SHIPMENT_LIST_FAILED = 'GET_SHIPMENT_LIST_FAILED',
  GET_SHIPMENT_LIST_RESET = 'GET_SHIPMENT_LIST_RESET',
}

export interface IGetShipmentListActionData {
  supplyOrderAliasId: string;
}

export interface IGetShipmentListSuccessActionData {
  shipmentList: Array<IShipmentListItem>;
}

export const getShipmentList = createAction(GetShipmentListActionTypes.GET_SHIPMENT_LIST)
<IGetShipmentListActionData>();
export const getShipmentListSuccess = createAction(GetShipmentListActionTypes.GET_SHIPMENT_LIST_SUCCESS)
<IGetShipmentListSuccessActionData>();
export const getShipmentListFailure = createAction(GetShipmentListActionTypes.GET_SHIPMENT_LIST_FAILED)
<IErrorActionData>();
export const getShipmentListReset = createAction(GetShipmentListActionTypes.GET_SHIPMENT_LIST_RESET)();

export type GetShipmentListAction = ActionType<typeof getShipmentList>;
type GetShipmentListSuccessAction = ActionType<typeof getShipmentListSuccess>;
type GetShipmentListFailureAction = ActionType<typeof getShipmentListFailure>;
type GetShipmentListResetAction = ActionType<typeof getShipmentListReset>;

export type GetShipmentListActions = GetShipmentListAction
| GetShipmentListSuccessAction
| GetShipmentListFailureAction
| GetShipmentListResetAction;
