import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IPage } from '../../../utils/sortFilterFramework';

const GET_OPEN_CASH_TRANSACTIONS = gql`
  query getOpenCashTransactions($path: String!) {
    getOpenCashTransactions @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getOpenCashTransactionsService = (storeAliasId: string, page?: IPage) => {
  const url = constructUrlWithParams(URL.getOpenCashTransactions.replace('{storeAliasId}', storeAliasId), undefined, undefined, page);
  return client.query({
    query: GET_OPEN_CASH_TRANSACTIONS,
    variables: {
      path: url,
    },
  });
};

export default getOpenCashTransactionsService;
