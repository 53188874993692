/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  logoutSuccess,
  logoutFailure,
  LogoutAction,
  LogoutActionTypes,
} from '../actions/Logout';
import logoutService from '../services/Logout';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import { defaultOTPTimeout } from '../../../constants/Common';

const LogoutEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(LogoutActionTypes.LOGOUT_ACTION)),
  mergeMap((action: LogoutAction) => from(
    logoutService(action.payload),
  ).pipe(
    map((response: any) => {
      let otpTimeout = defaultOTPTimeout;
      if (response.data.logout.data.otpTimeout) {
        otpTimeout = response.data.logout.data.otpTimeout;
      }
      localStorage.removeItem('userLoginToken');
      localStorage.removeItem('storeId');
      return logoutSuccess();
    }),
    catchError((error: IErrorActionData) => of(
      logoutFailure({
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default LogoutEpic;
