import { Reducer } from 'redux';

import { IStoreDetails, StoreDetails } from '../models/StoreDetails';
import { GetStoreDetailsActionTypes, GetStoreDetailsActions } from '../actions/GetStoreDetails';
import { IErrorActionData } from '../../../models/Error';

export interface IGetStoreDetailsState {
  storeDetails: StoreDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialStoreDetailsState = {
  storeDetails: new StoreDetails({} as IStoreDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetStoreDetailsState;

const GetStoreDetailsReducer: Reducer<IGetStoreDetailsState, GetStoreDetailsActions> = (
  state = initialStoreDetailsState,
  action: GetStoreDetailsActions,
): IGetStoreDetailsState => {
  switch (action.type) {
    case GetStoreDetailsActionTypes.GET_STORE_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetStoreDetailsActionTypes.GET_STORE_DETAILS_SUCCESS: {
      return {
        ...state,
        storeDetails: new StoreDetails(action.payload.storeDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetStoreDetailsActionTypes.GET_STORE_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetStoreDetailsActionTypes.GET_STORE_DETAILS_RESET:
      return initialStoreDetailsState;

    default:
      return state;
  }
};

export default GetStoreDetailsReducer;
