import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_RETURN_DETAILS = gql`
  query getReturnDetails($path: String!) {
    getReturnDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getReturnDetailsService = (returnAliasId: string) => {
  const url = URL.getReturnDetails.replace('{returnAliasId}', returnAliasId);
  return client.query({ query: GET_RETURN_DETAILS, variables: { path: url } });
};

export default getReturnDetailsService;
