/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  cancelOrderSuccess,
  cancelOrderFailure,
  CancelOrderAction,
  CancelOrderActionTypes,
} from '../actions/CancelOrder';
import cancelOrderService from '../services/CancelOrder';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CancelOrderEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CancelOrderActionTypes.CANCEL_ORDER_ACTION)),
  mergeMap((action: CancelOrderAction) => from(
    cancelOrderService(action.payload.orderReferenceId, action.payload.payload),
  ).pipe(
    map((response: any) => (cancelOrderSuccess())),
    catchError((error: any) => of(
      cancelOrderFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CancelOrderEpic;
