import { Reducer } from 'redux';

import { UpdateCartItemActionTypes, UpdateCartItemActions } from '../actions/UpdateCartItem';
import { IErrorActionData } from '../../../models/Error';

export interface IUpdateCartItemState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialUpdateCartItemState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IUpdateCartItemState;

const UpdateCartItemReducer: Reducer<IUpdateCartItemState, UpdateCartItemActions> = (
  state = initialUpdateCartItemState,
  action: UpdateCartItemActions,
): IUpdateCartItemState => {
  switch (action.type) {
    case UpdateCartItemActionTypes.UPDATE_CART_ITEM:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case UpdateCartItemActionTypes.UPDATE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case UpdateCartItemActionTypes.UPDATE_CART_ITEM_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case UpdateCartItemActionTypes.UPDATE_CART_ITEM_RESET:
      return initialUpdateCartItemState;

    default:
      return state;
  }
};

export default UpdateCartItemReducer;
