/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCashClosureListSuccess,
  getCashClosureListFailure,
  GetCashClosureListAction,
  GetCashClosureListActionTypes,
} from '../actions/GetCashClosureList';
import getCashClosureListService from '../services/GetCashClosureList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCashClosureListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST)),
  mergeMap((action: GetCashClosureListAction) => from(
    getCashClosureListService(
      action.payload.storeAliasId,
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getCashClosureListSuccess({
      cashClosureList: response.data.getCashClosureList.data.closureList || [],
      pageInfo: response.data.getCashClosureList.pageInfo,
      isOpenClosureAvailable: response.data.getCashClosureList.data.isOpenClosure,
    })),
    catchError((error: IErrorActionData) => of(
      getCashClosureListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCashClosureListEpic;
