import { Reducer } from 'redux';

import {
  GetCustomerCreditBalanceDetailsActions,
  GetCustomerCreditBalanceDetailsActionTypes,
} from '../actions/GetCustomerCreditBalanceDetails';
import { IErrorActionData } from '../../../models/Error';
import { IGetCustomerCreditBalanceDetails, GetCustomerCreditBalanceDetails } from '../models/GetCustomerCreditBalanceDetails';

export interface IGetCustomerCreditBalanceDetailsState {
  getCustomerCreditBalanceDetails: GetCustomerCreditBalanceDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetCustomerCreditBalanceDetailsState = {
  getCustomerCreditBalanceDetails: new GetCustomerCreditBalanceDetails({} as IGetCustomerCreditBalanceDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetCustomerCreditBalanceDetailsState;

const GetCustomerCreditBalanceDetailsReducer: Reducer<IGetCustomerCreditBalanceDetailsState, GetCustomerCreditBalanceDetailsActions> = (
  state = initialGetCustomerCreditBalanceDetailsState,
  action: GetCustomerCreditBalanceDetailsActions,
): IGetCustomerCreditBalanceDetailsState => {
  switch (action.type) {
    case GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS_SUCCESS:
      return {
        ...state,
        getCustomerCreditBalanceDetails: new GetCustomerCreditBalanceDetails(action.payload.getCustomerCreditBalanceDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCustomerCreditBalanceDetailsActionTypes.GET_CUSTOMER_CREDIT_BALANCE_DETAILS_RESET:
      return initialGetCustomerCreditBalanceDetailsState;

    default:
      return state;
  }
};

export default GetCustomerCreditBalanceDetailsReducer;
