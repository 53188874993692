import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { UpdateProjectDetailsActions, UpdateProjectDetailsActionTypes } from '../actions/UpdateProjectDetails';

export interface IUpdateProjectDetailsState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

export const initialUpdateProjectDetails = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
} as IUpdateProjectDetailsState;

const UpdateProjectDetailsReducer: Reducer<IUpdateProjectDetailsState, UpdateProjectDetailsActions> = (
  state = initialUpdateProjectDetails,
  action: UpdateProjectDetailsActions,
): IUpdateProjectDetailsState => {
  switch (action.type) {
    case UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS:
      return {
        ...state,
        isSuccess: false,
        isProcessing: true,
        error: undefined,
      };

    case UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isProcessing: false,
        error: undefined,
      };

    case UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isProcessing: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case UpdateProjectDetailsActionTypes.UPDATE_PROJECT_DETAILS_RESET:
      return initialUpdateProjectDetails;

    default:
      return state;
  }
};

export default UpdateProjectDetailsReducer;
