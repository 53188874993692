import { Reducer } from 'redux';

import { IDealDesign } from '../models/GetDealDesignList';
import { IErrorActionData } from '../../../models/Error';
import { GetDealDesignListActionTypes, GetDealDesignListActions } from '../actions/GetDealDesignList';

export interface IDealDesignListState {
  dealDesignList: Array<IDealDesign>;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDealDesignListState = {
  dealDesignList: [],
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDealDesignListState;

const GetDealDesignListReducer: Reducer<IDealDesignListState, GetDealDesignListActions> = (
  state = initialDealDesignListState,
  action: GetDealDesignListActions,
): IDealDesignListState => {
  switch (action.type) {
    case GetDealDesignListActionTypes.GET_DEAL_DESIGN_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };
    case GetDealDesignListActionTypes.GET_DEAL_DESIGN_LIST_SUCCESS:
      return {
        dealDesignList: action.payload.dealDesignList ?? [],
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    case GetDealDesignListActionTypes.GET_DEAL_DESIGN_LIST_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };
    case GetDealDesignListActionTypes.RESET_GET_DEAL_DESIGN_LIST:
      return initialDealDesignListState;
    default:
      return state;
  }
};

export default GetDealDesignListReducer;
