/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getSavedCartItemListSuccess,
  getSavedCartItemListFailure,
  GetSavedCartItemListAction,
  GetSavedCartItemListActionTypes,
} from '../actions/GetSavedCartItemList';
import getSavedCartItemListService from '../services/GetSavedCartItemList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetSavedCartItemListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST)),
  mergeMap((action: GetSavedCartItemListAction) => from(
    getSavedCartItemListService(
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getSavedCartItemListSuccess({
      savedCartItemList: response.data.getSavedCartItemList.data.cartList || [],
      pageInfo: response.data.getSavedCartItemList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getSavedCartItemListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetSavedCartItemListEpic;
