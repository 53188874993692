import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GENERATE_INVOICE = gql`
  query generateInvoice($path: String!, $payload: any) {
    generateInvoice (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
      }
  }
`;

const generateInvoiceService = (orderReferenceId: string) => client.query({
  query: GENERATE_INVOICE,
  variables: {
    path: URL.generateInvoice,
    payload: { orderRefId: orderReferenceId },
  },
});

export default generateInvoiceService;
