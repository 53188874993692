import { Reducer } from 'redux';

import { IOrderCustomerDetails, OrderCustomerDetails } from '../models/OrderCustomerDetails';
import { GetOrderCustomerDetailsActionTypes, GetOrderCustomerDetailsActions } from '../actions/GetOrderCustomerDetails';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IGetOrderCustomerDetailsState {
  orderCustomerDetails: OrderCustomerDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialOrderCustomerDetailsState = {
  orderCustomerDetails: new OrderCustomerDetails({} as IOrderCustomerDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetOrderCustomerDetailsState;

const GetOrderCustomerDetailsReducer: Reducer<IGetOrderCustomerDetailsState, GetOrderCustomerDetailsActions> = (
  state = initialOrderCustomerDetailsState,
  action: GetOrderCustomerDetailsActions,
): IGetOrderCustomerDetailsState => {
  switch (action.type) {
    case GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS_SUCCESS: {
      return {
        ...state,
        orderCustomerDetails: new OrderCustomerDetails(action.payload.orderCustomerDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetOrderCustomerDetailsActionTypes.GET_ORDER_CUSTOMER_DETAILS_RESET:
      return initialOrderCustomerDetailsState;

    default:
      return state;
  }
};

export default GetOrderCustomerDetailsReducer;
