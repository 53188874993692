import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IConfirmRefundPayload } from '../actions/ConfirmRefund';

const CONFIRM_REFUND = gql`
  query confirmRefund($path: String!, $data: any) {
    confirmRefund(input: $data, path: $path) 
      @rest(method: "PUT", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const confirmRefundService = (refundAliasId: string, payload: IConfirmRefundPayload) => {
  const url = URL.confirmRefund.replace('{refundAliasId}', refundAliasId);
  return client.query({ query: CONFIRM_REFUND, variables: { path: url, data: payload } });
};

export default confirmRefundService;
