import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  updateCustomerDetailsSuccess,
  updateCustomerDetailsFailure,
  UpdateCustomerDetailsAction,
  UpdateCustomerDetailsActionTypes,
} from '../actions/UpdateCustomerDetails';
import updateCustomerDetailsService from '../services/UpdateCustomerDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const UpdateCustomerDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS)),
  mergeMap((action: UpdateCustomerDetailsAction) => from(
    updateCustomerDetailsService(action.payload.customerId, action.payload.data),
  ).pipe(
    map((response) => updateCustomerDetailsSuccess()),
    catchError((error: IErrorActionData) => of(
      updateCustomerDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default UpdateCustomerDetailsEpic;
