import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum CancelCartActionTypes {
  CANCEL_CART_ACTION = 'CANCEL_CART_ACTION',
  CANCEL_CART_ACTION_SUCCESS = 'CANCEL_CART_ACTION_SUCCESS',
  CANCEL_CART_ACTION_FAILURE = 'CANCEL_CART_ACTION_FAILURE',
  CANCEL_CART_ACTION_RESET = 'CANCEL_CART_ACTION_RESET',
}

export interface ICancelCartActionData {
  cartAliasId: string;
}

export const cancelCart = createAction(CancelCartActionTypes.CANCEL_CART_ACTION)<ICancelCartActionData>();
export const cancelCartSuccess = createAction(CancelCartActionTypes.CANCEL_CART_ACTION_SUCCESS)();
export const cancelCartFailure = createAction(CancelCartActionTypes.CANCEL_CART_ACTION_FAILURE)<IErrorActionData>();
export const cancelCartReset = createAction(CancelCartActionTypes.CANCEL_CART_ACTION_RESET)();

export type CancelCartAction = ActionType<typeof cancelCart>;
type CancelCartSuccessAction = ActionType<typeof cancelCartSuccess>;
type CancelCartFailureAction = ActionType<typeof cancelCartFailure>;
type CancelCartResetAction = ActionType<typeof cancelCartReset>;

export type CancelCartActions =
  | CancelCartAction
  | CancelCartSuccessAction
  | CancelCartFailureAction
  | CancelCartResetAction;
