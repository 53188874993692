import { Reducer } from 'redux';

import { FetchRemoteConfigActions, FetchRemoteConfigActionTypes } from '../actions/fetchRemoteConfig';
import IConfigData from '../models/remoteConfig';

export interface IRemoteConfigState {
  configData: IConfigData;
  isLoading: boolean;
  isSuccess: boolean;
  error?: any;
}

const initialRemoteConfigState: IRemoteConfigState = {
  configData: {} as IConfigData,
  isLoading: false,
  isSuccess: false,
  error: undefined,
};

const FetchRemoteConfigReducer: Reducer<IRemoteConfigState, FetchRemoteConfigActions> = (
  state = initialRemoteConfigState,
  action: FetchRemoteConfigActions,
): IRemoteConfigState => {
  switch (action.type) {
    case FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: undefined,
      };
    case FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG_SUCCESS:
      return {
        configData: action.payload,
        isLoading: false,
        isSuccess: true,
        error: undefined,
      };
    case FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        configData: {},
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
        },
      };
    case FetchRemoteConfigActionTypes.GET_REMOTE_CONFIG_RESET:
      return initialRemoteConfigState;
    default:
      return state;
  }
};

export default FetchRemoteConfigReducer;
