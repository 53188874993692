/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  createInfluencerSuccess,
  createInfluencerFailed,
  CreateInfluencerAction,
  CreateInfluencerActionTypes,
} from '../actions/CreateInfluencer';
import { IErrorActionData } from '../../../models/Error';
import createInfluencerService from '../services/CreateInfluencer';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CreateInfluencerEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CreateInfluencerActionTypes.CREATE_INFLUENCER)),
  mergeMap((action: CreateInfluencerAction) => from(
    createInfluencerService(
      action.payload.name,
      action.payload.phoneNumber,
      action.payload.type,
      action.payload.languagePreference,
    ),
  ).pipe(
    map((response) => createInfluencerSuccess({
      newInfluencerData: response.data.createInfluencer.data,
    })),
    catchError((error: IErrorActionData) => of(
      createInfluencerFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CreateInfluencerEpic;
