/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getLedgerTxnInvoiceUrlSuccess,
  getLedgerTxnInvoiceUrlFailure,
  GetLedgerTxnInvoiceUrlAction,
  GetLedgerTxnInvoiceUrlActionTypes,
} from '../actions/GetLedgerTxnInvoiceUrl';
import getLedgerTxnInvoiceUrlService from '../services/GetLedgerTxnInvoiceUrl';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetLedgerTxnInvoiceUrlEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetLedgerTxnInvoiceUrlActionTypes.GET_LEDGER_TXN_INVOICE_URL)),
  mergeMap((action: GetLedgerTxnInvoiceUrlAction) => from(
    getLedgerTxnInvoiceUrlService(action.payload.invoiceAliasId),
  ).pipe(
    map((response: any) => getLedgerTxnInvoiceUrlSuccess({
      invoiceAliasId: action.payload.invoiceAliasId,
      invoiceSerialNumber: response.data.getLedgerTxnInvoiceUrl.data.invoiceSerialNumber,
      invoiceType: response.data.getLedgerTxnInvoiceUrl.data.invoiceType,
      preSignedUrl: response.data.getLedgerTxnInvoiceUrl.data.preSignedUrl || '',
    })),
    catchError((error: IErrorActionData) => of(
      getLedgerTxnInvoiceUrlFailure({
        invoiceAliasId: action.payload.invoiceAliasId,
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetLedgerTxnInvoiceUrlEpic;
