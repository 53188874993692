import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_STORE_DETAILS = gql`
  query getStoreDetails($path: String!) {
    getStoreDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getStoreDetailsService = (storeAliasId: string) => client.query(
  {
    query: GET_STORE_DETAILS,
    variables: { path: URL.getStoreDetails.replace('{storeAliasId}', storeAliasId) },
  },
);

export default getStoreDetailsService;
