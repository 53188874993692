import { Reducer } from 'redux';

import { GetCustomerSavedCartListCountActions, GetCustomerSavedCartListCountActionTypes } from '../actions/GetCustomerSavedCartListCount';
import { IErrorActionData } from '../../../models/Error';

export interface ICustomerSavedCartListCountState {
  customerSavedCartListCount: number;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCustomerSavedCartListCountState = {
  customerSavedCartListCount: 0,
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICustomerSavedCartListCountState;

const GetCustomerSavedCartListCountReducer: Reducer<ICustomerSavedCartListCountState, GetCustomerSavedCartListCountActions> = (
  state = initialCustomerSavedCartListCountState,
  action: GetCustomerSavedCartListCountActions,
): ICustomerSavedCartListCountState => {
  switch (action.type) {
    case GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT_SUCCESS:
      return {
        ...state,
        customerSavedCartListCount: action.payload.count,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCustomerSavedCartListCountActionTypes.GET_CUSTOMER_SAVED_CART_LIST_COUNT_RESET:
      return initialCustomerSavedCartListCountState;

    default:
      return state;
  }
};

export default GetCustomerSavedCartListCountReducer;
