import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_CASH_CLOSURE_LIST = gql`
  query getCashClosureList($path: String!) {
    getCashClosureList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getCashClosureListService = (storeAliasId: string, filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getCashClosureList.replace('{storeAliasId}', storeAliasId), filter, undefined, page);
  return client.query({ query: GET_CASH_CLOSURE_LIST, variables: { path: url } });
};

export default getCashClosureListService;
