import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const SAVE_CART = gql`
  query saveCart($path: String!, $data: any) {
    saveCart(input: $data, path: $path) 
      @rest(method: "PUT", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const saveCartService = (cartState: string, cartAliasId: string) => {
  const url = URL.saveCart.replace('{cartAliasId}', cartAliasId);
  return client.query({ query: SAVE_CART, variables: { data: { cartState }, path: url } });
};

export default saveCartService;
