import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IUpdateInfluencerPayload } from '../models/UpdateInfluencer';

const UPDATE_INFLUENCER = gql`
query updateInfluencer($path: String!, $payload: any) {
  updateInfluencer(path: $path, payload: $payload) 
    @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "pheonix") {
      data
    }
  }
`;

const updateInfluencerService = (influencerAliasId: string, payload: IUpdateInfluencerPayload) => {
  const url = URL.updateInfluencer.replace('{influencerAliasId}', influencerAliasId);
  return client.query({ query: UPDATE_INFLUENCER, variables: { path: url, payload } });
};

export default updateInfluencerService;
