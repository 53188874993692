/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCashTransactionListSuccess,
  getCashTransactionListFailure,
  GetCashTransactionListAction,
  GetCashTransactionListActionTypes,
} from '../actions/GetCashTransactionList';
import getCashTransactionListService from '../services/GetCashTransactionList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCashTransactionListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST)),
  mergeMap((action: GetCashTransactionListAction) => from(
    getCashTransactionListService(
      action.payload.storeAliasId,
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response) => getCashTransactionListSuccess({
      cashTransactionsList: response.data.getCashTransactionList.data.cashTransactions || [],
      pageInfo: response.data.getCashTransactionList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getCashTransactionListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCashTransactionListEpic;
