import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum EditSKUDeliveryDateActionTypes {
  EDIT_SKU_DELIVERY_DATE = 'EDIT_SKU_DELIVERY_DATE',
  EDIT_SKU_DELIVERY_DATE_SUCCESS = 'EDIT_SKU_DELIVERY_DATE_SUCCESS',
  EDIT_SKU_DELIVERY_DATE_FAILED = 'EDIT_SKU_DELIVERY_DATE_FAILED',
  EDIT_SKU_DELIVERY_DATE_RESET = 'EDIT_SKU_DELIVERY_DATE_RESET',
}

export interface IEditSKUDeliveryDateActionData {
  orderReferenceId: string;
  skuId: string;
  preferredDate: number;
}

export const editSKUDeliveryDate = createAction(EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE)<IEditSKUDeliveryDateActionData>();
export const editSKUDeliveryDateSuccess = createAction(EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE_SUCCESS)();
export const editSKUDeliveryDateFailure = createAction(EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE_FAILED)<IErrorActionData>();
export const editSKUDeliveryDateReset = createAction(EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE_RESET)();

export type EditSKUDeliveryDateAction = ActionType<typeof editSKUDeliveryDate>;
type EditSKUDeliveryDateSuccessAction = ActionType<typeof editSKUDeliveryDateSuccess>;
type EditSKUDeliveryDateFailureAction = ActionType<typeof editSKUDeliveryDateFailure>;
type EditSKUDeliveryDateResetAction = ActionType<typeof editSKUDeliveryDateReset>;

export type EditSKUDeliveryDateActions = EditSKUDeliveryDateAction
| EditSKUDeliveryDateSuccessAction
| EditSKUDeliveryDateFailureAction
| EditSKUDeliveryDateResetAction;
