import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { CancelSKUActions, CancelSKUActionTypes } from '../actions/CancelSKU';

export interface ICancelSKUState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialCancelSKUState: ICancelSKUState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const CancelSKUReducer: Reducer<ICancelSKUState, CancelSKUActions> = (
  state = initialCancelSKUState,
  action: CancelSKUActions,
): ICancelSKUState => {
  switch (action.type) {
    case CancelSKUActionTypes.CANCEL_SKU_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CancelSKUActionTypes.CANCEL_SKU_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CancelSKUActionTypes.CANCEL_SKU_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CancelSKUActionTypes.CANCEL_SKU_ACTION_RESET:
      return initialCancelSKUState;

    default:
      return state;
  }
};

export default CancelSKUReducer;
