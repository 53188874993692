import { IErrorActionData } from '../../../models/Error';

export interface IShipmentInvoiceUrlData {
  presignedInvoiceUrl?: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class ShipmentInvoiceUrlData {
  shipmentInvoiceUrlData: Record<string, IShipmentInvoiceUrlData> = {};
  constructor(shipmentInvoiceUrlData: Record<string, IShipmentInvoiceUrlData>) {
    this.shipmentInvoiceUrlData = shipmentInvoiceUrlData;
  }
}

export const addPresignedUrlToExisting = (existingPresignedURL: Record<string, IShipmentInvoiceUrlData>,
  invoiceAliasId: string, shipmentInvoiceUrlData: IShipmentInvoiceUrlData) => (
  { ...existingPresignedURL, [invoiceAliasId]: shipmentInvoiceUrlData }
);
