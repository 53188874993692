import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { UpdateCustomerDetailsActions, UpdateCustomerDetailsActionTypes } from '../actions/UpdateCustomerDetails';

export interface IUpdateCustomerDetailsState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

export const initialUpdateCustomerDetails = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
} as IUpdateCustomerDetailsState;

const UpdateCustomerDetailsReducer: Reducer<IUpdateCustomerDetailsState, UpdateCustomerDetailsActions> = (
  state = initialUpdateCustomerDetails,
  action: UpdateCustomerDetailsActions,
): IUpdateCustomerDetailsState => {
  switch (action.type) {
    case UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS:
      return {
        ...state,
        isSuccess: false,
        isProcessing: true,
        error: undefined,
      };

    case UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isProcessing: false,
        error: undefined,
      };

    case UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isProcessing: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case UpdateCustomerDetailsActionTypes.UPDATE_CUSTOMER_DETAILS_RESET:
      return initialUpdateCustomerDetails;

    default:
      return state;
  }
};

export default UpdateCustomerDetailsReducer;
