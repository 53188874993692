import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_LEDGER_TXN_INVOICE_URL = gql`
  query getLedgerTxnInvoiceUrl($path: String!) {
    getLedgerTxnInvoiceUrl @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getLedgerTxnInvoiceUrlService = (invoiceAliasId: string) => {
  const url = URL.getLedgerTxnInvoiceUrl.replace('{invoiceAliasId}', `INV${invoiceAliasId}`);
  return client.query({ query: GET_LEDGER_TXN_INVOICE_URL, variables: { path: url } });
};

export default getLedgerTxnInvoiceUrlService;
