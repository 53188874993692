/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  startProcessingOrderSuccess,
  startProcessingOrderFailure,
  StartProcessingOrderAction,
  StartProcessingOrderActionTypes,
} from '../actions/StartProcessingOrder';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import startProcessingOrderService from '../services/StartProcessingOrder';

const StartProcessingOrderEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(StartProcessingOrderActionTypes.START_PROCESSING_ORDER)),
  mergeMap((action: StartProcessingOrderAction) => from(
    startProcessingOrderService(action.payload.orderReferenceId),
  ).pipe(
    map((response) => startProcessingOrderSuccess()),
    catchError((error: IErrorActionData) => of(
      startProcessingOrderFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default StartProcessingOrderEpic;
