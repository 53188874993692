/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getOrderPaymentDetailsSuccess,
  getOrderPaymentDetailsFailure,
  GetOrderPaymentDetailsAction,
  GetOrderPaymentDetailsActionTypes,
} from '../actions/GetOrderPaymentDetails';
import getOrderPaymentDetailsService from '../services/GetOrderPaymentDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetOrderPaymentDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS)),
  mergeMap((action: GetOrderPaymentDetailsAction) => from(
    getOrderPaymentDetailsService(action.payload.orderReferenceId),
  ).pipe(
    map((response) => getOrderPaymentDetailsSuccess({
      orderPaymentDetails: response.data.getOrderPaymentDetails.data || {},
    })),
    catchError((error: IErrorActionData) => of(
      getOrderPaymentDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetOrderPaymentDetailsEpic;
