import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { ICashClosureListItem } from '../models/CashClosureList';

export enum GetCashClosureListActionTypes {
  GET_CASH_CLOSURE_LIST = 'GET_CASH_CLOSURE_LIST',
  GET_CASH_CLOSURE_LIST_SUCCESS = 'GET_CASH_CLOSURE_LIST_SUCCESS',
  GET_CASH_CLOSURE_LIST_FAILED = 'GET_CASH_CLOSURE_LIST_FAILED',
  GET_CASH_CLOSURE_LIST_RESET = 'GET_CASH_CLOSURE_LIST_RESET',
}

export enum AvailableFilterProperty {
  cmsSlipNumber = 'cmsSlipNumber',
  closureId = 'closureId',
  depositStartDate = 'depositStartDate',
  depositEndDate = 'depositEndDate',
  closingStartDate = 'closingStartDate',
  closingEndDate = 'closingEndDate',
}

export interface IGetCashClosureListActionData {
  storeAliasId: string;
  filter?: IFilter[];
  page?: IPage;
}
export interface IGetCashClosureListSuccessActionData {
  cashClosureList: Array<ICashClosureListItem>;
  isOpenClosureAvailable: boolean;
  pageInfo: IPageInfo;
}

export const getCashClosureList = createAction(GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST)<IGetCashClosureListActionData>();
export const getCashClosureListSuccess = createAction(GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST_SUCCESS)
<IGetCashClosureListSuccessActionData>();
export const getCashClosureListFailure = createAction(GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST_FAILED)
<IErrorActionData>();
export const getCashClosureListReset = createAction(GetCashClosureListActionTypes.GET_CASH_CLOSURE_LIST_RESET)();

export type GetCashClosureListAction = ActionType<typeof getCashClosureList>;
type GetCashClosureListSuccessAction = ActionType<typeof getCashClosureListSuccess>;
type GetCashClosureListFailedAction = ActionType<typeof getCashClosureListFailure>;
type GetCashClosureListResetAction = ActionType<typeof getCashClosureListReset>;

export type GetCashClosureListActions =
  | GetCashClosureListAction
  | GetCashClosureListSuccessAction
  | GetCashClosureListFailedAction
  | GetCashClosureListResetAction;
