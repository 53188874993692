import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum DispatchSupplyOrderActionTypes {
  DISPATCH_SUPPLY_ORDER_ACTION = 'DISPATCH_SUPPLY_ORDER_ACTION',
  DISPATCH_SUPPLY_ORDER_ACTION_SUCCESS = 'DISPATCH_SUPPLY_ORDER_ACTION_SUCCESS',
  DISPATCH_SUPPLY_ORDER_ACTION_FAILURE = 'DISPATCH_SUPPLY_ORDER_ACTION_FAILURE',
  DISPATCH_SUPPLY_ORDER_ACTION_RESET = 'DISPATCH_SUPPLY_ORDER_ACTION_RESET',
}

export interface IDispatchSupplyOrderActionData {
  supplyOrderId: number;
}

export const dispatchSupplyOrder = createAction(DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION)
<IDispatchSupplyOrderActionData>();
export const dispatchSupplyOrderSuccess = createAction(DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION_SUCCESS)();
export const dispatchSupplyOrderFailure = createAction(DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION_FAILURE)
<IErrorActionData>();
export const dispatchSupplyOrderReset = createAction(DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION_RESET)();

export type DispatchSupplyOrderAction = ActionType<typeof dispatchSupplyOrder>;
type DispatchSupplyOrderSuccessAction = ActionType<typeof dispatchSupplyOrderSuccess>;
type DispatchSupplyOrderFailureAction = ActionType<typeof dispatchSupplyOrderFailure>;
type DispatchSupplyOrderResetAction = ActionType<typeof dispatchSupplyOrderReset>;

export type DispatchSupplyOrderActions =
  | DispatchSupplyOrderAction
  | DispatchSupplyOrderSuccessAction
  | DispatchSupplyOrderFailureAction
  | DispatchSupplyOrderResetAction;
