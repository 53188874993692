import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_CUSTOMER_DETAILS = gql`
  query getCustomerDetails($path: String!) {
    customerDetails @rest(method: "GET", path: $path, endpoint: "photon") {
      data
    }
  }
`;

// TODO add url and query at integration.
const getCustomerDetailsService = (customerAliasId: string) => Promise.resolve({
  data: {
    customerAliasId,
  },
});

export default getCustomerDetailsService;
