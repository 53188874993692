import { Reducer } from 'redux';

import { IReturnDetails, ReturnDetails } from '../models/ReturnDetails';
import { GetReturnDetailsActionTypes, GetReturnDetailsActions } from '../actions/GetReturnDetails';
import { IErrorActionData } from '../../../models/Error';

export interface IGetReturnDetailsState {
  returnDetails: ReturnDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialReturnDetailsState = {
  returnDetails: new ReturnDetails({} as IReturnDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetReturnDetailsState;

const GetReturnDetailsReducer: Reducer<IGetReturnDetailsState, GetReturnDetailsActions> = (
  state = initialReturnDetailsState,
  action: GetReturnDetailsActions,
): IGetReturnDetailsState => {
  switch (action.type) {
    case GetReturnDetailsActionTypes.GET_RETURN_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetReturnDetailsActionTypes.GET_RETURN_DETAILS_SUCCESS: {
      return {
        ...state,
        returnDetails: new ReturnDetails(action.payload.returnDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetReturnDetailsActionTypes.GET_RETURN_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetReturnDetailsActionTypes.GET_RETURN_DETAILS_RESET:
      return initialReturnDetailsState;

    default:
      return state;
  }
};

export default GetReturnDetailsReducer;
