import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { UpdateInfluencerActions, UpdateInfluencerActionTypes } from '../actions/UpdateInfluencer';

export interface IUpdateInfluencerState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

export const initialUpdateInfluencer = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
} as IUpdateInfluencerState;

const UpdateInfluencerReducer: Reducer<IUpdateInfluencerState, UpdateInfluencerActions> = (
  state = initialUpdateInfluencer,
  action: UpdateInfluencerActions,
): IUpdateInfluencerState => {
  switch (action.type) {
    case UpdateInfluencerActionTypes.UPDATE_INFLUENCER:
      return {
        ...state,
        isSuccess: false,
        isProcessing: true,
        error: undefined,
      };

    case UpdateInfluencerActionTypes.UPDATE_INFLUENCER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isProcessing: false,
        error: undefined,
      };

    case UpdateInfluencerActionTypes.UPDATE_INFLUENCER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isProcessing: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case UpdateInfluencerActionTypes.UPDATE_INFLUENCER_RESET:
      return initialUpdateInfluencer;

    default:
      return state;
  }
};

export default UpdateInfluencerReducer;
