import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum DownloadLedgerAccountStatementActionTypes{
  DOWNLOAD_LEDGER_ACCOUNT_STATEMENT = 'DOWNLOAD_LEDGER_ACCOUNT_STATEMENT',
  DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_SUCCESS = 'DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_SUCCESS',
  DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_FAILED = 'DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_FAILED',
  DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_RESET = 'DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_RESET',
}

export enum AvailableFilterProperty {
  transactionStartDate = 'transactionStartDate',
  transactionEndDate = 'transactionEndDate',
}

export interface IDownloadLedgerAccountStatementPayload {
  transactionStartDate: number,
  transactionEndDate: number,
}

export interface IDownloadLedgerAccountStatementActionData {
  customerAliasId: string;
  filter: IFilter[];
}

export interface IDownloadLedgerAccountStatementSuccessActionData {
  requestId: string;
}

export const downloadLedgerAccountStatement = createAction(
  DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT,
)<IDownloadLedgerAccountStatementActionData>();
export const downloadLedgerAccountStatementSuccess = createAction(
  DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_SUCCESS,
)<IDownloadLedgerAccountStatementSuccessActionData>();
export const downloadLedgerAccountStatementFailed = createAction(
  DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_FAILED,
)<IErrorActionData>();
export const downloadLedgerAccountStatementReset = createAction(
  DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT_RESET,
)();

export type DownloadLedgerAccountStatementAction = ActionType<typeof downloadLedgerAccountStatement>;
export type DownloadLedgerAccountStatementSuccessAction = ActionType<typeof downloadLedgerAccountStatementSuccess>;
export type DownloadLedgerAccountStatementFailedAction = ActionType<typeof downloadLedgerAccountStatementFailed>;
export type DownloadLedgerAccountStatementResetAction = ActionType<typeof downloadLedgerAccountStatementReset>;

export type DownloadLedgerAccountStatementActions =
  | DownloadLedgerAccountStatementAction
  | DownloadLedgerAccountStatementSuccessAction
  | DownloadLedgerAccountStatementFailedAction
  | DownloadLedgerAccountStatementResetAction;
