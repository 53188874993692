import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IUpdateAddressPayload } from '../models/UpdateAddress';
import URL from '../../../constants/ApiEndPoints';

const UPDATE_ADDRESS = gql`
  query updateAddress($path: String!, $data: any) {
    updateAddress(input: $data, path: $path) 
      @rest(method: "PUT", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const updateAddressService = (customerId: string, addressId: string, payload: IUpdateAddressPayload) => {
  const url = URL.updateAddress.replace('{customerId}', customerId).replace('{addressId}', addressId);
  return client.query({ query: UPDATE_ADDRESS, variables: { data: payload, path: url } });
};

export default updateAddressService;
