import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ICreateProjectActionData } from '../actions/CreateProject';

const CREATE_PROJECT = gql`
  query createProject($path: String!, $payload: any) {
    createProject (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "photon") {
        data
      }
  }
`;

const createProjectService = (payload: ICreateProjectActionData) => client.query(
  {
    query: CREATE_PROJECT,
    variables: {
      path: URL.createProject,
      payload,
    },
  },
);

export default createProjectService;
