import { ReturnStatus, ShipmentStatus } from '../../../constants/Strings';

export interface IShipmentListItem {
  shipmentAliasId : string,
  shipmentCode: string,
  status: ShipmentStatus,
  returnStatus: ReturnStatus,
  isReturnApplicable: boolean;
}

export class ShipmentList {
  shipmentList = [] as Array<IShipmentListItem>;
  constructor(shipmentDetails: Array<IShipmentListItem>) {
    this.shipmentList = shipmentDetails;
  }
}
