import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IOrderDetails } from '../models/OrderDetails';

export enum GetOrderDetailsActionTypes {
  GET_ORDER_DETAILS = 'GET_ORDER_DETAILS',
  GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS',
  GET_ORDER_DETAILS_FAILED = 'GET_ORDER_DETAILS_FAILED',
  GET_ORDER_DETAILS_RESET = 'GET_ORDER_DETAILS_RESET',
}

export interface IGetOrderDetailsActionData {
  orderReferenceId: string;
}

export interface IGetOrderDetailsSuccessActionData {
  orderDetails: IOrderDetails;
}

export const getOrderDetails = createAction(GetOrderDetailsActionTypes.GET_ORDER_DETAILS)<IGetOrderDetailsActionData>();
export const getOrderDetailsSuccess = createAction(GetOrderDetailsActionTypes.GET_ORDER_DETAILS_SUCCESS)
<IGetOrderDetailsSuccessActionData>();
export const getOrderDetailsFailure = createAction(GetOrderDetailsActionTypes.GET_ORDER_DETAILS_FAILED)<IErrorActionData>();
export const getOrderDetailsReset = createAction(GetOrderDetailsActionTypes.GET_ORDER_DETAILS_RESET)();

export type GetOrderDetailsAction = ActionType<typeof getOrderDetails>;
type GetOrderDetailsSuccessAction = ActionType<typeof getOrderDetailsSuccess>;
type GetOrderDetailsFailureAction = ActionType<typeof getOrderDetailsFailure>;
type GetOrderDetailsResetAction = ActionType<typeof getOrderDetailsReset>;

export type GetOrderDetailsActions = GetOrderDetailsAction
| GetOrderDetailsSuccessAction
| GetOrderDetailsFailureAction
| GetOrderDetailsResetAction;
