/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  cancelSKUSuccess,
  cancelSKUFailure,
  CancelSKUAction,
  CancelSKUActionTypes,
} from '../actions/CancelSKU';
import cancelSKUService from '../services/CancelSKU';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const CancelSKUEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(CancelSKUActionTypes.CANCEL_SKU_ACTION)),
  mergeMap((action: CancelSKUAction) => from(
    cancelSKUService(action.payload.supplyOrderAliasId, action.payload.payload),
  ).pipe(
    map(() => (cancelSKUSuccess())),
    catchError((error: any) => of(
      cancelSKUFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default CancelSKUEpic;
