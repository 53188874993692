import { createAction, ActionType } from 'typesafe-actions';
import { IApiAutoCompleteOptionItem } from '../../../models/Common';

import { IErrorActionData } from '../../../models/Error';
import { PageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';

export enum GetStoreEmployeeListActionTypes {
  GET_STORE_EMPLOYEE_LIST = 'GET_STORE_EMPLOYEE_LIST',
  GET_STORE_EMPLOYEE_LIST_SUCCESS = 'GET_STORE_EMPLOYEE_LIST_SUCCESS',
  GET_STORE_EMPLOYEE_LIST_FAILED = 'GET_STORE_EMPLOYEE_LIST_FAILED',
  GET_STORE_EMPLOYEE_LIST_RESET = 'GET_STORE_EMPLOYEE_LIST_RESET',
}

export interface IGetStoreEmployeeListActionData {
  storeAliasId: string;
  filter?: IFilter[];
  page?: IPage;
}

export enum AvailableFilterProperty {
  search = 'search',
  department = 'department',
}

export interface IGetStoreEmployeeListSuccessActionData {
  storeEmployeeList: IApiAutoCompleteOptionItem[];
  pageInfo: PageInfo;
}

export const getStoreEmployeeList = createAction(GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST)
<IGetStoreEmployeeListActionData>();
export const getStoreEmployeeListSuccess = createAction(GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST_SUCCESS)
<IGetStoreEmployeeListSuccessActionData>();
export const getStoreEmployeeListFailed = createAction(GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST_FAILED)
<IErrorActionData>();
export const getStoreEmployeeListReset = createAction(GetStoreEmployeeListActionTypes.GET_STORE_EMPLOYEE_LIST_RESET)();

export type GetStoreEmployeeListAction = ActionType<typeof getStoreEmployeeList>;
type GetStoreEmployeeListSuccessAction = ActionType<typeof getStoreEmployeeListSuccess>;
type GetStoreEmployeeListFailedAction = ActionType<typeof getStoreEmployeeListFailed>;
type GetStoreEmployeeListResetAction = ActionType<typeof getStoreEmployeeListReset>;

export type GetStoreEmployeeListActions =
  | GetStoreEmployeeListAction
  | GetStoreEmployeeListSuccessAction
  | GetStoreEmployeeListFailedAction
  | GetStoreEmployeeListResetAction;
