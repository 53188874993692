export interface IOrderPaymentInfo {
  paymentAliasId: string;
  mode: string;
  amount: number;
  createdAtTs: number;
  extraInfo: Record<string, string>;
  status: string;
}

export interface IPaymentInfo {
  amountAvailableForRealisation: number;
  balanceAmount: number;
  amountReceived: number;
  orderAliasId: string,
  pan: string; // NOT AVAILABLE
  awaitingPaymentConfirmation: IOrderPaymentInfo[];
  awaitingPaymentConfirmationAmount: number;
}

export interface IOrderPaymentDetails {
  paymentInfo: IPaymentInfo;
  paymentHistory: IOrderPaymentInfo[];
}

export class OrderPaymentDetails {
  orderPaymentDetails = {} as IOrderPaymentDetails;
  constructor(orderDetails: IOrderPaymentDetails) {
    this.orderPaymentDetails = orderDetails;
  }
}
