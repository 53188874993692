import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IAddCmsSlipPayload } from '../actions/AddCmsSlip';

const ADD_CMS_SLIP = gql`
  query addCmsSlip($path: String!, $payload: any) {
    addCmsSlip (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const addCmsSlipService = (closureAliasId: string, payload: IAddCmsSlipPayload) => {
  const url = URL.addCmsSlip.replace('{closureAliasId}', closureAliasId);
  return client.query({ query: ADD_CMS_SLIP, variables: { path: url, payload } });
};

export default addCmsSlipService;
