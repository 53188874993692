import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { INewOrderPayload } from '../models/NewOrder';

export enum CreateOrderActionTypes {
  CREATE_ORDER = 'CREATE_ORDER',
  CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED',
  CREATE_ORDER_RESET = 'CREATE_ORDER_RESET',
}

export interface ICreateOrderActionData {
  createOrderDetails: INewOrderPayload;
}

export interface ICreateOrderSuccessActionData {
  orderAliasId: string;
}

export const createOrder = createAction(CreateOrderActionTypes.CREATE_ORDER)<ICreateOrderActionData>();
export const createOrderSuccess = createAction(CreateOrderActionTypes.CREATE_ORDER_SUCCESS)<ICreateOrderSuccessActionData>();
export const createOrderFailure = createAction(CreateOrderActionTypes.CREATE_ORDER_FAILED)<IErrorActionData>();
export const createOrderReset = createAction(CreateOrderActionTypes.CREATE_ORDER_RESET)();

export type CreateOrderAction = ActionType<typeof createOrder>;
type CreateOrderSuccessAction = ActionType<typeof createOrderSuccess>;
type CreateOrderFailureAction = ActionType<typeof createOrderFailure>;
type CreateOrderResetAction = ActionType<typeof createOrderReset>;

export type CreateOrderActions = CreateOrderAction
| CreateOrderSuccessAction
| CreateOrderFailureAction
| CreateOrderResetAction;
