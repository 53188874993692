/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCustomerLedgerDetailsSuccess,
  getCustomerLedgerDetailsFailure,
  GetCustomerLedgerDetailsAction,
  GetCustomerLedgerDetailsActionTypes,
} from '../actions/GetCustomerLedgerDetails';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import getCustomerLedgerDetailsService from '../services/GetCustomerLedgerDetails';

const GetCustomerLedgerDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCustomerLedgerDetailsActionTypes.GET_CUSTOMER_LEDGER_DETAILS)),
  mergeMap((action: GetCustomerLedgerDetailsAction) => from(
    getCustomerLedgerDetailsService(action.payload.customerAliasId),
  ).pipe(
    map((response) => getCustomerLedgerDetailsSuccess({
      customerLedgerDetails: response.data.getCustomerLedgerDetails.data || {},
    })),
    catchError((error: IErrorActionData) => of(
      getCustomerLedgerDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCustomerLedgerDetailsEpic;
