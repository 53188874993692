import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Typography, Toolbar } from '@mui/material';

import LoggedInUserDetails from './LoggedInUserDetails';
import StoreList from './StoreList';
import useStyles from './styles';

const HeaderBar: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <Typography id="photonHeader-imPhoton" className={classes.title}>
          {t('appName')}
        </Typography>
        <StoreList />
        <LoggedInUserDetails />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
