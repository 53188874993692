/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError, filter, map, mergeMap,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import RootAction from '../../../config/store/RootAction';
import RootState from '../../../config/store/RootState';
import { IErrorActionData } from '../../../models/Error';
import {
  DownloadLedgerAccountStatementAction,
  DownloadLedgerAccountStatementActionTypes,
  downloadLedgerAccountStatementFailed,
  downloadLedgerAccountStatementSuccess,
} from '../actions/DownloadLedgerAccountStatement';
import downloadLedgerAccountStatementService from '../services/DownloadLedgerAccountStatement';

const DownloadLedgerAccountStatementEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(DownloadLedgerAccountStatementActionTypes.DOWNLOAD_LEDGER_ACCOUNT_STATEMENT)),
  mergeMap((action: DownloadLedgerAccountStatementAction) => from(
    downloadLedgerAccountStatementService(
      action.payload.customerAliasId,
      action.payload.filter,
    ),
  ).pipe(
    map((response) => downloadLedgerAccountStatementSuccess({ requestId: response.data.downloadLedgerAccountStatement.data.requestId })),
    catchError((error: IErrorActionData) => of(
      downloadLedgerAccountStatementFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);
export default DownloadLedgerAccountStatementEpic;
