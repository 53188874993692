import { Reducer } from 'redux';

import { CancelSkuList, ICancelSkuList } from '../models/GetCancelSkuList';
import { GetCancelSkuListActionTypes, GetCancelSkuListActions } from '../actions/GetCancelSkuList';
import { IErrorActionData } from '../../../models/Error';

export interface ICancelSkuListState {
  cancelSkuList: CancelSkuList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCancelSkuListState = {
  cancelSkuList: new CancelSkuList({} as ICancelSkuList),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICancelSkuListState;

const GetCancelSkuListReducer: Reducer<ICancelSkuListState, GetCancelSkuListActions> = (
  state = initialCancelSkuListState,
  action: GetCancelSkuListActions,
): ICancelSkuListState => {
  switch (action.type) {
    case GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST_SUCCESS: {
      return {
        ...state,
        cancelSkuList: new CancelSkuList(action.payload.cancelSkuListDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST_RESET:
      return initialCancelSkuListState;

    default:
      return state;
  }
};

export default GetCancelSkuListReducer;
