import { ChannelNames } from '../../../constants/Strings';

interface OrderInfo{
  numberOfItems: number,
  channelName: ChannelNames,
  orderAlias: string,
  orderReferenceId: string,
  state: string,
  orderDate: number;
}

interface CustomerInfo {
  contactNumber: string;
  firstName?: string;
  lastName?: string;
}

interface PaymentInfo {
  isPaymentReceived: boolean,
  awaitingPaymentConfirmation: boolean,
  balanceAmount: number,
  totalOrderValue: number,
}

export interface IOrderListItem {
  orderInfo: OrderInfo;
  customerInfo: CustomerInfo;
  paymentInfo: PaymentInfo;
}

export class OrderList {
  orderList = [] as Array<IOrderListItem>;
  constructor(orderList: Array<IOrderListItem>) {
    this.orderList = orderList;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<IOrderListItem>,
  payloadList: Array<IOrderListItem>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
