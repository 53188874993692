import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ICheckInventoryActionData } from '../actions/checkInventory';

const CHECK_INVENTORY = gql`
  query checkInventory($path: String!) {
    checkInventory @rest(method: "GET", path: $path, endpoint: "photon") {
      data
      pageInfo
    }
  }
`;

const checkInventoryService = (payload: ICheckInventoryActionData) => {
  let url = `${URL.checkInventory}?sku=${payload.skuId}`;
  if (payload.locationPincode) url = `${url}&pincode=${payload.locationPincode}`;
  if (payload.qty) url = `${url}&quantity=${payload.qty}`;
  return client.query({ query: CHECK_INVENTORY, variables: { path: url } });
};

export default checkInventoryService;
