/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  recordRefundSuccess,
  recordRefundFailure,
  RecordRefundAction,
  RecordRefundActionTypes,
} from '../actions/RecordRefund';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import recordRefundService from '../services/RecordRefund';

const RecordRefundEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(RecordRefundActionTypes.RECORD_REFUND)),
  mergeMap((action: RecordRefundAction) => from(
    recordRefundService(action.payload.recordRefundDetails),
  ).pipe(
    map((response) => recordRefundSuccess()),
    catchError((error: IErrorActionData) => of(
      recordRefundFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default RecordRefundEpic;
