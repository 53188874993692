import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IPage } from '../../../utils/sortFilterFramework';
import { IOpenCashTransactionItem } from '../models/OpenCashTransactions';

export enum GetOpenCashTransactionsActionTypes {
  GET_OPEN_CASH_TRANSACTIONS = 'GET_OPEN_CASH_TRANSACTIONS',
  GET_OPEN_CASH_TRANSACTIONS_SUCCESS = 'GET_OPEN_CASH_TRANSACTIONS_SUCCESS',
  GET_OPEN_CASH_TRANSACTIONS_FAILED = 'GET_OPEN_CASH_TRANSACTIONS_FAILED',
  GET_OPEN_CASH_TRANSACTIONS_RESET = 'GET_OPEN_CASH_TRANSACTIONS_RESET',
}

export interface IGetOpenCashTransactionsActionData {
  storeAliasId: string;
  page?: IPage;
}

export interface IGetOpenCashTransactionsSuccessActionData {
  openCashTransactions: Array<IOpenCashTransactionItem>;
  pageInfo: IPageInfo;
}

export const getOpenCashTransactions = createAction(GetOpenCashTransactionsActionTypes.GET_OPEN_CASH_TRANSACTIONS)
<IGetOpenCashTransactionsActionData>();
export const getOpenCashTransactionsSuccess = createAction(GetOpenCashTransactionsActionTypes.GET_OPEN_CASH_TRANSACTIONS_SUCCESS)
<IGetOpenCashTransactionsSuccessActionData>();
export const getOpenCashTransactionsFailure = createAction(GetOpenCashTransactionsActionTypes.GET_OPEN_CASH_TRANSACTIONS_FAILED)
<IErrorActionData>();
export const getOpenCashTransactionsReset = createAction(GetOpenCashTransactionsActionTypes.GET_OPEN_CASH_TRANSACTIONS_RESET)();

export type GetOpenCashTransactionsAction = ActionType<typeof getOpenCashTransactions>;
type GetOpenCashTransactionsSuccessAction = ActionType<typeof getOpenCashTransactionsSuccess>;
type GetOpenCashTransactionsFailureAction = ActionType<typeof getOpenCashTransactionsFailure>;
type GetOpenCashTransactionsResetAction = ActionType<typeof getOpenCashTransactionsReset>;

export type GetOpenCashTransactionsActions = GetOpenCashTransactionsAction
| GetOpenCashTransactionsSuccessAction
| GetOpenCashTransactionsFailureAction
| GetOpenCashTransactionsResetAction;
