import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IInventoryDetails } from '../models/checkInventory';
import { IPageInfo } from '../../../models/PageInfo';

export enum CheckInventoryActionTypes {
  CHECK_INVENTORY = 'CHECK_INVENTORY',
  CHECK_INVENTORY_SUCCESS = 'CHECK_INVENTORY_SUCCESS',
  CHECK_INVENTORY_FAILED = 'CHECK_INVENTORY_FAILED',
  CHECK_INVENTORY_RESET = 'CHECK_INVENTORY_RESET',
}

export interface ICheckInventoryActionData {
  skuId: string,
  locationPincode?: string;
  qty?: string;
}

export interface ICheckInventorySuccessData {
  skuId: string;
  inventoryInfo: Array<IInventoryDetails>
  pageInfo: IPageInfo;
}

interface ICheckInventoryActionDataErrorActionData extends IErrorActionData {
  skuId: string;
}

export const checkInventory = createAction(CheckInventoryActionTypes.CHECK_INVENTORY)<ICheckInventoryActionData>();
export const checkInventorySuccess = createAction(CheckInventoryActionTypes.CHECK_INVENTORY_SUCCESS)<ICheckInventorySuccessData>();
export const checkInventoryFailed = createAction(CheckInventoryActionTypes.CHECK_INVENTORY_FAILED)
<ICheckInventoryActionDataErrorActionData>();
export const checkInventoryReset = createAction(CheckInventoryActionTypes.CHECK_INVENTORY_RESET)();

export type CheckInventoryAction = ActionType<typeof checkInventory>;
type CheckInventorySuccessAction = ActionType<typeof checkInventorySuccess>;
type CheckInventoryFailedAction = ActionType<typeof checkInventoryFailed>;
type CheckInventoryResetAction = ActionType<typeof checkInventoryReset>;

export type CheckInventoryActions =
  | CheckInventoryAction
  | CheckInventorySuccessAction
  | CheckInventoryFailedAction
  | CheckInventoryResetAction;
