import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GetCartStateActionTypes {
  GET_CART_STATE = 'GET_CART_STATE',
  GET_CART_STATE_SUCCESS = 'GET_CART_STATE_SUCCESS',
  GET_CART_STATE_FAILED = 'GET_CART_STATE_FAILED',
  GET_CART_STATE_RESET = 'GET_CART_STATE_RESET',
}

export interface IGetCartStateActionData {
  cartAliasId: string;
}

export interface IGetCartStateSuccessActionData {
  cartState: string;
}

export const getCartState = createAction(GetCartStateActionTypes.GET_CART_STATE)
<IGetCartStateActionData>();
export const getCartStateSuccess = createAction(GetCartStateActionTypes.GET_CART_STATE_SUCCESS)
<IGetCartStateSuccessActionData>();
export const getCartStateFailed = createAction(GetCartStateActionTypes.GET_CART_STATE_FAILED)
<IErrorActionData>();
export const getCartStateReset = createAction(GetCartStateActionTypes.GET_CART_STATE_RESET)();

export type GetCartStateAction = ActionType<typeof getCartState>;
type GetCartStateSuccessAction = ActionType<typeof getCartStateSuccess>;
type GetCartStateFailedAction = ActionType<typeof getCartStateFailed>;
type GetCartStateResetAction = ActionType<typeof getCartStateReset>;

export type GetCartStateActions =
  | GetCartStateAction
  | GetCartStateSuccessAction
  | GetCartStateFailedAction
  | GetCartStateResetAction;
