/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCustomerDetailsListSuccess,
  getCustomerDetailsListFailed,
  GetCustomerDetailsListAction,
  GetCustomerDetailsListActionTypes,
} from '../actions/GetCustomerDetailsList';
import { IErrorActionData } from '../../../models/Error';
import getCustomerDetailsListService from '../services/GetCustomerDetailsList';
import { ICustomerDetailsList } from '../models/CustomerDetailsList';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCustomerDetailsListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST)),
  mergeMap((action: GetCustomerDetailsListAction) => from(
    getCustomerDetailsListService(
      action.payload.filter,
      action.payload.customerId,
    ),
  ).pipe(
    map((response: any) => {
      if (response.data.getCustomerDetailsList.data?.length > 0) {
        return getCustomerDetailsListSuccess({ data: response.data.getCustomerDetailsList.data });
      }
      return getCustomerDetailsListSuccess({ data: [] as ICustomerDetailsList[] });
    }),
    catchError((error: IErrorActionData) => of(
      getCustomerDetailsListFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCustomerDetailsListEpic;
