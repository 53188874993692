import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IShipmentSkuList } from '../models/GetShipmentSkuList';

export enum GetShipmentSkuListActionTypes {
  GET_SHIPMENT_SKU_LIST = 'GET_SHIPMENT_SKU_LIST',
  GET_SHIPMENT_SKU_LIST_SUCCESS = 'GET_SHIPMENT_SKU_LIST_SUCCESS',
  GET_SHIPMENT_SKU_LIST_FAILED = 'GET_SHIPMENT_SKU_LIST_FAILED',
  GET_SHIPMENT_SKU_LIST_RESET = 'GET_SHIPMENT_SKU_LIST_RESET',
}

export interface IGetShipmentSkuListActionData {
  shipmentAliasId: string;
}

export interface IGetShipmentSkuListSuccessActionData {
  skuListDetails: IShipmentSkuList;
}

export const getShipmentSkuList = createAction(GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST)<IGetShipmentSkuListActionData>();
export const getShipmentSkuListSuccess = createAction(GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST_SUCCESS)
<IGetShipmentSkuListSuccessActionData>();
export const getShipmentSkuListFailure = createAction(GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST_FAILED)
<IErrorActionData>();
export const getShipmentSkuListReset = createAction(GetShipmentSkuListActionTypes.GET_SHIPMENT_SKU_LIST_RESET)();

export type GetShipmentSkuListAction = ActionType<typeof getShipmentSkuList>;
type GetShipmentSkuListSuccessAction = ActionType<typeof getShipmentSkuListSuccess>;
type GetShipmentSkuListFailedAction = ActionType<typeof getShipmentSkuListFailure>;
type GetShipmentSkuListResetAction = ActionType<typeof getShipmentSkuListReset>;

export type GetShipmentSkuListActions =
  | GetShipmentSkuListAction
  | GetShipmentSkuListSuccessAction
  | GetShipmentSkuListFailedAction
  | GetShipmentSkuListResetAction;
