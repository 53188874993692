import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { ICustomerDetails } from '../models/CustomerDetails';

export enum GetCustomerDetailsActionTypes {
  GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS',
  GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS',
  GET_CUSTOMER_DETAILS_FAILED = 'GET_CUSTOMER_DETAILS_FAILED',
  GET_CUSTOMER_DETAILS_RESET = 'GET_CUSTOMER_DETAILS_RESET',
}

export interface IGetCustomerDetailsActionData {
  customerAliasId: string;
}

export interface IGetCustomerDetailsSuccessActionData {
  data: ICustomerDetails;
}

export const getCustomerDetails = createAction(GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS)
<IGetCustomerDetailsActionData>();
export const getCustomerDetailsSuccess = createAction(GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS_SUCCESS)
<IGetCustomerDetailsSuccessActionData>();
export const getCustomerDetailsFailed = createAction(GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS_FAILED)
<IErrorActionData>();
export const getCustomerDetailsReset = createAction(GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS_RESET)();

export type GetCustomerDetailsAction = ActionType<typeof getCustomerDetails>;
type GetCustomerDetailsSuccessAction = ActionType<typeof getCustomerDetailsSuccess>;
type GetCustomerDetailsFailedAction = ActionType<typeof getCustomerDetailsFailed>;
type GetCustomerDetailsResetAction = ActionType<typeof getCustomerDetailsReset>;

export type GetCustomerDetailsActions =
  | GetCustomerDetailsAction
  | GetCustomerDetailsSuccessAction
  | GetCustomerDetailsFailedAction
  | GetCustomerDetailsResetAction;
