import { ActionType, createAction } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IDealDesign } from '../models/GetDealDesignList';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum GetDealDesignListActionTypes {
  GET_DEAL_DESIGN_LIST = 'GET_DEAL_DESIGN_LIST',
  GET_DEAL_DESIGN_LIST_SUCCESS = 'GET_DEAL_DESIGN_LIST_SUCCESS',
  GET_DEAL_DESIGN_LIST_FAILURE = 'GET_DEAL_DESIGN_LIST_FAILURE',
  RESET_GET_DEAL_DESIGN_LIST = 'RESET_GET_DEAL_DESIGN_LIST',
}

export enum AvailableFilterProperty {
  filename = 'filename',
}

interface IGetDealDesignListActionData {
  filter: IFilter[];
}

interface IGetDealDesignListSuccessData {
  dealDesignList: Array<IDealDesign>;
}

export const getDealDesignList = createAction(
  GetDealDesignListActionTypes.GET_DEAL_DESIGN_LIST,
)<IGetDealDesignListActionData>();
export const getDealDesignListSuccess = createAction(
  GetDealDesignListActionTypes.GET_DEAL_DESIGN_LIST_SUCCESS,
)<IGetDealDesignListSuccessData>();
export const getDealDesignListFailure = createAction(
  GetDealDesignListActionTypes.GET_DEAL_DESIGN_LIST_FAILURE,
)<IErrorActionData>();
export const resetGetDealDesignList = createAction(GetDealDesignListActionTypes.RESET_GET_DEAL_DESIGN_LIST)();

export type GetDealDesignListAction = ActionType<typeof getDealDesignList>;
type GetDealDesignListSuccessAction = ActionType<typeof getDealDesignListSuccess>;
type GetDealDesignListFailureAction = ActionType<typeof getDealDesignListFailure>;
type ResetGetDealDesignListAction = ActionType<typeof resetGetDealDesignList>;

export type GetDealDesignListActions =
  | GetDealDesignListAction
  | GetDealDesignListSuccessAction
  | GetDealDesignListFailureAction
  | ResetGetDealDesignListAction;
