import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum StartProcessingSupplyOrderActionTypes {
  START_PROCESSING_SUPPLY_ORDER = 'START_PROCESSING_SUPPLY_ORDER',
  START_PROCESSING_SUPPLY_ORDER_SUCCESS = 'START_PROCESSING_SUPPLY_ORDER_SUCCESS',
  START_PROCESSING_SUPPLY_ORDER_FAILED = 'START_PROCESSING_SUPPLY_ORDER_FAILED',
  START_PROCESSING_SUPPLY_ORDER_RESET = 'START_PROCESSING_SUPPLY_ORDER_RESET',
}

export interface IStartProcessingSupplyOrderActionData {
  supplyOrderId: number;
}

export const startProcessingSupplyOrder = createAction(StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER)
<IStartProcessingSupplyOrderActionData>();
export const
  startProcessingSupplyOrderSuccess = createAction(StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER_SUCCESS)();
export const
  startProcessingSupplyOrderFailure = createAction(StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER_FAILED)
  <IErrorActionData>();
export const startProcessingSupplyOrderReset = createAction(StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER_RESET)();

export type StartProcessingSupplyOrderAction = ActionType<typeof startProcessingSupplyOrder>;
type StartProcessingSupplyOrderSuccessAction = ActionType<typeof startProcessingSupplyOrderSuccess>;
type StartProcessingSupplyOrderFailureAction = ActionType<typeof startProcessingSupplyOrderFailure>;
type StartProcessingSupplyOrderResetAction = ActionType<typeof startProcessingSupplyOrderReset>;

export type StartProcessingSupplyOrderActions = StartProcessingSupplyOrderAction
| StartProcessingSupplyOrderSuccessAction
| StartProcessingSupplyOrderFailureAction
| StartProcessingSupplyOrderResetAction;
