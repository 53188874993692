import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IUserProfileData, PermissionApiStructure } from '../models/UserProfile';

export enum GetUserProfileActionTypes {
  GET_USER_PROFILE_ACTION = 'GET_USER_PROFILE_ACTION',
  GET_USER_PROFILE_ACTION_SUCCESS = 'GET_USER_PROFILE_ACTION_SUCCESS',
  GET_USER_PROFILE_ACTION_FAILURE = 'GET_USER_PROFILE_ACTION_FAILURE',
  GET_USER_PROFILE_ACTION_RESET = 'GET_USER_PROFILE_ACTION_RESET',
}

export interface IGetUserProfileSuccessActionData {
  authorisationToken: string;
  userData?: IUserProfileData;
  permissions?: PermissionApiStructure[]
}

export const getUserProfile = createAction(GetUserProfileActionTypes.GET_USER_PROFILE_ACTION)();
export const getUserProfileSuccess = createAction(GetUserProfileActionTypes.GET_USER_PROFILE_ACTION_SUCCESS)
<IGetUserProfileSuccessActionData>();
export const getUserProfileFailure = createAction(GetUserProfileActionTypes.GET_USER_PROFILE_ACTION_FAILURE)<IErrorActionData>();
export const getUserProfileReset = createAction(GetUserProfileActionTypes.GET_USER_PROFILE_ACTION_RESET)();

export type GetUserProfileAction = ActionType<typeof getUserProfile>;
type GetUserProfileSuccessAction = ActionType<typeof getUserProfileSuccess>;
type GetUserProfileFailureAction = ActionType<typeof getUserProfileFailure>;
type GetUserProfileResetAction = ActionType<typeof getUserProfileReset>;

export type GetUserProfileActions =
  | GetUserProfileAction
  | GetUserProfileSuccessAction
  | GetUserProfileFailureAction
  | GetUserProfileResetAction;
