/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  resendOtpSuccess,
  resendOtpFailure,
  ResendOtpAction,
  ResendOtpActionTypes,
} from '../actions/ResendOTP';
import resendOtpService from '../services/ResendOTP';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const ResendOtpEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(ResendOtpActionTypes.RESEND_OTP)),
  mergeMap((action: ResendOtpAction) => from(
    resendOtpService(action.payload),
  ).pipe(
    map((response: any) => resendOtpSuccess()),
    catchError((error: IErrorActionData) => of(
      resendOtpFailure({
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default ResendOtpEpic;
