/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  editSKUDeliveryDateSuccess,
  editSKUDeliveryDateFailure,
  EditSKUDeliveryDateAction,
  EditSKUDeliveryDateActionTypes,
} from '../actions/EditSKUDeliveryDate';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import editSKUDeliveryDateService from '../services/EditSKUDeliveryDate';

const EditSKUDeliveryDateEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE)),
  mergeMap((action: EditSKUDeliveryDateAction) => from(
    editSKUDeliveryDateService(action.payload.orderReferenceId, action.payload.skuId, action.payload.preferredDate),
  ).pipe(
    map((response) => editSKUDeliveryDateSuccess()),
    catchError((error: IErrorActionData) => of(
      editSKUDeliveryDateFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default EditSKUDeliveryDateEpic;
