import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { UpdateCartActions, UpdateCartActionTypes } from '../actions/UpdateCart';

export interface IUpdateCartState {
  stateUpdate: boolean;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialUpdateCartState: IUpdateCartState = {
  stateUpdate: false,
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const UpdateCartReducer: Reducer<IUpdateCartState, UpdateCartActions> = (
  state = initialUpdateCartState,
  action: UpdateCartActions,
): IUpdateCartState => {
  switch (action.type) {
    case UpdateCartActionTypes.UPDATE_CART_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case UpdateCartActionTypes.UPDATE_CART_ACTION_SUCCESS:
      return {
        ...state,
        stateUpdate: action.payload.stateUpdate,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case UpdateCartActionTypes.UPDATE_CART_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case UpdateCartActionTypes.UPDATE_CART_ACTION_RESET:
      return initialUpdateCartState;

    default:
      return state;
  }
};

export default UpdateCartReducer;
