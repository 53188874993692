/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getUserProfileSuccess,
  getUserProfileFailure,
  GetUserProfileAction,
  GetUserProfileActionTypes,
} from '../actions/GetUserProfile';
import getUserProfileService from '../services/GetUserProfile';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import { IUserProfileData } from '../models/UserProfile';

const GetUserProfileEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetUserProfileActionTypes.GET_USER_PROFILE_ACTION)),
  mergeMap((action: GetUserProfileAction) => from(
    getUserProfileService(),
  ).pipe(
    map((response: any) => getUserProfileSuccess({ authorisationToken: '', userData: {} as IUserProfileData })),
    catchError((error: IErrorActionData) => of(
      getUserProfileFailure({
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetUserProfileEpic;
