import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_ORDER_DETAILS = gql`
  query getOrderDetails($path: String!) {
    getOrderDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getOrderDetailsService = (orderReferenceId: string) => client.query({
  query: GET_ORDER_DETAILS,
  variables: {
    path: URL.getOrderDetails.replace('{orderReferenceId}', orderReferenceId),
  },
});

export default getOrderDetailsService;
