/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getRefundListSuccess,
  getRefundListFailure,
  GetRefundListAction,
  GetRefundListActionTypes,
} from '../actions/GetRefundList';
import getRefundListService from '../services/GetRefundList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetRefundListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetRefundListActionTypes.GET_REFUND_LIST)),
  mergeMap((action: GetRefundListAction) => from(
    getRefundListService(
      action.payload.storeAliasId,
      action.payload.filter,
      action.payload.page,
    ),
  ).pipe(
    map((response: any) => getRefundListSuccess({
      refundList: response.data.getRefundList.data.refund || [],
      pageInfo: response.data.getRefundList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getRefundListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetRefundListEpic;
