import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_CASH_TRANSACTION_LIST = gql`
  query getCashTransactionList($path: String!) {
    getCashTransactionList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getCashTransactionListService = (storeAliasId: string, filter?: IFilter[], page?: IPage) => {
  const url = constructUrlWithParams(URL.getCashTransactionList.replace('{storeAliasId}', storeAliasId), filter, undefined, page);
  return client.query({ query: GET_CASH_TRANSACTION_LIST, variables: { path: url } });
};

export default getCashTransactionListService;
