import { Reducer } from 'redux';

import { SkuListInfo } from '../models/GetDisplayInventoryList';
import { GetDisplayInventoryListActionTypes, GetDisplayInventoryListActions } from '../actions/GetDisplayInventoryList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IDisplayInventoryListState {
  skuList: Array<SkuListInfo>;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDisplayInventoryListState = {
  skuList: [] as Array<SkuListInfo>,
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDisplayInventoryListState;

const GetDisplayInventoryListReducer: Reducer<IDisplayInventoryListState, GetDisplayInventoryListActions> = (
  state = initialDisplayInventoryListState,
  action: GetDisplayInventoryListActions,
): IDisplayInventoryListState => {
  switch (action.type) {
    case GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST_SUCCESS: {
      return {
        ...state,
        skuList: action.payload.skuList,
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
          cursor: action.payload.pageInfo.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDisplayInventoryListActionTypes.GET_DISPLAY_INVENTORY_LIST_RESET:
      return initialDisplayInventoryListState;

    default:
      return state;
  }
};

export default GetDisplayInventoryListReducer;
