import { Reducer } from 'redux';

import { AddCartItemDiscountActionTypes, AddCartItemDiscountActions } from '../actions/AddCartItemDiscount';
import { IErrorActionData } from '../../../models/Error';

export interface IAddCartItemDiscountState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialAddCartItemDiscountState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IAddCartItemDiscountState;

const AddCartItemDiscountReducer: Reducer<IAddCartItemDiscountState, AddCartItemDiscountActions> = (
  state = initialAddCartItemDiscountState,
  action: AddCartItemDiscountActions,
): IAddCartItemDiscountState => {
  switch (action.type) {
    case AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case AddCartItemDiscountActionTypes.ADD_CART_ITEM_DISCOUNT_RESET:
      return initialAddCartItemDiscountState;

    default:
      return state;
  }
};

export default AddCartItemDiscountReducer;
