import { createAction, ActionType } from 'typesafe-actions';

import { IProjectListItem } from '../models/GetProjectList';
import { IPage } from '../../../utils/sortFilterFramework';
import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';

export enum GetProjectListActionTypes {
  GET_PROJECT_LIST = 'GET_PROJECT_LIST',
  GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS',
  GET_PROJECT_LIST_FAILURE = 'GET_PROJECT_LIST_FAILURE',
  GET_PROJECT_LIST_RESET = 'GET_PROJECT_LIST_RESET',
}

export interface IGetProjectListActionData {
  page?: IPage;
}

interface IGetProjectListSuccessActionData {
  data: Array<IProjectListItem>;
  pageInfo: IPageInfo;
}

export const getProjectList = createAction(GetProjectListActionTypes.GET_PROJECT_LIST)<IGetProjectListActionData>();
export const getProjectListSuccess = createAction(GetProjectListActionTypes.GET_PROJECT_LIST_SUCCESS)<IGetProjectListSuccessActionData>();
export const getProjectListFailure = createAction(GetProjectListActionTypes.GET_PROJECT_LIST_FAILURE)<IErrorActionData>();
export const getProjectListReset = createAction(GetProjectListActionTypes.GET_PROJECT_LIST_RESET)();

export type GetProjectListAction = ActionType<typeof getProjectList>;
type GetProjectListSuccessAction = ActionType<typeof getProjectListSuccess>;
type GetProjectListFailureAction = ActionType<typeof getProjectListFailure>;
type GetProjectListResetAction = ActionType<typeof getProjectListReset>;

export type GetProjectListActions =
  | GetProjectListAction
  | GetProjectListSuccessAction
  | GetProjectListFailureAction
  | GetProjectListResetAction;
