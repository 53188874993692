import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum SaveCartActionTypes {
  SAVE_CART_ACTION = 'SAVE_CART_ACTION',
  SAVE_CART_ACTION_SUCCESS = 'SAVE_CART_ACTION_SUCCESS',
  SAVE_CART_ACTION_FAILURE = 'SAVE_CART_ACTION_FAILURE',
  SAVE_CART_ACTION_RESET = 'SAVE_CART_ACTION_RESET',
}

export interface ISaveCartActionData {
  cartState: string;
  cartAliasId: string;
}

export const saveCart = createAction(SaveCartActionTypes.SAVE_CART_ACTION)<ISaveCartActionData>();
export const saveCartSuccess = createAction(SaveCartActionTypes.SAVE_CART_ACTION_SUCCESS)();
export const saveCartFailure = createAction(SaveCartActionTypes.SAVE_CART_ACTION_FAILURE)<IErrorActionData>();
export const saveCartReset = createAction(SaveCartActionTypes.SAVE_CART_ACTION_RESET)();

export type SaveCartAction = ActionType<typeof saveCart>;
type SaveCartSuccessAction = ActionType<typeof saveCartSuccess>;
type SaveCartFailureAction = ActionType<typeof saveCartFailure>;
type SaveCartResetAction = ActionType<typeof saveCartReset>;

export type SaveCartActions =
  | SaveCartAction
  | SaveCartSuccessAction
  | SaveCartFailureAction
  | SaveCartResetAction;
