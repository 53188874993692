import { Reducer } from 'redux';

import { CreateInfluencerActions, CreateInfluencerActionTypes } from '../actions/CreateInfluencer';
import { IErrorActionData } from '../../../models/Error';
import { INewInfluencer, NewInfluencer } from '../models/NewInfluencer';

export interface ICreateInfluencerState {
  newInfluencerDetails: NewInfluencer;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCreateInfluencerState = {
  newInfluencerDetails: new NewInfluencer({} as INewInfluencer),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICreateInfluencerState;

const CreateInfluencerReducer: Reducer<ICreateInfluencerState, CreateInfluencerActions> = (
  state = initialCreateInfluencerState,
  action: CreateInfluencerActions,
): ICreateInfluencerState => {
  switch (action.type) {
    case CreateInfluencerActionTypes.CREATE_INFLUENCER:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CreateInfluencerActionTypes.CREATE_INFLUENCER_SUCCESS:
      return {
        ...state,
        newInfluencerDetails: new NewInfluencer(action.payload.newInfluencerData),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CreateInfluencerActionTypes.CREATE_INFLUENCER_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CreateInfluencerActionTypes.CREATE_INFLUENCER_RESET:
      return initialCreateInfluencerState;

    default:
      return state;
  }
};

export default CreateInfluencerReducer;
