import { Reducer } from 'redux';

import { CashTransactionList, mergeTwoLists } from '../models/CashTransactionList';
import { GetCashTransactionListActionTypes, GetCashTransactionListActions } from '../actions/GetCashTransactionList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface ICashTransactionListState {
  cashTransactionList: CashTransactionList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialOrderListState = {
  cashTransactionList: new CashTransactionList([]),
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICashTransactionListState;

const GetCashTransactionListReducer: Reducer<ICashTransactionListState, GetCashTransactionListActions> = (
  state = initialOrderListState,
  action: GetCashTransactionListActions,
): ICashTransactionListState => {
  switch (action.type) {
    case GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST_SUCCESS: {
      const newCashTransactionList = mergeTwoLists(
        state.cashTransactionList.cashTransactions,
        action.payload.cashTransactionsList,
      );
      return {
        ...state,
        cashTransactionList: new CashTransactionList(newCashTransactionList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo?.pageSize,
          hasNext: action.payload.pageInfo?.hasNext,
          pageNumber: action.payload.pageInfo?.pageNumber,
          totalPages: action.payload.pageInfo?.totalPages,
          cursor: action.payload.pageInfo?.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCashTransactionListActionTypes.GET_CASH_TRANSACTION_LIST_RESET:
      return initialOrderListState;

    default:
      return state;
  }
};

export default GetCashTransactionListReducer;
