export interface ICustomerAddressList {
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2: string
  addressType: string,
  city: string,
  country: string,
  createdAt: string,
  createdBy: number,
  customerId: number,
  deletedAt: string,
  id: number,
  info: string,
  isActive: boolean,
  modifiedBy: number,
  state: string,
  updatedAt: string,
  zip: number,
  label?: string,
  isShippingBillingAddressSame: boolean,
}

export class CustomerAddressList {
  addressList: Array<ICustomerAddressList> = [];
  constructor(addressList: ICustomerAddressList[]) {
    this.addressList = addressList;
  }
}
