import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IUpdateCartPayload } from '../models/UpdateCart';

export enum UpdateCartActionTypes {
  UPDATE_CART_ACTION = 'UPDATE_CART_ACTION',
  UPDATE_CART_ACTION_SUCCESS = 'UPDATE_CART_ACTION_SUCCESS',
  UPDATE_CART_ACTION_FAILURE = 'UPDATE_CART_ACTION_FAILURE',
  UPDATE_CART_ACTION_RESET = 'UPDATE_CART_ACTION_RESET',
}

export interface IUpdateCartActionData {
  cartAliasId: string;
  payload: IUpdateCartPayload;
}

export interface IUpdateCartSuccessData {
  stateUpdate: boolean;
}

export const updateCart = createAction(UpdateCartActionTypes.UPDATE_CART_ACTION)<IUpdateCartActionData>();
export const updateCartSuccess = createAction(UpdateCartActionTypes.UPDATE_CART_ACTION_SUCCESS)<IUpdateCartSuccessData>();
export const updateCartFailure = createAction(UpdateCartActionTypes.UPDATE_CART_ACTION_FAILURE)<IErrorActionData>();
export const updateCartReset = createAction(UpdateCartActionTypes.UPDATE_CART_ACTION_RESET)();

export type UpdateCartAction = ActionType<typeof updateCart>;
type UpdateCartSuccessAction = ActionType<typeof updateCartSuccess>;
type UpdateCartFailureAction = ActionType<typeof updateCartFailure>;
type UpdateCartResetAction = ActionType<typeof updateCartReset>;

export type UpdateCartActions =
  | UpdateCartAction
  | UpdateCartSuccessAction
  | UpdateCartFailureAction
  | UpdateCartResetAction;
