/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  deleteCartItemDiscountSuccess,
  deleteCartItemDiscountFailure,
  DeleteCartItemDiscountAction,
  DeleteCartItemDiscountActionTypes,
} from '../actions/DeleteCartItemDiscount';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import deleteCartItemDiscountService from '../services/DeleteCartItemDiscount';

const DeleteCartItemDiscountEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(DeleteCartItemDiscountActionTypes.DELETE_CART_ITEM_DISCOUNT)),
  mergeMap((action: DeleteCartItemDiscountAction) => from(
    deleteCartItemDiscountService(
      action.payload.cartAliasId,
      action.payload.cartItemAliasId,
      action.payload.payload,
    ),
  ).pipe(
    map((response) => deleteCartItemDiscountSuccess()),
    catchError((error: IErrorActionData) => of(
      deleteCartItemDiscountFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default DeleteCartItemDiscountEpic;
