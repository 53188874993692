import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IReturnDetails } from '../models/ReturnDetails';

export enum GetReturnDetailsActionTypes {
  GET_RETURN_DETAILS = 'GET_RETURN_DETAILS',
  GET_RETURN_DETAILS_SUCCESS = 'GET_RETURN_DETAILS_SUCCESS',
  GET_RETURN_DETAILS_FAILED = 'GET_RETURN_DETAILS_FAILED',
  GET_RETURN_DETAILS_RESET = 'GET_RETURN_DETAILS_RESET',
}

export interface IGetReturnDetailsActionData {
  returnAliasId: string;
}

export interface IGetReturnDetailsSuccessActionData {
  returnDetails: IReturnDetails;
}

export const getReturnDetails = createAction(GetReturnDetailsActionTypes.GET_RETURN_DETAILS)<IGetReturnDetailsActionData>();
export const getReturnDetailsSuccess = createAction(GetReturnDetailsActionTypes.GET_RETURN_DETAILS_SUCCESS)
<IGetReturnDetailsSuccessActionData>();
export const getReturnDetailsFailure = createAction(GetReturnDetailsActionTypes.GET_RETURN_DETAILS_FAILED)<IErrorActionData>();
export const getReturnDetailsReset = createAction(GetReturnDetailsActionTypes.GET_RETURN_DETAILS_RESET)();

export type GetReturnDetailsAction = ActionType<typeof getReturnDetails>;
type GetReturnDetailsSuccessAction = ActionType<typeof getReturnDetailsSuccess>;
type GetReturnDetailsFailureAction = ActionType<typeof getReturnDetailsFailure>;
type GetReturnDetailsResetAction = ActionType<typeof getReturnDetailsReset>;

export type GetReturnDetailsActions = GetReturnDetailsAction
| GetReturnDetailsSuccessAction
| GetReturnDetailsFailureAction
| GetReturnDetailsResetAction;
