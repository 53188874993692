import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ISetActiveDesignPayload } from '../models/DesignInfo';

const SET_ACTIVE_DESIGN = gql`
  query setActiveDesign($path: String!, $payload: any) {
    setActiveDesign (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix")
  }
`;

const setActiveDesignService = (payload: ISetActiveDesignPayload) => client.query({
  query: SET_ACTIVE_DESIGN,
  variables: {
    path: URL.setActiveDesign,
    payload,
  },
});

export default setActiveDesignService;
