import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { IInitiateCartPaymentSuccessActionData } from '../models/InitiateCartPayment';
import { IInitiateCartPaymentActionTypes, InitiateCartPaymentActions } from '../actions/InitiateCartPayment';

export interface IInitiateCartPaymentState {
  initiatePaymentStatus: IInitiateCartPaymentSuccessActionData;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialInitiateCartPaymentState = {
  initiatePaymentStatus: { },
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IInitiateCartPaymentState;

const InitiateCartPaymentReducer: Reducer<IInitiateCartPaymentState, InitiateCartPaymentActions> = (
  state = initialInitiateCartPaymentState,
  action: InitiateCartPaymentActions,
): IInitiateCartPaymentState => {
  switch (action.type) {
    case IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT_SUCCESS: {
      return {
        ...state,
        initiatePaymentStatus: {
          data: action.payload.data,
          success: action.payload.success,
        },
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case IInitiateCartPaymentActionTypes.INITIATE_CART_PAYMENT_RESET:
      return initialInitiateCartPaymentState;

    default:
      return state;
  }
};

export default InitiateCartPaymentReducer;
