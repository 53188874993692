import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IDownloadCashClosureReportPayload } from '../models/DownloadCashClosureReport';

export enum DownloadCashClosureReportActionTypes {
  DOWNLOAD_CASH_CLOSURE_REPORT = 'DOWNLOAD_CASH_CLOSURE_REPORT',
  DOWNLOAD_CASH_CLOSURE_REPORT_SUCCESS = 'DOWNLOAD_CASH_CLOSURE_REPORT_SUCCESS',
  DOWNLOAD_CASH_CLOSURE_REPORT_FAILED = 'DOWNLOAD_CASH_CLOSURE_REPORT_FAILED',
  DOWNLOAD_CASH_CLOSURE_REPORT_RESET = 'DOWNLOAD_CASH_CLOSURE_REPORT_RESET',
}

export enum AvailableFilterProperty {
  storeAliasId = 'storeAliasId',
  depositStartDate = 'depositStartDate',
  depositEndDate = 'depositEndDate',
  closingStartDate = 'closingStartDate',
  closingEndDate = 'closingEndDate',
}

export interface IDownloadCashClosureReportActionData {
  storeAliasId: string;
  payloadDetails?: IDownloadCashClosureReportPayload;
}

export interface IDownloadCashClosureReportSuccessActionData {
  requestId: string;
}

export const downloadCashClosureReport = createAction(DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT)
<IDownloadCashClosureReportActionData>();
export const downloadCashClosureReportSuccess = createAction(DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT_SUCCESS)
<IDownloadCashClosureReportSuccessActionData>();
export const downloadCashClosureReportFailure = createAction(DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT_FAILED)
<IErrorActionData>();
export const downloadCashClosureReportReset = createAction(DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT_RESET)();

export type DownloadCashClosureReportAction = ActionType<typeof downloadCashClosureReport>;
type DownloadCashClosureReportSuccessAction = ActionType<typeof downloadCashClosureReportSuccess>;
type DownloadCashClosureReportFailedAction = ActionType<typeof downloadCashClosureReportFailure>;
type DownloadCashClosureReportResetAction = ActionType<typeof downloadCashClosureReportReset>;

export type DownloadCashClosureReportActions =
  | DownloadCashClosureReportAction
  | DownloadCashClosureReportSuccessAction
  | DownloadCashClosureReportFailedAction
  | DownloadCashClosureReportResetAction;
