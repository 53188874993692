export interface IReturnSkuListItem {
  productName: string;
  quantityReturned: number;
  quantityInvoiced: number;
  skuId: string;
  imageUrl: string;
  measurementUnitName: string;
  measurementUnitQuantity: string;
  uomDecimalPrecision: number;
  sellingPrice: number;
}

export interface IShipmentSkuList {
  shipmentAliasId: string;
  shipmentItems: Array<IReturnSkuListItem>;
}

export class ShipmentSkuList {
  skuList = {} as IShipmentSkuList;
  constructor(skuList: IShipmentSkuList) {
    this.skuList = skuList;
  }
}
