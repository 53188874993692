import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_E_WAY_BILL_URL = gql`
  query getEWayBillUrl($path: String!) {
    getEWayBillUrl @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getEWayBillUrlService = (invoiceAliasId: string) => client.query({
  query: GET_E_WAY_BILL_URL,
  variables: {
    path: URL.getShipmentInvoiceUrl.replace('{invoiceAliasId}', invoiceAliasId),
  },
});

export default getEWayBillUrlService;
