interface IBankDetails {
  accountHolderName: string,
  accountNumber: string,
  bankName: string,
  ifsc: string,
}

interface IBasicDetails {
  email: string,
  experience: number,
  fullName: string,
  phoneNumber: string,
  profession: string,
  influencerId: string;
}

interface IGSTDetails {
  constitutionofbusiness: string,
  dateofregistration: string,
  gstnumber: string,
  natureofbusiness: string,
  pannumber: string,
  registeredaddressofbusiness: string,
  tradename: string,
}

interface IService {
  name : string,
  serviceAliasId: string,
}

export interface IInfluencerDetails {
  bankDetails: IBankDetails,
  basicDetails: IBasicDetails,
  gst: IGSTDetails,
  gstStatus: string,
  kycStatus: string,
  kycType: string,
  pan: {
    identificationNumber: string,
    name: string,
  },
  panStatus: string,
  services: Array<IService>
}

export class InfluencerDetails {
  influencerDetails = {} as IInfluencerDetails;
  constructor(influencerDetails: IInfluencerDetails) {
    this.influencerDetails = influencerDetails;
  }
}
