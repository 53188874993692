export interface INewInfluencer {
  influencerAliasId: string;
}

export class NewInfluencer {
  newInfluencer = {} as INewInfluencer;
  constructor(newInfluencer: INewInfluencer) {
    this.newInfluencer = newInfluencer;
  }
}
