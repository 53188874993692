/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  generateInvoiceSuccess,
  generateInvoiceFailure,
  GenerateInvoiceAction,
  GenerateInvoiceActionTypes,
} from '../actions/GenerateInvoice';
import generateInvoiceService from '../services/GenerateInvoice';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GenerateInvoiceEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GenerateInvoiceActionTypes.GENERATE_INVOICE)),
  mergeMap((action: GenerateInvoiceAction) => from(
    generateInvoiceService(action.payload.orderReferenceId),
  ).pipe(
    map((response) => generateInvoiceSuccess({
      invoiceAliasId: response.data.generateInvoice.data.invoiceId || '',
    })),
    catchError((error: IErrorActionData) => of(
      generateInvoiceFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GenerateInvoiceEpic;
