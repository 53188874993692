import { Reducer } from 'redux';

import { GetDownloadTransactionStatusActions, GetDownloadTransactionStatusActionTypes } from '../actions/GetDownloadTransactionStatus';
import { IErrorActionData } from '../../../models/Error';

export interface IGetDownloadTransactionStatusState {
  downloadReportStatus: string,
  downloadReportUrl: string,
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetDownloadTransactionStatusState = {
  downloadReportStatus: '',
  downloadReportUrl: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetDownloadTransactionStatusState;

const GetDownloadTransactionStatusReducer: Reducer<IGetDownloadTransactionStatusState, GetDownloadTransactionStatusActions> = (
  state = initialGetDownloadTransactionStatusState,
  action: GetDownloadTransactionStatusActions,
): IGetDownloadTransactionStatusState => {
  switch (action.type) {
    case GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        downloadReportStatus: action.payload.downloadReportStatus,
        downloadReportUrl: action.payload.downloadReportUrl,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDownloadTransactionStatusActionTypes.GET_DOWNLOAD_TRANSACTION_STATUS_RESET:
      return initialGetDownloadTransactionStatusState;

    default:
      return state;
  }
};

export default GetDownloadTransactionStatusReducer;
