import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_SHIPMENT_INVOICE_URL = gql`
  query getShipmentInvoiceUrl($path: String!) {
    getShipmentInvoiceUrl @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getShipmentInvoiceUrlService = (invoiceAliasId: string) => client.query({
  query: GET_SHIPMENT_INVOICE_URL,
  variables: {
    path: URL.getShipmentInvoiceUrl.replace('{invoiceAliasId}', invoiceAliasId),
  },
});

export default getShipmentInvoiceUrlService;
