import { Reducer } from 'redux';

import { addPresignedUrlToExisting, QuotationUrlData } from '../models/GetQuotationUrl';
import { GetQuotationUrlActionTypes, GetQuotationUrlActions } from '../actions/GetQuotationUrl';
import { IErrorActionData } from '../../../models/Error';

export interface IGetQuotationUrlState {
  quotationUrlData: QuotationUrlData;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProformaInvoiceUrlState = {
  quotationUrlData: new QuotationUrlData({}),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetQuotationUrlState;

const GetQuotationUrlReducer: Reducer<IGetQuotationUrlState, GetQuotationUrlActions> = (
  state = initialProformaInvoiceUrlState,
  action: GetQuotationUrlActions,
): IGetQuotationUrlState => {
  switch (action.type) {
    case GetQuotationUrlActionTypes.GET_QUOTATION_URL: {
      const updatedQuotationData = addPresignedUrlToExisting(
        state.quotationUrlData.quotationUrlData,
        action.payload.quotationAliasId,
        { isProcessing: true, isSuccess: false },
      );
      return { ...state, quotationUrlData: new QuotationUrlData(updatedQuotationData) };
    }

    case GetQuotationUrlActionTypes.GET_QUOTATION_URL_SUCCESS: {
      const updatedQuotationData = addPresignedUrlToExisting(
        state.quotationUrlData.quotationUrlData,
        action.payload.quotationAliasId,
        { isProcessing: false, isSuccess: true, presignedQuotationUrl: action.payload.quotationPresignedUrl },
      );
      return { ...state, quotationUrlData: new QuotationUrlData(updatedQuotationData) };
    }

    case GetQuotationUrlActionTypes.GET_QUOTATION_URL_FAILED: {
      const updatedQuotationData = addPresignedUrlToExisting(
        state.quotationUrlData.quotationUrlData,
        action.payload.quotationAliasId,
        {
          isProcessing: false,
          isSuccess: false,
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, quotationUrlData: new QuotationUrlData(updatedQuotationData) };
    }

    case GetQuotationUrlActionTypes.GET_QUOTATION_URL_RESET:
      return initialProformaInvoiceUrlState;

    default:
      return state;
  }
};

export default GetQuotationUrlReducer;
