import GetDealDesignListReducer from './GetDealDesignList';
import DeleteDesignReducer from './DeleteDesign';
import GetDealDetailsReducer from './GetDealDetails';
import DealListReducer from './GetDealList';
import SetActiveDesignReducer from './SetActiveDesign';
import UploadDesignReducer from './UploadDesign';

const DealReducers = {
  getDealList: DealListReducer,
  getDealDetails: GetDealDetailsReducer,
  getDealDesignList: GetDealDesignListReducer,
  setActiveDesign: SetActiveDesignReducer,
  deleteDesign: DeleteDesignReducer,
  uploadDesign: UploadDesignReducer,
};

export default DealReducers;
