/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  updateAddressSuccess,
  updateAddressFailure,
  UpdateAddressAction,
  UpdateAddressActionTypes,
} from '../actions/UpdateAddress';
import updateAddressService from '../services/UpdateAddress';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const UpdateAddressEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION)),
  mergeMap((action: UpdateAddressAction) => from(
    updateAddressService(
      action.payload.customerId,
      action.payload.addressId,
      action.payload.address,
    ),
  ).pipe(
    map((response: any) => (updateAddressSuccess())),
    catchError((error: any) => of(
      updateAddressFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default UpdateAddressEpic;
