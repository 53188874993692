import { Reducer } from 'redux';

import { IOrderDetails, OrderDetails } from '../models/OrderDetails';
import { GetOrderDetailsActionTypes, GetOrderDetailsActions } from '../actions/GetOrderDetails';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IGetOrderDetailsState {
  orderDetails: OrderDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialOrderDetailsState = {
  orderDetails: new OrderDetails({} as IOrderDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetOrderDetailsState;

const GetOrderDetailsReducer: Reducer<IGetOrderDetailsState, GetOrderDetailsActions> = (
  state = initialOrderDetailsState,
  action: GetOrderDetailsActions,
): IGetOrderDetailsState => {
  switch (action.type) {
    case GetOrderDetailsActionTypes.GET_ORDER_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetOrderDetailsActionTypes.GET_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        orderDetails: new OrderDetails(action.payload.orderDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetOrderDetailsActionTypes.GET_ORDER_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetOrderDetailsActionTypes.GET_ORDER_DETAILS_RESET:
      return initialOrderDetailsState;

    default:
      return state;
  }
};

export default GetOrderDetailsReducer;
