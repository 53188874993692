/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { combineEpics } from 'redux-observable';

import LoginEpic from '../../feature/auth/epics/Login';
import AutoLoginEpic from '../../feature/auth/epics/AutoLogin';
import VerifyOtpEpic from '../../feature/auth/epics/VerifyOTP';
import ResendOtpEpic from '../../feature/auth/epics/ResendOTP';
import LogoutEpic from '../../feature/auth/epics/Logout';
import GetUserProfileEpic from '../../feature/auth/epics/GetUserProfile';
import GetProductListEpic from '../../feature/product/epics/GetProductList';
import GetOrderListEpic from '../../feature/order/epics/GetOrderList';
import CreateOrderEpic from '../../feature/order/epics/CreateOrder';
import CancelOrderEpic from '../../feature/order/epics/CancelOrder';
import GetOrderDetailsEpic from '../../feature/order/epics/GetOrderDetails';
import GetOrderPaymentDetailsEpic from '../../feature/payment/epics/GetOrderPaymentDetails';
import GetProformaInvoiceUrlEpic from '../../feature/invoice/epics/GetProformaInvoiceUrl';
import UpdatePaymentEpic from '../../feature/payment/epics/UpdatePayment';
import GetStoreListEpic from '../../feature/retailStore/epics/GetStoreList';
import GetStoreDetailsEpic from '../../feature/retailStore/epics/GetStoreDetails';
import CreateOrderPaymentEpic from '../../feature/payment/epics/CreateOrderPayment';
import GenerateInvoiceEpic from '../../feature/invoice/epics/GenerateInvoice';
import GenerateQuotationEpic from '../../feature/quotation/epics/GenerateQuotation';
import GetQuotationUrlEpic from '../../feature/quotation/epics/GetQuotationUrl';
import CreateInfluencerEpic from '../../feature/influencer/epics/CreateInfluencer';
import UpdateInfluencerEpic from '../../feature/influencer/epics/UpdateInfluencer';
import GetStoreEmployeeListEpic from '../../feature/users/epics/GetStoreEmployeeList';
import DispatchSupplyOrderEpic from '../../feature/order/epics/DispatchSupplyOrder';
import EditOrderEpic from '../../feature/order/epics/EditOrder';
import StartProcessingOrderEpic from '../../feature/order/epics/StartProcessingOrder';
import StartProcessingSupplyOrderEpic from '../../feature/order/epics/StartProcessingSupplyOrder';
import GetOrderCustomerDetailsEpic from '../../feature/order/epics/GetOrderCustomerDetails';
import GetSupplyOrderListEpic from '../../feature/order/epics/GetSupplyOrderList';
import GetShipmentDetailsEpic from '../../feature/order/epics/GetShipmentDetails';
import GetShipmentInvoiceUrlEpic from '../../feature/invoice/epics/GetShipmentInvoiceUrl';
import GetOpeningCashBalanceDetailsEpic from '../../feature/cash/epics/GetOpeningCashBalanceDetails';
import GetOpenCashTransactionsEpic from '../../feature/cash/epics/GetOpenCashTransactions';
import AddCmsSlipEpic from '../../feature/cash/epics/AddCmsSlip';
import EditCmsSlipEpic from '../../feature/cash/epics/EditCmsSlip';
import GetCashClosureListEpic from '../../feature/cash/epics/GetCashClosureList';
import AddCashClosureEpic from '../../feature/cash/epics/AddCashClosure';
import GetCashClosureEpic from '../../feature/cash/epics/GetCashClosureDetails';
import EditCashClosureEpic from '../../feature/cash/epics/EditCashClosure';
import DownloadCashClosureReportEpic from '../../feature/cash/epics/DownloadCashClosureReport';
import DownloadCashTransactionsReportEpic from '../../feature/cash/epics/DownloadCashTransactionsReport';
import GetCashTransactionListEpic from '../../feature/cash/epics/GetCashTransactionList';
import GetAdditionalCashListEpic from '../../feature/cash/epics/GetAdditionalCashList';
import GetDownloadCashClosureStatusEpic from '../../feature/cash/epics/GetDownloadCashClosureStatus';
import GetDownloadTransactionStatusEpic from '../../feature/cash/epics/GetDownloadTransactionStatus';
import GetAuditLogsEpic from '../../feature/order/epics/GetAuditLogs';
import EditSKUDeliveryDateEpic from '../../feature/order/epics/EditSKUDeliveryDate';
import GetRefundListEpic from '../../feature/refund/epics/GetRefundList';
import ConfirmRefundEpic from '../../feature/refund/epics/ConfirmRefund';
import CancelRefundEpic from '../../feature/refund/epics/CancelRefund';
import RecordRefundEpic from '../../feature/refund/epics/RecordRefund';
import GetCustomerCreditBalanceDetailsEpic from '../../feature/refund/epics/GetCustomerCreditBalanceDetails';
import CancelSKUEpic from '../../feature/order/epics/CancelSKU';
import RequestReturnEpic from '../../feature/return/epics/RequestReturn';
import GetSOCancellationDetailsEpic from '../../feature/order/epics/GetSOCancellationDetails';
import GetShipmentSkuListEpic from '../../feature/return/epics/GetShipmentSkuList';
import GetShipmentListEpic from '../../feature/return/epics/GetShipmentList';
import GetCancelSkuListEpic from '../../feature/order/epics/GetCancelSkuList';
import GetEWayBillUrlEpic from '../../feature/invoice/epics/GetEWayBillUrl';
import GetReturnDetailsEpic from '../../feature/return/epics/GetReturnDetails';
import GetReturnListEpic from '../../feature/return/epics/GetReturnList';
import GetCustomerLedgerDetailsEpic from '../../feature/ledger/epics/GetCustomerLedgerDetails';
import GetAccountStatementEpic from '../../feature/ledger/epics/GetAccountStatement';
import GetLedgerTxnInvoiceUrlEpic from '../../feature/ledger/epics/GetLedgerTxnInvoiceUrl';
import DownloadLedgerAccountStatementEpic from '../../feature/ledger/epics/DownloadLedgerAccountStatement';
import GetDownloadLedgerStatementStatusEpic from '../../feature/ledger/epics/GetDownloadLedgerStatementStatus';
import DisplayManagementEpics from '../../feature/displayManagement/epics';
import InitiateOrderPaymentEpic from '../../feature/payment/epics/InitiateOrderPayment';
import GetEDCMachineListEpic from '../../feature/payment/epics/GetEDCMachineList';
import GetInfluencerDetailsEpic from '../../feature/influencer/epics/GetInfluencerDetails';
import CheckInventoryEpic from '../../feature/inventory/epics/checkInventory';
import CartEpics from '../../feature/cart/epics';
import CustomerEpics from '../../feature/customer/epics';
import MarkWrongTransactionEpic from '../../feature/cash/epics/MarkWrongTransaction';
import LocationEpics from '../../feature/location/epics';
import fetchRemoteConfigEpic from '../../feature/auth/epics/fetchRemoteConfig';
import DealEpics from '../../feature/deal/epics';

const RootEpic = combineEpics(
  LoginEpic,
  AutoLoginEpic,
  VerifyOtpEpic,
  ResendOtpEpic,
  fetchRemoteConfigEpic,
  LogoutEpic,
  GetUserProfileEpic,
  GetProductListEpic,
  GetOrderListEpic,
  CreateOrderEpic,
  CancelOrderEpic,
  GetOrderDetailsEpic,
  GetOrderPaymentDetailsEpic,
  GetProformaInvoiceUrlEpic,
  UpdatePaymentEpic,
  GetStoreListEpic,
  GetStoreDetailsEpic,
  CreateOrderPaymentEpic,
  GenerateInvoiceEpic,
  GenerateQuotationEpic,
  GetQuotationUrlEpic,
  GetInfluencerDetailsEpic,
  CreateInfluencerEpic,
  UpdateInfluencerEpic,
  GetStoreEmployeeListEpic,
  DispatchSupplyOrderEpic,
  EditOrderEpic,
  StartProcessingOrderEpic,
  StartProcessingSupplyOrderEpic,
  GetOrderCustomerDetailsEpic,
  GetSupplyOrderListEpic,
  GetShipmentDetailsEpic,
  GetShipmentInvoiceUrlEpic,
  GetOpeningCashBalanceDetailsEpic,
  GetOpenCashTransactionsEpic,
  AddCmsSlipEpic,
  EditCmsSlipEpic,
  GetCashClosureListEpic,
  AddCashClosureEpic,
  GetCashClosureEpic,
  EditCashClosureEpic,
  DownloadCashClosureReportEpic,
  DownloadCashTransactionsReportEpic,
  GetCashTransactionListEpic,
  GetAdditionalCashListEpic,
  GetDownloadCashClosureStatusEpic,
  GetDownloadTransactionStatusEpic,
  GetAuditLogsEpic,
  EditSKUDeliveryDateEpic,
  GetRefundListEpic,
  ConfirmRefundEpic,
  CancelRefundEpic,
  CancelSKUEpic,
  GetCustomerCreditBalanceDetailsEpic,
  RecordRefundEpic,
  RequestReturnEpic,
  GetSOCancellationDetailsEpic,
  GetShipmentSkuListEpic,
  GetShipmentListEpic,
  GetCancelSkuListEpic,
  GetEWayBillUrlEpic,
  GetReturnDetailsEpic,
  GetReturnListEpic,
  GetCustomerLedgerDetailsEpic,
  GetAccountStatementEpic,
  GetLedgerTxnInvoiceUrlEpic,
  DownloadLedgerAccountStatementEpic,
  GetDownloadLedgerStatementStatusEpic,
  DisplayManagementEpics,
  InitiateOrderPaymentEpic,
  GetEDCMachineListEpic,
  CheckInventoryEpic,
  ...CartEpics,
  ...CustomerEpics,
  ...LocationEpics,
  ...DealEpics,
  MarkWrongTransactionEpic,
);

export default RootEpic;
