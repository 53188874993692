export interface IAccountStatement {
  uniqueId: string;
  invoiceId: string;
  dateTime: number;
  transactionType: string;
  parentOrderId: string;
  parentOrderBalance: number;
  parentOrderStatus: string;
  debit: number;
  credit: number;
  netBalance: number;
  transactionId: string;
  natureOfPayment: string;
  supplyOrderId: string;
  supplyOrderStatus: string;
  invoiceNumber: string;
  store: string;
  ERPStoreCode: string;
  creditNoteNumber: string;
  warehouseCode: string;
}

export class AccountStatement {
  accountStatement = [] as Array<IAccountStatement>;
  constructor(accountStatement: Array<IAccountStatement>) {
    this.accountStatement = accountStatement;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<IAccountStatement>,
  payloadList: Array<IAccountStatement>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
