import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { GetDealListActions, GetDealListActionTypes } from '../actions/GetDealList';
import { DealList, mergeTwoLists } from '../models/GetDealList';
import { PageInfo } from '../../../models/PageInfo';

export interface DealListState {
  dealList: DealList;
  isLoading: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
  pageInfo?: PageInfo;
}

const initialDealListState: DealListState = {
  dealList: new DealList([]),
  isLoading: false,
  isSuccess: false,
  pageInfo: undefined,
};

const DealListReducer: Reducer<DealListState, GetDealListActions> = (
  state = initialDealListState,
  action: GetDealListActions,
): DealListState => {
  switch (action.type) {
    case GetDealListActionTypes.GET_DEAL_LIST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    case GetDealListActionTypes.GET_DEAL_LIST_SUCCESS: {
      const newDealList = mergeTwoLists(
        state.dealList.dealList,
        action.payload.data,
      );
      return {
        ...state,
        dealList: new DealList(newDealList),
        pageInfo: action.payload.pageInfo ? new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
          cursor: action.payload.pageInfo.cursor,
        }) : undefined,
        isLoading: false,
        isSuccess: true,
        error: undefined,
      };
    }
    case GetDealListActionTypes.GET_DEAL_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };
    case GetDealListActionTypes.GET_DEAL_LIST_RESET:
      return initialDealListState;

    default:
      return state;
  }
};

export default DealListReducer;
