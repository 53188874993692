import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum VerifyOtpActionTypes {
  VERIFY_OTP = 'VERIFY_OTP',
  VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE',
  VERIFY_OTP_RESET = 'VERIFY_OTP_RESET',
}

export interface IVerifyOtpActionData {
  loginID: string;
  mode: string;
  loginType: string;
  otp: string;
}

export const verifyOtp = createAction(VerifyOtpActionTypes.VERIFY_OTP)<IVerifyOtpActionData>();
export const verifyOtpSuccess = createAction(VerifyOtpActionTypes.VERIFY_OTP_SUCCESS)();
export const verifyOtpFailure = createAction(VerifyOtpActionTypes.VERIFY_OTP_FAILURE)<IErrorActionData>();
export const verifyOtpReset = createAction(VerifyOtpActionTypes.VERIFY_OTP_RESET)();

export type VerifyOtpAction = ActionType<typeof verifyOtp>;
type VerifyOtpSuccessAction = ActionType<typeof verifyOtpSuccess>;
type VerifyOtpFailureAction = ActionType<typeof verifyOtpFailure>;
type VerifyOtpResetAction = ActionType<typeof verifyOtpReset>;

export type VerifyOtpActions =
  | VerifyOtpAction
  | VerifyOtpSuccessAction
  | VerifyOtpFailureAction
  | VerifyOtpResetAction;
