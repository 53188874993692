import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const DISPATCH_SUPPLY_ORDER = gql`
  query dispatchSupplyOrder($path: String!, $payload: any) {
    dispatchSupplyOrder (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const dispatchSupplyOrderService = (supplyOrderId: number) => client.query({
  query: DISPATCH_SUPPLY_ORDER,
  variables: {
    path: URL.dispatchSupplyOrder.replace('{supplyOrderId}', supplyOrderId.toString()),
    payload: { test: 'test' }, //  TODO: Remove the test parameter when BE resolves the 412 error
  },
});

export default dispatchSupplyOrderService;
