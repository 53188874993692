import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { DispatchSupplyOrderActions, DispatchSupplyOrderActionTypes } from '../actions/DispatchSupplyOrder';

export interface IDispatchSupplyOrderState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialDispatchSupplyOrderState: IDispatchSupplyOrderState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const DispatchSupplyOrderReducer: Reducer<IDispatchSupplyOrderState, DispatchSupplyOrderActions> = (
  state = initialDispatchSupplyOrderState,
  action: DispatchSupplyOrderActions,
): IDispatchSupplyOrderState => {
  switch (action.type) {
    case DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION_RESET:
      return initialDispatchSupplyOrderState;

    default:
      return state;
  }
};

export default DispatchSupplyOrderReducer;
