import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IProjectDetails } from '../models/CreateProject';

export enum CreateProjectActionTypes {
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED',
  CREATE_PROJECT_RESET = 'CREATE_PROJECT_RESET',
}

export interface ICreateProjectActionData {
  projectName: string;
  projectType: string;
  stageOfConstruction: string;
  projectLocation: string;
}

export interface ICreateProjectSuccessActionData {
  projectDetails: IProjectDetails;
}

export const createProject = createAction(CreateProjectActionTypes.CREATE_PROJECT)
<ICreateProjectActionData>();
export const createProjectSuccess = createAction(CreateProjectActionTypes.CREATE_PROJECT_SUCCESS)
<ICreateProjectSuccessActionData>();
export const createProjectFailed = createAction(CreateProjectActionTypes.CREATE_PROJECT_FAILED)
<IErrorActionData>();
export const createProjectReset = createAction(CreateProjectActionTypes.CREATE_PROJECT_RESET)();

export type CreateProjectAction = ActionType<typeof createProject>;
type CreateProjectSuccessAction = ActionType<typeof createProjectSuccess>;
type CreateProjectFailedAction = ActionType<typeof createProjectFailed>;
type CreateProjectResetAction = ActionType<typeof createProjectReset>;

export type CreateProjectActions =
  | CreateProjectAction
  | CreateProjectSuccessAction
  | CreateProjectFailedAction
  | CreateProjectResetAction;
