import { EDCInstrumentType, EDCInstrumentTypeMap } from '../../../constants/Strings';
import { IIdLabelPair } from '../../../models/Common';
import { generateIdLabelPairs } from '../../../utils/common';

interface ITIDList {
  mid: string;
  tid: string;
  bankingPartner: string,
  instrumentType: string,
  storeAliasId: string,
  storeName: string,
}

export interface IEDCMachineList {
  tidList: Array<ITIDList>;
  tidDropdown: Array<IIdLabelPair>;
}

export class EDCMachineList {
  edcMachineList = {} as IEDCMachineList;
  constructor(machineList: IEDCMachineList) {
    const updatedEDCMachineList = machineList.tidList?.map((item) => ({
      name: `${item.tid} - ${EDCInstrumentTypeMap.get(item.instrumentType as EDCInstrumentType) ?? ''}`,
      tid: item.tid,
    })) ?? [];
    const TIDDropdownList = generateIdLabelPairs(updatedEDCMachineList, 'tid', 'name');

    this.edcMachineList = machineList;
    this.edcMachineList.tidDropdown = TIDDropdownList;
  }
}
