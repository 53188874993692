/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  updatePaymentSuccess,
  updatePaymentFailure,
  UpdatePaymentAction,
  UpdatePaymentActionTypes,
} from '../actions/UpdatePayment';
import updatePaymentService from '../services/UpdatePayment';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const UpdatePaymentEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(UpdatePaymentActionTypes.UPDATE_PAYMENT_ACTION)),
  mergeMap((action: UpdatePaymentAction) => from(
    updatePaymentService(action.payload.orderReferenceId, action.payload.paymentAliasId, action.payload.action),
  ).pipe(
    map((response: any) => (updatePaymentSuccess({
      paymentAliasId: action.payload.paymentAliasId,
    }))),
    catchError((error: any) => of(
      updatePaymentFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
        paymentAliasId: action.payload.paymentAliasId,
      }),
    )),
  )),
);

export default UpdatePaymentEpic;
