import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_SHIPMENT_DETAILS = gql`
  query getShipmentDetails($path: String!) {
    getShipmentDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getShipmentDetailsService = (supplyOrderAliasId: string) => client.query({
  query: GET_SHIPMENT_DETAILS,
  variables: {
    path: URL.getShipmentDetails.replace('{supplyOrderAliasId}', supplyOrderAliasId),
  },
});

export default getShipmentDetailsService;
