import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { CancelRefundActions, CancelRefundActionTypes } from '../actions/CancelRefund';

export interface ICancelRefundState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialCancelRefundState: ICancelRefundState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const CancelRefundReducer: Reducer<ICancelRefundState, CancelRefundActions> = (
  state = initialCancelRefundState,
  action: CancelRefundActions,
): ICancelRefundState => {
  switch (action.type) {
    case CancelRefundActionTypes.CANCEL_REFUND_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CancelRefundActionTypes.CANCEL_REFUND_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case CancelRefundActionTypes.CANCEL_REFUND_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CancelRefundActionTypes.CANCEL_REFUND_ACTION_RESET:
      return initialCancelRefundState;

    default:
      return state;
  }
};

export default CancelRefundReducer;
