import { ChannelNames, OrderStatus } from '../../../constants/Strings';
import { ICartInfluencer, ISalesPerson } from '../../cart/models/UpdateCart';

interface OrderInfo {
  orderAlias: string;
  orderReferenceId: string;
  state: OrderStatus;
  orderDate: number;
  comment: string;
  channelName: ChannelNames;
}

interface InvoiceDetail {
  invoiceAliasId: string;
  invoiceUrl: string;
  invoiceStatus: string;
}

interface OrderSummary {
  cancellationAmount: number;
  numberOfItems: number;
  productValue: number;
  subTotal: number;
  roundOff: number;
  discounts: {
    totalDiscounts: number;
    cartDiscounts: number;
    lineItemDiscounts: number;
  },
  otherCharges: {
    totalOtherCharges: number;
    totalDeliveryCharge: number;
  },
  orderValue: {
    totalOrderValue: number;
    totalGst: number;
  }
}

export interface IOrderDetails {
  info: OrderInfo;
  orderSummary: OrderSummary;
  invoiceDetail: InvoiceDetail;
  paymentStatus: string;
  referralInfo: {
    influencer: ICartInfluencer;
    salesperson: ISalesPerson[];
  };
}

export class OrderDetails {
  orderDetails = {} as IOrderDetails;
  constructor(orderDetails: IOrderDetails) {
    this.orderDetails = orderDetails;
  }
}
