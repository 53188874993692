import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_SO_CANCELLATION_DETAILS = gql`
  query getSOCancellationDetails($path: String!) {
    getSOCancellationDetails @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getSOCancellationDetailsService = (filter: IFilter[]) => {
  const url = constructUrlWithParams(URL.getSOCancellationDetails, filter);
  return client.query({
    query: GET_SO_CANCELLATION_DETAILS,
    variables: { path: url },
  });
};

export default getSOCancellationDetailsService;
