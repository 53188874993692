/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import {
  GetDisplayInventorySummaryAction,
  GetDisplayInventorySummaryActionTypes,
  getDisplayInventorySummaryFailure,
  getDisplayInventorySummarySuccess,
} from '../actions/GetDisplayInventorySummary';
import getDisplayInventorySummaryService from '../services/GetDisplayInventorySummary';

const GetDisplayInventorySummaryEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY)),
  mergeMap((action: GetDisplayInventorySummaryAction) => from(
    getDisplayInventorySummaryService(
      action.payload.storeAliasId,
    ),
  ).pipe(
    map((response) => getDisplayInventorySummarySuccess({ data: response.data.getDisplayInventorySummary.data || {} })),
    catchError((error: IErrorActionData) => of(
      getDisplayInventorySummaryFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDisplayInventorySummaryEpic;
