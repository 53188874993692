import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { VerifyOtpActions, VerifyOtpActionTypes } from '../actions/VerifyOTP';

export interface IVerifyOtpState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialVerifyOtpState: IVerifyOtpState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const VerifyOtpReducer: Reducer<IVerifyOtpState, VerifyOtpActions> = (
  state = initialVerifyOtpState,
  action: VerifyOtpActions,
): IVerifyOtpState => {
  switch (action.type) {
    case VerifyOtpActionTypes.VERIFY_OTP:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case VerifyOtpActionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case VerifyOtpActionTypes.VERIFY_OTP_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case VerifyOtpActionTypes.VERIFY_OTP_RESET:
      return initialVerifyOtpState;

    default:
      return state;
  }
};

export default VerifyOtpReducer;
