import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';
import { ISetActiveDesignPayload } from '../models/DesignInfo';

export enum SetActiveDesignActionTypes {
  SET_ACTIVE_DESIGN = 'SET_ACTIVE_DESIGN',
  SET_ACTIVE_DESIGN_SUCCESS = 'SET_ACTIVE_DESIGN_SUCCESS',
  SET_ACTIVE_DESIGN_FAILURE = 'SET_ACTIVE_DESIGN_FAILURE',
  SET_ACTIVE_DESIGN_RESET = 'SET_ACTIVE_DESIGN_RESET',
}

export const setActiveDesign = createAction(SetActiveDesignActionTypes.SET_ACTIVE_DESIGN)<ISetActiveDesignPayload>();
export const setActiveDesignSuccess = createAction(SetActiveDesignActionTypes.SET_ACTIVE_DESIGN_SUCCESS)();
export const setActiveDesignFailure = createAction(SetActiveDesignActionTypes.SET_ACTIVE_DESIGN_FAILURE)<IErrorActionData>();
export const setActiveDesignReset = createAction(SetActiveDesignActionTypes.SET_ACTIVE_DESIGN_RESET)();

export type SetActiveDesignAction = ActionType<typeof setActiveDesign>;
export type SetActiveDesignSuccessAction = ActionType<typeof setActiveDesignSuccess>;
export type SetActiveDesignFailureAction = ActionType<typeof setActiveDesignFailure>;
export type SetActiveDesignResetAction = ActionType<typeof setActiveDesignReset>;

export type SetActiveDesignActions =
  | SetActiveDesignAction
  | SetActiveDesignSuccessAction
  | SetActiveDesignFailureAction
  | SetActiveDesignResetAction;
