import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GetShipmentInvoiceUrlActionTypes {
  GET_SHIPMENT_INVOICE_URL = 'GET_SHIPMENT_INVOICE_URL',
  GET_SHIPMENT_INVOICE_URL_SUCCESS = 'GET_SHIPMENT_INVOICE_URL_SUCCESS',
  GET_SHIPMENT_INVOICE_URL_FAILED = 'GET_SHIPMENT_INVOICE_URL_FAILED',
  GET_SHIPMENT_INVOICE_URL_RESET = 'GET_SHIPMENT_INVOICE_URL_RESET',
}

export interface IGetShipmentInvoiceUrlActionData {
  invoiceAliasId: string;
}

export interface IGetShipmentInvoiceUrlSuccessActionData {
  invoiceAliasId: string;
  invoicePresignedUrl: string;
}
interface IGetShipmentInvoiceUrlErrorActionData extends IErrorActionData {
  invoiceAliasId: string;
}

export const getShipmentInvoiceUrl = createAction(GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL)
<IGetShipmentInvoiceUrlActionData>();
export const getShipmentInvoiceUrlSuccess = createAction(GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL_SUCCESS)
<IGetShipmentInvoiceUrlSuccessActionData>();
export const getShipmentInvoiceUrlFailure = createAction(GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL_FAILED)
<IGetShipmentInvoiceUrlErrorActionData>();
export const getShipmentInvoiceUrlReset = createAction(GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL_RESET)();

export type GetShipmentInvoiceUrlAction = ActionType<typeof getShipmentInvoiceUrl>;
type GetShipmentInvoiceUrlSuccessAction = ActionType<typeof getShipmentInvoiceUrlSuccess>;
type GetShipmentInvoiceUrlFailureAction = ActionType<typeof getShipmentInvoiceUrlFailure>;
type GetShipmentInvoiceUrlResetAction = ActionType<typeof getShipmentInvoiceUrlReset>;

export type GetShipmentInvoiceUrlActions = GetShipmentInvoiceUrlAction
| GetShipmentInvoiceUrlSuccessAction
| GetShipmentInvoiceUrlFailureAction
| GetShipmentInvoiceUrlResetAction;
