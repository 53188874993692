/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getStoreListSuccess,
  getStoreListFailure,
  GetStoreListAction,
  GetStoreListActionTypes,
} from '../actions/GetStoreList';
import getStoreListService from '../services/GetStoreList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetStoreListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetStoreListActionTypes.GET_SORE_LIST_ACTION)),
  mergeMap((action: GetStoreListAction) => from(
    getStoreListService(action.payload.page),
  ).pipe(
    map((response: any) => getStoreListSuccess({
      storeListData: response.data.getStoreList.data || [],
      pageInfo: response.data.getStoreList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getStoreListFailure({
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetStoreListEpic;
