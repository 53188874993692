import { Reducer } from 'redux';

import { StartProcessingOrderActionTypes, StartProcessingOrderActions } from '../actions/StartProcessingOrder';
import { IErrorActionData } from '../../../models/Error';

export interface IStartProcessingOrderState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialStartProcessingOrderState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IStartProcessingOrderState;

const StartProcessingOrderReducer: Reducer<IStartProcessingOrderState, StartProcessingOrderActions> = (
  state = initialStartProcessingOrderState,
  action: StartProcessingOrderActions,
): IStartProcessingOrderState => {
  switch (action.type) {
    case StartProcessingOrderActionTypes.START_PROCESSING_ORDER:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case StartProcessingOrderActionTypes.START_PROCESSING_ORDER_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case StartProcessingOrderActionTypes.START_PROCESSING_ORDER_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case StartProcessingOrderActionTypes.START_PROCESSING_ORDER_RESET:
      return initialStartProcessingOrderState;

    default:
      return state;
  }
};

export default StartProcessingOrderReducer;
