import { IErrorActionData } from '../../../models/Error';

export interface ILedgerTxnInvoiceUrlData {
  presignedInvoiceUrl?: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class LedgerTxnInvoiceUrlData {
  ledgerTxnInvoiceUrlData: Record<string, ILedgerTxnInvoiceUrlData> = {};
  constructor(ledgerTxnInvoiceUrlData: Record<string, ILedgerTxnInvoiceUrlData>) {
    this.ledgerTxnInvoiceUrlData = ledgerTxnInvoiceUrlData;
  }
}

export const addPresignedUrlToExisting = (existingPresignedURL: Record<string, ILedgerTxnInvoiceUrlData>,
  invoiceAliasId: string, ledgerTxnInvoiceUrlData: ILedgerTxnInvoiceUrlData) => (
  { ...existingPresignedURL, [invoiceAliasId]: ledgerTxnInvoiceUrlData }
);
