import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const CANCEL_CART = gql`
  query cancelCart($path: String!, $data: any) {
    cancelCart(input: $data, path: $path) 
      @rest(method: "DELETE", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const cancelCartService = (cartAliasId: string) => {
  const url = URL.cancelCart.replace('{cartAliasId}', cartAliasId);
  return client.query({ query: CANCEL_CART, variables: { path: url } });
};

export default cancelCartService;
