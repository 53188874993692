import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IUpdateInfluencerPayload } from '../models/UpdateInfluencer';

export enum UpdateInfluencerActionTypes {
  UPDATE_INFLUENCER = 'UPDATE_INFLUENCER',
  UPDATE_INFLUENCER_SUCCESS = 'UPDATE_INFLUENCER_SUCCESS',
  UPDATE_INFLUENCER_FAILURE = 'UPDATE_INFLUENCER_FAILURE',
  UPDATE_INFLUENCER_RESET = 'UPDATE_INFLUENCER_RESET',
}

interface IUpdateInfluencer {
  influencerAliasId: string;
  data: IUpdateInfluencerPayload;
}

export const updateInfluencer = createAction(UpdateInfluencerActionTypes.UPDATE_INFLUENCER)<IUpdateInfluencer>();
export const updateInfluencerSuccess = createAction(UpdateInfluencerActionTypes.UPDATE_INFLUENCER_SUCCESS)();
export const updateInfluencerFailure = createAction(UpdateInfluencerActionTypes.UPDATE_INFLUENCER_FAILURE)<IErrorActionData>();
export const updateInfluencerReset = createAction(UpdateInfluencerActionTypes.UPDATE_INFLUENCER_RESET)();

export type UpdateInfluencerAction = ActionType<typeof updateInfluencer>;
type UpdateInfluencerSuccessAction = ActionType<typeof updateInfluencerSuccess>;
type UpdateInfluencerFailureAction = ActionType<typeof updateInfluencerFailure>;
type UpdateInfluencerResetAction = ActionType<typeof updateInfluencerReset>;

export type UpdateInfluencerActions =
  | UpdateInfluencerAction
  | UpdateInfluencerSuccessAction
  | UpdateInfluencerFailureAction
  | UpdateInfluencerResetAction;
