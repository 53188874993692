import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';

export enum GetDisplayRequestListUrlActionTypes {
  GET_DISPLAY_REQUEST_LIST_URL = 'GET_DISPLAY_REQUEST_LIST_URL',
  GET_DISPLAY_REQUEST_LIST_URL_SUCCESS = 'GET_DISPLAY_REQUEST_LIST_URL_SUCCESS',
  GET_DISPLAY_REQUEST_LIST_URL_FAILED = 'GET_DISPLAY_REQUEST_LIST_URL_FAILED',
  GET_DISPLAY_REQUEST_LIST_URL_RESET = 'GET_DISPLAY_REQUEST_LIST_URL_RESET',
}

export enum AvailableFilterProperty {
  recommendationForSm = 'recommendationForSm',
}
export interface IGetDisplayRequestListUrlActionData {
  processAliasId: string;
  filter?: IFilter[];
}

export interface IGetDisplayRequestListUrlSuccessActionData {
  data: string;
}

export const getDisplayRequestListUrl = createAction(GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL)
<IGetDisplayRequestListUrlActionData>();
export const getDisplayRequestListUrlSuccess = createAction(GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL_SUCCESS)
<IGetDisplayRequestListUrlSuccessActionData>();
export const getDisplayRequestListUrlFailure = createAction(GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL_FAILED)
<IErrorActionData>();
export const getDisplayRequestListUrlReset = createAction(GetDisplayRequestListUrlActionTypes.GET_DISPLAY_REQUEST_LIST_URL_RESET)();

export type GetDisplayRequestListUrlAction = ActionType<typeof getDisplayRequestListUrl>;
type GetDisplayRequestListUrlSuccessAction = ActionType<typeof getDisplayRequestListUrlSuccess>;
type GetDisplayRequestListUrlFailureAction = ActionType<typeof getDisplayRequestListUrlFailure>;
type GetDisplayRequestListUrlResetAction = ActionType<typeof getDisplayRequestListUrlReset>;

export type GetDisplayRequestListUrlActions = GetDisplayRequestListUrlAction
| GetDisplayRequestListUrlSuccessAction
| GetDisplayRequestListUrlFailureAction
| GetDisplayRequestListUrlResetAction;
