import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { INewCartPayload } from '../models/NewCartDetails';

const CREATE_CART = gql`
  query createCart($path: String!, $payload: any) {
    createCart (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const createCartService = (payload: INewCartPayload) => {
  const url = URL.createCart;
  return client.query({
    query: CREATE_CART,
    variables: {
      path: url,
      payload,
    },
  });
};

export default createCartService;
