/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  GetPincodeInfoAction,
  GetPincodeInfoActionTypes,
  getPincodeInfoFailure,
  getPincodeInfoSuccess,
} from '../actions/getPincodeInfo';
import GetPincodeInfoService from '../services/getPincodeInfo';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import { IErrorActionData } from '../../../models/Error';

const GetPincodeInfoEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetPincodeInfoActionTypes.GET_PINCODE_INFO)),
  mergeMap((action: GetPincodeInfoAction) => from(
    GetPincodeInfoService(
      action.payload.filter,
    ),
  ).pipe(
    map((response) => getPincodeInfoSuccess({
      data: response.data.getPincodeInfo.data,
    })),
    catchError((error: IErrorActionData) => of(
      getPincodeInfoFailure(
        {
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          callBack: error?.callBack,
        },
      ),
    )),
  )),
);

export default GetPincodeInfoEpic;
