interface IStoreAddress {
  addressLine1: string,
  addressLine2: string,
  city: string,
  country: string,
  district: string,
  landmark: string,
  latitude: number,
  longitude: number,
  name: string,
  pincode: string,
  state: string
}

export interface IStoreDetails {
  storeAliasId: string;
  name: string;
  storeType: string;
  paymentDispatch: string;
  paymentProcessing: string;
  priceListId: string;
  address: IStoreAddress;
}

export class StoreDetails {
  storeDetails = {} as IStoreDetails;
  constructor(storeDetails: IStoreDetails) {
    this.storeDetails = storeDetails;
  }
}

interface StoreDetailsAttributes {
  key: string;
  value: string;
}

export interface StoreDetailsPayload {
  storeAliasId: string;
  name: string;
  storeType: string;
  address: IStoreAddress;
  attributes: StoreDetailsAttributes[];
}

export const getFormattedStoreDetails = (storeDetails: StoreDetailsPayload) => {
  const paymentDispatch = storeDetails.attributes.find((attribute) => attribute.key === 'payment_dispatch')?.value || '100';
  const paymentProcessing = storeDetails.attributes.find((attribute) => attribute.key === 'payment_processing')?.value || '50';
  const priceListId = storeDetails.attributes.find((attribute) => attribute.key === 'price_list_id')?.value || '';

  return {
    storeAliasId: storeDetails.storeAliasId,
    name: storeDetails.name,
    storeType: storeDetails.storeType,
    paymentDispatch,
    paymentProcessing,
    priceListId,
    address: storeDetails.address,
  } as IStoreDetails;
};
