import { Reducer } from 'redux';

import { GetCustomerDetailsActions, GetCustomerDetailsActionTypes } from '../actions/GetCustomerDetails';
import { Customer, ICustomerDetails } from '../models/CustomerDetails';
import { IErrorActionData } from '../../../models/Error';

export interface IGetCustomerDetailsState {
  customerDetails: Customer;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetCustomerDetailsState = {
  customerDetails: new Customer({} as ICustomerDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetCustomerDetailsState;

const GetCustomerDetailsReducer: Reducer<IGetCustomerDetailsState, GetCustomerDetailsActions> = (
  state = initialGetCustomerDetailsState,
  action: GetCustomerDetailsActions,
): IGetCustomerDetailsState => {
  switch (action.type) {
    case GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };
    case GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerDetails: new Customer(action.payload.data),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    case GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };
    case GetCustomerDetailsActionTypes.GET_CUSTOMER_DETAILS_RESET:
      return initialGetCustomerDetailsState;

    default:
      return state;
  }
};

export default GetCustomerDetailsReducer;
