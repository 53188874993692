import mixpanel, { Dict, Mixpanel } from 'mixpanel-browser';
import { AnalyticsInterface } from './interface';

class MixpanelLayer implements AnalyticsInterface {
  mixPanel: Mixpanel;
  constructor() {
    this.mixPanel = mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ?? '', { debug: true }) ?? {} as Mixpanel;
  }

  eventTrack = (eventName: string, properties?: Dict) => {
    mixpanel.track(eventName, properties);
  };

  setUser = (distinctKey: string, userName: string, userPhone: string) => {
    mixpanel.identify(distinctKey);
    mixpanel.people.set('$CleverTap_user_id', distinctKey);
    mixpanel.people.set('$name', userName);
    mixpanel.people.set('$phone', userPhone);
  };
}

const mixpanelLayer = new MixpanelLayer();

export default mixpanelLayer;
