import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { UpdateAddressActions, UpdateAddressActionTypes } from '../actions/UpdateAddress';

export interface IUpdateAddressState {
  stateUpdate: boolean;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialUpdateAddressState: IUpdateAddressState = {
  stateUpdate: false,
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const UpdateAddressReducer: Reducer<IUpdateAddressState, UpdateAddressActions> = (
  state = initialUpdateAddressState,
  action: UpdateAddressActions,
): IUpdateAddressState => {
  switch (action.type) {
    case UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case UpdateAddressActionTypes.UPDATE_ADDRESS_ACTION_RESET:
      return initialUpdateAddressState;

    default:
      return state;
  }
};

export default UpdateAddressReducer;
