import { Reducer } from 'redux';

import { EditSKUDeliveryDateActionTypes, EditSKUDeliveryDateActions } from '../actions/EditSKUDeliveryDate';
import { IErrorActionData } from '../../../models/Error';

export interface IEditSKUDeliveryDateState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialEditSKUDeliveryDateState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IEditSKUDeliveryDateState;

const EditSKUDeliveryDateReducer: Reducer<IEditSKUDeliveryDateState, EditSKUDeliveryDateActions> = (
  state = initialEditSKUDeliveryDateState,
  action: EditSKUDeliveryDateActions,
): IEditSKUDeliveryDateState => {
  switch (action.type) {
    case EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case EditSKUDeliveryDateActionTypes.EDIT_SKU_DELIVERY_DATE_RESET:
      return initialEditSKUDeliveryDateState;

    default:
      return state;
  }
};

export default EditSKUDeliveryDateReducer;
