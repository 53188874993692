import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { InventoryData, addInventory } from '../models/checkInventory';
import { CheckInventoryActionTypes, CheckInventoryActions } from '../actions/checkInventory';
import { IPageInfo } from '../../../models/PageInfo';

export interface ICheckInventoryState {
  inventoryInfo: InventoryData;
  isProcessing: boolean;
  isSuccess: boolean;
  pageInfo: IPageInfo;
  error?: IErrorActionData;
}

const initialCheckInventoryState = {
  inventoryInfo: {} as InventoryData,
  isProcessing: false,
  isSuccess: false,
  pageInfo: {},
  error: undefined,
} as ICheckInventoryState;

const CheckInventoryReducer: Reducer<ICheckInventoryState, CheckInventoryActions> = (
  state = initialCheckInventoryState,
  action: CheckInventoryActions,
): ICheckInventoryState => {
  switch (action.type) {
    case CheckInventoryActionTypes.CHECK_INVENTORY: {
      const updateWarehouseData = addInventory(
        state.inventoryInfo.inventoryData,
        action.payload.skuId,
        { isProcessing: true, isSuccess: false, inventoryInfo: [] },
      );
      return { ...state, inventoryInfo: new InventoryData(updateWarehouseData) };
    }

    case CheckInventoryActionTypes.CHECK_INVENTORY_SUCCESS: {
      const updateWarehouseData = addInventory(
        state.inventoryInfo.inventoryData,
        action.payload.skuId,
        { isProcessing: false, isSuccess: true, inventoryInfo: action.payload.inventoryInfo },
      );
      return {
        ...state,
        inventoryInfo: new InventoryData(updateWarehouseData),
        pageInfo: action.payload.pageInfo,
      };
    }

    case CheckInventoryActionTypes.CHECK_INVENTORY_FAILED: {
      const updateWarehouseData = addInventory(
        state.inventoryInfo.inventoryData,
        action.payload.skuId,
        {
          isProcessing: false,
          isSuccess: false,
          inventoryInfo: [],
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, inventoryInfo: new InventoryData(updateWarehouseData) };
    }

    case CheckInventoryActionTypes.CHECK_INVENTORY_RESET:
      return initialCheckInventoryState;

    default:
      return state;
  }
};

export default CheckInventoryReducer;
