import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GenerateInvoiceActionTypes {
  GENERATE_INVOICE = 'GENERATE_INVOICE',
  GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS',
  GENERATE_INVOICE_FAILED = 'GENERATE_INVOICE_FAILED',
  GENERATE_INVOICE_RESET = 'GENERATE_INVOICE_RESET',
}

export interface IGenerateInvoiceActionData {
  orderReferenceId: string;
}

export interface IGenerateInvoiceSuccessActionData {
  invoiceAliasId: string;
}

export const generateInvoice = createAction(GenerateInvoiceActionTypes.GENERATE_INVOICE)
<IGenerateInvoiceActionData>();
export const generateInvoiceSuccess = createAction(GenerateInvoiceActionTypes.GENERATE_INVOICE_SUCCESS)
<IGenerateInvoiceSuccessActionData>();
export const generateInvoiceFailure = createAction(GenerateInvoiceActionTypes.GENERATE_INVOICE_FAILED)
<IErrorActionData>();
export const generateInvoiceReset = createAction(GenerateInvoiceActionTypes.GENERATE_INVOICE_RESET)();

export type GenerateInvoiceAction = ActionType<typeof generateInvoice>;
type GenerateInvoiceSuccessAction = ActionType<typeof generateInvoiceSuccess>;
type GenerateInvoiceFailureAction = ActionType<typeof generateInvoiceFailure>;
type GenerateInvoiceResetAction = ActionType<typeof generateInvoiceReset>;

export type GenerateInvoiceActions = GenerateInvoiceAction
| GenerateInvoiceSuccessAction
| GenerateInvoiceFailureAction
| GenerateInvoiceResetAction;
