/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import {
  GetInfluencerDetailsAction,
  GetInfluencerDetailsActionTypes,
  getInfluencerDetailsFailed,
  getInfluencerDetailsSuccess,
} from '../actions/GetInfluencerDetails';
import getInfluencerDetailsService from '../services/GetInfluencerDetails';

const GetInfluencerDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetInfluencerDetailsActionTypes.GET_INFLUENCER_DETAILS)),
  mergeMap((action: GetInfluencerDetailsAction) => from(
    getInfluencerDetailsService(action.payload.filter),
  ).pipe(
    map((response: any) => getInfluencerDetailsSuccess({
      data: response.data.getInfluencerDetails.data,
    })),
    catchError((error: IErrorActionData) => of(
      getInfluencerDetailsFailed({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetInfluencerDetailsEpic;
