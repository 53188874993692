import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import WifiOffRounded from '@mui/icons-material/WifiOffRounded';

import useStyles from './styles';

const NetworkErrorToast: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Box className={classes.networkErrorToastContainer}>
      <WifiOffRounded className={classes.offlineIcon} />
      <Typography className={classes.toastSubTitle}>{t('noNetwork')}</Typography>
      <Button className={classes.toastTryAgainButton} onClick={refreshPage}>{t('tryAgain')}</Button>
    </Box>
  );
};

export default NetworkErrorToast;
