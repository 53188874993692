import { IErrorActionData } from '../../../models/Error';

export interface IQuotationUrlData {
  presignedQuotationUrl?: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class QuotationUrlData {
  quotationUrlData: Record<string, IQuotationUrlData> = {};
  constructor(quotationUrlData: Record<string, IQuotationUrlData>) {
    this.quotationUrlData = quotationUrlData;
  }
}

export const addPresignedUrlToExisting = (existingPresignedURL: Record<string, IQuotationUrlData>,
  quotationAliasId: string, quotationUrlData: IQuotationUrlData) => (
  { ...existingPresignedURL, [quotationAliasId]: quotationUrlData }
);
