import { Reducer } from 'redux';

import { DeleteCartItemActionTypes, DeleteCartItemActions } from '../actions/DeleteCartItem';
import { IErrorActionData } from '../../../models/Error';

export interface IDeleteCartItemState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDeleteCartItemState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDeleteCartItemState;

const DeleteCartItemReducer: Reducer<IDeleteCartItemState, DeleteCartItemActions> = (
  state = initialDeleteCartItemState,
  action: DeleteCartItemActions,
): IDeleteCartItemState => {
  switch (action.type) {
    case DeleteCartItemActionTypes.DELETE_CART_ITEM:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case DeleteCartItemActionTypes.DELETE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case DeleteCartItemActionTypes.DELETE_CART_ITEM_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case DeleteCartItemActionTypes.DELETE_CART_ITEM_RESET:
      return initialDeleteCartItemState;

    default:
      return state;
  }
};

export default DeleteCartItemReducer;
