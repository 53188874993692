import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IDisplayInventorySummaryDetails } from '../models/GetDisplayInventorySummary';

export enum GetDisplayInventorySummaryActionTypes {
  GET_DISPLAY_INVENTORY_SUMMARY = 'GET_DISPLAY_INVENTORY_SUMMARY',
  GET_DISPLAY_INVENTORY_SUMMARY_SUCCESS = 'GET_DISPLAY_INVENTORY_SUMMARY_SUCCESS',
  GET_DISPLAY_INVENTORY_SUMMARY_FAILED = 'GET_DISPLAY_INVENTORY_SUMMARY_FAILED',
  GET_DISPLAY_INVENTORY_SUMMARY_RESET = 'GET_DISPLAY_INVENTORY_SUMMARY_RESET',
}

export interface IGetDisplaySummaryActionData {
  storeAliasId: string;
}

export interface IGetDisplaySummarySuccessActionData {
  data: IDisplayInventorySummaryDetails;
}

export const getDisplayInventorySummary = createAction(
  GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY,
)<IGetDisplaySummaryActionData>();
export const getDisplayInventorySummarySuccess = createAction(
  GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY_SUCCESS,
)<IGetDisplaySummarySuccessActionData>();
export const getDisplayInventorySummaryFailure = createAction(
  GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY_FAILED,
)<IErrorActionData>();
export const getDisplayInventorySummaryReset = createAction(GetDisplayInventorySummaryActionTypes.GET_DISPLAY_INVENTORY_SUMMARY_RESET)();

export type GetDisplayInventorySummaryAction = ActionType<typeof getDisplayInventorySummary>;
type GetDisplayInventorySummarySuccessAction = ActionType<typeof getDisplayInventorySummarySuccess>;
type GetDisplayInventorySummaryFailureAction = ActionType<typeof getDisplayInventorySummaryFailure>;
type GetDisplayInventorySummaryResetAction = ActionType<typeof getDisplayInventorySummaryReset>;

export type GetDisplayInventorySummaryActions = GetDisplayInventorySummaryAction
| GetDisplayInventorySummarySuccessAction
| GetDisplayInventorySummaryFailureAction
| GetDisplayInventorySummaryResetAction;
