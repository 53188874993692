import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IRefundListItem } from '../models/GetRefundList';

export enum GetRefundListActionTypes {
  GET_REFUND_LIST = 'GET_REFUND_LIST',
  GET_REFUND_LIST_SUCCESS = 'GET_REFUND_LIST_SUCCESS',
  GET_REFUND_LIST_FAILED = 'GET_REFUND_LIST_FAILED',
  GET_REFUND_LIST_RESET = 'GET_REFUND_LIST_RESET',
}

export enum AvailableFilterProperty {
  customerId = 'customerId',
  refundAliasId = 'refundId',
  refundStartDate = 'refundStartDate',
  refundEndDate = 'refundEndDate',
}

export interface IGetRefundListActionData {
  storeAliasId: string;
  filter?: IFilter[];
  page?: IPage;
}
export interface IGetRefundListSuccessActionData {
  refundList: Array<IRefundListItem>;
  pageInfo: IPageInfo;
}

export const getRefundList = createAction(GetRefundListActionTypes.GET_REFUND_LIST)<IGetRefundListActionData>();
export const getRefundListSuccess = createAction(GetRefundListActionTypes.GET_REFUND_LIST_SUCCESS)
<IGetRefundListSuccessActionData>();
export const getRefundListFailure = createAction(GetRefundListActionTypes.GET_REFUND_LIST_FAILED)
<IErrorActionData>();
export const getRefundListReset = createAction(GetRefundListActionTypes.GET_REFUND_LIST_RESET)();

export type GetRefundListAction = ActionType<typeof getRefundList>;
type GetRefundListSuccessAction = ActionType<typeof getRefundListSuccess>;
type GetRefundListFailedAction = ActionType<typeof getRefundListFailure>;
type GetRefundListResetAction = ActionType<typeof getRefundListReset>;

export type GetRefundListActions =
  | GetRefundListAction
  | GetRefundListSuccessAction
  | GetRefundListFailedAction
  | GetRefundListResetAction;
