/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  downloadCashTransactionsReportSuccess,
  downloadCashTransactionsReportFailure,
  DownloadCashTransactionsReportAction,
  DownloadCashTransactionsReportActionTypes,
} from '../actions/DownloadCashTransactionsReport';
import downloadCashTransactionsReportService from '../services/DownloadCashTransactionsReport';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const DownloadCashTransactionsReportEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(DownloadCashTransactionsReportActionTypes.DOWNLOAD_CASH_TRANSACTIONS_REPORT)),
  mergeMap((action: DownloadCashTransactionsReportAction) => from(
    downloadCashTransactionsReportService(
      action.payload.storeAliasId,
      action.payload.payloadDetails,
    ),
  ).pipe(
    map((response) => downloadCashTransactionsReportSuccess({
      requestId: response.data.downloadCashTransactionsReport.data.requestId,
    })),
    catchError((error: IErrorActionData) => of(
      downloadCashTransactionsReportFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default DownloadCashTransactionsReportEpic;
