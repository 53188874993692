import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const EDIT_ORDER = gql`
  query editOrder($path: String!, $data: any) {
    editOrder(input: $data, path: $path) 
      @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const editOrderService = (orderReferenceId: string) => {
  const url = URL.editOrder.replace('{orderReferenceId}', orderReferenceId);
  return client.query({ query: EDIT_ORDER, variables: { path: url } });
};

export default editOrderService;
