import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IInitiateOrderPaymentPayload, IInitiateOrderPaymentSuccessActionData } from '../models/InitiateOrderPayment';

export enum InitiateOrderPaymentActionTypes {
  INITIATE_ORDER_PAYMENT = 'INITIATE_ORDER_PAYMENT',
  INITIATE_ORDER_PAYMENT_SUCCESS = 'INITIATE_ORDER_PAYMENT_SUCCESS',
  INITIATE_ORDER_PAYMENT_FAILURE = 'INITIATE_ORDER_PAYMENT_FAILURE',
  INITIATE_ORDER_PAYMENT_RESET = 'INITIATE_ORDER_PAYMENT_RESET',
}

export interface IInitiateOrderPaymentActionData {
  orderReferenceId: string;
  paymentDetails: IInitiateOrderPaymentPayload;
}

export const initiateOrderPayment = createAction(InitiateOrderPaymentActionTypes.INITIATE_ORDER_PAYMENT)<IInitiateOrderPaymentActionData>();
export const initiateOrderPaymentSuccess = createAction(InitiateOrderPaymentActionTypes.INITIATE_ORDER_PAYMENT_SUCCESS)
<IInitiateOrderPaymentSuccessActionData>();
export const initiateOrderPaymentFailure = createAction(InitiateOrderPaymentActionTypes.INITIATE_ORDER_PAYMENT_FAILURE)<IErrorActionData>();
export const initiateOrderPaymentReset = createAction(InitiateOrderPaymentActionTypes.INITIATE_ORDER_PAYMENT_RESET)();

export type InitiateOrderPaymentAction = ActionType<typeof initiateOrderPayment>;
type InitiateOrderPaymentSuccessAction = ActionType<typeof initiateOrderPaymentSuccess>;
type InitiateOrderPaymentFailureAction = ActionType<typeof initiateOrderPaymentFailure>;
type InitiateOrderPaymentResetAction = ActionType<typeof initiateOrderPaymentReset>;

export type InitiateOrderPaymentActions =
  | InitiateOrderPaymentAction
  | InitiateOrderPaymentSuccessAction
  | InitiateOrderPaymentFailureAction
  | InitiateOrderPaymentResetAction;
