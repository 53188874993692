interface IAddressInfo {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}

interface ICustomerInfo {
  customerId: string;
  firstName: string;
  lastName: string;
  gst: string;
  panNumber: string;
  phoneNumber: string;
}

export interface IOrderCustomerDetails {
  billingAddressInfo: IAddressInfo;
  customerInfo: ICustomerInfo;
  shippingAddressInfo: IAddressInfo;
}

export class OrderCustomerDetails {
  orderCustomerDetails = {} as IOrderCustomerDetails;
  constructor(orderCustomerDetails: IOrderCustomerDetails) {
    this.orderCustomerDetails = orderCustomerDetails;
  }
}
