/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import RootAction from '../../../config/store/RootAction';
import RootState from '../../../config/store/RootState';
import { IErrorActionData } from '../../../models/Error';
import {
  GetDealDetailsAction,
  GetDealDetailsActionTypes,
  getDealDetailsFailure,
  getDealDetailsSuccess,
} from '../actions/GetDealDetails';
import GetDealDetailsService from '../services/GetDealDetails';

const GetDealDetailsEpic = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) => action$.pipe(
  filter(isOfType(GetDealDetailsActionTypes.GET_DEAL_DETAILS)),
  mergeMap((action: GetDealDetailsAction) => from(
    GetDealDetailsService(action.payload.dealAliasId),
  ).pipe(
    map((response: any) => getDealDetailsSuccess({ dealDetails: response.data.getDealDetails.data })),
    catchError((error: IErrorActionData) => of(
      getDealDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDealDetailsEpic;
