import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_CART_SUMMARY = gql`
  query getCartSummary($path: String!) {
    getCartSummary @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getCartSummaryService = (cartAliasId: string, filter?: IFilter[]) => {
  const url = constructUrlWithParams(URL.cartSummary, filter, undefined, undefined);
  return client.query({ query: GET_CART_SUMMARY, variables: { path: url.replace('{cartAliasId}', cartAliasId) } });
};

export default getCartSummaryService;
