import { combineReducers } from 'redux';
import GetDisplayInventorySummaryReducer from './GetDisplayInventorySummary';
import GetDisplayInventoryListReducer from './GetDisplayInventoryList';
import GetRecommendationTaskSummaryReducer from './GetRecommendationTaskSummary';
import GetDisplayInventoryListUrlReducer from './GetDisplayInventoryListUrl';
import CompleteTaskReducer from './CompleteTask';
import SaveProgressReducer from './SaveProgress';
import GetDisplayInventoryRecommendationListReducer from './GetDisplayInventoryRecommendationList';
import GetDisplayRequestListUrlReducer from './GetDisplayRequestUrl';

const DisplayManagementReducers = combineReducers({
  getDisplayInventorySummary: GetDisplayInventorySummaryReducer,
  displayInventoryList: GetDisplayInventoryListReducer,
  getRecommendationTaskSummary: GetRecommendationTaskSummaryReducer,
  getDisplayInventoryListUrl: GetDisplayInventoryListUrlReducer,
  getDisplayInventoryRecommendationList: GetDisplayInventoryRecommendationListReducer,
  completeTask: CompleteTaskReducer,
  saveProgress: SaveProgressReducer,
  getDisplayRequestListUrl: GetDisplayRequestListUrlReducer,
});

export default DisplayManagementReducers;
