import { Reducer } from 'redux';

import { SOCancellationDetails } from '../models/SOCancellationDetails';
import { GetSOCancellationDetailsActionTypes, GetSOCancellationDetailsActions } from '../actions/GetSOCancellationDetails';
import { IErrorActionData } from '../../../models/Error';

export interface IGetSOCancellationDetailsState {
  cancellationSODetails: SOCancellationDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialSOCancellationDetailsState = {
  cancellationSODetails: new SOCancellationDetails([]),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetSOCancellationDetailsState;

const GetSOCancellationDetailsReducer: Reducer<IGetSOCancellationDetailsState, GetSOCancellationDetailsActions> = (
  state = initialSOCancellationDetailsState,
  action: GetSOCancellationDetailsActions,
): IGetSOCancellationDetailsState => {
  switch (action.type) {
    case GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS_SUCCESS: {
      return {
        ...state,
        cancellationSODetails: new SOCancellationDetails(action.payload.cancellationSODetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetSOCancellationDetailsActionTypes.GET_SO_CANCELLATION_DETAILS_RESET:
      return initialSOCancellationDetailsState;

    default:
      return state;
  }
};

export default GetSOCancellationDetailsReducer;
