import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const START_PROCESSING_ORDER = gql`
  query startProcessingOrder($path: String!, $payload: any) {
    startProcessingOrder (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const startProcessingOrderService = (orderReferenceId: string) => client.query({
  query: START_PROCESSING_ORDER,
  variables: {
    path: URL.startProcessingOrder.replace('{orderReferenceId}', orderReferenceId),
    payload: { test: 'test' }, //  TODO: Remove the test parameter when BE resolves the 412 error
  },
});

export default startProcessingOrderService;
