import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { EditOrderActions, EditOrderActionTypes } from '../actions/EditOrder';

export interface IEditOrderState {
  cartAliasId: string;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialEditOrderState: IEditOrderState = {
  cartAliasId: '',
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const EditOrderReducer: Reducer<IEditOrderState, EditOrderActions> = (
  state = initialEditOrderState,
  action: EditOrderActions,
): IEditOrderState => {
  switch (action.type) {
    case EditOrderActionTypes.EDIT_ORDER_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case EditOrderActionTypes.EDIT_ORDER_ACTION_SUCCESS:
      return {
        ...state,
        cartAliasId: action.payload.cartAliasId,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case EditOrderActionTypes.EDIT_ORDER_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case EditOrderActionTypes.EDIT_ORDER_ACTION_RESET:
      return initialEditOrderState;

    default:
      return state;
  }
};

export default EditOrderReducer;
