/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getQuotationUrlSuccess,
  getQuotationUrlFailure,
  GetQuotationUrlAction,
  GetQuotationUrlActionTypes,
} from '../actions/GetQuotationUrl';
import getQuotationUrlService from '../services/GetQuotationUrl';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetQuotationUrlEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetQuotationUrlActionTypes.GET_QUOTATION_URL)),
  mergeMap((action: GetQuotationUrlAction) => from(
    getQuotationUrlService(action.payload.quotationAliasId),
  ).pipe(
    map((response) => getQuotationUrlSuccess({
      quotationPresignedUrl: response.data.getQuotationUrl.data.preSignedUrl || '',
      quotationAliasId: action.payload.quotationAliasId,
    })),
    catchError((error: IErrorActionData) => of(
      getQuotationUrlFailure({
        quotationAliasId: action.payload.quotationAliasId,
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetQuotationUrlEpic;
