import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_PROFORMA_INVOICE_URL = gql`
  query getQuotationUrl($path: String!) {
    getQuotationUrl @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getQuotationUrlService = (quotationAliasId: string) => client.query({
  query: GET_PROFORMA_INVOICE_URL,
  variables: {
    path: URL.getQuotationUrl.replace('{quotationAliasId}', quotationAliasId),
  },
});

export default getQuotationUrlService;
