import { Reducer } from 'redux';

import { AccountStatement, mergeTwoLists } from '../models/GetAccountStatement';
import { GetAccountStatementInfoActionTypes, GetAccountStatementInfoActions } from '../actions/GetAccountStatement';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IAccountStatementInfoState {
  accountStatement: AccountStatement;
  customerAliasId: string;
  openingBalance: number;
  invoicedAmount: number;
  amountReceived: number;
  balanceDue: number;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialAccountStatementInfoState = {
  accountStatement: new AccountStatement([]),
  openingBalance: 0,
  invoicedAmount: 0,
  amountReceived: 0,
  pageInfo: new PageInfo({
    pageSize: defaultPageSize,
    pageNumber: 0,
    hasNext: false,
    cursor: 0,
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IAccountStatementInfoState;

const GetAccountStatementReducer: Reducer<IAccountStatementInfoState, GetAccountStatementInfoActions> = (
  state = initialAccountStatementInfoState,
  action: GetAccountStatementInfoActions,
): IAccountStatementInfoState => {
  switch (action.type) {
    case GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO_SUCCESS: {
      const newAccountStatementInfoState = mergeTwoLists(
        state.accountStatement.accountStatement,
        action.payload.accountStatement,
      );
      return {
        ...state,
        accountStatement: new AccountStatement(newAccountStatementInfoState),
        customerAliasId: action.payload.customerAliasId,
        openingBalance: action.payload.openingBalance,
        invoicedAmount: action.payload.invoicedAmount,
        amountReceived: action.payload.amountReceived,
        balanceDue: action.payload.balanceDue,
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
          cursor: action.payload.pageInfo.cursor,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO_RESET:
      return initialAccountStatementInfoState;

    default:
      return state;
  }
};

export default GetAccountStatementReducer;
