import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GetEWayBillUrlActionTypes {
  GET_E_WAY_BILL_URL = 'GET_E_WAY_BILL_URL',
  GET_E_WAY_BILL_URL_SUCCESS = 'GET_E_WAY_BILL_URL_SUCCESS',
  GET_E_WAY_BILL_URL_FAILED = 'GET_E_WAY_BILL_URL_FAILED',
  GET_E_WAY_BILL_URL_RESET = 'GET_E_WAY_BILL_URL_RESET',
}

export interface IGetEWayBillUrlActionData {
  invoiceAliasId: string;
}

export interface IGetEWayBillUrlSuccessActionData {
  eWayBillAliasId: string;
  eWayBillPresignedUrl: string;
}

interface IGetEWayBillUrlErrorActionData extends IErrorActionData {
  invoiceAliasId: string;
}

export const getEWayBillUrl = createAction(GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL)
<IGetEWayBillUrlActionData>();
export const getEWayBillUrlSuccess = createAction(GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL_SUCCESS)
<IGetEWayBillUrlSuccessActionData>();
export const getEWayBillUrlFailure = createAction(GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL_FAILED)
<IGetEWayBillUrlErrorActionData>();
export const getEWayBillUrlReset = createAction(GetEWayBillUrlActionTypes.GET_E_WAY_BILL_URL_RESET)();

export type GetEWayBillUrlAction = ActionType<typeof getEWayBillUrl>;
type GetEWayBillUrlSuccessAction = ActionType<typeof getEWayBillUrlSuccess>;
type GetEWayBillUrlFailureAction = ActionType<typeof getEWayBillUrlFailure>;
type GetEWayBillUrlResetAction = ActionType<typeof getEWayBillUrlReset>;

export type GetEWayBillUrlActions = GetEWayBillUrlAction
| GetEWayBillUrlSuccessAction
| GetEWayBillUrlFailureAction
| GetEWayBillUrlResetAction;
