import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 400,
    overflowY: 'scroll',
  },
}));

export default useStyles;
