import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CssBaseline } from '@mui/material';
import clsx from 'clsx';

import HeaderBar from './HeaderBar';
import DrawerNavigation from '../../navigation/DrawerNavigation';
import NetworkErrorToast from './NetworkErrorToast';
import useStyles from './styles';

interface IStoreMainLayout {
  children?: React.ReactNode
}

const StoreMainLayout: FunctionComponent<IStoreMainLayout> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  window.onoffline = () => {
    setIsOnline(navigator.onLine);
  };

  window.ononline = () => {
    setIsOnline(navigator.onLine);
  };

  return (
    <>
      <CssBaseline />
      <HeaderBar />
      {!isOnline && <NetworkErrorToast />}
      <div className={classes.root}>
        <DrawerNavigation isOffline={!isOnline} />
        <main className={clsx(classes.main, !isOnline && classes.offlineMain)}>
          {props.children || <></>}
        </main>
      </div>
    </>
  );
};

export default StoreMainLayout;
