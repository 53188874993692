import { Reducer } from 'redux';
import { defaultPageSize } from '../../../constants/Common';

import { IErrorActionData } from '../../../models/Error';
import { PageInfo } from '../../../models/PageInfo';
import { GetSavedCartItemListActions, GetSavedCartItemListActionTypes } from '../actions/GetSavedCartItemList';
import { mergeTwoLists, SavedCartItemList } from '../models/SavedCartItemList';

export interface IGetSavedCartItemListState {
  savedCartItemList: SavedCartItemList;
  pageInfo: PageInfo;
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialGetSavedCartItemListState: IGetSavedCartItemListState = {
  savedCartItemList: new SavedCartItemList([]),
  pageInfo: new PageInfo({ pageSize: defaultPageSize, pageNumber: 0, hasNext: false }),
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const GetSavedCartItemListReducer: Reducer<IGetSavedCartItemListState, GetSavedCartItemListActions> = (
  state = initialGetSavedCartItemListState,
  action: GetSavedCartItemListActions,
): IGetSavedCartItemListState => {
  switch (action.type) {
    case GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST_SUCCESS: {
      const newSavedCartItemList = mergeTwoLists(
        state.savedCartItemList.savedCartItemList,
        action.payload.savedCartItemList,
      );
      return {
        ...state,
        savedCartItemList: new SavedCartItemList(newSavedCartItemList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetSavedCartItemListActionTypes.GET_SAVED_CART_ITEM_LIST_RESET:
      return initialGetSavedCartItemListState;

    default:
      return state;
  }
};

export default GetSavedCartItemListReducer;
