import { Reducer } from 'redux';

import { AddCartItemActionTypes, AddCartItemActions } from '../actions/AddCartItem';
import { IErrorActionData } from '../../../models/Error';
import { INewCartItemDetails, NewCartItemDetails } from '../models/NewCartItemDetails';

export interface IAddCartItemState {
  newCartItem: NewCartItemDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialAddCartItemState = {
  newCartItem: new NewCartItemDetails({} as INewCartItemDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IAddCartItemState;

const AddCartItemReducer: Reducer<IAddCartItemState, AddCartItemActions> = (
  state = initialAddCartItemState,
  action: AddCartItemActions,
): IAddCartItemState => {
  switch (action.type) {
    case AddCartItemActionTypes.ADD_CART_ITEM:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case AddCartItemActionTypes.ADD_CART_ITEM_SUCCESS: {
      return {
        ...state,
        newCartItem: new NewCartItemDetails(action.payload.newCartItemDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case AddCartItemActionTypes.ADD_CART_ITEM_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case AddCartItemActionTypes.ADD_CART_ITEM_RESET:
      return initialAddCartItemState;

    default:
      return state;
  }
};

export default AddCartItemReducer;
