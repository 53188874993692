import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IFilter } from '../../../utils/sortFilterFramework';
import { IPincodeInfo } from '../models/getPincodeInfo';

export enum GetPincodeInfoActionTypes {
  GET_PINCODE_INFO = 'GET_PINCODE_INFO',
  GET_PINCODE_INFO_SUCCESS = 'GET_PINCODE_INFO_SUCCESS',
  GET_PINCODE_INFO_FAILURE = 'GET_PINCODE_INFO_FAILURE',
  GET_PINCODE_INFO_RESET = 'GET_PINCODE_INFO_RESET',
}

export enum AvailableFilterProperty {
  pincode = 'pincode',
}

export interface IGetPincodeInfoActionData {
  filter: IFilter[];
}

export interface IGetPincodeInfoSuccessData {
  data: IPincodeInfo;
}

export const getPincodeInfo = createAction(
  GetPincodeInfoActionTypes.GET_PINCODE_INFO,
)<IGetPincodeInfoActionData>();
export const getPincodeInfoSuccess = createAction(
  GetPincodeInfoActionTypes.GET_PINCODE_INFO_SUCCESS,
)<IGetPincodeInfoSuccessData>();
export const getPincodeInfoFailure = createAction(
  GetPincodeInfoActionTypes.GET_PINCODE_INFO_FAILURE,
)<IErrorActionData>();
export const getPincodeInfoReset = createAction(GetPincodeInfoActionTypes.GET_PINCODE_INFO_RESET)();

export type GetPincodeInfoAction = ActionType<typeof getPincodeInfo>;
type GetPincodeInfoSuccessAction = ActionType<typeof getPincodeInfoSuccess>;
type GetPincodeInfoFailureAction = ActionType<typeof getPincodeInfoFailure>;
type GetPincodeInfoResetAction = ActionType<typeof getPincodeInfoReset>;

export type GetPincodeInfoActions =
  | GetPincodeInfoAction
  | GetPincodeInfoSuccessAction
  | GetPincodeInfoFailureAction
  | GetPincodeInfoResetAction;
