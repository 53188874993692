export interface INewAddress {
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2: string
  addressType: string,
  city: string,
  country: string,
  customerId: number,
  id: number,
  info: string,
  isActive: boolean,
  state: string,
  updatedAt: string,
  zip: number,
}

export interface INewAddressPayload {
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2: string
  addressType: string,
  customerId: number,
  city: string,
  state: string,
  zip: number
}

export class NewAddress {
  newAddress = {} as INewAddress;
  constructor(newAddress: INewAddress) {
    this.newAddress = newAddress;
  }
}
