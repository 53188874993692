/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { get } from 'lodash';

import { CountryCodes, CurrencyCodes } from '../constants/Common';
import { PermissionApiStructure } from '../feature/auth/models/UserProfile';
import { ICartInfluencer, ISalesPerson } from '../feature/cart/models/UpdateCart';
import { IAddress } from '../models/Address';

export const formatCurrency = (amount?: number | string, country?: CountryCodes) => {
  if (!amount) return country ? `${CurrencyCodes[country]} 0` : '0';
  const amountString: string = typeof amount === 'number' ? amount.toString() : amount;
  const [numeric, decimal] = amountString.split('.');
  return (country ? `${CurrencyCodes[country]} ` : '')
    .concat(numeric.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,'))
    .concat(decimal ? `.${decimal}` : '');
};

/**
 *
 * @param source
 * @param idAlias
 * @param labelAlias
 */
export const generateIdLabelPairs = (source: Array<any>, idAlias: string, labelAlias: string) => source.map(
  (item) => ({ id: item[idAlias], label: item[labelAlias] }),
);

export const getApiAutoCompleteOptionData = (
  data: Array<Record<string, any>>, idKey: string, titleKey: string, subTitleKey: string, avatarSrcKey?: string,
) => (
  data.map((item) => ({
    id: get(item, idKey), title: get(item, titleKey), subTitle: get(item, subTitleKey), avatarSrc: get(item, avatarSrcKey || ''),
  }))
);

/**
 *
 * @param epochTimeInSec
 * @param format
 */
export const formatEpochTime = (epochTimeInSec?: number, format = 'DD MMM YYYY', isUtcTime?: boolean) => {
  if (!epochTimeInSec) return '-';
  if (isUtcTime) {
    dayjs.extend(utc);
    return dayjs.utc(epochTimeInSec * 1000).local().format(format);
  }
  return dayjs(epochTimeInSec * 1000).format(format);
};

/**
 *
 * @param permissions
 */
export const constructPermission = (permissions?: PermissionApiStructure[]) => {
  const formattedPermission: Record<string, any> = {};
  permissions?.forEach((permission) => {
    if (Object.keys(formattedPermission).includes(permission.level)) {
      formattedPermission[permission.level][permission.name] = {
        parentPermissionId: permission.parentPermissionId,
        hasPermission: true,
      };
    } else {
      formattedPermission[permission.level] = {
        [permission.name]: {
          parentPermissionId: permission.parentPermissionId,
          hasPermission: true,
        },
      };
    }
  });
  return formattedPermission;
};

const calcCheckSum = (gstNumber: string): string => {
  const GSTN_CODEPOINT_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let factor = 2;
  let sum = 0;
  let checkCodePoint = 0;
  const mod = GSTN_CODEPOINT_CHARS.length;

  for (let i = gstNumber.length - 2; i >= 0; i--) {
    let codePoint = -1;
    for (let j = 0; j < GSTN_CODEPOINT_CHARS.length; j++) {
      if (GSTN_CODEPOINT_CHARS[j] === gstNumber[i]) {
        codePoint = j;
      }
    }
    let digit = factor * codePoint;
    factor = factor === 2 ? 1 : 2;
    digit = Math.floor(digit / mod) + (digit % mod);
    sum += digit;
  }
  checkCodePoint = (mod - (sum % mod)) % mod;
  return GSTN_CODEPOINT_CHARS[checkCodePoint];
};

export const isValidGSTNumber = (gstNumber: string): boolean => {
  const gstinRegexPattern = /^([0-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/;
  if (!gstinRegexPattern.test(gstNumber)) {
    return false;
  }
  return gstNumber.toUpperCase()[14] === calcCheckSum(gstNumber.toUpperCase());
};

export const getConcatenatedAddress = (address?: IAddress) => (
  (address) ? (address.firstName.concat(' ', address.lastName, ', ', address.addressLine1, ', ',
    address.addressLine2, (address.addressLine2 === '') ? '' : ', ', address.city, ', ',
    address.state, ', ', address.zip.toString())) : ''
);

export const getReferralFormattedDetails = (influencer?: ICartInfluencer, salespersons?: ISalesPerson[]) => {
  let formattedString = '';
  let extraPersonCount = 0;
  if (salespersons?.length) {
    formattedString = salespersons.slice(0, 2).map((salesperson) => salesperson.userName).join(', ');
    if (salespersons?.length > 2) {
      extraPersonCount = salespersons?.length - 2;
    }
  }
  if (influencer?.influencerAliasId) {
    if (salespersons?.length && salespersons?.length > 1) {
      extraPersonCount += 1;
    } else {
      formattedString = `${formattedString}${formattedString && ' & '} ${influencer.name}`;
    }
  }
  if (extraPersonCount) {
    formattedString = `${formattedString} & +${extraPersonCount}`;
  }
  if (formattedString?.trim() === '') formattedString = '-';
  return formattedString || '-';
};

export const getLocalStorageValue = (key: string) => localStorage.getItem(key);

export const setLocalStorageValue = (key: string, value: any) => {
  if (typeof value === 'string') return localStorage.setItem(key, value);
  return localStorage.setItem(key, JSON.stringify(value));
};

const getMultiplier = (a: number, b: number) => {
  const precisionA = a.toString().split('.')[1]?.length || 0;
  const precisionB = b.toString().split('.')[1]?.length || 0;
  return 10 ** Math.max(precisionA, precisionB);
};

export const addDecimals = (a: number, b: number) => {
  const multiplier = getMultiplier(a, b);
  return (a * multiplier + b * multiplier) / multiplier;
};

export const subtractDecimals = (a: number, b: number) => {
  const multiplier = getMultiplier(a, b);
  return (a * multiplier - b * multiplier) / multiplier;
};

export const hasRolePermission = (roles: string[], reqRolePermission?: string[]) => {
  if (!roles || Object.keys(roles).length === 0) return false;
  if (!reqRolePermission) return true;
  return roles.some((item) => reqRolePermission?.includes(item));
};
