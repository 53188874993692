import { IErrorActionData } from '../../../models/Error';

export interface IInventoryDetails {
  erpWarehouseName: string;
  erpWarehouseCode: string;
  warehouseAliasId: string;
  quantityAvailable: number;
  status: string;
  baseUomQuantity: number;
  baseUomName: string;
}

export interface IInventoryData {
  inventoryInfo: IInventoryDetails[],
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class InventoryData {
  inventoryData: Record<string, IInventoryData> = {};
  constructor(inventoryData: Record<string, IInventoryData>) {
    this.inventoryData = inventoryData;
  }
}

export const addInventory = (existingInventory: Record<string, IInventoryData>,
  skuId: string, newInventoryData: IInventoryData) => (
  { ...existingInventory, [skuId]: newInventoryData }
);
