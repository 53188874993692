import { Reducer } from 'redux';

import { UploadDesignActionTypes, UploadDesignActions } from '../actions/UploadDesign';
import { IErrorActionData } from '../../../models/Error';

export interface IUploadDesignState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialUploadDesignState: IUploadDesignState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
};

const UploadDesignReducer: Reducer<IUploadDesignState, UploadDesignActions> = (
  state = initialUploadDesignState,
  action: UploadDesignActions,
): IUploadDesignState => {
  switch (action.type) {
    case UploadDesignActionTypes.UPLOAD_DESIGN:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case UploadDesignActionTypes.UPLOAD_DESIGN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case UploadDesignActionTypes.UPLOAD_DESIGN_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case UploadDesignActionTypes.UPLOAD_DESIGN_RESET:
      return initialUploadDesignState;

    default:
      return state;
  }
};

export default UploadDesignReducer;
