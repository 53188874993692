import { Reducer } from 'redux';
import { SaveProgressActionTypes, SaveProgressActions } from '../actions/SaveProgress';
import { IErrorActionData } from '../../../models/Error';

export interface ISaveProgressState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialSaveProgressState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ISaveProgressState;

const SaveProgressReducer: Reducer<ISaveProgressState, SaveProgressActions> = (
  state = initialSaveProgressState,
  action: SaveProgressActions,
): ISaveProgressState => {
  switch (action.type) {
    case SaveProgressActionTypes.SAVE_PROGRESS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };
    case SaveProgressActionTypes.SAVE_PROGRESS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }
    case SaveProgressActionTypes.SAVE_PROGRESS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };
    case SaveProgressActionTypes.SAVE_PROGRESS_RESET:
      return initialSaveProgressState;
    default:
      return state;
  }
};

export default SaveProgressReducer;
