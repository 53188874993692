import { Reducer } from 'redux';

import { CustomerDetailsList, ICustomerDetailsList } from '../models/CustomerDetailsList';
import { GetCustomerDetailsListActions, GetCustomerDetailsListActionTypes } from '../actions/GetCustomerDetailsList';
import { IErrorActionData } from '../../../models/Error';

export interface ICustomerDetailsListState {
  customerDetailsList: CustomerDetailsList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCustomerDetailsState = {
  customerDetailsList: new CustomerDetailsList([] as ICustomerDetailsList[]),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICustomerDetailsListState;

const GetCustomerDetailsListReducer: Reducer<ICustomerDetailsListState, GetCustomerDetailsListActions> = (
  state = initialCustomerDetailsState,
  action: GetCustomerDetailsListActions,
): ICustomerDetailsListState => {
  switch (action.type) {
    case GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        customerDetailsList: new CustomerDetailsList(action.payload.data),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCustomerDetailsListActionTypes.GET_CUSTOMER_DETAILS_LIST_RESET:
      return initialCustomerDetailsState;

    default:
      return state;
  }
};

export default GetCustomerDetailsListReducer;
