/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getDownloadCashClosureStatusSuccess,
  getDownloadCashClosureStatusFailed,
  GetDownloadCashClosureStatusAction,
  GetDownloadCashClosureStatusActionTypes,
} from '../actions/GetDownloadCashClosureStatus';
import getDownloadCashClosureStatusService from '../services/GetDownloadCashClosureStatus';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetDownloadCashClosureStatusEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDownloadCashClosureStatusActionTypes.GET_DOWNLOAD_CASH_CLOSURE_STATUS)),
  mergeMap((action: GetDownloadCashClosureStatusAction) => from(
    getDownloadCashClosureStatusService(action.payload.filter, action.payload.storeAliasId),
  ).pipe(
    map((response: any) => getDownloadCashClosureStatusSuccess({
      downloadReportStatus: response.data.getDownloadCashClosureStatus.data.status || '',
      downloadReportUrl: response.data.getDownloadCashClosureStatus.data.url || '',
    })),
    catchError((error: any) => of(
      getDownloadCashClosureStatusFailed({
        errorCode: error.errorCode || error.networkError?.statusCode || 500,
        errorMessage: error.errorMessage || error?.networkError?.result?.error_message as string,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDownloadCashClosureStatusEpic;
