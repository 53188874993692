/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import {
  GetDisplayInventoryListUrlAction,
  GetDisplayInventoryListUrlActionTypes,
  getDisplayInventoryListUrlFailure,
  getDisplayInventoryListUrlSuccess,
} from '../actions/GetDisplayInventoryListUrl';
import getDisplayInventoryListUrlService from '../services/GetDisplayInventoryListUrl';

const GetDisplayInventoryListUrlEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDisplayInventoryListUrlActionTypes.GET_DISPLAY_INVENTORY_LIST_URL)),
  mergeMap((action: GetDisplayInventoryListUrlAction) => from(
    getDisplayInventoryListUrlService(
      action.payload.storeAliasId,
      action.payload.filter,
    ),
  ).pipe(
    map((response) => getDisplayInventoryListUrlSuccess({
      data: response.data.getDisplayInventoryListUrl.data,
    })),
    catchError((error: IErrorActionData) => of(
      getDisplayInventoryListUrlFailure(
        {
          errorCode: error.errorCode || 500,
          errorMessage: error.errorMessage,
          callBack: error.callBack,
        },
      ),
    )),
  )),
);

export default GetDisplayInventoryListUrlEpic;
