import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IReturnRequestPayload } from '../models/RequestReturn';

const REQUEST_RETURN = gql`
  query RequestReturn($path: String!, $payload: any) {
    RequestReturn (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const requestReturnService = (payload: IReturnRequestPayload, shipmentAliasId: string) => client.query({
  query: REQUEST_RETURN,
  variables: {
    path: URL.requestReturn.replace('{shipmentAliasId}', shipmentAliasId),
    payload,
  },
});

export default requestReturnService;
