import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IAutoLoginActionData } from '../actions/AutoLogin';

const AUTO_LOGIN = gql`
  query autoLogin($path: String!, $data: any) {
    autoLogin(input: $data, path: $path) 
      @rest(method: "POST", path: $path, endpoint: "pheonix") {
        data
        success
    }
  }
`;

const autoLoginService = (payload: IAutoLoginActionData) => client.query(
  { query: AUTO_LOGIN, variables: { path: URL.autoLogin, data: payload } },
);

export default autoLoginService;
