import { Reducer } from 'redux';

import { SupplyOrderList, ISupplyOrderList } from '../models/SupplyOrderList';
import { IErrorActionData } from '../../../models/Error';
import { GetSupplyOrderListActions, GetSupplyOrderListActionTypes } from '../actions/GetSupplyOrderList';

export interface IGetSupplyOrderListState {
  supplyOrderList: SupplyOrderList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialSupplyOrderListState = {
  supplyOrderList: new SupplyOrderList({} as ISupplyOrderList),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetSupplyOrderListState;

const GetSupplyOrderListReducer: Reducer<IGetSupplyOrderListState, GetSupplyOrderListActions> = (
  state = initialSupplyOrderListState,
  action: GetSupplyOrderListActions,
): IGetSupplyOrderListState => {
  switch (action.type) {
    case GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST_SUCCESS: {
      return {
        ...state,
        supplyOrderList: new SupplyOrderList(action.payload.supplyOrderList),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetSupplyOrderListActionTypes.GET_SUPPLY_ORDER_LIST_RESET:
      return initialSupplyOrderListState;

    default:
      return state;
  }
};

export default GetSupplyOrderListReducer;
