import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_DISPLAY_REQUEST_LIST_URL = gql`
  query getDisplayRequestListUrl($path: String!) {
    getDisplayRequestListUrl @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
      }
  }
`;

const getDisplayRequestListUrlService = (processAliasId: string, filter?: IFilter[]) => {
  let url = URL.getDisplayRequestListUrl.replace('{processAliasId}', processAliasId);
  url = constructUrlWithParams(url, filter, undefined, undefined);
  return client.query({ query: GET_DISPLAY_REQUEST_LIST_URL, variables: { path: url } });
};

export default getDisplayRequestListUrlService;
