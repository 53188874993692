/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  autoLoginSuccess,
  autoLoginFailure,
  AutoLoginAction,
  AutoLoginActionTypes,
} from '../actions/AutoLogin';
import autoLoginService from '../services/AutoLogin';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import { getUserProfileSuccess } from '../actions/GetUserProfile';

const AutoLoginEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(AutoLoginActionTypes.AUTO_LOGIN)),
  mergeMap((action: AutoLoginAction) => from(autoLoginService(action.payload)).pipe(
    mergeMap((response: any) => {
      if (response.data.autoLogin.success) {
        return of(
          autoLoginSuccess(),
          getUserProfileSuccess({
            authorisationToken: response.data.autoLogin.token || '',
            userData: response.data.autoLogin.data,
            permissions: response.data.autoLogin.data.userPermissionAndEntityPayload?.permissions || [],
          }),
        );
      }
      return of(
        autoLoginFailure({
          errorCode: 401,
          errorMessage: 'autoLogin failed',
        }),
      );
    }),
    catchError((error: IErrorActionData) => of(
      autoLoginFailure({
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default AutoLoginEpic;
