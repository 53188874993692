import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IFilter, IPage } from '../../../utils/sortFilterFramework';
import { IAccountStatement } from '../models/GetAccountStatement';

export enum GetAccountStatementInfoActionTypes {
  GET_ACCOUNT_STATEMENT_INFO = 'GET_ACCOUNT_STATEMENT_INFO',
  GET_ACCOUNT_STATEMENT_INFO_SUCCESS = 'GET_ACCOUNT_STATEMENT_INFO_SUCCESS',
  GET_ACCOUNT_STATEMENT_INFO_FAILED = 'GET_ACCOUNT_STATEMENT_INFO_FAILED',
  GET_ACCOUNT_STATEMENT_INFO_RESET = 'GET_ACCOUNT_STATEMENT_INFO_RESET',
}

export interface IGetAccountStatementInfoActionData {
  customerAliasId: string;
  filter?: IFilter[];
  page?: IPage;
}

export interface IGetAccountStatementInfoSuccessActionData {
  accountStatement: Array<IAccountStatement>;
  customerAliasId: string;
  openingBalance: number;
  invoicedAmount: number;
  amountReceived: number;
  balanceDue: number;
  pageInfo: IPageInfo;
}

export const getAccountStatementInfo = createAction(GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO)
<IGetAccountStatementInfoActionData>();
export const getAccountStatementInfoSuccess = createAction(GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO_SUCCESS)
<IGetAccountStatementInfoSuccessActionData>();
export const getAccountStatementInfoFailure = createAction(GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO_FAILED)
<IErrorActionData>();
export const getAccountStatementInfoReset = createAction(GetAccountStatementInfoActionTypes.GET_ACCOUNT_STATEMENT_INFO_RESET)();

export type GetAccountStatementInfoAction = ActionType<typeof getAccountStatementInfo>;
type GetAccountStatementInfoSuccessAction = ActionType<typeof getAccountStatementInfoSuccess>;
type GetAccountStatementInfoFailedAction = ActionType<typeof getAccountStatementInfoFailure>;
type GetAccountStatementInfoResetAction = ActionType<typeof getAccountStatementInfoReset>;

export type GetAccountStatementInfoActions =
  | GetAccountStatementInfoAction
  | GetAccountStatementInfoSuccessAction
  | GetAccountStatementInfoFailedAction
  | GetAccountStatementInfoResetAction;
