export interface ICustomerDetailsList {
  id: number;
  phoneNumber: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  gst?: string;
  companyName?: string;
  info?: Record<string, any>,
}

export class CustomerDetailsList {
  customerDetailsList = [] as ICustomerDetailsList[];
  constructor(customerDetailsList: ICustomerDetailsList[]) {
    this.customerDetailsList = customerDetailsList;
  }
}
