import { Reducer } from 'redux';

import { CustomerAddressList } from '../../../models/AddressList';
import { GetAddressListActions, GetAddressListActionTypes } from '../actions/GetAddressList';
import { IErrorActionData } from '../../../models/Error';

export interface IAddressListState {
  addressList: CustomerAddressList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialAddressListState = {
  addressList: new CustomerAddressList([]),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IAddressListState;

const GetAddressListReducer: Reducer<IAddressListState, GetAddressListActions> = (
  state = initialAddressListState,
  action: GetAddressListActions,
): IAddressListState => {
  switch (action.type) {
    case GetAddressListActionTypes.GET_ADDRESS_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetAddressListActionTypes.GET_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        addressList: new CustomerAddressList(action.payload.data),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetAddressListActionTypes.GET_ADDRESS_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetAddressListActionTypes.GET_ADDRESS_LIST_RESET:
      return initialAddressListState;

    default:
      return state;
  }
};

export default GetAddressListReducer;
