import { Reducer } from 'redux';

import { addPresignedUrlToExisting, ShipmentInvoiceUrlData } from '../models/GetShipmentInvoiceUrl';
import { GetShipmentInvoiceUrlActionTypes, GetShipmentInvoiceUrlActions } from '../actions/GetShipmentInvoiceUrl';
import { IErrorActionData } from '../../../models/Error';

export interface IGetShipmentInvoiceUrlState {
  shipmentInvoiceUrlData: ShipmentInvoiceUrlData;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProformaInvoiceUrlState = {
  shipmentInvoiceUrlData: new ShipmentInvoiceUrlData({}),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetShipmentInvoiceUrlState;

const GetShipmentInvoiceUrlReducer: Reducer<IGetShipmentInvoiceUrlState, GetShipmentInvoiceUrlActions> = (
  state = initialProformaInvoiceUrlState,
  action: GetShipmentInvoiceUrlActions,
): IGetShipmentInvoiceUrlState => {
  switch (action.type) {
    case GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL: {
      const updatedShipmentInvoiceData = addPresignedUrlToExisting(
        state.shipmentInvoiceUrlData.shipmentInvoiceUrlData,
        action.payload.invoiceAliasId,
        { isProcessing: true, isSuccess: false },
      );
      return { ...state, shipmentInvoiceUrlData: new ShipmentInvoiceUrlData(updatedShipmentInvoiceData) };
    }

    case GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL_SUCCESS: {
      const updatedShipmentInvoiceData = addPresignedUrlToExisting(
        state.shipmentInvoiceUrlData.shipmentInvoiceUrlData,
        action.payload.invoiceAliasId,
        { isProcessing: false, isSuccess: true, presignedInvoiceUrl: action.payload.invoicePresignedUrl },
      );
      return { ...state, shipmentInvoiceUrlData: new ShipmentInvoiceUrlData(updatedShipmentInvoiceData) };
    }

    case GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL_FAILED: {
      const updatedShipmentInvoiceData = addPresignedUrlToExisting(
        state.shipmentInvoiceUrlData.shipmentInvoiceUrlData,
        action.payload.invoiceAliasId,
        {
          isProcessing: false,
          isSuccess: false,
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, shipmentInvoiceUrlData: new ShipmentInvoiceUrlData(updatedShipmentInvoiceData) };
    }

    case GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL_RESET:
      return initialProformaInvoiceUrlState;

    default:
      return state;
  }
};

export default GetShipmentInvoiceUrlReducer;
