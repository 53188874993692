export interface ICreditNote {
  creditNoteAliasId: string;
  erpCreditNoteId: string;
  amount: number;
  creditNoteDate: number;
}

export interface IWalletDetails {
  walletAliasId: string;
  amount: number;
}

export interface IGetCustomerCreditBalanceDetails {
  totalAmount: number;
  creditNotes: ICreditNote[];
  walletDetails: IWalletDetails;
  outstandingReceivables: number;
}

export class GetCustomerCreditBalanceDetails {
  getCustomerCreditBalanceDetails = {} as IGetCustomerCreditBalanceDetails;
  constructor(getCustomerCreditBalanceDetails: IGetCustomerCreditBalanceDetails) {
    this.getCustomerCreditBalanceDetails = getCustomerCreditBalanceDetails;
  }
}
