import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IInitiateCartPaymentActionData } from '../models/InitiateCartPayment';

const INITIATE_CART_PAYMENT = gql`
  query initiateCartPayment($path: String!, $payload: any) {
    initiateCartPayment (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
    }
  }
`;

const initiateCartPaymentService = (payload: IInitiateCartPaymentActionData) => {
  const url = URL.initiateCartPayment;
  return client.query({
    query: INITIATE_CART_PAYMENT,
    variables: {
      path: url,
      payload,
    },
  });
};

export default initiateCartPaymentService;
