import AddCartItemEpic from './AddCartItem';
import AddCartItemDiscountEpic from './AddCartItemDiscount';
import CancelCartEpic from './CancelCart';
import CreateAddressEpic from './CreateAddress';
import CreateCartEpic from './CreateCart';
import DeleteAddressEpic from './DeleteAddress';
import DeleteCartItemEpic from './DeleteCartItem';
import DeleteCartItemDiscountEpic from './DeleteCartItemDiscount';
import GetAddressListEpic from './GetAddressList';
import GetCartItemListEpic from './GetCartItemList';
import GetCartStateEpic from './GetCartState';
import GetCartSummaryEpic from './GetCartSummary';
import GetSavedCartItemListEpic from './GetSavedCartItemList';
import InitiateCartPaymentEpic from './InitiateCartPayment';
import SaveCartEpic from './SaveCart';
import UpdateAddressEpic from './UpdateAddress';
import UpdateCartEpic from './UpdateCart';
import UpdateCartItemEpic from './UpdateCartItem';

const CartEpics = [
  CreateCartEpic,
  AddCartItemEpic,
  AddCartItemDiscountEpic,
  CancelCartEpic,
  CreateAddressEpic,
  DeleteAddressEpic,
  DeleteCartItemEpic,
  DeleteCartItemDiscountEpic,
  GetAddressListEpic,
  GetCartItemListEpic,
  GetCartStateEpic,
  GetCartSummaryEpic,
  GetSavedCartItemListEpic,
  InitiateCartPaymentEpic,
  SaveCartEpic,
  UpdateAddressEpic,
  UpdateCartEpic,
  UpdateCartItemEpic,
];

export default CartEpics;
