import { Reducer } from 'redux';

import { GetOpeningCashBalanceDetailsActions, GetOpeningCashBalanceDetailsActionTypes } from '../actions/GetOpeningCashBalanceDetails';
import { IErrorActionData } from '../../../models/Error';
import { IGetOpeningCashBalanceDetails, GetOpeningCashBalanceDetails } from '../models/GetOpeningCashBalanceDetails';

export interface IGetOpeningCashBalanceDetailsState {
  getOpeningCashBalanceDetails: GetOpeningCashBalanceDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetOpeningCashBalanceDetailsState = {
  getOpeningCashBalanceDetails: new GetOpeningCashBalanceDetails({} as IGetOpeningCashBalanceDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetOpeningCashBalanceDetailsState;

const GetOpeningCashBalanceDetailsReducer: Reducer<IGetOpeningCashBalanceDetailsState, GetOpeningCashBalanceDetailsActions> = (
  state = initialGetOpeningCashBalanceDetailsState,
  action: GetOpeningCashBalanceDetailsActions,
): IGetOpeningCashBalanceDetailsState => {
  switch (action.type) {
    case GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS_SUCCESS:
      return {
        ...state,
        getOpeningCashBalanceDetails: new GetOpeningCashBalanceDetails(action.payload.getOpeningCashBalanceDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS_RESET:
      return initialGetOpeningCashBalanceDetailsState;

    default:
      return state;
  }
};

export default GetOpeningCashBalanceDetailsReducer;
