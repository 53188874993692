import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IFilter, IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST = gql`
  query getDisplayInventoryRecommendationList($path: String!) {
    getDisplayInventoryRecommendationList @rest(method: "GET", path: $path, endpoint: "pheonix") {
        data
        pageInfo
      }
  }
`;

const getDisplayInventoryRecommendationListService = (processAliasId: string, filter?: IFilter[], page?: IPage) => {
  let url = URL.getDisplayInventoryRecommendationList.replace('{processAliasId}', processAliasId);
  url = constructUrlWithParams(url, filter, undefined, page);
  return client.query({ query: GET_DISPLAY_INVENTORY_RECOMMENDATION_LIST, variables: { path: url } });
};

export default getDisplayInventoryRecommendationListService;
