import { Reducer } from 'redux';

import { StartProcessingSupplyOrderActionTypes, StartProcessingSupplyOrderActions } from '../actions/StartProcessingSupplyOrder';
import { IErrorActionData } from '../../../models/Error';

export interface IStartProcessingSupplyOrderState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialStartProcessingSupplyOrderState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IStartProcessingSupplyOrderState;

const StartProcessingSupplyOrderReducer: Reducer<IStartProcessingSupplyOrderState, StartProcessingSupplyOrderActions> = (
  state = initialStartProcessingSupplyOrderState,
  action: StartProcessingSupplyOrderActions,
): IStartProcessingSupplyOrderState => {
  switch (action.type) {
    case StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case StartProcessingSupplyOrderActionTypes.START_PROCESSING_SUPPLY_ORDER_RESET:
      return initialStartProcessingSupplyOrderState;

    default:
      return state;
  }
};

export default StartProcessingSupplyOrderReducer;
