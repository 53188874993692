import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IUpdateProjectDetailsPayload } from '../models/UpdateProjectDetails';

const UPDATE_PROJECT_DETAILS = gql`
query updateProjectDetails($path: String!, $payload: any) {
  updateProjectDetails(path: $path, payload: $payload) 
    @rest(method: "PUT", path: $path, bodyKey: "payload", endpoint: "photon") {
      data
    }
  }
`;

const updateProjectDetailsService = (projectId: string, payload: IUpdateProjectDetailsPayload) => {
  const url = URL.updateProjectDetails.replace('{projectId}', projectId);
  return client.query({ query: UPDATE_PROJECT_DETAILS, variables: { path: url, payload } });
};

export default updateProjectDetailsService;
