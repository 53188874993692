import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IVerifyOtpActionData } from '../actions/VerifyOTP';

const LOGIN = gql`
  query verifyOtp($path: String!, $data: any) {
    verifyOtp(input: $data, path: $path) 
      @rest(method: "POST", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

// const verifyOtpService = (payload: IVerifyOtpActionData) => client.query({
//   query: LOGIN,
//   variables: { data: payload, path: URL.verifyOtp },
//   context: {
//     headers: {
//       'App-ID': process.env.REACT_APP_PHOTON_WEB_APP_URL,
//     },
//   },
// });

const verifyOtpService = (payload: IVerifyOtpActionData) => client.query(
  { query: LOGIN, variables: { data: payload, path: URL.verifyOtp } },
);

export default verifyOtpService;
