import { Reducer } from 'redux';

import { IShipmentDetails, ShipmentDetails } from '../models/ShipmentDetails';
import { GetShipmentDetailsActionTypes, GetShipmentDetailsActions } from '../actions/GetShipmentDetails';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IGetShipmentDetailsState {
  shipmentDetails: ShipmentDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialShipmentDetailsState = {
  shipmentDetails: new ShipmentDetails([]),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetShipmentDetailsState;

const GetShipmentDetailsReducer: Reducer<IGetShipmentDetailsState, GetShipmentDetailsActions> = (
  state = initialShipmentDetailsState,
  action: GetShipmentDetailsActions,
): IGetShipmentDetailsState => {
  switch (action.type) {
    case GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        shipmentDetails: new ShipmentDetails(action.payload.shipmentDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetShipmentDetailsActionTypes.GET_SHIPMENT_DETAILS_RESET:
      return initialShipmentDetailsState;

    default:
      return state;
  }
};

export default GetShipmentDetailsReducer;
