import { Reducer } from 'redux';

import { IEDCMachineList, EDCMachineList } from '../models/EDCMachineList';
import { GetEDCMachineListActionTypes, GetEDCMachineListActions } from '../actions/GetEDCMachineList';
import { IErrorActionData } from '../../../models/Error';

export interface IGetEDCMachineListState {
  edcMachineList: EDCMachineList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGetEDCMachineListState = {
  edcMachineList: new EDCMachineList({} as IEDCMachineList),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetEDCMachineListState;

const GetEDCMachineListReducer: Reducer<IGetEDCMachineListState, GetEDCMachineListActions> = (
  state = initialGetEDCMachineListState,
  action: GetEDCMachineListActions,
): IGetEDCMachineListState => {
  switch (action.type) {
    case GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST_SUCCESS: {
      return {
        ...state,
        edcMachineList: new EDCMachineList(action.payload.data),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetEDCMachineListActionTypes.GET_EDC_MACHINE_LIST_RESET:
      return initialGetEDCMachineListState;

    default:
      return state;
  }
};

export default GetEDCMachineListReducer;
