import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IStoreDetails } from '../models/StoreDetails';

export enum GetStoreDetailsActionTypes {
  GET_STORE_DETAILS = 'GET_STORE_DETAILS',
  GET_STORE_DETAILS_SUCCESS = 'GET_STORE_DETAILS_SUCCESS',
  GET_STORE_DETAILS_FAILED = 'GET_STORE_DETAILS_FAILED',
  GET_STORE_DETAILS_RESET = 'GET_STORE_DETAILS_RESET',
}

interface IGetStoreDetailsActionData {
  storeAliasId: string;
}
interface IGetStoreDetailsSuccessActionData {
  storeDetails: IStoreDetails;
}

export const getStoreDetails = createAction(GetStoreDetailsActionTypes.GET_STORE_DETAILS)<IGetStoreDetailsActionData>();
export const getStoreDetailsSuccess = createAction(GetStoreDetailsActionTypes.GET_STORE_DETAILS_SUCCESS)
<IGetStoreDetailsSuccessActionData>();
export const getStoreDetailsFailed = createAction(GetStoreDetailsActionTypes.GET_STORE_DETAILS_FAILED)
<IErrorActionData>();
export const getStoreDetailsReset = createAction(GetStoreDetailsActionTypes.GET_STORE_DETAILS_RESET)();

export type GetStoreDetailsAction = ActionType<typeof getStoreDetails>;
type GetStoreDetailsSuccessAction = ActionType<typeof getStoreDetailsSuccess>;
type GetStoreDetailsFailedAction = ActionType<typeof getStoreDetailsFailed>;
type GetStoreDetailsResetAction = ActionType<typeof getStoreDetailsReset>;

export type GetStoreDetailsActions =
  | GetStoreDetailsAction
  | GetStoreDetailsSuccessAction
  | GetStoreDetailsFailedAction
  | GetStoreDetailsResetAction;
