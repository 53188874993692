/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getShipmentInvoiceUrlSuccess,
  getShipmentInvoiceUrlFailure,
  GetShipmentInvoiceUrlAction,
  GetShipmentInvoiceUrlActionTypes,
} from '../actions/GetShipmentInvoiceUrl';
import getShipmentInvoiceUrlService from '../services/GetShipmentInvoiceUrl';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetShipmentInvoiceUrlEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetShipmentInvoiceUrlActionTypes.GET_SHIPMENT_INVOICE_URL)),
  mergeMap((action: GetShipmentInvoiceUrlAction) => from(
    getShipmentInvoiceUrlService(action.payload.invoiceAliasId),
  ).pipe(
    map((response) => getShipmentInvoiceUrlSuccess({
      invoicePresignedUrl: response.data.getShipmentInvoiceUrl.data.preSignedUrl || '',
      invoiceAliasId: action.payload.invoiceAliasId,
    })),
    catchError((error: IErrorActionData) => of(
      getShipmentInvoiceUrlFailure({
        invoiceAliasId: action.payload.invoiceAliasId,
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetShipmentInvoiceUrlEpic;
