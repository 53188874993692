import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IGetOpeningCashBalanceDetails } from '../models/GetOpeningCashBalanceDetails';

export enum GetOpeningCashBalanceDetailsActionTypes {
  GET_OPENING_CASH_BALANCE_DETAILS = 'GET_OPENING_CASH_BALANCE_DETAILS',
  GET_OPENING_CASH_BALANCE_DETAILS_SUCCESS = 'GET_OPENING_CASH_BALANCE_DETAILS_SUCCESS',
  GET_OPENING_CASH_BALANCE_DETAILS_FAILED = 'GET_OPENING_CASH_BALANCE_DETAILS_FAILED',
  GET_OPENING_CASH_BALANCE_DETAILS_RESET = 'GET_OPENING_CASH_BALANCE_DETAILS_RESET',
}

export interface IGetOpeningCashBalanceDetailsActionData {
  storeAliasId: string;
}

export interface IGetOpeningCashBalanceDetailsSuccessActionData {
  getOpeningCashBalanceDetails: IGetOpeningCashBalanceDetails;
}

export const
  getOpeningCashBalanceDetails = createAction(GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS)
  <IGetOpeningCashBalanceDetailsActionData>();
export const
  getOpeningCashBalanceDetailsSuccess = createAction(GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS_SUCCESS)
  <IGetOpeningCashBalanceDetailsSuccessActionData>();
export const
  getOpeningCashBalanceDetailsFailed = createAction(GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS_FAILED)
  <IErrorActionData>();
export const
  getOpeningCashBalanceDetailsReset = createAction(GetOpeningCashBalanceDetailsActionTypes.GET_OPENING_CASH_BALANCE_DETAILS_RESET)();

export type GetOpeningCashBalanceDetailsAction = ActionType<typeof getOpeningCashBalanceDetails>;
type GetOpeningCashBalanceDetailsSuccessAction = ActionType<typeof getOpeningCashBalanceDetailsSuccess>;
type GetOpeningCashBalanceDetailsFailedAction = ActionType<typeof getOpeningCashBalanceDetailsFailed>;
type GetOpeningCashBalanceDetailsResetAction = ActionType<typeof getOpeningCashBalanceDetailsReset>;

export type GetOpeningCashBalanceDetailsActions =
  | GetOpeningCashBalanceDetailsAction
  | GetOpeningCashBalanceDetailsSuccessAction
  | GetOpeningCashBalanceDetailsFailedAction
  | GetOpeningCashBalanceDetailsResetAction;
