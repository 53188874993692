/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCancelSkuListSuccess,
  getCancelSkuListFailure,
  GetCancelSkuListAction,
  GetCancelSkuListActionTypes,
} from '../actions/GetCancelSkuList';
import getCancelSkuListService from '../services/GetCancelSkuList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCancelSkuListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCancelSkuListActionTypes.GET_CANCEL_SKU_LIST)),
  mergeMap((action: GetCancelSkuListAction) => from(
    getCancelSkuListService(
      action.payload.supplyOrderAliasId,
    ),
  ).pipe(
    map((response) => getCancelSkuListSuccess({
      cancelSkuListDetails: response.data.getCancelSkuList.data ?? {},
    })),
    catchError((error: IErrorActionData) => of(
      getCancelSkuListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCancelSkuListEpic;
