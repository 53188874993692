import { Reducer } from 'redux';

import { IErrorActionData } from '../../../models/Error';
import { LogoutActions, LogoutActionTypes } from '../actions/Logout';

export interface ILogoutState {
  isSuccess: boolean;
  isProcessing: boolean;
  error?: IErrorActionData;
}

const initialLogoutState: ILogoutState = {
  isSuccess: false,
  isProcessing: false,
  error: undefined,
};

const LogoutReducer: Reducer<ILogoutState, LogoutActions> = (
  state = initialLogoutState,
  action: LogoutActions,
): ILogoutState => {
  switch (action.type) {
    case LogoutActionTypes.LOGOUT_ACTION:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case LogoutActionTypes.LOGOUT_ACTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case LogoutActionTypes.LOGOUT_ACTION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case LogoutActionTypes.LOGOUT_ACTION_RESET:
      return initialLogoutState;

    default:
      return state;
  }
};

export default LogoutReducer;
