/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getDownloadLedgerStatementStatusSuccess,
  getDownloadLedgerStatementStatusFailed,
  GetDownloadLedgerStatementStatusAction,
  GetDownloadLedgerStatementStatusActionTypes,
} from '../actions/GetDownloadLedgerStatementStatus';
import getDownloadLedgerStatementStatusService from '../services/GetDownloadLedgerStatementStatus';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetDownloadLedgerStatementStatusEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDownloadLedgerStatementStatusActionTypes.GET_DOWNLOAD_LEDGER_STATEMENT_STATUS)),
  mergeMap((action: GetDownloadLedgerStatementStatusAction) => from(
    getDownloadLedgerStatementStatusService(action.payload.requestId),
  ).pipe(
    map((response: any) => getDownloadLedgerStatementStatusSuccess({
      message: response.data.getDownloadLedgerStatementStatus.data.message || '',
      url: response.data.getDownloadLedgerStatementStatus.data.url || '',
    })),
    catchError((error: any) => of(
      getDownloadLedgerStatementStatusFailed({
        errorCode: error.errorCode || error.networkError?.statusCode || 500,
        errorMessage: error.errorMessage || error?.networkError?.result?.error_message as string,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDownloadLedgerStatementStatusEpic;
