import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum GetQuotationUrlActionTypes {
  GET_QUOTATION_URL = 'GET_QUOTATION_URL',
  GET_QUOTATION_URL_SUCCESS = 'GET_QUOTATION_URL_SUCCESS',
  GET_QUOTATION_URL_FAILED = 'GET_QUOTATION_URL_FAILED',
  GET_QUOTATION_URL_RESET = 'GET_QUOTATION_URL_RESET',
}

export interface IGetQuotationUrlActionData {
  quotationAliasId: string;
}

export interface IGetQuotationUrlSuccessActionData {
  quotationAliasId: string;
  quotationPresignedUrl: string;
}
interface IGetQuotationUrlErrorActionData extends IErrorActionData {
  quotationAliasId: string;
}

export const getQuotationUrl = createAction(GetQuotationUrlActionTypes.GET_QUOTATION_URL)
<IGetQuotationUrlActionData>();
export const getQuotationUrlSuccess = createAction(GetQuotationUrlActionTypes.GET_QUOTATION_URL_SUCCESS)
<IGetQuotationUrlSuccessActionData>();
export const getQuotationUrlFailure = createAction(GetQuotationUrlActionTypes.GET_QUOTATION_URL_FAILED)
<IGetQuotationUrlErrorActionData>();
export const getQuotationUrlReset = createAction(GetQuotationUrlActionTypes.GET_QUOTATION_URL_RESET)();

export type GetQuotationUrlAction = ActionType<typeof getQuotationUrl>;
type GetQuotationUrlSuccessAction = ActionType<typeof getQuotationUrlSuccess>;
type GetQuotationUrlFailureAction = ActionType<typeof getQuotationUrlFailure>;
type GetQuotationUrlResetAction = ActionType<typeof getQuotationUrlReset>;

export type GetQuotationUrlActions = GetQuotationUrlAction
| GetQuotationUrlSuccessAction
| GetQuotationUrlFailureAction
| GetQuotationUrlResetAction;
