import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { constructUrlWithParams, IFilter } from '../../../utils/sortFilterFramework';

const GET_DOWNLOAD_CASH_CLOSURE_STATUS = gql`
  query getDownloadCashClosureStatus($path: String!) {
    getDownloadCashClosureStatus @rest(method: "GET", path: $path, endpoint: "pheonix") {
      data
    }
  }
`;

const getDownloadCashClosureStatusService = (filter: IFilter[], storeAliasId: string) => {
  const url = constructUrlWithParams(URL.getDownloadCashClosureStatus.replace('{storeAliasId}', storeAliasId), filter);
  return client.query({ query: GET_DOWNLOAD_CASH_CLOSURE_STATUS, variables: { path: url } });
};

export default getDownloadCashClosureStatusService;
