import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ICancelOrderPayload } from '../actions/CancelOrder';

const CANCEL_ORDER = gql`
  query cancelOrder($path: String!, $data: any) {
    cancelOrder(input: $data, path: $path) 
      @rest(method: "PUT", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const cancelOrderService = (orderReferenceId: string, payload: ICancelOrderPayload) => {
  const url = URL.cancelOrder.replace('{orderReferenceId}', orderReferenceId);
  return client.query({ query: CANCEL_ORDER, variables: { path: url, data: payload } });
};

export default cancelOrderService;
