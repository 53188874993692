import { Reducer } from 'redux';

import { IOrderPaymentDetails, OrderPaymentDetails } from '../models/OrderPaymentDetails';
import { GetOrderPaymentDetailsActionTypes, GetOrderPaymentDetailsActions } from '../actions/GetOrderPaymentDetails';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';

export interface IGetOrderPaymentDetailsState {
  orderPaymentDetails: OrderPaymentDetails;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialOrderPaymentDetailsState = {
  orderPaymentDetails: new OrderPaymentDetails({} as IOrderPaymentDetails),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetOrderPaymentDetailsState;

const GetOrderPaymentDetailsReducer: Reducer<IGetOrderPaymentDetailsState, GetOrderPaymentDetailsActions> = (
  state = initialOrderPaymentDetailsState,
  action: GetOrderPaymentDetailsActions,
): IGetOrderPaymentDetailsState => {
  switch (action.type) {
    case GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        orderPaymentDetails: new OrderPaymentDetails(action.payload.orderPaymentDetails),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetOrderPaymentDetailsActionTypes.GET_ORDER_PAYMENT_DETAILS_RESET:
      return initialOrderPaymentDetailsState;

    default:
      return state;
  }
};

export default GetOrderPaymentDetailsReducer;
