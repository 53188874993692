/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  downloadCashClosureReportSuccess,
  downloadCashClosureReportFailure,
  DownloadCashClosureReportAction,
  DownloadCashClosureReportActionTypes,
} from '../actions/DownloadCashClosureReport';
import downloadCashClosureReportService from '../services/DownloadCashClosureReport';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const DownloadCashClosureReportEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(DownloadCashClosureReportActionTypes.DOWNLOAD_CASH_CLOSURE_REPORT)),
  mergeMap((action: DownloadCashClosureReportAction) => from(
    downloadCashClosureReportService(
      action.payload.storeAliasId,
      action.payload.payloadDetails,
    ),
  ).pipe(
    map((response) => downloadCashClosureReportSuccess({
      requestId: response.data.downloadCashClosureReport.data.requestId,
    })),
    catchError((error: IErrorActionData) => of(
      downloadCashClosureReportFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default DownloadCashClosureReportEpic;
