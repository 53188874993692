/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCartItemListSuccess,
  getCartItemListFailure,
  GetCartItemListAction,
  GetCartItemListActionTypes,
} from '../actions/GetCartItemList';
import getCartItemListService from '../services/GetCartItemList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetCartItemListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCartItemListActionTypes.GET_CART_ITEM_LIST)),
  mergeMap((action: GetCartItemListAction) => from(
    getCartItemListService(
      action.payload.cartAliasId,
    ),
  ).pipe(
    map((response) => getCartItemListSuccess({
      cartItemList: response.data.getCartItemList.data || [],
    })),
    catchError((error: IErrorActionData) => of(
      getCartItemListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCartItemListEpic;
