/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  requestReturnSuccess,
  requestReturnFailure,
  RequestReturnAction,
  RequestReturnActionTypes,
} from '../actions/RequestReturn';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import RequestReturnService from '../services/RequestReturn';

const RequestReturnEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(RequestReturnActionTypes.REQUEST_RETURN)),
  mergeMap((action: RequestReturnAction) => from(
    RequestReturnService(action.payload.requestReturnDetails, action.payload.shipmentAliasId),
  ).pipe(
    map((response) => requestReturnSuccess()),
    catchError((error: IErrorActionData) => of(
      requestReturnFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default RequestReturnEpic;
