import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const CREATE_INFLUENCER = gql`
  query createInfluencer($path: String!, $payload: any) {
    createInfluencer (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "pheonix") {
        data
      }
  }
`;

const createInfluencerService = (name: string, phoneNumber: string, type: string, languagePreference: string) => client.query(
  {
    query: CREATE_INFLUENCER,
    variables: {
      path: URL.createInfluencer,
      payload: {
        name, phoneNumber, type, languagePreference,
      },
    },
  },
);

export default createInfluencerService;
