/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  dispatchSupplyOrderSuccess,
  dispatchSupplyOrderFailure,
  DispatchSupplyOrderAction,
  DispatchSupplyOrderActionTypes,
} from '../actions/DispatchSupplyOrder';
import dispatchSupplyOrderService from '../services/DispatchSupplyOrder';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const DispatchSupplyOrderEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(DispatchSupplyOrderActionTypes.DISPATCH_SUPPLY_ORDER_ACTION)),
  mergeMap((action: DispatchSupplyOrderAction) => from(
    dispatchSupplyOrderService(action.payload.supplyOrderId),
  ).pipe(
    map((response: any) => (dispatchSupplyOrderSuccess())),
    catchError((error: any) => of(
      dispatchSupplyOrderFailure({
        errorCode: error.networkError?.statusCode || 500,
        errorMessage: error.networkError?.result?.error_message || '',
        callBack: error.callBack,
      }),
    )),
  )),
);

export default DispatchSupplyOrderEpic;
