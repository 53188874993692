import { Reducer } from 'redux';

import { CreateOrderActionTypes, CreateOrderActions } from '../actions/CreateOrder';
import { IErrorActionData } from '../../../models/Error';

export interface ICreateOrderState {
  orderAliasId: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCreateOrderState = {
  orderAliasId: '',
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICreateOrderState;

const CreateOrderReducer: Reducer<ICreateOrderState, CreateOrderActions> = (
  state = initialCreateOrderState,
  action: CreateOrderActions,
): ICreateOrderState => {
  switch (action.type) {
    case CreateOrderActionTypes.CREATE_ORDER:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case CreateOrderActionTypes.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        orderAliasId: action.payload.orderAliasId,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case CreateOrderActionTypes.CREATE_ORDER_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case CreateOrderActionTypes.CREATE_ORDER_RESET:
      return initialCreateOrderState;

    default:
      return state;
  }
};

export default CreateOrderReducer;
