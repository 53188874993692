import { ActionType, createAction } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { IDealDetails } from '../models/GetDealDetails';

export enum GetDealDetailsActionTypes {
  GET_DEAL_DETAILS = 'GET_DEAL_DETAILS',
  GET_DEAL_DETAILS_SUCCESS = 'GET_DEAL_DETAILS_SUCCESS',
  GET_DEAL_DETAILS_FAILURE = 'GET_DEAL_DETAILS_FAILURE',
  RESET_GET_DEAL_DETAILS = 'RESET_GET_DEAL_DETAILS',
}

interface IGetDealDetailsActionData {
  dealAliasId: string;
}

interface IGetDealDetailsSuccessData {
  dealDetails: IDealDetails;
}

export const getDealDetails = createAction(
  GetDealDetailsActionTypes.GET_DEAL_DETAILS,
)<IGetDealDetailsActionData>();
export const getDealDetailsSuccess = createAction(
  GetDealDetailsActionTypes.GET_DEAL_DETAILS_SUCCESS,
)<IGetDealDetailsSuccessData>();
export const getDealDetailsFailure = createAction(
  GetDealDetailsActionTypes.GET_DEAL_DETAILS_FAILURE,
)<IErrorActionData>();
export const resetGetDealDetails = createAction(GetDealDetailsActionTypes.RESET_GET_DEAL_DETAILS)();

export type GetDealDetailsAction = ActionType<typeof getDealDetails>;
type GetDealDetailsSuccessAction = ActionType<typeof getDealDetailsSuccess>;
type GetDealDetailsFailureAction = ActionType<typeof getDealDetailsFailure>;
type ResetGetDealDetailsAction = ActionType<typeof resetGetDealDetails>;

export type GetDealDetailsActions =
  | GetDealDetailsAction
  | GetDealDetailsSuccessAction
  | GetDealDetailsFailureAction
  | ResetGetDealDetailsAction;
