import { Reducer } from 'redux';

import { GenerateInvoiceActionTypes, GenerateInvoiceActions } from '../actions/GenerateInvoice';
import { IErrorActionData } from '../../../models/Error';

export interface IGenerateInvoiceState {
  invoiceId?: string;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialGenerateInvoiceState = {
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGenerateInvoiceState;

const GenerateInvoiceReducer: Reducer<IGenerateInvoiceState, GenerateInvoiceActions> = (
  state = initialGenerateInvoiceState,
  action: GenerateInvoiceActions,
): IGenerateInvoiceState => {
  switch (action.type) {
    case GenerateInvoiceActionTypes.GENERATE_INVOICE:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GenerateInvoiceActionTypes.GENERATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceId: action.payload.invoiceAliasId,
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GenerateInvoiceActionTypes.GENERATE_INVOICE_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GenerateInvoiceActionTypes.GENERATE_INVOICE_RESET:
      return initialGenerateInvoiceState;

    default:
      return state;
  }
};

export default GenerateInvoiceReducer;
